import config from '../../config/config';
import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import { ChecklistDTO, ChecklistExecutionItemDTO } from '../../stores/checklist/Checklist';

/**
 * Projects api methods
 */
export default class ChecklistApi {
	static async saveAnswer(
		checklistId: number,
		executionId: number,
		executionItem: Pick<
			ChecklistExecutionItemDTO,
			'checklistItemId' | 'responseText' | 'responseChecked' | 'responseOptionId'
		>
	): Promise<FetchWrapperTypedResponse<ChecklistExecutionItemDTO>> {
		const url = `//${config.hosts.api}/v1/checklist/${checklistId}/${executionId}/item/${executionItem.checklistItemId}`;
		return FetchWrapper.typedPost<ChecklistExecutionItemDTO>(url, {
			body: JSON.stringify({ executionItem }),
			requireAuth: true,
		});
	}

	static async deleteChecklist(checklistId: number): Promise<FetchWrapperTypedResponse<ChecklistDTO>> {
		const url = `//${config.hosts.api}/v1/checklist/${checklistId}`;
		return FetchWrapper.typedDelete<ChecklistDTO>(url, {
			requireAuth: true,
		});
	}

	static async createChecklistExecution(
		checklistId: number,
		projectId: number
	): Promise<FetchWrapperTypedResponse<ChecklistDTO>> {
		const url = `//${config.hosts.api}/v1/checklist/${checklistId}/add/${projectId}`;
		return FetchWrapper.typedPost<ChecklistDTO>(url, {
			requireAuth: true,
		});
	}

	static async deleteChecklistExecution(
		checklistId: number,
		executionId: number
	): Promise<FetchWrapperTypedResponse<ChecklistDTO>> {
		const url = `//${config.hosts.api}/v1/checklist/${checklistId}/execution/${executionId}`;
		return FetchWrapper.typedDelete<ChecklistDTO>(url, {
			requireAuth: true,
		});
	}

	static async getChecklists(): Promise<FetchWrapperTypedResponse<ChecklistDTO[]>> {
		const url = `//${config.hosts.api}/v1/checklist`;
		return FetchWrapper.typedGet<ChecklistDTO[]>(url, {
			requireAuth: true,
		});
	}

	static async createChecklist(checklist: ChecklistDTO): Promise<FetchWrapperTypedResponse<ChecklistDTO>> {
		const url = `//${config.hosts.api}/v1/checklist`;
		return FetchWrapper.typedPost<ChecklistDTO>(url, {
			body: JSON.stringify({ checklist }),
			requireAuth: true,
		});
	}

	static async updateChecklist(checklist: ChecklistDTO): Promise<FetchWrapperTypedResponse<ChecklistDTO>> {
		const url = `//${config.hosts.api}/v1/checklist/${checklist.id}`;
		return FetchWrapper.put(url, {
			body: JSON.stringify({ checklist }),
			requireAuth: true,
		});
	}
}
