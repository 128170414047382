import * as React from 'react';

import { Paper, Stack, Typography } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { observer } from 'mobx-react';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type Props = {
	headline?: string;
	interval?: number;
	hideButtons?: boolean;
	children: React.ReactNode[];
	numChildrenPerPage?: number;
};

const AutoplayCarousel = observer(
	class AutoplayCarousel extends React.Component<Props> {
		activeStep: number = 0;
		prevActiveStep: number = 0;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				activeStep: observable,
				maxSteps: computed,
				numChildrenPerPage: computed,
				handleStepChange: action,
				setActiveStep: action,
				handleBack: action,
				handleNext: action,
			});
		}

		get numChildrenPerPage() {
			return this.props.numChildrenPerPage ?? 1;
		}

		get maxSteps() {
			return Math.ceil(this.props.children.length / this.numChildrenPerPage);
		}

		get interval() {
			return this.props.interval ?? 10000;
		}

		get hideButtons() {
			return this.props.hideButtons ?? false;
		}

		handleStepChange = (step: number) => {
			this.setActiveStep(step);
		};

		setActiveStep = (step: number) => {
			this.activeStep = step;
		};

		handleNext = () => {
			this.prevActiveStep++;
			this.setActiveStep(this.prevActiveStep);
		};

		handleBack = () => {
			this.prevActiveStep--;
			this.setActiveStep(this.prevActiveStep);
		};

		renderChildren() {
			// if numChildrenPerPage is > 1, group the children with stack to create  pages
			const pages = [];
			for (let i = 0; i < this.props.children.length; i += this.numChildrenPerPage) {
				const page = this.props.children.slice(i, i + this.numChildrenPerPage);
				pages.push(<Box key={i}>{page}</Box>);
			}
			return pages.map((page, index) => (
				<Stack key={index} direction="column" spacing={2}>
					{page}
				</Stack>
			));
		}

		renderMobileStepper() {
			return (
				<MobileStepper
					steps={this.maxSteps}
					position="static"
					sx={{ justifyContent: this.hideButtons ? 'center' : undefined }}
					activeStep={this.activeStep}
					nextButton={
						!this.hideButtons && (
							<Button
								size="small"
								onClick={this.handleNext}
								disabled={this.activeStep === this.maxSteps - 1}
							>
								Neste
								<KeyboardArrowRight />
							</Button>
						)
					}
					backButton={
						!this.hideButtons && (
							<Button size="small" onClick={this.handleBack} disabled={this.activeStep === 0}>
								<KeyboardArrowLeft />
								Tilbake
							</Button>
						)
					}
				/>
			);
		}

		render() {
			const { headline } = this.props;
			return (
				<Box sx={{ maxWidth: '100%', flexGrow: 1, marginTop: '1rem' }}>
					{headline && (
						<Paper
							square
							elevation={0}
							sx={{
								display: 'flex',
								alignItems: 'center',
								pl: 2,
								bgcolor: 'transparent',
								marginTop: 1,
							}}
						>
							<Typography>{headline}</Typography>
						</Paper>
					)}
					{this.hideButtons && this.renderMobileStepper()}
					<AutoPlaySwipeableViews
						axis={'x'}
						index={this.activeStep}
						onChangeIndex={this.handleStepChange}
						enableMouseEvents
						interval={this.interval}
					>
						{this.renderChildren()}
					</AutoPlaySwipeableViews>
					{!this.hideButtons && this.renderMobileStepper()}
				</Box>
			);
		}
	}
);

export default AutoplayCarousel;
