import config from '../../config/config';
import { FetchWrapper } from '../../fetch/FetchWrapper';
import ProjectTemplate, { ProjectUrgency } from '../../stores/TemplateStore';

export type ProjectUrgencyDTO = 'minimal' | 'normal' | 'elevated' | 'urgent' | 'critical';

export type ProjectTemplateDTO = {
	templateId?: number; // bigint in SQL, but we'll use number here per your instruction
	workspaceId: number;
	created: string; // iso datetime
	updated: string; // iso datetime
	deleted?: string;
	name: string;
	description?: string;
	category?: string;
	estimatedBudget?: number; // decimal in SQL, but we'll use number here
	budgetCurrency: string;
	industryId?: number;
	urgency: ProjectUrgency;
	priorities: any; // json in SQL corresponds to any type in TypeScript as JSON can contain any data structure
	specialRequirements?: string;
	tags: string[];
};

/**
 * Template
 */
export default class TemplateApi {
	static async getTemplates(): Promise<ProjectTemplate[]> {
		const host = config.hosts.api;
		const url = `//${host}/v1/project-templates`;

		const requestResult = await FetchWrapper.typedGet<ProjectTemplateDTO[]>(url, {
			requireAuth: true,
		});

		return requestResult.data.map((template) => new ProjectTemplate(template));
	}

	/**
	 *
	 * @param templateId
	 * @returns
	 */
	static async deleteTemplate(templateId: number): Promise<boolean> {
		const host = config.hosts.api;
		const url = `//${host}/v1/project-templates/${templateId}`;

		const deleteResult = await FetchWrapper.typedDelete<ProjectTemplateDTO>(url, {
			requireAuth: true,
		});

		return deleteResult.statusCode === 200;
	}

	/**
	 *
	 * @param template
	 * @returns
	 */
	static async upsertTemplate(template: ProjectTemplate): Promise<ProjectTemplate | false> {
		const host = config.hosts.api;
		const url = `//${host}/v1/project-templates`;

		const postResult = await FetchWrapper.typedPost<ProjectTemplateDTO>(url, {
			body: JSON.stringify({
				template,
			}),
			requireAuth: true,
		});

		if (postResult.statusCode === 200) {
			return new ProjectTemplate(postResult.data);
		} else {
			return false;
		}
	}
}
