import { Link, StyledComponentProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';
import { observer } from 'mobx-react';
import React from 'react';
import { Company } from '../../../stores/company/Company';
import { TargetDTO } from '../../../dto/company.types';

const PREFIX = 'CompanyContactInformation';

const classes = {
	link: `${PREFIX}-link`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
	[`& .${classes.link}`]: {
		width: 'fit-content',
	},
});

type Props = StyledComponentProps & {
	company: Company;
	target?: TargetDTO;
	hidePhoneNumber?: boolean;
	hideEmailAddress?: boolean;
};

const CompanyContactInformation = observer(
	class CompanyContactInformation extends React.Component<Props> {
		variant: Variant = 'body2';

		get webpage() {
			const { company } = this.props;
			if (!!company.webpage && !/^https?:\/\//i.test(company.webpage)) {
				return 'https://' + company.webpage;
			}

			return company.webpage;
		}

		render() {
			const { company, target, hidePhoneNumber, hideEmailAddress } = this.props;

			return (
				<Root>
					<Typography gutterBottom variant={this.variant}>
						{company.address}, {company.postalCode} {company.city}{' '}
						{target && `(Ca. ${target.distanceKm} km unna)`}
					</Typography>
					{!hidePhoneNumber && (
						<Link
							className={classes?.link}
							display="block"
							gutterBottom
							href={`tel:${company.phone}`}
							underline="always"
							variant={this.variant}
						>
							{company.phone}
						</Link>
					)}
					{!hideEmailAddress && (
						<Link
							className={classes?.link}
							display="block"
							gutterBottom
							href={`mailto:${company.email}`}
							underline="always"
							variant={this.variant}
						>
							{company.email}
						</Link>
					)}
					{this.webpage && (
						<Link
							className={classes?.link}
							display="block"
							href={this.webpage}
							target="_blank"
							underline="always"
							variant={this.variant}
						>
							{company.webpage}
						</Link>
					)}
				</Root>
			);
		}
	}
);

export default CompanyContactInformation;
