import React from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import Layout from '../layout/Layout';
import LayoutContent from '../layout/LayoutContent';
import { RainbowBorderBox } from '../common/AnimateUI';
import CampaignChatCTA from './CampaignChatCTA';

@observer
class Campaign extends React.Component {
	name: string = '';
	email: string = '';
	message: string = '';

	constructor(props: any) {
		super(props);
		makeObservable(this, {
			name: observable,
			email: observable,
			message: observable,
			handleNameInputChange: action,
			handleEmailInputChange: action,
			handleMessageInputChange: action,
			handleSubmit: action,
		});
	}

	handleNameInputChange = (value: string) => {
		this.name = value;
	};

	handleEmailInputChange = (value: string) => {
		this.email = value;
	};

	handleMessageInputChange = (value: string) => {
		this.message = value;
	};

	handleSubmit = () => {
		// Form submission logic (mockup)
		console.log('Form submitted:', {
			name: this.name,
			email: this.email,
			message: this.message,
		});
	};

	render(): React.ReactNode {
		return (
			<Layout center>
				<LayoutContent>
					<Box sx={{ padding: 4 }}>
						{/* Campaign Header */}
						<Box
							sx={{
								backgroundImage: 'url(/illustrations/smart-fuse.webp)',
								backgroundSize: 'cover',
								padding: 4,
								textAlign: 'center',
								color: 'white',
								minHeight: 400,
							}}
						>
							<Typography variant="h3" gutterBottom>
								Oppgrader Ditt Sikringsskap
							</Typography>
							<Typography variant="h5">
								En tryggere, smartere løsning for hjemmet ditt med våre oppgraderingsalternativer.
							</Typography>
						</Box>

						{/* Campaign Information */}
						<Box sx={{ padding: 4 }}>
							<Typography variant="h4" gutterBottom>
								Hvorfor velge oss?
							</Typography>
							<Typography variant="body1" sx={{ marginBottom: 4 }}>
								Sikringsskapet ditt er hjertet av det elektriske systemet i hjemmet ditt. Med en
								oppgradering til et moderne sikringsskap får du både bedre sikkerhet og energisparing.
								Våre løsninger inkluderer de nyeste teknologiene for å gjøre hjemmet ditt tryggere,
								smartere, og mer effektivt.
							</Typography>

							<Typography variant="h4" gutterBottom>
								Hva vi tilbyr
							</Typography>
							<Typography variant="body1" sx={{ marginBottom: 4 }}>
								Vi tilbyr en rekke tjenester som hjelper deg med å forbedre ditt elektriske system,
								inkludert:
								<ul>
									<li>Oppgradering av sikringsskap</li>
									<li>Installasjon av jordfeilbrytere</li>
									<li>Strømsparingstiltak</li>
								</ul>
								Kontakt oss i dag for mer informasjon om våre tjenester og hvordan vi kan hjelpe deg.
							</Typography>
						</Box>

						{/* Call-to-Actions (CTA) */}
						<Grid container spacing={4}>
							{/* Chat CTA */}
							<Grid item xs={12} md={6}>
								<RainbowBorderBox
									sx={{
										borderRadius: 2,
										textAlign: 'center',
									}}
								>
									<Box sx={{ padding: 4 }}>
										<Typography variant="h5" gutterBottom>
											Har du spørsmål? Chat med oss!
										</Typography>
										<Typography variant="body1" sx={{ marginBottom: 2 }}>
											Vår AI-assistent er her for å svare på alle dine spørsmål. Klikk på knappen
											nedenfor for å starte en samtale.
										</Typography>
										<Button
											variant="contained"
											color="primary"
											sx={{ marginTop: 2 }}
											onClick={() => alert('Åpner chat')}
										>
											Chat med oss
										</Button>
									</Box>
								</RainbowBorderBox>
							</Grid>

							{/* Request a Quote Form CTA */}
							<Grid item xs={12} md={6}>
								<Box
									sx={{
										padding: 4,
										border: '1px solid #ccc',
										borderRadius: 2,
										textAlign: 'center',
										backgroundColor: '#f9f9f9',
									}}
								>
									<Typography variant="h5" gutterBottom>
										Be om et pristilbud
									</Typography>
									<Typography variant="body1" sx={{ marginBottom: 2 }}>
										Fyll ut skjemaet nedenfor, så tar vi kontakt med deg med et pristilbud tilpasset
										ditt behov.
									</Typography>
									<TextField
										fullWidth
										label="Navn"
										value={this.name}
										onChange={(e) => this.handleNameInputChange(e.target.value)}
										margin="normal"
									/>
									<TextField
										fullWidth
										label="E-post"
										value={this.email}
										onChange={(e) => this.handleEmailInputChange(e.target.value)}
										margin="normal"
									/>
									<TextField
										fullWidth
										label="Melding"
										value={this.message}
										onChange={(e) => this.handleMessageInputChange(e.target.value)}
										margin="normal"
										multiline
										rows={4}
									/>
									<Button
										variant="contained"
										color="primary"
										sx={{ marginTop: 2 }}
										onClick={this.handleSubmit}
									>
										Send forespørsel
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</LayoutContent>
				<CampaignChatCTA />
			</Layout>
		);
	}
}

export default Campaign;
