import './CompanyAvailabilityDialog.scss';

import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { Company } from '../../stores/company/Company';
import { HookTypes, withNavigation } from '../../utils/withHooks';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import FeatureGuard from '../../utils/FeatureGuard';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { observer } from 'mobx-react';

type CompanyListItemProps = {
	company: Company;
	postCode: string;
};

const CompanyListItem = observer((props: CompanyListItemProps) => {
	const { company, postCode } = props;
	const isNear = company.isNear(postCode);
	const industries = company.industries ?? [];
	return (
		<FormControlLabel
			control={<Radio required />}
			key={company.id}
			label={
				<ListItem dense>
					<ListItemText
						primary={company.name}
						secondary={
							<>
								{company.ingress && (
									<Typography variant="caption" color="primary">
										<strong>{company.ingress}</strong>
									</Typography>
								)}
								<Typography variant="body2">
									{`${company.address || ''}, ${company.postalCode || ''} ${company.city || ''}`}
								</Typography>
								<FeatureGuard feature="companies.industries">
									<Stack
										direction="row"
										divider={<Divider orientation="vertical" flexItem />}
										spacing={1}
									>
										{industries.map((industry) => (
											<Chip
												key={`INDUSTRY-${industry.id}`}
												label={industry.name}
												variant="outlined"
												size="small"
											/>
										))}
									</Stack>
								</FeatureGuard>
							</>
						}
					/>
					<ListItemSecondaryAction>
						<Typography color="textSecondary" variant="caption">
							{isNear?.distanceKm ? isNear.distanceKm : '-'} km
						</Typography>
					</ListItemSecondaryAction>
				</ListItem>
			}
			value={company.id}
		/>
	);
});

type Props = HookTypes & {
	isOpen: boolean;
	postCode: string;
	// eslint-disable-next-line no-unused-vars
	onClose: (event?: {} | MouseEvent, reason?: string) => void;
	// eslint-disable-next-line no-unused-vars
	onSelect: (company?: Company | undefined) => void;
};

const CompanyAvailabilityDialog = observer(
	class CompanyAvailabilityDialog extends React.Component<Props> {
		static readonly contextType = StoreContext;
		get history() {
			return this.props.navigate!;
		}

		dialogContentElement = React.createRef<HTMLDivElement>();
		selectedCompany: Company | null = null;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				dialogContentElement: observable,
				selectedCompany: observable,
				companyStore: computed,
				companies: computed,
				isValidPostCode: computed,
				isScrollable: computed,
				componentDidUpdate: action,
				handleChangeCompany: action,
			});
		}

		async componentDidUpdate(prevProps: Props) {
			const { postCode } = this.props;

			if (this.isValidPostCode && postCode !== prevProps.postCode) {
				await this.companyStore.loadCompanies(postCode);

				runInAction(() => {
					if (this.companies?.length) {
						this.selectedCompany = this.companies[0];
					}
				});
			}
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get companies() {
			const { postCode } = this.props;

			return this.companyStore.getCompaniesNearPostCode(postCode)?.sort((a, b) => {
				const aDist = a?.isNear(postCode)?.distanceKm ?? Infinity;
				const bDist = b?.isNear(postCode)?.distanceKm ?? Infinity;

				return aDist > bDist ? 1 : -1;
			});
		}

		get isValidPostCode() {
			return /^\d{4}$/.test(this.props.postCode);
		}

		get isScrollable() {
			const { current } = this.dialogContentElement;

			if (!current) {
				return false;
			}

			return current.scrollHeight > current.clientHeight;
		}

		handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
			this.selectedCompany = this.companyStore.findCompanyById(value)!;
		};

		handleGoToOrder = (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			const { onClose, postCode, onSelect } = this.props;
			onClose();

			if (onSelect) {
				onSelect(this.selectedCompany ?? undefined);
			} else {
				this.history(`/order-chat/${postCode}/${this.selectedCompany!.id}`, {
					state: {
						postCode,
						companyId: this.selectedCompany!.id,
					},
				});
			}
		};

		renderCompanies() {
			const { postCode } = this.props;

			if (!this.companies) {
				return null;
			}

			return this.companies.map((company) => (
				<CompanyListItem company={company} key={company.id} postCode={postCode} />
			));
		}

		render() {
			const { isOpen, onClose } = this.props;

			return (
				<Dialog
					aria-describedby="dialog-description"
					aria-labelledby="dialog-title"
					className="CompanyAvailabilityDialog"
					open={isOpen}
					onClose={onClose}
				>
					<DialogTitle id="dialog-title">
						{this.companies?.length ? 'Vi er tilgjengelige i ditt område' : 'Ikke tilgjengelig'}
					</DialogTitle>
					<DialogContent dividers={this.isScrollable} ref={this.dialogContentElement}>
						<DialogContentText id="dialog-description">
							{this.companies?.length
								? 'Velg bedriften du vil chatte med.'
								: 'Vi er dessverre ikke tilgjengelige i ditt område enda.'}
						</DialogContentText>
						{Boolean(this.companies?.length) && (
							<form id="company-form" onSubmit={this.handleGoToOrder}>
								<FormControl component="fieldset" fullWidth>
									<RadioGroup
										aria-label="company"
										value={this.selectedCompany?.id ?? null}
										onChange={this.handleChangeCompany}
									>
										{this.renderCompanies()}
									</RadioGroup>
								</FormControl>
							</form>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>{this.companies?.length ? 'Avbryt' : 'Ok'}</Button>
						{Boolean(this.companies?.length) && (
							<Button color="primary" form="company-form" type="submit" variant="contained">
								Gå videre
							</Button>
						)}
					</DialogActions>
				</Dialog>
			);
		}
	}
);

export default withNavigation(CompanyAvailabilityDialog);
