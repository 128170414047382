import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import AuthManager from '../../auth/AuthManager';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { Link } from 'react-router-dom';

type Props = {
	token: string;
	onSuccess: () => void;
	onError: () => void;
};

const MagicLinkLogin = observer(
	class MagicLinkLogin extends React.Component<Props> {
		static readonly contextType = StoreContext;

		signInStatus: string = 'Vennligst vent...';
		error: boolean = false;
		redirect: boolean = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				signInStatus: observable,
				redirect: observable,
				error: observable,
				uiState: computed,
				userStore: computed,
				token: computed,
				signIn: action,
				init: action,
			});
		}

		componentDidMount() {
			this.init();
		}

		init = () => {
			if (!this.userStore.isLoggedIn) {
				this.signIn();
			} else {
				this.props.onSuccess();
			}
		};

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get token() {
			return this.props.token;
		}

		async signIn() {
			const result = await AuthManager.magicLinkVerification(this.token);

			if (result.statusCode !== 200) {
				if (this.userStore.isLoggedIn) {
					runInAction(() => {
						this.signInStatus =
							'Magisk lenke var utløpt, men du er allerede logget inn. Sender deg videre til dashbord.';
						this.error = true;
					});
					this.props.onSuccess();
				} else {
					runInAction(() => {
						this.signInStatus = 'Lenken fungerte ikke - du må logge inn med mobilnr';
						this.redirect = true;
						this.error = true;
					});

					this.props.onError();
				}
			} else {
				runInAction(() => (this.signInStatus = 'Du er nå logget inn'));
				this.userStore.triggerSignOut();
				await this.userStore.checkLoginStatus(true);
				this.props.onSuccess();
			}
		}

		render() {
			const { isMobile, isTablet, isPortraitOrientation } = this.uiState;
			const { isLoggedIn } = this.userStore;

			return (
				<div className="MagicLinkSignIn">
					{isLoggedIn ? (
						<Stack spacing={2}>
							<CircularProgress />
							<Button component={Link} to="/dashboard" variant="contained">
								Gå til dashbord
							</Button>
						</Stack>
					) : (
						<>
							<Typography
								align="center"
								gutterBottom
								variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
							>
								{this.error ? (
									this.signInStatus
								) : (
									<>
										Logger deg inn med{' '}
										<span className="text--bold text--italic text--red">magisk</span> lenke
									</>
								)}
							</Typography>
							{!this.error && (
								<Typography align="center" gutterBottom variant="body2">
									{this.signInStatus}
								</Typography>
							)}
						</>
					)}
				</div>
			);
		}
	}
);

export default MagicLinkLogin;
