import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { observer } from 'mobx-react';
import React from 'react';
import { ProfileDTO } from '../../dto/profile.types';
import { action, makeObservable } from 'mobx';

type Props = TextFieldProps & {
	// eslint-disable-next-line no-unused-vars
	onFoundProfile?: (profileDto: ProfileDTO) => void;
};

const EmailTextField = observer(
	class EmailTextField extends React.Component<Props> {
		// eslint-disable-next-line no-unused-vars
		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				onChange: action,
			});
		}

		onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (this.props.onChange) {
				this.props.onChange(event);
			}
		};

		render() {
			const { InputProps, ...rest } = this.props;

			return (
				<TextField
					autoComplete="email"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<EmailIcon color="action" style={{ marginRight: 8 }} />
							</InputAdornment>
						),
						...InputProps,
					}}
					label="E-post"
					name="email"
					type="email"
					variant="filled"
					{...rest}
					onChange={this.onChange}
				/>
			);
		}
	}
);

export default EmailTextField;
