import React from 'react';
import Article from './Article';
import { Button, Stack, Typography } from '@mui/material';
import BefareIllustration from '../common/illustrations/BefareIllustration';
import { ServicesEnum } from '../home/generic/GenericServices';
import { Link } from 'react-router-dom';

export default class HeatingArticle extends React.Component {
	render() {
		return (
			<Article>
				<Typography variant="h3" gutterBottom>
					Oppvarming med eksperthjelp
				</Typography>
				<Typography variant="body1" gutterBottom>
					Når kulda setter inn, er valg av oppvarmingssystem viktigere enn noensinne. Å involvere en
					profesjonell elektriker i valget og installasjonen av elektrisk oppvarming, som varmekabler,
					varmefolie, panelovner og varmepumpe, kan gi en rekke fordeler når det kommer til komfort,
					effektivitet og energisparing. La oss utforske hvordan en elektriker kan hjelpe deg med å velge
					riktig system og til og med implementere smartstyring for å øke komforten og spare penger.
				</Typography>
				<BefareIllustration
					illustration="phone"
					sx={{
						margin: '1rem auto',
						maxWidth: '300px',
						background: '#ccc',
						padding: '2rem',
						borderRadius: '2rem',
					}}
				/>

				<Typography variant="h5" gutterBottom>
					Valg av riktig oppvarmingssystem
				</Typography>

				<Typography variant="body1" gutterBottom>
					En profesjonell elektriker er din beste rådgiver når det gjelder å velge riktig oppvarmingssystem
					for ditt hjem. De vil vurdere faktorer som romstørrelse, isolasjon og klimaforhold for å anbefale
					den optimale løsningen som passer dine behov.
				</Typography>

				<Typography variant="h5" gutterBottom>
					Varmekabler
				</Typography>
				<Typography variant="body1" gutterBottom>
					Varmekabler gir jevn varme under gulvflaten og er ideelle for både bad og andre oppholdsrom. En
					elektriker kan installere varmekabler riktig for å sikre komfort og effektivitet.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Varmefolie
				</Typography>
				<Typography variant="body1" gutterBottom>
					Varmefolie er en tynn og diskret løsning som passer perfekt under gulvbelegg. Med profesjonell
					installasjon sørger en elektriker for at varmefolien fungerer optimalt og gir jevn varme til rommet.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Panelovner
				</Typography>
				<Typography variant="body1" gutterBottom>
					Panelovner er en praktisk måte å varme opp enkeltrom på. En elektriker vil hjelpe deg med å plassere
					panelovnene strategisk for maksimal varmefordeling.
				</Typography>

				<Typography variant="h5" gutterBottom>
					Varmepumpe
				</Typography>
				<Typography variant="body1" gutterBottom>
					Varmepumper er allsidige og effektive oppvarmingsalternativer som også kan gi kjøling om sommeren.
					En elektriker vil sørge for korrekt installasjon og effektiv drift.
				</Typography>

				<Typography variant="h5" gutterBottom>
					Implementering av smartstyring
				</Typography>
				<Typography variant="body1" gutterBottom>
					En av de mest innovative aspektene ved moderne elektrisk oppvarming er muligheten for smartstyring.
					En elektriker kan installere smarte termostater og kontroller som gir deg fjernstyring av
					varmesystemet ditt via en app på telefonen din. Dette betyr at du kan justere temperaturen i hjemmet
					ditt uansett hvor du er, og til og med lage tidsskjemaer for oppvarmingen.
				</Typography>

				<Typography variant="h5" gutterBottom>
					Fordelene med smartstyring
				</Typography>
				<Typography variant="body1" gutterBottom>
					Smartstyring øker komforten og effektiviteten ved å tilpasse varmen til din timeplan og behov. For
					eksempel kan du senke temperaturen når du ikke er hjemme, og deretter øke den før du kommer tilbake.
					Dette sparer energi og penger ved å unngå unødvendig oppvarming.
				</Typography>

				<Typography variant="body1" gutterBottom sx={{ marginTop: '2rem' }}>
					Å få en profesjonell elektriker til å hjelpe deg med elektrisk oppvarming gir deg en skreddersydd
					løsning som kombinerer komfort, effektivitet og energisparing. Uansett om du velger varmekabler,
					varmefolie, panelovner eller varmepumpe, vil en elektriker sikre riktig installasjon og drift. Med
					smartstyring kan du ta komfort og besparelser til neste nivå ved å ha full kontroll over
					oppvarmingen når som helst og hvor som helst. Gjør den kalde årstiden varm og behagelig med hjelp
					fra en profesjonell elektriker.
				</Typography>

				<Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{
							borderRadius: 16,
							letterSpacing: '1px',
							boxShadow: '0 2px 4px rgba(0,0,0,.26)',
						}}
						component={Link}
						to={`/quote/${ServicesEnum.Other}`}
					>
						Få pristilbud
					</Button>
					<Button
						variant="outlined"
						color="primary"
						component={Link}
						to={`/quote/${ServicesEnum.FreeInspection}`}
					>
						Gratis befaring
					</Button>
				</Stack>
			</Article>
		);
	}
}
