import { makeAutoObservable } from 'mobx';
import { ESingleValueStatKey } from './ESingleValueStatKey';

export type TSingleValueStat = {
	title: string;
	number: number;
	unit?: string;
	date?: Date;
	key: ESingleValueStatKey;
	comparison?: SingleValueStatModel;
};

export class SingleValueStatModel {
	title: string;
	number: number;
	unit?: string;
	date?: Date;
	key: ESingleValueStatKey;
	growth: number = 0;
	growthPercentage: number = 0;

	comparison?: SingleValueStatModel;

	constructor(stat: TSingleValueStat) {
		makeAutoObservable(this);

		this.title = stat.title;
		this.number = stat.number;
		this.unit = stat.unit;
		this.date = stat.date;
		this.key = stat.key;
		this.setComparison(stat.comparison);
	}

	setComparison(comparison?: SingleValueStatModel) {
		this.comparison = comparison;
		this.calculateGrowth();
	}

	calculateGrowth() {
		if (this.comparison && this.comparison.number !== 0) {
			this.growth = this.number - this.comparison.number;
			this.growthPercentage = this.growth / this.comparison.number;
		}
	}
}
