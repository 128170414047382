import config from '../../config/config';
import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

export type SignicatSignatureMechanism =
	| 'pkisignature'
	| 'identification'
	| 'handwritten'
	| 'handwritten_with_identification';

export type BefareDocument = {
	id?: number;
	created?: string;
	updated?: string;
	deleted?: string;
	workspaceId: string;
	createdByUserId: string;
	title: string;
	description: string;
	contactEmail: string;
	contactUrl: string;
	status: 'unsigned' | 'signed' | 'expired' | 'cancelled';
	documentId?: string;
	deadline: string;
	filename: string;
	fileId: string;
	signedFileId?: string;
	mechanism: SignicatSignatureMechanism;
	signers: BefareDocumentSigner[];
	url?: string;
	urlExpiresAt?: string;
};

export type BefareDocumentSigner = {
	id?: number;
	documentSigningId?: number;
	userId?: string;
	created?: string;
	updated?: string;
	deleted?: string;
	countryCode: string;
	phoneNumber: string;
	email: string;
	name: string;
	status?: string;
	url?: string;
	// mechanism: SignicatSignatureMechanism;
	required: boolean;
	order: number;
};

export type BefareDocumentSigningLog = {
	id?: number;
	created?: string;
	event: string;
	userId?: string;
	documentSigningId: number;
	comment?: string;
};

/**
 * Document signing api
 */
export default class DocumentSigningApi {
	static async getDocuments(): Promise<FetchWrapperTypedResponse<BefareDocument[] | undefined>> {
		const url = `//${config.hosts.api}/v1/documents`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async getDocument(documentId: number): Promise<FetchWrapperTypedResponse<BefareDocument | undefined>> {
		const url = `//${config.hosts.api}/v1/documents/${documentId}`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async getLogs(
		documentId: number
	): Promise<FetchWrapperTypedResponse<BefareDocumentSigningLog[] | undefined>> {
		const url = `//${config.hosts.api}/v1/documents/${documentId}/logs`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async createDocument(
		document: BefareDocument
	): Promise<FetchWrapperTypedResponse<BefareDocument | undefined>> {
		const url = `//${config.hosts.api}/v1/documents/create`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ document }),
			requireAuth: true,
		});
	}
}
