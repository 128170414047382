import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import React from 'react';
import config from '../../config/config';
import AutoplayCarousel from '../common/carousel/AutoplayCarousel';

const { appId } = config;
const workspaceTheme = appId === 'MinElektriker' ? 'norgeseliten' : 'befare';

export const HowItWorksSteps = [
	{
		imageURL: `/illustrations/${workspaceTheme}/request.svg`,
		title: 'Beskriv problemet',
		description: <>Svar på noen enkle spørsmål om hva du trenger hjelp til </>,
	},
	{
		imageURL: `/illustrations/${workspaceTheme}/phone.svg`,
		title: 'Chat med en ekspert',
		description: 'Motta link til en privat chat mellom deg og elektrikeren på SMS',
	},
	{
		imageURL: `/illustrations/${workspaceTheme}/time2.svg`,
		title: 'Bli enige om neste steg',
		description: 'Trenger dere en videobefaring, eller holder det at du sender noen bilder?',
	},
	{
		imageURL: `/illustrations/${workspaceTheme}/inspection2.svg`,
		title: 'Videobefaring?',
		description: 'Elektrikeren vil ringe til avtalt tid, og guide deg rundt så han finner ut det han trenger',
	},
	{
		imageURL: `/illustrations/${workspaceTheme}/offer.svg`,
		title: 'Motta pristilbud',
		description: 'Innen kort tid vil du motta et pristilbud i chatten',
	},
	{
		imageURL: `/illustrations/${workspaceTheme}/result.svg`,
		title: 'Ta det piano!',
		description:
			'Gjør hva du vil mens elektrikeren utfører jobben. Når han er ferdig, vil du få en video av resultatet.',
	},
];

export default class HowItWorksCarousel extends React.Component {
	renderSteps() {
		return HowItWorksSteps.map((step, index) => (
			<Grid container key={`how-it-works-${index}`}>
				<Grid xs={12} sx={{ position: 'relative' }}>
					<img alt="" style={{ height: '300px', maxHeight: '50vh', width: '100%' }} src={step.imageURL} />
					<Box sx={{ position: 'absolute', bottom: '2rem', textAlign: 'right', left: '1rem', right: '1rem' }}>
						<Typography
							align="right"
							variant={'h6'}
							component="span"
							sx={{ background: '#424242', color: '#fafafa', padding: '2px 4px' }}
						>
							{step.title}
						</Typography>
						<br />
						<Typography
							align="right"
							variant={'body2'}
							component="span"
							sx={{ background: '#424242', color: '#fafafa', padding: '2px 4px' }}
						>
							{step.description}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		));
	}

	render() {
		return <AutoplayCarousel hideButtons>{this.renderSteps()}</AutoplayCarousel>;
	}
}
