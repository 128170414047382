import { Autocomplete, ListItem, ListItemAvatar, ListItemText, TextField, createFilterOptions } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Project } from '../../../../stores/Project';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { debounce } from 'lodash';
import { ProfileDTO } from '../../../../dto/profile.types';

import CheckIcon from '@mui/icons-material/Check';

type Props = {
	project: Project;
	// eslint-disable-next-line no-unused-vars
	onChange: (profiles: Array<ProfileDTO | string>) => void;
	values?: Array<ProfileDTO | string>;
};

const ShareProjectSearchAutocomplete = observer(
	class ShareProjectSearchAutocomplete extends React.Component<Props> {
		debouncedSearchSharedAccess: any;
		searchQuery: string = '';
		options: ProfileDTO[] = [];
		values: Array<ProfileDTO | string> = [];
		errorText: string = '';

		constructor(props: Props) {
			super(props);

			this.debouncedSearchSharedAccess = debounce(this.searchSharedAccess, 500);
			makeObservable(this, {
				searchQuery: observable,
				options: observable,
				values: observable,
				errorText: observable,
				onInputChange: action,
				onChange: action,
				setOptions: action,
				clearValue: action,
				searchSharedAccess: action,
				debouncedSearchSharedAccess: action,
			});
		}

		componentDidUpdate(prevProps: Readonly<Props>): void {
			if (prevProps.values !== this.props.values) {
				this.values = this.props.values ?? [];
			}
		}

		onInputChange = async (event: any, value: string) => {
			this.searchQuery = value;
			if (value.length < 4) return;

			this.debouncedSearchSharedAccess();
		};

		searchSharedAccess = async () => {
			runInAction(async () => {
				const { project } = this.props;
				if (this.searchQuery.length < 4) return;
				const options = await project.searchSharedAccess(this.searchQuery);

				this.setOptions(options);
			});
		};

		onChange = (_event: any, values: any) => {
			// filter out strings thats not valid email og an 8 digit phone number
			let hasError = false;
			values = values.filter((value: any) => {
				if (typeof value === 'string') {
					if (value.includes('@') && value.includes('.')) {
						return true;
					}
					if (value.length === 8 && !isNaN(Number(value))) {
						return true;
					}
					hasError = true;
					return false;
				}
				return true;
			});
			if (this.values !== values) {
				this.values = values;
				this.props.onChange(values);
			}

			if (hasError) {
				this.errorText = 'Ugyldig epost eller mobilnummer';
			} else {
				this.errorText = '';
			}
		};

		setOptions = (options: ProfileDTO[]) => {
			this.options = options;
			console.log('auto: ShareProjectSearchAutocomplete: setOptions', options);
			this.forceUpdate();
		};

		clearValue = () => {
			this.searchQuery = '';
			this.setOptions([]);
		};

		render() {
			const options = toJS(this.options);
			const values = toJS(this.values);
			const searchQuery = toJS(this.searchQuery);
			const filterOptions = createFilterOptions({
				matchFrom: 'any',
				stringify: (option: any) => {
					if (typeof option === 'string') {
						return option;
					}
					return `${option.name} ${option.email} ${option.phoneNumber}`;
				},
			});

			const errorText = this.errorText;

			return (
				<Autocomplete
					options={options}
					value={values}
					multiple
					freeSolo
					filterOptions={filterOptions}
					inputValue={searchQuery}
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option;
						}
						return option.name ?? option.email ?? option.phoneNumber ?? '';
					}}
					isOptionEqualToValue={(option: ProfileDTO, value: any) => {
						if (typeof value === 'string') {
							return option.name === value;
						}
						if (option.email === value) {
							return true;
						}

						if (option.phoneNumber === value) {
							return true;
						}

						return false;
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Del med mobilnummer, epost eller samarbeidspartner."
							variant="outlined"
							size="small"
							error={errorText.length > 0}
							helperText={errorText}
						/>
					)}
					renderOption={(props, option, { selected }) => {
						if (typeof option === 'string') {
							return (
								<ListItem {...props} key={option}>
									<ListItemText primary={option} />
								</ListItem>
							);
						}
						return (
							<ListItem {...props} key={option.id}>
								<ListItemAvatar>{selected ? <CheckIcon /> : null}</ListItemAvatar>
								<ListItemText primary={option.name} secondary={option.workspaceId} />
							</ListItem>
						);
					}}
					onChange={this.onChange}
					onInputChange={this.onInputChange}
				/>
			);
		}
	}
);

export default ShareProjectSearchAutocomplete;
