import config from '../../config/config';
import {
	CompanyPageDTO,
	ElvirksomhetsRegisteretType,
	IndustryDTO,
	UpdateCompanyPageDTO,
} from '../../dto/company.types';
import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

export type PublicCompanyRating = {
	avgRating: number | undefined;
	numReviews: number | undefined;
	comments: string[];
};

/**
 * Company api
 */
export default class CompanyApi {
	static async getCompanyPage(workspaceId: number): Promise<FetchWrapperTypedResponse<CompanyPageDTO>> {
		const url = `//${config.hosts.api}/v1/company/page/${workspaceId}`;
		return FetchWrapper.get(url);
	}

	static async updateCompanyPage(data: UpdateCompanyPageDTO): Promise<FetchWrapperTypedResponse<CompanyPageDTO>> {
		const url = `//${config.hosts.api}/v1/company/page`;
		return FetchWrapper.post(url, {
			body: JSON.stringify(data),
		});
	}

	static async getIndustries(): Promise<FetchWrapperTypedResponse<{ industries: IndustryDTO[]; categories: any[] }>> {
		const url = `//${config.hosts.api}/v1/company/industries`;
		return FetchWrapper.get(url);
	}

	static async getCompanyFromWorkspaceDomain(domain: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/company/workspace/${domain}`;
		return FetchWrapper.get(url);
	}

	static async getCompanyFromWorkspaceId(workspaceId: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/company/workspaceid/${workspaceId}`;
		return FetchWrapper.get(url);
	}

	static async getCompaniesFromWorkspaceIds(workspaceIds: string[]): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/company/workspaceids`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				workspaceIds,
			}),
		});
	}

	static async getCompanyFromCompanyId(companyId: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/company/companyId/${companyId}`;
		return FetchWrapper.get(url);
	}

	static async getCompanyReviews(workspaceId: string): Promise<FetchWrapperTypedResponse<PublicCompanyRating>> {
		const url = `//${config.hosts.api}/v1/company/reviews/${workspaceId}`;
		return FetchWrapper.get(url);
	}

	static async getElvirksomhetsregisteretFromWorkspaceId(
		workspaceId: string
	): Promise<FetchWrapperTypedResponse<ElvirksomhetsRegisteretType>> {
		const url = `//${config.hosts.api}/v1/company/workspaceid/${workspaceId}/elvirksomhetsregisteret`;
		return FetchWrapper.get(url);
	}

	/**
	 * Load profiles
	 * @param {Number[]} userIds
	 */
	static async loadCompaniesNearUser(industryId?: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/company/near-me`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				industryId,
			}),
		});
	}

	/**
	 * Load profiles
	 * @param {Number[]} userIds
	 */
	static async loadCompanies(postCode?: string, industryId?: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/company/list`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				postalCode: postCode,
				industryId,
			}),
		});
	}
}
