import { CircularProgress, Container, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import { makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import Footer from '../footer/Footer';
import './PrivacyPolicy.scss';

type Props = {};

const PrivacyPolicy = observer(
	class PrivacyPolicy extends React.Component {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				userStore: computed,
				gdprStatusIsLoading: computed,
				gdprStatus: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get gdprStatusIsLoading() {
			return this.userStore.gdprStatusIsLoading;
		}

		get gdprStatus() {
			return this.userStore.gdprStatus;
		}

		render() {
			let content = (
				<div
					className="PrivacyPolicy__content"
					dangerouslySetInnerHTML={{ __html: this.gdprStatus?.termsOfUse }}
				></div>
			);

			if (!this.gdprStatus?.termsOfUse && this.gdprStatusIsLoading) {
				content = (
					<>
						<CircularProgress></CircularProgress>
						<Typography variant="overline">Laster...</Typography>
					</>
				);
			} else if (!this.gdprStatus?.termsOfUse) {
				content = (
					<Alert severity="warning">
						Noe galt har skjedd. Her har ikke informasjonen som skulle vært på denne siden blitt lastet
						riktig.
					</Alert>
				);
			}
			return (
				<>
					<Container className="PrivacyPolicy">{content}</Container>
					<Footer />
				</>
			);
		}
	}
);

export default PrivacyPolicy;
