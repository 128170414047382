import { AppId } from './appId.enum';
import config from './config';

// Load feature files - could be smarter, but this works for now
import BefareFeatures from './befare/befare.features.config';
import MinElektrikerFeatures from './minelektriker/minelektriker.features.config';
import MinRorleggerFeatures from './minrorlegger/minrorlegger.features.config';
import SmartFeatures from './smart/smart.features.config';

let features: any = {};

// Find the right feature file for this appId
switch (config.appId) {
	case AppId.Smart:
		features = SmartFeatures;
		break;
	case AppId.MinElektriker:
		features = MinElektrikerFeatures;
		break;
	case AppId.MinRorlegger:
		features = MinRorleggerFeatures;
		break;
	case AppId.Befare:
		features = BefareFeatures;
		break;
}

export default features;
