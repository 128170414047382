import { makeAutoObservable } from 'mobx';
import { ChannelTypeDTO } from '../../dto/channel.types';
import { Channel } from '../ChatStore';
import { ProjectStatusCategory } from '../ProjectStore';
import { RootStore } from '../RootStore';
import { SingleValueStatModel, TSingleValueStat } from './SingleValueStatModel';
import { ESingleValueStatKey } from './ESingleValueStatKey';
import { ProjectStatusDTO } from '../../dto/project.types';
import { ProjectLead } from '../WorkspaceStore';
import { Project } from '../Project';

export type TSingleValueStatUserConfig = {
	key: ESingleValueStatKey;
	order: number;
};

export default class StatsFactory {
	rootStore: RootStore;

	singleValueStats: SingleValueStatModel[] = [];
	selectedMonth: Date = new Date();

	// fuckery
	comparisonPeriodFactory: StatsFactory | undefined;

	constructor(rootStore: RootStore, useComparisonFactory: boolean = true) {
		makeAutoObservable(this, {
			rootStore: false,
			projectStore: false,
			chatStore: false,
			workspaceStore: false,
			ratingStore: false,
			customerStore: false,
			topPerformingEmployeesStat: false,
			employeeUtilizationRateStat: false,
			averageResponseTimeStat: false,
			totalJobsInAreaStat: false,
		});
		this.rootStore = rootStore;
		if (useComparisonFactory) {
			this.comparisonPeriodFactory = new StatsFactory(this.rootStore, false);
		}

		this.init();
	}

	init() {
		this.setSelectedMonth(new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1));
	}

	get projectStore() {
		return this.rootStore.projectStore;
	}

	get chatStore() {
		return this.rootStore.chatStore;
	}

	get workspaceStore() {
		return this.rootStore.workspaceStore;
	}

	get ratingStore() {
		return this.rootStore.ratingStore;
	}

	get customerStore() {
		return this.rootStore.customerStore;
	}

	get projects() {
		return this.projectStore.projects;
	}

	get leads() {
		return this.workspaceStore.projectLeads;
	}

	get leadsSelectedMonth(): ProjectLead[] {
		return this.leads.filter((lead) => {
			const leadDate = new Date(lead.created);
			return (
				leadDate.getMonth() === this.selectedMonth.getMonth() &&
				leadDate.getFullYear() === this.selectedMonth.getFullYear()
			);
		});
	}

	get projectsSelectedMonth(): Project[] {
		return this.projects.filter((project) => {
			const projectDate = new Date(project.created);
			return (
				projectDate.getMonth() === this.selectedMonth.getMonth() &&
				projectDate.getFullYear() === this.selectedMonth.getFullYear()
			);
		});
	}

	get customersSelectedMonth() {
		return this.customerStore.customers.filter((customer) => {
			if (!customer.firstProjectDate) {
				return false;
			}

			return (
				customer.firstProjectDate.getMonth() === this.selectedMonth.getMonth() &&
				customer.firstProjectDate.getFullYear() === this.selectedMonth.getFullYear()
			);
		});
	}

	get unprocessedProjects() {
		return this.projectsSelectedMonth.filter(
			(project) => project.statusCategory === ProjectStatusCategory.Unprocessed
		);
	}

	get completedProjects() {
		return this.projectsSelectedMonth.filter((project) => project.statusCategory === ProjectStatusCategory.Done);
	}

	get projectsUnhandledCustomerMessages() {
		return this.projectsSelectedMonth.filter((project) => project.hasUnhandledCustomerMessages);
	}

	get projectsInProgress() {
		return this.projectsSelectedMonth.filter((project) =>
			[ProjectStatusCategory.InProgress, ProjectStatusCategory.ToDo].includes(project.statusCategory)
		);
	}

	get delegatedProjects() {
		return this.projectsSelectedMonth.filter((project) => project.workspaceId !== project.creatingWorkspaceId);
	}

	get partnerChannels() {
		return this.chatStore.channels.filter((channel) => channel.channelType === ChannelTypeDTO.Partner);
	}

	get customerChannels() {
		const channels: Channel[] = [];
		this.projectsUnhandledCustomerMessages.forEach((project) => {
			const channel = this.chatStore.channels.find(
				(channel) =>
					project.channels &&
					project.channels.find((pc) => pc.id === channel.id) &&
					channel.channelType === ChannelTypeDTO.ProjectCustomer
			);
			if (channel) {
				channels.push(channel);
			}
		});
		return channels;
	}

	get unreadPartnerMessages() {
		return this.partnerChannels.reduce((acc, channel) => acc + channel.unreadNum, 0);
	}

	get conversionRate(): number {
		const total = this.projectsSelectedMonth.length;
		const converted = this.projectsSelectedMonth.filter(
			(lead) =>
				![
					ProjectStatusDTO.Aborted,
					ProjectStatusDTO.ProjectLost,
					ProjectStatusDTO.OfferRejected,
					ProjectStatusDTO.Unknown,
					ProjectStatusDTO.Requested,
				].includes(lead.status as ProjectStatusDTO)
		).length;

		return total > 0 ? (converted / total) * 100 : 0;
	}

	get leadsConversionRate(): number {
		const totalLeads = this.leads.length;
		const convertedLeads = this.leads.filter(
			(lead) =>
				![
					ProjectStatusDTO.Aborted,
					ProjectStatusDTO.ProjectLost,
					ProjectStatusDTO.OfferRejected,
					ProjectStatusDTO.Unknown,
					ProjectStatusDTO.Requested,
				].includes(lead.status as ProjectStatusDTO)
		).length;

		return totalLeads > 0 ? (convertedLeads / totalLeads) * 100 : 0;
	}

	get totalLeads(): number {
		return this.leadsSelectedMonth.length;
	}

	get averageTimeToCompletion(): number {
		const completedLeads = this.leadsSelectedMonth.filter((lead) => lead.status === ProjectStatusDTO.Completed);
		const totalDays = completedLeads.reduce((sum, lead) => {
			const created = new Date(lead.created);
			const completed = new Date(lead.updated);
			return sum + (completed.getTime() - created.getTime()) / (1000 * 60 * 60 * 24);
		}, 0);
		return completedLeads.length > 0 ? totalDays / completedLeads.length : 0;
	}

	get estimatedBudget(): number {
		const totalBudget = this.leadsSelectedMonth.reduce((sum, lead) => sum + (lead.estimatedBudget || 15000), 0);
		return totalBudget;
	}

	get estimatedHours(): number {
		const totalHours = this.leadsSelectedMonth.reduce((sum, lead) => sum + (lead.estimatedHours || 4), 0);
		return totalHours;
	}

	get revenueForecast(): number {
		const completedLeads = this.leadsSelectedMonth.filter((lead) => lead.status === ProjectStatusDTO.Completed);
		const totalRevenue = completedLeads.reduce((sum, lead) => sum + (lead.estimatedBudget || 15000), 0);
		return totalRevenue;
	}

	get unprocessedJobs(): number {
		const thresholdDays = 3; // Example threshold
		return this.leadsSelectedMonth.filter((lead) => {
			const updatedDate = new Date(lead.updated);
			const daysSinceUpdate = (new Date().getTime() - updatedDate.getTime()) / (1000 * 60 * 60 * 24);
			return lead.status === ProjectStatusDTO.Requested && daysSinceUpdate > thresholdDays;
		}).length;
	}

	get projectsAtRisk(): Project[] {
		const thresholdDays = 3; // Example threshold
		return this.projectsSelectedMonth.filter((project) => {
			const updatedDate = new Date(project.updated);
			const daysSinceUpdate = (new Date().getTime() - updatedDate.getTime()) / (1000 * 60 * 60 * 24);
			return (
				daysSinceUpdate > thresholdDays &&
				![ProjectStatusDTO.Completed, ProjectStatusDTO.Aborted, ProjectStatusDTO.ProjectLost].includes(
					project.status as ProjectStatusDTO
				)
			);
		});
	}

	get leadsAtRisk(): ProjectLead[] {
		const thresholdDays = 3; // Example threshold
		return this.leadsSelectedMonth.filter((lead) => {
			const updatedDate = new Date(lead.updated);
			const daysSinceUpdate = (new Date().getTime() - updatedDate.getTime()) / (1000 * 60 * 60 * 24);
			return (
				daysSinceUpdate > thresholdDays &&
				![ProjectStatusDTO.Completed, ProjectStatusDTO.Aborted, ProjectStatusDTO.ProjectLost].includes(
					lead.status as ProjectStatusDTO
				)
			);
		});
	}

	get potentialLossValue(): number {
		return this.projectsAtRisk.reduce((sum, lead) => sum + (lead.estimatedBudget || 15000), 0);
	}

	get potentialLeadLossValue(): number {
		return this.leadsAtRisk.reduce((sum, lead) => sum + (lead.estimatedBudget || 15000), 0);
	}

	// Stat methods
	get revenueGeneratedStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.RevenueGenerated,
			title: 'Generert inntekt',
			number: this.completedProjects.reduce(
				(acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0),
				0
			),
			unit: 'kr',
		};
	}

	get projectsUnprocessedStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.UnprocessedJobs,
			title: 'Ubehandlede jobber',
			number: this.unprocessedProjects.length,
		};
	}

	get projectsCompletedStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsCompleted,
			title: 'Fullførte jobber',
			number: this.completedProjects.length,
		};
	}

	get projectsUnhandledCustomerMessagesStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsUnhandledCustomerMessages,
			title: 'Ubesvarte kundemeldinger',
			number: this.projectsUnhandledCustomerMessages.length,
		};
	}

	get projectsInProgressStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsInProgress,
			title: 'Påbegynte jobber',
			number: this.projectsInProgress.length,
		};
	}

	get projectsDelegatedStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsDelegated,
			title: 'Delegerte jobber',
			number: this.delegatedProjects.length,
		};
	}

	get projectsUnprocessedValueStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsUnprocessedValue,
			title: 'Est. verdi ubehandlede jobber',
			number: this.unprocessedProjects.reduce(
				(acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 15000),
				0
			),
			unit: 'kr',
		};
	}

	get projectsInProgressValueStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsInProgressValue,
			title: 'Est. verdi påbegynte jobber',
			number: this.projectsInProgress.reduce(
				(acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 15000),
				0
			),
			unit: 'kr',
		};
	}

	get projectsHoursLeftStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsHoursLeft,
			title: 'Est. Arbeid gjenstår',
			number: this.projectsInProgress.reduce(
				(acc, project) => acc + (project.estimatedHours ? +project.estimatedHours : 0),
				0
			),
			unit: 't',
		};
	}

	get valueOfLostJobsStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ValueOfLostJobs,
			title: 'Verdi av tapte jobber',
			number: this.projectsSelectedMonth
				.filter((project) => project.status === ProjectStatusDTO.ProjectLost)
				.reduce((acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0), 0),
			unit: 'kr',
		};
	}

	get estimatedBudgetStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.EstimatedBudget,
			title: 'Estimert budsjett',
			number: this.projectsSelectedMonth.reduce(
				(acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0),
				0
			),
			unit: 'kr',
		};
	}

	get estimatedHoursOfWorkStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.EstimatedHoursOfWork,
			title: 'Estimert timer',
			number: this.projectsSelectedMonth.reduce(
				(acc, project) => acc + (project.estimatedHours ? +project.estimatedHours : 0),
				0
			),
			unit: 't',
		};
	}

	get revenueForecastStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.RevenueForecast,
			title: 'Inntektsprognose',
			number: this.projectsSelectedMonth
				.filter((project) => project.status !== ProjectStatusDTO.ProjectLost)
				.reduce((acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0), 0),
			unit: 'kr',
		};
	}

	get valueOfOffersSentStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ValueOfOffersSent,
			title: 'Verdi av tilbud sendt',
			number: this.projectsSelectedMonth
				.filter((project) => project.status === ProjectStatusDTO.OfferSent)
				.reduce((acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0), 0),
			unit: 'kr',
		};
	}

	get valueOfOffersRejectedStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ValueOfOffersRejected,
			title: 'Verdi av tilbud avvist',
			number: this.projectsSelectedMonth
				.filter((project) => project.status === ProjectStatusDTO.OfferRejected)
				.reduce((acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0), 0),
			unit: 'kr',
		};
	}

	get valueOfJobsInLimboStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ValueOfJobsInLimbo,
			title: 'Verdi av jobber i limbo',
			number: this.projectsSelectedMonth
				.filter((project) => {
					const updatedDate = new Date(project.updated);
					const daysSinceUpdate = (new Date().getTime() - updatedDate.getTime()) / (1000 * 60 * 60 * 24);
					return daysSinceUpdate > 7;
				})
				.reduce((acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0), 0),
			unit: 'kr',
		};
	}

	// Methods returning N/A for missing data
	get projectsAtRiskStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ProjectsAtRisk,
			title: 'Prosjekter i fare',
			number: this.projectsAtRisk.length,
		};
	}

	get averageProjectCompletionTimeStat(): TSingleValueStat {
		const completedLeads = this.leadsSelectedMonth.filter((lead) => lead.status === ProjectStatusDTO.Completed);
		const totalDays = completedLeads.reduce((sum, lead) => {
			const created = new Date(lead.created);
			const completed = new Date(lead.updated);
			return sum + (completed.getTime() - created.getTime()) / (1000 * 60 * 60 * 24);
		}, 0);
		const completionDays = completedLeads.length > 0 ? totalDays / completedLeads.length : 0;
		return {
			key: ESingleValueStatKey.AverageProjectCompletionTime,
			title: 'Gj.snitt fullføringstid',
			number: completionDays,
			unit: 'dager',
		};
	}

	get newCustomersStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.NewCustomers,
			title: 'Nye kunder',
			number: this.customersSelectedMonth.length,
		};
	}

	get customerSatisfactionStat(): TSingleValueStat {
		const avgRating = this.ratingStore?.allRatings?.reduce((acc, rating) => acc + rating.score, 0);

		return {
			key: ESingleValueStatKey.CustomerSatisfaction,
			title: 'Kundetilfredshet',
			number: this.ratingStore?.allRatings?.length > 0 ? avgRating / this.ratingStore.allRatings.length : 0,
			unit: '⭐',
		};
	}

	get averageResponseTimeStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.AverageResponseTime,
			title: 'N/A: Gj.snitt svartid',
			number: 0,
			unit: 't',
		};
	}

	get employeeUtilizationRateStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.EmployeeUtilizationRate,
			title: 'N/A: Ansatte utnyttelsesgrad',
			number: 0,
			unit: '%',
		};
	}

	get topPerformingEmployeesStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.TopPerformingEmployees,
			title: 'N/A: Topp ansatte',
			number: 0,
		};
	}

	get totalJobsInAreaStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.TotalJobsInArea,
			title: 'N/A: Totale jobber i området',
			number: 0,
		};
	}

	get jobsForCompanyStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.JobsForCompany,
			title: 'Jobber for selskapet',
			number: this.projectsSelectedMonth.length,
		};
	}

	get conversionRateStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.ConversionRate,
			title: 'Konverteringsrate',
			number: this.conversionRate,
			unit: '%',
		};
	}

	get totalLeadsStat(): TSingleValueStat {
		return {
			key: ESingleValueStatKey.TotalLeads,
			title: 'Totale leads',
			number: this.totalLeads,
		};
	}

	get delegatedProjectsInProgressStat(): TSingleValueStat {
		const delegatedInProgress = this.delegatedProjects.filter(
			(project) => project.statusCategory === ProjectStatusCategory.InProgress
		);

		return {
			key: ESingleValueStatKey.DelegatedProjectsInProgress,
			title: 'Påbegynte delegerte jobber',
			number: delegatedInProgress.length,
		};
	}

	get delegatedProjectsCompletedStat(): TSingleValueStat {
		const delegatedCompleted = this.delegatedProjects.filter(
			(project) => project.statusCategory === ProjectStatusCategory.Done
		);
		return {
			key: ESingleValueStatKey.DelegatedProjectsCompleted,
			title: 'Fullførte delegerte jobber',
			number: delegatedCompleted.length,
		};
	}

	get delegatedProjectsValueStat(): TSingleValueStat {
		const delegatedValue = this.delegatedProjects.reduce(
			(acc, project) => acc + (project.estimatedBudget ? +project.estimatedBudget : 0),
			0
		);
		return {
			key: ESingleValueStatKey.DelegatedProjectsValue,
			title: 'Verdi av delegerte jobber',
			number: delegatedValue,
			unit: 'kr',
		};
	}

	get totalJobsForChainStat(): TSingleValueStat {
		const totalLeadsNotDone = this.leads.filter(
			(lead) =>
				![ProjectStatusDTO.Completed, ProjectStatusDTO.ProjectLost].includes(lead.status as ProjectStatusDTO)
		).length;
		return {
			key: ESingleValueStatKey.TotalJobsForChain,
			title: 'Totale jobber for kjeden',
			number: totalLeadsNotDone,
		};
	}

	get totalRevenueForChainStat(): TSingleValueStat {
		const totalRevenue = this.leads.reduce(
			(acc, lead) => acc + (lead.estimatedBudget ? +lead.estimatedBudget : 0),
			0
		);
		return {
			key: ESingleValueStatKey.TotalRevenueForChain,
			title: 'Totale est. inntekter for kjeden',
			number: totalRevenue,
			unit: 'kr',
		};
	}

	get totalLeadsForChainStat(): TSingleValueStat {
		const totalLeadsNotDone = this.leads.length;
		return {
			key: ESingleValueStatKey.TotalLeadsForChain,
			title: 'Totale leads for kjeden',
			number: totalLeadsNotDone,
		};
	}

	get totalUnprocessedJobsForChainStat(): TSingleValueStat {
		const totalUnprocessedJobs = this.leads.filter(
			(lead) => lead.status === ProjectStatusDTO.Requested && lead.hasUnhandledCustomerMessages
		).length;
		return {
			key: ESingleValueStatKey.TotalUnprocessedJobsForChain,
			title: 'Totale ubehandlede jobber for kjeden',
			number: totalUnprocessedJobs,
		};
	}

	setSelectedMonth(month: Date) {
		this.selectedMonth = new Date(month.getFullYear(), month.getMonth(), 1);
		// use comparisonPeriodFactory to get comparison data, set to previous month
		if (this.comparisonPeriodFactory) {
			this.comparisonPeriodFactory.setSelectedMonth(new Date(month.getFullYear(), month.getMonth() - 1, 1));
		}

		this.buildSingleValueStats();
	}

	buildSingleValueStats() {
		const stats = [
			this.revenueGeneratedStat,
			this.projectsUnprocessedStat,
			this.projectsCompletedStat,
			this.projectsUnhandledCustomerMessagesStat,
			this.projectsInProgressStat,
			this.projectsDelegatedStat,
			this.projectsUnprocessedValueStat,
			this.projectsInProgressValueStat,
			this.projectsHoursLeftStat,
			this.valueOfLostJobsStat,
			this.estimatedBudgetStat,
			this.estimatedHoursOfWorkStat,
			this.revenueForecastStat,
			this.valueOfOffersSentStat,
			this.valueOfOffersRejectedStat,
			this.valueOfJobsInLimboStat,
			this.projectsAtRiskStat,
			this.averageProjectCompletionTimeStat,
			this.newCustomersStat,
			this.customerSatisfactionStat,
			this.averageResponseTimeStat,
			this.employeeUtilizationRateStat,
			this.topPerformingEmployeesStat,
			this.totalJobsInAreaStat,
			this.jobsForCompanyStat,
			this.conversionRateStat,
			this.totalLeadsStat,
			this.delegatedProjectsInProgressStat,
			this.delegatedProjectsCompletedStat,
			this.delegatedProjectsValueStat,
			this.totalJobsForChainStat,
			this.totalRevenueForChainStat,
			this.totalLeadsForChainStat,
			this.totalUnprocessedJobsForChainStat,
		];

		if (this.comparisonPeriodFactory) {
			this.comparisonPeriodFactory.buildSingleValueStats();
		}

		this.singleValueStats = stats.map((stat) => {
			if (this.comparisonPeriodFactory) {
				const comparisonStat = this.comparisonPeriodFactory.getSingleValueStatByKey(stat.key);
				if (comparisonStat) {
					const model = new SingleValueStatModel({
						...stat,
						comparison: comparisonStat,
					});
					return model;
				}
			}
			return new SingleValueStatModel(stat);
		});
	}

	getSingleValueStatByKey(key: string): SingleValueStatModel | undefined {
		return this.singleValueStats.find((stat) => stat.key === key);
	}

	getSingleValueStatsByUserConfig(userConfig: TSingleValueStatUserConfig[]): SingleValueStatModel[] {
		const stats: SingleValueStatModel[] = userConfig
			.map((config) => this.getSingleValueStatByKey(config.key))
			.filter((stat) => !!stat?.key) as SingleValueStatModel[];

		// sort stats by order
		const sortedStats: SingleValueStatModel[] = [];
		userConfig
			.sort((a, b) => a.order - b.order)
			.forEach((config) => {
				const stat = stats.find((s) => s.key === config.key);
				if (stat) {
					sortedStats.push(stat);
				}
			});

		return sortedStats;
	}

	getSingleValueStatsByKeys(keys: string[]) {
		return keys.map((key) => this.getSingleValueStatByKey(key));
	}
}
