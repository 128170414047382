import { Box, Portal, styled } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import ChatInputIndicators from './ChatInputIndicators';
import { Channel } from '../../../../stores/ChatStore';
import { ChatInputActionType } from './ChatInputActions';

import { useDropzone } from 'react-dropzone';
import CreateVideoCallDialog from '../../dialogs/CreateVideoCallDialog';
import NewEventDialog from '../../NewEventDialog';
import ReactionsDialog from '../../reactions/ReactionsDialog';
import { EventDTO } from '../../../../dto/event.types';
import StoreContext from '../../../../stores/StoreContext';
import { Profile } from '../../../../stores/ProfileStore';
import ChatInputTextarea from './ChatInputTextarea';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { Emoji } from '../../../../types';
import ErrorBoundary from '../../../../utils/ErrorBoundary';
import ShareProjectDialog from '../../../dashboard2/projects/share-project/ShareProjectDialog';

const StyledDropzone = styled(Box)(({ theme }) => ({
	'display': 'flex',
	'flexDirection': 'column',
	'position': 'relative',
	'alignItems': 'center',
	'justifyContent': 'center',
	'height': 'auto',
	'width': '100%',
	[theme.breakpoints.up('md')]: {},
	'&.dragging': {
		'background': theme.palette.primary.light,
		'border': `2px dashed ${theme.palette.primary.dark}`,
		'&::before': {
			content: '"Drop here"',
			color: theme.palette.primary.dark,
			fontSize: '1.5rem',
			position: 'absolute',
		},
	},
}));

type Props = {
	channel?: Channel;
	customerServiceMode?: boolean;
	imageUploadOnly?: boolean;
};

const ChatInput = observer((props: Props) => {
	const { profileStore, userStore, projectStore, videoChatStore, eventStore } = useContext(StoreContext);
	const { channel, customerServiceMode, imageUploadOnly } = props;

	if (!channel?.id) {
		return null;
	}

	const project = projectStore.getProjectByChannelId(channel.id);

	const [dragging, setDragging] = React.useState(false);
	const [uploading, setUploading] = React.useState(false);

	const uploadAndAttachFile = async (file: File) => {
		// verify file type
		if (imageUploadOnly && !file.type.startsWith('image')) {
			toast.error('Du kan kun laste opp bilder i denne kanalen');
			return;
		}
		setUploading(true);

		const uploadedFile = await userStore.uploadFile(
			file,
			file.name,
			customerServiceMode,
			channel?.id ? parseInt(channel.id) : undefined
		);

		if (uploadedFile.statusCode === 200) {
			console.log('Uploaded file', uploadedFile.data[0]);
			runInAction(() => {
				channel.draftMessage.addContent({
					contentType: 'file',
					file: uploadedFile.data[0],
					comment: file.name,
				});
			});
			setUploading(false);
		} else {
			toast.error(`Fikk ikke til å laste opp filen: ${file.name}`);
			setUploading(false);
			throw new Error(`Error uploading file: ${file.name}`);
		}
	};

	const uploadAndAttachFiles = async (files: File[]) => {
		console.log('Uploading and attaching files', files);
		for await (const file of files) {
			await uploadAndAttachFile(file);
		}
	};

	// eslint-disable-next-line no-unused-vars
	const { getRootProps, open, getInputProps } = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		onDragEnter: () => {
			setDragging(true);
		},
		onDrop(acceptedFiles, fileRejections, event) {
			console.log('onDrop', acceptedFiles, fileRejections, event);
			uploadAndAttachFiles(acceptedFiles).catch((error) => {
				console.error('Error uploading and attaching files', error);
			});
			setDragging(false);
		},
		onDragLeave: () => {
			setDragging(false);
		},
		preventDropOnDocument: true,
		noDragEventsBubbling: true,
	});

	const startVideoCall = (channel: Channel, options: any) => {
		console.log('Starting video call', channel, options);
		const project = projectStore.getProjectByChannelId(channel.id);
		if (!project) {
			toast.error('Error starting video call: Project not found');
			return;
		}

		videoChatStore
			.startCall({
				channelId: channel.id,
				projectId: project.id,
				notifyUserIds: options,
			})
			.catch((error: any) => {
				console.error('Error starting video call', error);
			})
			.finally(() => {
				setShowCreateVideoCallDialog(false);
			});
	};

	const handleMeetingSend = (event: Partial<EventDTO>) => {
		if (event) {
			eventStore
				.createEvent(event, channel.id)
				.catch((error: any) => {
					console.error('Error creating event', error);
				})
				.finally(() => {
					setIsMeetingDialogOpen(false);
				});
		}
	};

	const handleAddReaction = (reaction: Emoji) => {
		channel.draftMessage.text += ` ${reaction.utf8Emoji}`;
		setReactionsOpen(false);
	};

	const [reactionsOpen, setReactionsOpen] = React.useState(false);
	const [showCreateVideoCallDialog, setShowCreateVideoCallDialog] = React.useState(false);
	const [isMeetingDialogOpen, setIsMeetingDialogOpen] = React.useState(false);
	const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);

	const onActionClick = (action: ChatInputActionType) => {
		switch (action) {
			case ChatInputActionType.Attach:
			case ChatInputActionType.AttachFile:
			case ChatInputActionType.AttachImage:
				console.log('Opening file dialog');
				open();
				break;
			case ChatInputActionType.VideoCall:
				setShowCreateVideoCallDialog(true);
				break;
			case ChatInputActionType.EventInvite:
				setIsMeetingDialogOpen(true);
				break;
			case ChatInputActionType.Reaction:
				setReactionsOpen(true);
				break;
			case ChatInputActionType.PurifyMessage:
				channel.draftMessage.purify().catch((error) => {
					console.error('Failed to purify message', error);
				});
				break;
			case ChatInputActionType.Share:
				setIsShareDialogOpen(true);
				break;
		}
	};

	const channelMemberProfiles: Profile[] = channel?.groupMembers
		.map((member: any) => profileStore.getProfile(member.userId))
		.filter((p) => Boolean(p)) as Profile[];

	if (!channel) {
		return null;
	}

	return (
		<ErrorBoundary>
			<StyledDropzone className={dragging ? 'dragging' : ''} {...getRootProps()}>
				<Portal container={() => document.querySelector('body')!}>
					<input {...getInputProps()} />
				</Portal>
				<ChatInputIndicators channel={channel} />
				<ChatInputTextarea
					channel={channel}
					onActionClick={onActionClick}
					isUploading={uploading}
					customerServiceMode={customerServiceMode}
				/>

				{isMeetingDialogOpen && (
					<NewEventDialog
						channelId={channel.id}
						participants={channelMemberProfiles}
						confirmingAction={handleMeetingSend}
						dismissiveAction={() => setIsMeetingDialogOpen(false)}
					/>
				)}

				{reactionsOpen && (
					<ReactionsDialog
						open={reactionsOpen}
						onClose={() => setReactionsOpen(false)}
						onSelect={handleAddReaction}
					/>
				)}

				{showCreateVideoCallDialog && (
					<CreateVideoCallDialog
						open={showCreateVideoCallDialog}
						channel={channel}
						onStartCall={startVideoCall}
						onClose={() => setShowCreateVideoCallDialog(false)}
					/>
				)}

				{project && isShareDialogOpen && (
					<ShareProjectDialog
						open={isShareDialogOpen}
						onClose={() => setIsShareDialogOpen(false)}
						project={project}
					/>
				)}
			</StyledDropzone>
		</ErrorBoundary>
	);
});

export default ChatInput;
