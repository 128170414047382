/* eslint-disable no-unused-vars */
import { Alert } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import LogUtil from '../helpers/LogUtil';
import { RootStore } from '../stores/RootStore';
import StoreContext from '../stores/StoreContext';

type Props = {
	children: React.ReactNode | React.ReactNode[] | undefined;
};

const CraftsmanGuard = observer(
	class CraftsmanGuard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				isCraftsman: computed,
			});

			this.state = { hasError: false };
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get isCraftsman() {
			return this.rootStore.profileStore.isCraftsman;
		}

		static getDerivedStateFromError(error: any) {
			// Update state so the next render will show the fallback UI.
			return { hasError: true };
		}

		componentDidCatch(error: any, errorInfo: any) {
			// You can also log the error to an error reporting service
			// @todo change this to error
			LogUtil.debug(error, errorInfo);
		}

		render() {
			if ((this.state as any).hasError) {
				// You can render any custom fallback UI
				return <Alert severity="error">Noe gikk galt</Alert>;
			}

			if (this.isCraftsman) {
				return this.props.children;
			}
		}
	}
);

export default CraftsmanGuard;
