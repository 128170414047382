import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';

/**
 * Notifications
 */
export default class NotificationApi {
	static async getNotifications(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/notification`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async clearNotifications(notificationIds: string[]): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/notification/clear`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				notificationIds,
			}),
			requireAuth: true,
		});
	}
}
