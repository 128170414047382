/* eslint eqeqeq: "off" */

import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import RatingApi, { RatingDTO, ProjectRatingDTO } from '../api/endpoints/RatingApi';

/**
 * ProjectStore
 */
export class RatingStore {
	rootStore: RootStore;
	allRatings: RatingDTO[] = [];

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.init();
	}

	init() {
		this.rootStore.userStore.SignedOut.on(this.reset);
		this.getRatings();
	}

	reset() {}

	async getRatings() {
		const result = await RatingApi.getRatings();
		if (result.statusCode === 200 && result.data?.length > 0) {
			this.allRatings = result.data;
		}
	}

	async rateProject(rating: ProjectRatingDTO) {
		const result = await RatingApi.postRating(rating);
		if (result.statusCode === 200 && result.data?.length > 0) {
			console.log('Ratings: ', result.data.success);
			this.addOrUpdateRating(result.data.success);
		}
		return result;
	}

	async getProjectRating() {
		const result = await RatingApi.getRatings();
		if (result.statusCode === 200 && result.data?.length > 0) {
			this.allRatings = result.data;
		}
	}

	addOrUpdateRating(rating: RatingDTO) {
		const index = this.allRatings.findIndex((r) => r.id === rating.id);
		if (index >= 0) {
			this.allRatings[index] = rating;
		} else {
			this.allRatings.push(rating);
		}
	}
}
