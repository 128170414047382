/* eslint-disable no-unused-vars */
export enum ResourceType {
	Project = 'Project',
	Channel = 'Channel',
}

export enum GranteeType {
	User = 'User',
	Profile = 'Profile',
	Workspace = 'Workspace',
}

export enum AccessLevel {
	Read = 'Read',
	Write = 'Write',
	Admin = 'Admin',
}

export type AccessDetail = {
	resourceType: ResourceType;
	projectId?: number;
	channelId?: number;
	resourceId: number; // For a project, this is the same as projectId; for a channel, it's channelId
	accessLevel: AccessLevel;
};

export enum InviteStatus {
	Pending = 'Pending',
	Accepted = 'Accepted',
	Declined = 'Declined',
	Cancelled = 'Cancelled',
	Expired = 'Expired',
}

export type GranteeAccess = {
	id?: number; // bigint in SQL, but we'll use number here
	granteeType: GranteeType;
	inviteId?: number; // bigint in SQL, but we'll use number here
	inviteStatus?: InviteStatus;
	userId: number; // Always present, tying access to a specific user
	profileId?: number; // Optional for specific profile access
	workspaceId?: number; // Optional for workspace-wide access
	accessDetails: AccessDetail[]; // List of resources the grantee has access to
};

export type ProjectAccessOverview = {
	projectId: number;
	granteeAccessList: GranteeAccess[]; // List of all entities with access to the project
};

export type ChannelAccessOverview = {
	channelId: number;
	granteeAccessList: GranteeAccess[]; // List of all entities with access to the channel
};

export type ResourceAccess = {
	resourceType: ResourceType;
	resourceId: number; // The ID of the resource (e.g., projectId, channelId)
	accessLevel: AccessLevel;
};

export type ShareAccessEntry = {
	granteeType: GranteeType; // The type of access being granted
	profileId?: number; // Required if accessType is 'Profile'
	workspaceId?: number; // Required if accessType is 'Workspace'
	userId?: number; // Optional: Specific user ID for the access
	phoneNumber?: string; // Optional: Phone number for inviting a new user
	email?: string; // Optional: Email for inviting a new user
	notify?: boolean; // Optional: Whether to notify the user of this access grant
	message?: string; // Optional: A custom message to include in the notification
	resources: ResourceAccess[]; // Array of resources to which access is granted
};

export type ShareAccessRequest = ShareAccessEntry[];
