import { makeAutoObservable, runInAction } from 'mobx';
import ChecklistApi from '../../api/endpoints/ChecklistApi';

// dtos/ChecklistDTO.ts
export type ChecklistDTO = {
	id: number;
	name: string;
	description: string;
	items: ChecklistItemDTO[];
	execution: ChecklistExecutionDTO | null;
};

export type ChecklistItemDTO = {
	id: number;
	itemText: string;
	title: string;
	order: number;
	isRequired: boolean;
	itemType: 'checkbox' | 'text' | 'radio' | 'section';
	options: ChecklistItemOptionDTO[] | null;
	executionItem: ChecklistExecutionItemDTO | null;
};

export type ChecklistItemOptionDTO = {
	id: number;
	optionText: string;
};

export type ChecklistExecutionDTO = {
	id: number;
	checklistId: number;
	projectId: number;
	executionDate: Date;
	responses: ChecklistExecutionItemDTO[];
};

export type ChecklistExecutionItemDTO = {
	id: number;
	checklistItemId: number;
	responseText: string | null;
	responseChecked: boolean | null;
	responseOptionId: number | null;
	userId: number;
	profileId?: number;
	created: Date;
	updated: Date;
};

export class ChecklistItem {
	id: number;
	itemText: string;
	title: string;
	order: number;
	isRequired: boolean;
	itemType: 'checkbox' | 'text' | 'radio' | 'section';
	options: ChecklistItemOption[] | null;
	executionItem!: ChecklistExecutionItem;

	constructor(dto: ChecklistItemDTO) {
		this.id = dto.id;
		this.itemText = dto.itemText;
		this.isRequired = dto.isRequired;
		this.itemType = dto.itemType;
		this.title = dto.title;
		this.order = dto.order;
		this.options = dto.options ? dto.options.map((option) => new ChecklistItemOption(option)) : null;
		const defaultExecutionItem: ChecklistExecutionItemDTO = {
			id: 0,
			checklistItemId: this.id,
			responseText: '',
			responseChecked: false,
			responseOptionId: null,
			userId: 0,
			created: new Date(),
			updated: new Date(),
		};
		this.executionItem = dto.executionItem
			? new ChecklistExecutionItem(dto.executionItem)
			: new ChecklistExecutionItem(defaultExecutionItem);

		makeAutoObservable(this);
	}

	get asDTO(): ChecklistItemDTO {
		return {
			id: this.id,
			itemText: this.itemText,
			title: this.title,
			order: this.order,
			isRequired: this.isRequired,
			itemType: this.itemType,
			options: this.options ? this.options.map((option) => option.asDTO) : null,
			executionItem: this.executionItem.asDTO,
		};
	}

	upsertExecutionItem(executionItem: ChecklistExecutionItem): void {
		this.executionItem = executionItem;
	}
}

export class ChecklistItemOption {
	id: number;
	optionText: string;

	constructor(dto: ChecklistItemOptionDTO) {
		this.id = dto.id;
		this.optionText = dto.optionText;

		makeAutoObservable(this);
	}

	get asDTO(): ChecklistItemOptionDTO {
		return {
			id: this.id,
			optionText: this.optionText,
		};
	}
}

export class ChecklistExecution {
	id: number;
	checklistId: number;
	projectId: number;
	executionDate: Date;
	responses: ChecklistExecutionItem[];

	constructor(dto: ChecklistExecutionDTO) {
		this.id = dto.id;
		this.checklistId = dto.checklistId;
		this.executionDate = dto.executionDate;
		this.projectId = dto.projectId;
		this.responses = dto.responses.map((response) => new ChecklistExecutionItem(response));

		makeAutoObservable(this);
	}

	get asDTO(): ChecklistExecutionDTO {
		return {
			id: this.id,
			checklistId: this.checklistId,
			projectId: this.projectId,
			executionDate: this.executionDate,
			responses: this.responses.map((response) => response),
		};
	}
}

export class ChecklistExecutionItem {
	id: number;
	checklistItemId: number;
	responseText: string | null;
	responseChecked: boolean | null;
	responseOptionId: number | null;
	userId: number;
	profileId?: number;
	created: Date;
	updated: Date;

	constructor(dto: ChecklistExecutionItemDTO) {
		this.id = dto.id;
		this.checklistItemId = dto.checklistItemId;
		this.responseText = dto.responseText;
		this.responseChecked = dto.responseChecked;
		this.responseOptionId = dto.responseOptionId;
		this.userId = dto.userId;
		this.profileId = dto.profileId;
		this.created = dto.created ? new Date(dto.created) : new Date();
		this.updated = dto.updated ? new Date(dto.updated) : new Date();

		makeAutoObservable(this);
	}

	get asDTO(): ChecklistExecutionItemDTO {
		return {
			id: this.id,
			checklistItemId: this.checklistItemId,
			responseText: this.responseText,
			responseChecked: this.responseChecked,
			responseOptionId: this.responseOptionId,
			userId: this.userId,
			created: this.created,
			updated: this.updated,
		};
	}
}

export default class Checklist {
	id: number;
	name: string;
	description: string;
	items: ChecklistItem[];
	execution: ChecklistExecution | null;

	constructor(dto: ChecklistDTO) {
		this.id = dto.id;
		this.name = dto.name;
		this.description = dto.description;
		this.items = dto.items.map((item) => new ChecklistItem(item));
		this.execution = dto.execution?.id ? new ChecklistExecution(dto.execution) : null;

		makeAutoObservable(this);
	}

	get completed(): boolean {
		return this.numRemaining === 0;
	}

	get numRemaining(): number {
		return this.items.filter((item) => item.itemType !== 'section' && !item.executionItem?.responseChecked).length;
	}

	get numItems(): number {
		return this.items.length;
	}

	get sortedItems(): ChecklistItem[] {
		return this.items.slice().sort((a, b) => a.order - b.order);
	}

	get asDTO(): ChecklistDTO {
		return {
			id: this.id,
			name: this.name,
			description: this.description,
			items: this.items.map((item) => ({
				id: item.id,
				itemText: item.itemText,
				title: item.title,
				order: item.order,
				isRequired: item.isRequired,
				itemType: item.itemType,
				options: item.options ? item.options.map((option) => option.asDTO) : null,
				executionItem: item.executionItem ? item.executionItem.asDTO : null,
			})),
			execution: this.execution ? this.execution : null,
		};
	}

	deleteItem(item: ChecklistItem): void {
		this.items = this.items.filter((i) => i.id !== item.id);
	}

	updateFromDTO(dto: ChecklistDTO): void {
		this.name = dto.name;
		this.description = dto.description;
		this.items = dto.items.map((item) => {
			const existingItem = this.items.find((i) => i.id === item.id);
			if (existingItem && item.executionItem?.id) {
				existingItem.upsertExecutionItem(new ChecklistExecutionItem(item.executionItem!));
				return existingItem;
			}
			return new ChecklistItem(item);
		});
	}

	addEmptyItem(): void {
		const newItem: ChecklistItemDTO = {
			id: 0,
			itemText: 'Beskrivelse av sjekkpunkt',
			title: 'Valgfri overskrift for sjekkpunkt',
			order: this.items.length,
			isRequired: false,
			itemType: 'text',
			options: null,
			executionItem: null,
		};
		this.items.push(new ChecklistItem(newItem));
	}

	async save() {
		if (!this.id) {
			// Create new checklist
			const result = await ChecklistApi.createChecklist(this.asDTO);
			if (result.statusCode === 200) {
				runInAction(() => {
					this.id = result.data.id;
				});
				this.updateFromDTO(result.data);
			} else {
				throw new Error('Failed to create checklist');
			}
		} else {
			const result = await ChecklistApi.updateChecklist(this.asDTO);
			if (result.statusCode === 200) {
				this.updateFromDTO(result.data);
			} else {
				throw new Error('Failed to update checklist');
			}
		}
	}

	async saveAnswer(
		executionItem: Pick<
			ChecklistExecutionItemDTO,
			'checklistItemId' | 'responseText' | 'responseChecked' | 'responseOptionId'
		>
	): Promise<void> {
		if (!this.execution) {
			return;
		}
		// Save answer to backend
		const result = await ChecklistApi.saveAnswer(this.id, this.execution?.id, executionItem);
		if (result.statusCode === 200) {
			// Update the local execution item
			runInAction(() => {
				const item = this.items.find((item) => item.id === executionItem.checklistItemId);
				if (item) {
					item.upsertExecutionItem(new ChecklistExecutionItem(result.data));
				}
			});
		} else {
			throw new Error('Failed to save answer');
		}
	}
}
