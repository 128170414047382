import { Workspace } from '../types';
import SSOAuthDetails from './SSOAuthDetails';

type AsyncStorageKey = '@BefWeb:Workspace' | '@BefWeb:SSO_AuthDetails' | '@BefWeb:Befare_JWT';

export type CookieConsentType = {
	allowRequired: boolean | undefined;
	allowAnalytics: boolean | undefined;
	allowMarketing: boolean | undefined;
	isSet?: boolean;
};

export default class AsyncStorageHelper {
	static async clearCache(): Promise<void> {
		await this.removeWorkspaceCache();
		await this.removeSSOAuthDetailsCache();
		await this.removeBefareJWTCache();
	}

	// Workspace
	static async getCachedWorkspace(): Promise<Workspace | undefined> {
		const cache = await this.getCache('@BefWeb:Workspace');
		return cache ? (cache as Workspace) : undefined;
	}

	static async cacheWorkspace(workspace: Workspace) {
		return this.cacheAsync('@BefWeb:Workspace', workspace);
	}

	static async removeWorkspaceCache() {
		return this.removeCache('@BefWeb:Workspace');
	}

	// Cookies
	static async getCachedCookieConsent(): Promise<CookieConsentType> {
		const cache = await this.getCache('@Befare:CookieConsent2');
		return {
			allowRequired: cache?.allowRequired,
			allowAnalytics: cache?.allowAnalytics,
			allowMarketing: cache?.allowMarketing,
			isSet: Boolean(cache),
		};
	}

	static async cacheCookieConsent(consent: CookieConsentType) {
		return this.setCache('@Befare:CookieConsent2', consent);
	}

	// SSO Auth Details
	static async getCachedSSOAuthDetails(): Promise<SSOAuthDetails | undefined> {
		const cache = this.getCache('@BefWeb:SSO_AuthDetails');
		return cache ? (cache as SSOAuthDetails) : undefined;
	}

	static async cacheSSOAuthDetails(authDetails: SSOAuthDetails) {
		return this.cacheAsync('@BefWeb:SSO_AuthDetails', authDetails);
	}

	static async removeSSOAuthDetailsCache() {
		return this.removeCache('@BefWeb:SSO_AuthDetails');
	}

	// SSO Auth Befare JWT
	static async getCachedBefareJWT(): Promise<string | undefined> {
		const cache = this.getCache('@BefWeb:Befare_JWT');
		return cache ? (cache as string) : undefined;
	}

	static async cacheBefareJWT(jwt: string) {
		return this.cacheAsync('@BefWeb:Befare_JWT', jwt);
	}

	static async removeBefareJWTCache() {
		return this.removeCache('@BefWeb:Befare_JWT');
	}

	// AsyncStorage actuators
	static async removeCache(key: string) {
		return localStorage.removeItem(key);
	}

	static getCache(key: string) {
		const cache = localStorage.getItem(key);
		try {
			return cache ? JSON.parse(cache) : undefined;
		} catch (err) {
			console.error(err);
			return undefined;
		}
	}

	private static cacheAsync(key: AsyncStorageKey, value: any) {
		return localStorage.setItem(key, JSON.stringify(value));
	}

	static setCache(key: string, value: any) {
		return localStorage.setItem(key, JSON.stringify(value));
	}
}
