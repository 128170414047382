import { ListItem, ListItemText } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { Address } from '../../types';

type Props = {
	address: Address;
};

const AddressText = observer(
	class AddressText extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				uiState: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		render() {
			const { isComputer } = this.uiState;
			const { street, addressString, postCode, postArea } = this.props.address;

			return (
				<ListItem className="AddressText" dense={isComputer}>
					<ListItemText primary={`${addressString ?? street}`} secondary={`${postCode} ${postArea}`} />
				</ListItem>
			);
		}
	}
);

export default AddressText;
