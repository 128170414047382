import { makeObservable, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import config from '../../config/config';
import { Address } from '../../types';

type Props = {
	address: Address;
	destination?: Address;
	height?: string | number;
};

const MapFrame = observer(
	class Map extends React.Component<Props> {
		iFrame = React.createRef<HTMLIFrameElement>();

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				iFrame: observable,
				googleMapsUrl: computed,
			});
		}

		get apiKey() {
			return config.maps.apiKey;
		}

		get googleMapsUrl() {
			const { address: a, destination: d } = this.props;

			const q = this.makeAddressString(a);

			if (d) {
				const origin = q;
				const destination = this.makeAddressString(d);

				return `https://www.google.com/maps/embed/v1/directions?origin=${origin}&destination=${destination}&key=${this.apiKey}`;
			}

			return `https://www.google.com/maps/embed/v1/place?q=${q}&key=${this.apiKey}`;
		}

		makeAddressString(address: Address) {
			return `${address.street} ${address.houseNumber ?? ''}${address.entrance ?? ''}, ${
				address.postCode ?? ''
			} ${address.postArea ?? ''}, ${address.country ?? ''}`;
		}

		render() {
			return (
				<iframe
					allowFullScreen
					className="Map"
					height={this.props.height ?? ((this.iFrame.current?.clientWidth || 0) * 2) / 3}
					loading="lazy"
					ref={this.iFrame}
					src={this.googleMapsUrl}
					style={{ border: 0 }}
					width="100%"
					title="Google Maps"
				/>
			);
		}
	}
);

export default MapFrame;
