import { Avatar, AvatarProps, Badge, Chip, Tooltip } from '@mui/material';
import { makeObservable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Profile } from '../../stores/ProfileStore';
import PersonIcon from '@mui/icons-material/Person';

import './ProfilePicture.scss';

type Props = AvatarProps<React.ElementType, { component?: React.ElementType }> & {
	profile?: Profile;
	workspaceId?: string;
	peeking?: boolean;
	typing?: boolean;
	noTooltip?: boolean;
	chip?: boolean;

	// eslint-disable-next-line no-unused-vars
	onClick?: (profile: Profile) => void;
};

const ProfilePicture = observer(
	class ProfilePicture extends React.Component<Props> {
		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				profile: computed,
				isOnline: computed,
				onClick: action,
			});
		}

		get profile() {
			const { profile } = this.props;
			return profile;
		}

		get isOnline() {
			const { profile } = this.props;
			return profile?.isOnline;
		}

		renderAvatar = (addRest: boolean = true) => {
			// eslint-disable-next-line no-unused-vars
			const { typing, peeking, noTooltip, onClick, profile, ...rest } = this.props;
			let className = 'online-badge';
			if (typing) {
				className += ` profile--typing`;
			}
			if (peeking) {
				className += ` profile--peeking`;
			}

			if (noTooltip) {
				className += ` profile--noToolTip`;
			}

			if (profile?.isAI) {
				className += ` profile--ai`;
			}

			const name = profile?.name ?? '';
			let avatarClassName = 'ProfilePicture';
			if (profile?.isAI) {
				avatarClassName += ' ai';
			}
			return (
				<Badge
					badgeContent=" "
					className={className}
					overlap="circular"
					color="secondary"
					variant="dot"
					invisible={!this.isOnline}
					style={{ position: 'relative' }}
				>
					{addRest ? (
						<Avatar
							alt={name}
							className={avatarClassName}
							data-hj-suppress
							src={profile?.profilePicture}
							onClick={this.onClick}
							{...rest}
						>
							{name || profile?.profilePicture ? name.charAt(0) : <PersonIcon color="primary" />}
						</Avatar>
					) : (
						<Avatar
							alt={name}
							className={avatarClassName}
							data-hj-suppress
							src={profile?.profilePicture}
							onClick={this.onClick}
							sx={{ width: '100%', height: '100%' }}
						>
							{name || profile?.profilePicture ? name.charAt(0) : <PersonIcon color="primary" />}
						</Avatar>
					)}
				</Badge>
			);
		};

		onClick = () => {
			const { onClick, profile } = this.props;
			if (onClick && profile) {
				onClick(profile);
			}
		};

		renderAvatarWithChip = () => {
			const { onClick, profile } = this.props;
			const name = profile?.name ?? '';
			return (
				<div>
					<Chip
						avatar={this.renderAvatar(false)}
						data-hj-suppress
						label={name}
						sx={{ border: 0, color: 'inherit' }}
						onClick={this.onClick}
						variant={onClick ? 'filled' : 'outlined'}
					/>
				</div>
			);
		};

		render() {
			if (!this.profile) {
				return <>N/A</>;
			}

			const { noTooltip, chip } = this.props;
			const name = this.profile?.name || '';
			return (
				<Tooltip title={noTooltip ? '' : name + (this.isOnline ? ' (online)' : '')}>
					{chip ? this.renderAvatarWithChip() : this.renderAvatar()}
				</Tooltip>
			);
		}
	}
);

export default React.memo(ProfilePicture);
