import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import GdprApi from '../api/endpoints/GdprApi';
import AsyncStorageHelper from '../auth/AsyncStorageHelper';
import AuthManager from '../auth/AuthManager';
import config from '../config/config';
import { FetchWrapperResponse } from '../fetch/FetchWrapper';
import LiteEvent from '../helpers/LiteEvent';
import { Workspace } from '../types';
import { RootStore } from './RootStore';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

/**
 * UserStore
 */
export class UserStore {
	rootStore: RootStore;

	lastOnlineIndicator: number = 0;
	prevUserId: string | undefined = '';
	befJwt: string | undefined;
	workspace?: Workspace | null;

	isUploadingFile: any = false;
	uploadProgress: number = 0;

	// @mia we need to type this
	gdprStatus: any;
	gdprStatusIsLoading: boolean = true;

	private readonly isSignedIn = new LiteEvent<any>();
	private readonly isSignedOut = new LiteEvent<any>();
	private readonly hasUserIdChanged = new LiteEvent<any>();

	// eslint-disable-next-line no-undef
	private loginCheckInterval: any = null;
	private hasCheckedLoginStatus: boolean = false;
	initTimestamp: number = 0;

	debouncedCheckLoginStatus: any;

	isLoggedInPromise: () => { read(): boolean } = () => ({
		// eslint-disable-next-line no-unused-labels
		read: () => false,
	});

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		this.checkLoginStatus = this.checkLoginStatus.bind(this);
		this.acceptTermsOfUse = this.acceptTermsOfUse.bind(this);
		this.logOut = this.logOut.bind(this);
		this.checkLoginStatus = this.checkLoginStatus.bind(this);

		this.debouncedCheckLoginStatus = debounce(this.checkLoginStatus, 500);

		makeAutoObservable(this, {
			rootStore: false,
			UserIdChanged: computed,
			checkLoginStatus: action,
			currentUserId: computed,
			isLoggedIn: computed,
			redirectPath: false,
		});

		// @todo find out if we need to  reenable this interval - issue if token suddenly is invalid or deleted
		// this.loginCheckInterval = setInterval(this.checkLoginStatus, 30000);

		this.init();
	}

	init() {
		this.workspace = AsyncStorageHelper.getCache(`@BefWeb:login:workspace`);
		this.checkLoginStatus();
		this.hasUserIdChanged.on(() => {
			this.getGdprConsentStatus();
		});
		this.getGdprConsentStatus();
		this.initTimestamp = Date.now();
		this.isLoggedInPromise = () => {
			const { isLoggedIn } = this;
			return {
				read() {
					return Boolean(isLoggedIn);
				},
			};
		};

		window.addEventListener('storage', (event) => {
			if (event.key === '@BefWeb:Befare_JWT') {
				toast.error('Du har blitt logget ut på grunn av en annen pålogging, last inn siden på nytt', {
					toastId: 'logged-out',
					autoClose: 10000,
				});
				setTimeout(() => {
					window.location.reload();
				}, 3000);
			}
		});
	}

	get workspaceStore() {
		return this.rootStore.workspaceStore;
	}

	get profileStore() {
		return this.rootStore.profileStore;
	}

	get currentUserId() {
		return this.rootStore.profileStore.currentUserProfile?.userId;
	}

	get isLoggedIn() {
		return Boolean(this.befJwt);
	}

	get hasGivenConsent() {
		return Boolean(this.gdprStatus?.hasAcceptedTerms);
	}

	get termsOfUse() {
		return this.gdprStatus?.termsOfUse;
	}

	public get SignedIn() {
		return this.isSignedIn.expose();
	}

	public get SignedOut() {
		return this.isSignedOut.expose();
	}

	public get UserIdChanged() {
		return this.hasUserIdChanged.expose();
	}

	get redirectPath() {
		return AsyncStorageHelper.getCache(`@BefWeb:login:redirect`) ?? '/';
	}

	setRedirectPath = (path: string) => {
		AsyncStorageHelper.setCache(`@BefWeb:login:redirect`, path);
	};

	async getGdprConsentStatus() {
		this.gdprStatusIsLoading = true;
		const result = await GdprApi.getConsentStatus();
		if (result.statusCode === 200) {
			runInAction(() => {
				this.gdprStatus = result.data;
				this.gdprStatusIsLoading = false;
			});
		} else {
			runInAction(() => {
				this.gdprStatusIsLoading = false;
			});
		}
	}

	async acceptTermsOfUse() {
		this.gdprStatus.hasAcceptedTerms = true;
		const result = await GdprApi.acceptTerms(this.gdprStatus?.consentVersionId);

		if (result.statusCode === 200) {
			runInAction(() => {
				this.gdprStatus = result.data;
				this.gdprStatus.hasAcceptedTerms = true;
			});
		}
	}

	isCurrentUser(userId: string) {
		return userId === this.currentUserId;
	}

	async checkLoginStatus(force: boolean = false) {
		const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
		runInAction(() => {
			if (befareJWT) {
				this.befJwt = befareJWT;
				if (!this.currentUserId) {
					this.isSignedIn.trigger();
				}
				if (force || this.lastOnlineIndicator > Date.now() - 10000) {
					if (force || this.prevUserId?.toString() != this.currentUserId?.toString()) {
						this.prevUserId = this.currentUserId;
						if (this.currentUserId) {
							this.hasUserIdChanged.trigger();
						}
					}
				}
			} else {
				console.log('Missing token');
				this.befJwt = undefined;

				if (this.currentUserId) {
					console.log('Signing out');
					this.logOut();
				}
			}
			this.hasCheckedLoginStatus = true;
		});
	}

	async switchWorkspace(workspace?: Workspace, autoNavigate: boolean = true) {
		// Skip workspace id to refresh to a token for sluttkunde
		const result = await AuthManager.refreshToken(workspace?.id);
		console.info('Switching workspace', workspace, autoNavigate, result);

		if (result.statusCode === 200) {
			this.triggerSignOut();
			await this.checkLoginStatus(true);
			await this.setWorkspace(workspace);
			if (autoNavigate) {
				const cachedPath = AsyncStorageHelper.getCache(`@BefWeb:login:redirect`);

				if (cachedPath && cachedPath != '/') {
					AsyncStorageHelper.removeCache(`@BefWeb:login:redirect`);
					this.rootStore.navigate({
						path: cachedPath,
						historyOnly: true,
					});
				} else {
					this.rootStore.navigate({
						path: workspace ? '/permissions' : '/app/dashboard',
						historyOnly: true,
					});
				}
			}
		} else if ([401, 403].includes(result.statusCode)) {
			toast.error('Du har ikke tilgang til denne bedriften - logg inn på nytt');
			this.logOut();
		}

		return result;
	}

	setWorkspace(workspace?: Workspace | null) {
		AsyncStorageHelper.setCache(`@BefWeb:login:workspace`, workspace ?? null);

		this.workspace = workspace ?? null;
	}

	deleteWorkspace() {
		console.info('Deleting workspace');
		AsyncStorageHelper.removeCache(`@BefWeb:login:workspace`);
		this.workspace = undefined;
	}

	logOut() {
		AsyncStorageHelper.clearCache();
		this.deleteWorkspace();
		this.triggerSignOut();
		this.rootStore.navigate({ path: '/' });
	}

	triggerSignOut() {
		this.isSignedOut.trigger();
	}

	/**
	 * @param  {number} progress
	 */
	setUploadProgress(progress: number) {
		runInAction(() => {
			if (progress >= 1) {
				this.isUploadingFile = false;
			} else {
				this.uploadProgress = progress;
			}
		});
	}

	/**
	 * @param  {any} file
	 */
	async uploadFile(
		fileObj: any,
		filename?: string,
		sessionIdBasedUpload: boolean = false,
		channelId?: number
	): Promise<FetchWrapperResponse> {
		try {
			this.isUploadingFile = fileObj;
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = sessionIdBasedUpload ? `//${host}/v1/customer-service/file/upload` : `//${host}/v1/file/upload`;

			// if it's session based upload, we rootStore.sessionId and channelId
			if (sessionIdBasedUpload && !(this.rootStore.sessionId && channelId)) {
				throw new Error('Missing sessionId or channelId');
			}

			return new Promise((resolve, reject) => {
				// 1. initialize request
				const xhr = new XMLHttpRequest();

				// 2. open request
				xhr.open('POST', url);
				xhr.setRequestHeader('Authorization', `Bearer ${befareJWT}`);
				xhr.setRequestHeader('Accept', 'application/json');
				// xhr.setRequestHeader("Content-Type", "multipart/form-data");
				// 3. set up callback for request
				xhr.onload = async () => {
					const response: any = JSON.parse(xhr.response);
					const status = xhr.status;
					runInAction(() => {
						this.setUploadProgress(0);
						this.isUploadingFile = false;
					});

					resolve({
						data: response,
						statusCode: status,
					});
					// ... do something with the successful response
				};
				// 4. catch for request error
				xhr.onerror = (e) => {
					console.log(e, 'upload failed');
					runInAction(() => {
						this.setUploadProgress(0);
						this.isUploadingFile = false;
					});
					reject(e);
				};
				// 4. catch for request timeout
				xhr.ontimeout = (e) => {
					console.log(e, 'upload timeout');
					runInAction(() => {
						this.setUploadProgress(0);
						this.isUploadingFile = false;
					});
					reject(e);
				};
				// 4. create formData to upload
				const formData = new FormData();

				// TODO Fix the second argument to be assignable to type string or Blob
				formData.append('befFile', fileObj, filename || fileObj.name);
				if (sessionIdBasedUpload) {
					// add sessionId and channelId to the formData
					formData.append('sessionId', '' + this.rootStore.sessionId);
					formData.append('channelId', '' + channelId);
				}
				// 6. track upload progress
				if (xhr.upload) {
					this.setUploadProgress(0);
					// track the upload progress
					xhr.upload.onprogress = ({ total, loaded }) => {
						const uploadProgress = loaded / total;
						this.setUploadProgress(uploadProgress);
					};
				}
				// 7. upload the request
				xhr.send(formData);
			});
		} catch (error) {
			runInAction(() => {
				this.setUploadProgress(0);
				this.isUploadingFile = false;
			});
			return Promise.reject(error);
		}
	}

	getProjects(userId: string) {
		return this.rootStore.projectStore.projects.filter((project) =>
			project.projectMembers.some((member) => '' + member.userId === userId)
		);
	}
}

// TODO Create domain object
