import { observer } from 'mobx-react';
import { Project } from '../../../../stores/Project';
import React from 'react';
import {
	Avatar,
	Box,
	Button,
	Divider,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	MenuProps,
	Stack,
	Typography,
	alpha,
	styled,
} from '@mui/material';
import { action, makeObservable, observable } from 'mobx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ShareProjectSearchAutocomplete from './ShareProjectSearchProfileAutocomplete';
import { ProfileDTO } from '../../../../dto/profile.types';
import { AccessLevel, GranteeAccess, GranteeType, ResourceType } from '../../../../dto/sharing.types';
import { ChannelDTO, ChannelTypeDTO } from '../../../../dto/channel.types';
import StoreContext from '../../../../stores/StoreContext';
import { RootStore } from '../../../../stores/RootStore';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		'borderRadius': 6,
		'marginTop': theme.spacing(1),
		'minWidth': 180,
		'color': 'rgb(55, 65, 81)',
		'boxShadow':
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

type MemberMenuItemProps = {
	member: GranteeAccess;
	project: Project; // Needed to know the available channels
};

const MemberMenuItem = observer(
	class MemberMenuItem extends React.Component<MemberMenuItemProps> {
		static readonly contextType = StoreContext;

		open: boolean = false;
		anchorEl: null | HTMLElement = null;

		constructor(props: MemberMenuItemProps) {
			super(props);

			makeObservable(this, {
				open: observable,
				anchorEl: observable,
				handleClick: action,
				handleClose: action,
				setCustomerGrant: action,
				setPartnerGrant: action,
				setCustomerAndPartnerGrant: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get profile() {
			if (!this.props.member.profileId) return null;

			return this.profileStore.getProfileByFilter({
				profileId: this.props.member.profileId,
				userId: this.props.member.userId,
				workspaceId: this.props.member.workspaceId,
			});
		}

		get project() {
			return this.props.project;
		}

		get channels() {
			return this.project.channels;
		}

		get customerChannel() {
			return this.channels.find((channel) => channel.channelType === ChannelTypeDTO.ProjectCustomer);
		}

		get partnerChannel() {
			return this.channels.find((channel) => channel.channelType === ChannelTypeDTO.ProjectExternal);
		}

		get internalChannel() {
			return this.channels.find((channel) => channel.channelType === ChannelTypeDTO.ProjectInternal);
		}

		get hasAccessToCustomerChannel() {
			return this.hasAccessToChannel(this.customerChannel);
		}

		get hasAccessToPartnerChannel() {
			return this.hasAccessToChannel(this.partnerChannel);
		}

		get hasAccessToCustomerAndPartnerChannel() {
			return this.hasAccessToCustomerChannel && this.hasAccessToPartnerChannel;
		}

		get hasAccessToProject() {
			// first filter access details for project resource type
			const accessDetails = this.props.member.accessDetails.filter(
				(access) => access.resourceType === ResourceType.Project
			);
			// then filter for the specific project
			return accessDetails.some((access) => access.resourceId === this.project.id);
		}

		get currentAccessGrantTitle() {
			if (this.hasAccessToCustomerAndPartnerChannel) {
				return 'Kunde- og partnerkanal';
			} else if (this.hasAccessToCustomerChannel) {
				return 'Kundekanal';
			} else if (this.hasAccessToPartnerChannel) {
				return 'Partnerkanal';
			} else {
				return 'Ingen tilgang';
			}
		}

		hasAccessToChannel(channel?: ChannelDTO) {
			if (!channel) return false;

			// first filter access details for channel resource type
			const accessDetails = this.props.member.accessDetails.filter(
				(access) => access.resourceType === ResourceType.Channel
			);
			// then filter for the specific channel
			return accessDetails.some((access) => access.resourceId === +channel.id);
		}

		handleClick = (event: React.MouseEvent<HTMLElement>) => {
			this.anchorEl = event.currentTarget;
			this.open = true;
		};

		handleClose = () => {
			this.anchorEl = null;
			this.open = false;
		};

		setCustomerGrant = () => {
			if (!this.customerChannel) return;
			if (!this.project?.id) return;

			this.removeGrant();

			if (!this.hasAccessToCustomerChannel) {
				this.props.member.accessDetails.push({
					resourceType: ResourceType.Channel,
					projectId: this.project.id,
					resourceId: +this.customerChannel.id,
					channelId: +this.customerChannel.id,
					accessLevel: AccessLevel.Read,
				});
			}

			this.handleClose();
		};

		setPartnerGrant = () => {
			if (!this.partnerChannel) return;
			if (!this.project?.id) return;

			this.removeGrant();

			if (!this.hasAccessToPartnerChannel) {
				this.props.member.accessDetails.push({
					resourceType: ResourceType.Channel,
					projectId: this.project.id,
					resourceId: +this.partnerChannel.id,
					channelId: +this.partnerChannel.id,
					accessLevel: AccessLevel.Read,
				});
			}

			this.handleClose();
		};

		setCustomerAndPartnerGrant = () => {
			this.removeGrant();

			this.setCustomerGrant();
			this.setPartnerGrant();

			this.handleClose();
		};

		setProjectGrant = () => {
			if (!this.project?.id) return;

			if (!this.hasAccessToProject) {
				this.props.member.accessDetails.push({
					resourceType: ResourceType.Project,
					projectId: this.project.id,
					resourceId: this.project.id,
					accessLevel: AccessLevel.Read,
				});
			}
		};

		removeGrant = () => {
			// remove all grants for the project
			this.props.member.accessDetails = [];

			this.handleClose();
		};

		render() {
			const { member } = this.props;
			return (
				<Stack key={member.profileId} direction="row" justifyContent="space-between" alignItems="center">
					<ListItem sx={{ paddingLeft: 0, paddingBottom: 0, paddingTop: 0 }}>
						<ListItemAvatar>
							<Avatar
								sx={({ palette }) => ({
									backgroundColor: palette.primary.main,
								})}
								src={this.profile?.profilePicture}
							>
								{this.profile?.firstName ?? member.profileId}
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={this.profile?.name} secondary={member.granteeType ?? ''} />
					</ListItem>
					<Button
						id="demo-customized-button"
						aria-controls={this.open ? 'share-job' : undefined}
						aria-haspopup="true"
						aria-expanded={this.open ? 'true' : undefined}
						variant="text"
						disableElevation
						disableRipple
						onClick={this.handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						<Box
							component="span"
							className="text-ellipsis"
							sx={{
								width: '150px',
								display: 'inline-block',
							}}
						>
							{this.currentAccessGrantTitle}
						</Box>
					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'share-job',
						}}
						anchorEl={this.anchorEl}
						open={this.open}
						onClose={this.handleClose}
					>
						<MenuItem onClick={this.setCustomerGrant} disableRipple>
							{!this.hasAccessToCustomerAndPartnerChannel && this.hasAccessToCustomerChannel ? (
								<CheckCircleOutlineIcon />
							) : (
								<RadioButtonUncheckedIcon />
							)}
							Kundekanal
						</MenuItem>
						<MenuItem onClick={this.setPartnerGrant} disableRipple>
							{this.hasAccessToPartnerChannel ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
							Partnerkanal
						</MenuItem>
						<MenuItem onClick={this.setCustomerAndPartnerGrant} disableRipple>
							{!this.hasAccessToCustomerAndPartnerChannel && this.hasAccessToCustomerAndPartnerChannel ? (
								<CheckCircleOutlineIcon />
							) : (
								<RadioButtonUncheckedIcon />
							)}
							Kunde- og partnerkanal
						</MenuItem>
						<Divider sx={{ my: 0.5 }} />
						<MenuItem onClick={this.removeGrant} disableRipple>
							Fjern tilgang
						</MenuItem>
					</StyledMenu>
				</Stack>
			);
		}
	}
);

type ShareProjectFormProps = {
	project: Project;
	// eslint-disable-next-line no-unused-vars
	onShareAccessChange: (values: Array<ProfileDTO | string>) => void;
};

const ShareProjectForm = observer(
	class ShareProjectForm extends React.Component<ShareProjectFormProps> {
		constructor(props: ShareProjectFormProps) {
			super(props);

			makeObservable(this, {
				onSearchInputChange: action,
			});
		}

		get members(): GranteeAccess[] {
			const { project } = this.props;
			return project.sharedAccess;
		}

		get filteredMembers(): GranteeAccess[] {
			return this.members.filter((member) => member.granteeType === GranteeType.Profile);
		}

		get filteredCompanies(): GranteeAccess[] {
			return this.members.filter((member) => member.granteeType === GranteeType.Workspace);
		}

		onSearchInputChange = (values: Array<ProfileDTO | string>) => {
			this.props.onShareAccessChange(values);
		};

		render() {
			const { project } = this.props;
			return (
				<Stack spacing={2} sx={{ marginTop: '1rem' }}>
					<ShareProjectSearchAutocomplete project={project} onChange={this.onSearchInputChange} />
					<Box>
						<Typography variant="h6">Personer med tilgang</Typography>
						<Stack spacing={1} sx={{ margin: '1rem 0' }}>
							{this.filteredMembers.map((member) => (
								<MemberMenuItem member={member} key={member.profileId} project={project} />
							))}
						</Stack>
						<Typography variant="h6">Bedrifter med tilgang</Typography>
						<Stack spacing={1} sx={{ margin: '1rem 0' }}>
							{this.filteredCompanies.map((member) => (
								<MemberMenuItem member={member} key={member.profileId} project={project} />
							))}
						</Stack>
					</Box>
				</Stack>
			);
		}
	}
);

export default ShareProjectForm;
