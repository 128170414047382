import React from 'react';
import config from '../../../config/config';
import { AppId } from '../../../config/appId.enum';
import { Box } from '@mui/material';

export type BefareIllustrationProps = {
	illustration:
		| 'chill'
		| 'electrician'
		| 'inspection1'
		| 'inspection2'
		| 'offer'
		| 'phone'
		| 'result'
		| 'request'
		| 'time1'
		| 'time2'
		| 'wait';
	style?: React.CSSProperties;
	sx?: React.CSSProperties;
};

export default class BefareIllustration extends React.Component<BefareIllustrationProps> {
	get theme() {
		switch (config.appId) {
			case AppId.Befare:
				return 'befare';
			case AppId.MinElektriker:
				return 'norgeseliten';
			case AppId.MinRorlegger:
				return 'befare';
			default:
				return 'befare';
		}
	}

	render() {
		const { illustration, ...rest } = this.props;
		return (
			<Box {...rest}>
				<img
					src={`/illustrations/${this.theme}/${illustration}.svg`}
					alt="Befare"
					style={{ width: '100%', height: '100%' }}
				/>
			</Box>
		);
	}
}
