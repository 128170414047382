import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import './CookieConsentToggles.scss';

type Props = {
	defaultAllToTrue?: boolean;
};

const CookieConsentToggles = observer(
	class CookieConsentToggles extends React.Component<Props> {
		static readonly contextType = StoreContext;

		allowRequired: boolean | undefined = false;
		allowAnalytics: boolean | undefined = false;
		allowMarketing: boolean | undefined = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				allowRequired: observable,
				allowAnalytics: observable,
				allowMarketing: observable,
				init: action,
				handleConsentUpdate: action,
				handleRequiredChange: action,
				handleAnalyticsChange: action,
				handleMarketingChange: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		componentDidMount() {
			this.init();
		}

		init = async () => {
			if (this.props.defaultAllToTrue) {
				this.allowRequired = true;
				this.allowAnalytics = true;
				this.allowMarketing = true;
			}
			const cookieSettings = await AsyncStorageHelper.getCachedCookieConsent();

			if (cookieSettings?.isSet) {
				runInAction(() => {
					this.allowRequired = true; // this has to  be true :/  cookieSettings?.allowRequired;
					this.allowAnalytics = cookieSettings?.allowAnalytics;
					this.allowMarketing = cookieSettings?.allowMarketing;
				});
			}
		};

		handleConsentUpdate = () => {
			// It has to be impossible to decline required
			this.allowRequired = true;
			AsyncStorageHelper.cacheCookieConsent({
				allowRequired: this.allowRequired,
				allowAnalytics: this.allowAnalytics,
				allowMarketing: this.allowMarketing,
			});
			if (this.allowAnalytics) {
				this.rootStore.initAnalytics();
			}

			if (this.allowMarketing) {
				this.rootStore.initMarketing();
			}
		};

		handleRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.allowRequired = event.target.checked;
			this.handleConsentUpdate();
		};

		handleAnalyticsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.allowAnalytics = event.target.checked;
			this.handleConsentUpdate();
		};

		handleMarketingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.allowMarketing = event.target.checked;
			this.handleConsentUpdate();
		};

		render() {
			return (
				<div className="CookieConsentToggles">
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={this.allowRequired}
									onChange={this.handleRequiredChange}
									name="checkedA"
								/>
							}
							disabled={this.allowRequired}
							label={
								<>
									<Typography variant="overline">Nødvendige</Typography>
									<Typography variant="body2">Kreves for at nettsiden skal fungere</Typography>
								</>
							}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={this.allowAnalytics}
									onChange={this.handleAnalyticsChange}
									name="checkedAnalytics"
									color="primary"
								/>
							}
							label={
								<>
									<Typography variant="overline">Statistikk</Typography>
									<Typography variant="body2">
										Samler anonym informasjon om bruk slik at vi kan lage en enda bedre tjeneste for
										deg
									</Typography>
								</>
							}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={this.allowMarketing}
									onChange={this.handleMarketingChange}
									name="checkedMarketing"
									color="primary"
								/>
							}
							label={
								<>
									<Typography variant="overline">Markedsføring</Typography>
									<Typography variant="body2">
										Samler informasjon om aktivitet på nettsiden og kan bli brukt av tredjeparter
										til å målrette kampanjer hos tjenester fom Facebook og Google.
									</Typography>
								</>
							}
						/>
					</FormGroup>
				</div>
			);
		}
	}
);

export default CookieConsentToggles;
