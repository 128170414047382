import { observer } from 'mobx-react';
import React from 'react';
import { MessageContent } from '../../../../types';
import { computed, makeObservable } from 'mobx';
import { Box, Icon, IconButton, Stack, styled } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const StyledImgPreview = styled('img')(() => ({
	width: '100%',
	height: '100%',
	objectFit: 'cover',
}));

const StyledVideoPreview = styled('video')(() => ({
	width: '100%',
	height: '100%',
	objectFit: 'cover',
}));

const StyledPreviewBox = styled(Box)(({ theme }) => ({
	border: `1px solid ${theme.palette.primary.light}`,
	borderRadius: '8px',
	padding: '0.5rem',
	width: '48px',
	height: '48px',
	overflow: 'hidden',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: '0 0 8px rgba(0,0,0,0.2)',
	position: 'relative',
	background: theme.palette.primary.contrastText,
}));

const RemoveIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: 2,
	right: 2,
	color: theme.palette.primary.main,
	background: theme.palette.primary.contrastText,
	padding: 0,
	fontSize: '0.7rem',
	boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
}));

//

type ChatMessageContentPreviewProps = {
	content: MessageContent[];
	// eslint-disable-next-line no-unused-vars
	onRemove?: (content: MessageContent) => void;
	onAdd?: () => void;
};

type ChatMessageContentFilePreviewProps = {
	content: MessageContent;
	// eslint-disable-next-line no-unused-vars
	onRemove?: (content: MessageContent) => void;
};

const ChatMessageContentFilePreview = observer((props: ChatMessageContentFilePreviewProps) => {
	const { content, onRemove } = props;
	const file = content.file;
	let element = <FilePresentIcon />;
	if (file?.mimetype?.startsWith('image')) {
		element = <StyledImgPreview src={file.preview} alt="Selected file preview" />;
	}
	if (file?.mimetype?.startsWith('video')) {
		element = <StyledVideoPreview src={content.file?.name} />;
	}

	const remove = () => {
		onRemove?.(content);
	};

	// style with paper and place a remove x in the top right corner
	return (
		<StyledPreviewBox>
			{element}
			<RemoveIconButton size="small" onClick={remove}>
				<Icon fontSize="inherit">close</Icon>
			</RemoveIconButton>
		</StyledPreviewBox>
	);
});

const ChatMessageContentPreview = observer(
	class ChatMessageContentPreview extends React.Component<ChatMessageContentPreviewProps> {
		constructor(props: any) {
			super(props);
			makeObservable(this, {
				content: computed,
			});
		}

		get content() {
			return this.props.content;
		}

		render() {
			const { onRemove, onAdd } = this.props;
			return (
				<Stack direction="row" spacing={1} alignItems="flex-start" flexWrap="wrap">
					<StyledPreviewBox>
						<IconButton size="large" onClick={onAdd}>
							<AddPhotoAlternateIcon />
						</IconButton>
					</StyledPreviewBox>
					{this.content.map((content, index) => (
						<ChatMessageContentFilePreview
							key={content.file?.id ?? `content-${index}`}
							content={content}
							onRemove={onRemove}
						/>
					))}
				</Stack>
			);
		}
	}
);

export default ChatMessageContentPreview;
