import config from '../../config/config';
import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

export type CustomerDTO = {
	customerId: number;
	numProjects: number;
	firstProjectDate: Date;
	avgRating: number | null;
	projectIds: number[]; // This will be a comma-separated string of project IDs
	totalEstimatedBudget: number;
	doneProjects: number;
	toDoProjects: number;
	unprocessedProjects: number;
	inProgressProjects: number;
	lastInteractionDate: string | null; // ISO 8601 date string
	conversionRate: number; // Represented as a percentage
	unrealizedValue: number;
	realizedValue: number;
	lostValue: number;
};

export default class CustomerApi {
	static async getCustomers(): Promise<FetchWrapperTypedResponse<{ customers: CustomerDTO[] }>> {
		const url = `//${config.hosts.api}/v1/customer`;

		return FetchWrapper.typedGet<{ customers: CustomerDTO[] }>(url);
	}
}
