import config from '../../config/config';
import { FetchWrapperResponse, FetchWrapper } from '../../fetch/FetchWrapper';

/**
 * Workspace API
 */
export default class AiApi {
	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async completeEmail(text: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/ai/email`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				text,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async completeChat(channelId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/ai/chat/${channelId}`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getSummary(channelId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/ai/chat/${channelId}/summary`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async purifyMessage(text: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/ai/purify-message`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				text,
			}),
			requireAuth: true,
		});
	}
}
