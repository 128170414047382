import React from 'react';
import Article from './Article';
import { Button, Stack, Typography } from '@mui/material';
import BefareIllustration from '../common/illustrations/BefareIllustration';
import { ServicesEnum } from '../home/generic/GenericServices';
import { Link } from 'react-router-dom';

export default class FuseBoxArticle extends React.Component {
	render() {
		return (
			<Article>
				<Typography variant="h3" gutterBottom>
					6 grunner til å modernisere sikringsskapet
				</Typography>
				<Typography variant="body1" gutterBottom>
					I en tid der teknologiske fremskritt og økt strømbehov er en realitet, er det å ta vare på hjemmets
					elektriske system mer viktig enn noensinne. Sikringsskapet, som er hjertet av det elektriske
					systemet i hjemmet, spiller en sentral rolle i å levere strøm til alle hjørner av boligen. En
					renovasjon av sikringsskapet kan være en nøkkel til økt sikkerhet, effektivitet og bekvemmelighet.
				</Typography>
				<BefareIllustration illustration="inspection1" sx={{ margin: '1rem auto', maxWidth: '300px' }} />
				<Typography variant="body1" gutterBottom>
					Her er noen av fordelene ved å modernisere sikringsskapet:
				</Typography>
				<Typography variant="h5" gutterBottom>
					1. Forbedret sikkerhet
				</Typography>

				<Typography variant="body1" gutterBottom>
					Eldre sikringsskap kan ha utdaterte sikringsteknologier som ikke gir tilstrekkelig beskyttelse mot
					overbelastning og kortslutning. Ved å oppgradere til moderne automatsikringer eller jordfeilbrytere,
					kan du redusere risikoen for brann og elektriske farer betydelig.
				</Typography>

				<Typography variant="h5" gutterBottom>
					2. Økt kapasitet
				</Typography>
				<Typography variant="body1" gutterBottom>
					Med dagens økte bruk av elektriske apparater og teknologier, kan et eldre sikringsskap ha begrenset
					kapasitet. Dette kan føre til hyppige strømbrudd eller overbelastning. En oppgradering gir deg
					muligheten til å øke kapasiteten og håndtere dagens strømbehov.
				</Typography>
				<Typography variant="h5" gutterBottom>
					3. Bedre energiøkonomi
				</Typography>
				<Typography variant="body1" gutterBottom>
					Moderne sikringsskap gir ofte muligheten til å installere energieffektive enheter, som smarte
					termostater og tidsstyrte brytere. Dette kan bidra til å redusere strømforbruket og dermed senke
					strømutgiftene.
				</Typography>
				<Typography variant="h5" gutterBottom>
					4. Tilpasning til teknologiske behov
				</Typography>
				<Typography variant="body1" gutterBottom>
					Med stadig økende teknologiske behov, som hjemmekontor og smarthusteknologi, kan et oppdatert
					sikringsskap integrere disse elementene sømløst. Du kan legge til flere stikkontakter,
					USB-ladestasjoner og annet for å tilfredsstille moderne krav.
				</Typography>
				<Typography variant="h5" gutterBottom>
					5. Fremtidssikring
				</Typography>
				<Typography variant="body1" gutterBottom>
					En modernisert sikringsskap kan inkludere fleksibilitet for fremtidige oppgraderinger. Dette gjør
					det enklere å tilpasse seg nye teknologier og strømbehov uten å måtte gjøre større endringer senere.
				</Typography>
				<Typography variant="h5" gutterBottom>
					6. Økt boligverdi
				</Typography>
				<Typography variant="body1" gutterBottom>
					En renovering av sikringsskapet kan bidra til å øke verdien av hjemmet ditt. Potensielle kjøpere vil
					sette pris på et oppdatert elektrisk system som er klart for moderne krav.
				</Typography>

				<Typography variant="body1" gutterBottom sx={{ marginTop: '2rem' }}>
					Moderne livsstil og økt teknologibruk krever en tilpasset tilnærming til det elektriske systemet i
					hjemmet. Renovering av sikringsskapet er en investering som gir betydelige fordeler når det gjelder
					sikkerhet, energieffektivitet og tilpasningsevne. Ved å arbeide med profesjonelle elektrikere kan du
					sikre at renoveringen utføres på en trygg og pålitelig måte. Så, hvis du vil sikre en mer pålitelig
					og bærekraftig strømforsyning, kan en oppgradering av sikringsskapet være veien å gå.
				</Typography>

				<Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{
							borderRadius: 16,
							letterSpacing: '1px',
							boxShadow: '0 2px 4px rgba(0,0,0,.26)',
						}}
						component={Link}
						to={`/quote/${ServicesEnum.Fusebox}`}
					>
						Få pristilbud
					</Button>
					<Button
						variant="outlined"
						color="primary"
						component={Link}
						to={`/quote/${ServicesEnum.FreeInspection}`}
					>
						Gratis befaring
					</Button>
				</Stack>
			</Article>
		);
	}
}
