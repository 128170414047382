import { ListItem } from '@mui/material';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type Props = {
	to: string;
	children: React.ReactNode;
	selected?: boolean;
	className?: string;
};

function ListItemLink(props: Readonly<Props>) {
	const { to, children, className, selected } = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef<any, Omit<LinkProps, 'to'>>(function forwardRef(itemProps, ref) {
				return <Link to={to} ref={ref} {...itemProps} />;
			}),
		[to]
	);

	return (
		<li className={className}>
			<ListItem button selected={selected} component={renderLink}>
				{children}
			</ListItem>
		</li>
	);
}

export default ListItemLink;
