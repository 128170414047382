import { Workspace } from '../types';

export default class SSOAuthDetails {
	// TokenResponse
	accessToken: string | null;
	accessTokenExpirationDate: string | null;
	additionalParameters: { [key: string]: any } | null;
	idToken: string | null;
	tokenType: string | null;
	refreshToken: string | null;

	// Befare
	workspace: Workspace;

	constructor(tokenResponse: any, workspace: Workspace) {
		this.accessToken = tokenResponse.accessToken;
		this.accessTokenExpirationDate = tokenResponse.accessTokenExpirationDate || tokenResponse.expiresOn;
		this.additionalParameters = tokenResponse.additionalParameters || tokenResponse.idTokenClaims;
		this.idToken = tokenResponse.idToken;
		this.tokenType = tokenResponse.tokenType;
		this.refreshToken = tokenResponse.refreshToken;
		this.workspace = workspace;
	}
}
