import { Button, Card, CardContent, Grid, StyledComponentProps, Typography } from '@mui/material';
import { action, computed, makeObservable } from 'mobx';

import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';

const PREFIX = 'IndustrySelector';

const classes = {
	root: `${PREFIX}-root`,
	image: `${PREFIX}-image`,
};

const StyledGrid = styled(Grid)(({ theme: { breakpoints, spacing } }) => ({
	[`& .${classes.root}`]: {
		marginBottom: spacing(1),
		[breakpoints.up('sm')]: {
			'&:nth-of-type(odd)': {
				flexDirection: 'row-reverse',
			},
		},
	},

	[`& .${classes.image}`]: {
		width: '100%',
		maxHeight: '330px',
	},
}));

type Props = StyledComponentProps;

const IndustrySelector = observer(
	class IndustrySelector extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				rootStore: computed,
				companyStore: computed,
				industries: computed,
				selectedIndustry: computed,
				toggleIndustry: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get selectedIndustry() {
			return this.companyStore.industryId;
		}

		get industries() {
			return this.companyStore.industries;
		}

		toggleIndustry = (industryId?: string) => {
			if (this.companyStore.industryId == industryId) {
				this.companyStore.setIndustryId();
			} else {
				this.companyStore.setIndustryId(industryId);
			}
		};

		render() {
			return (
				<StyledGrid
					className={`IndustrySelector ${classes.root}`}
					container
					item
					alignItems="center"
					justifyContent={'center'}
					spacing={2}
				>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Velg bransje
								</Typography>
								{this.industries.map((industry) => (
									<Button
										key={`INDUSTRIE-${industry.id}`}
										variant={this.selectedIndustry == industry.id ? 'contained' : 'outlined'}
										onClick={() => this.toggleIndustry(industry.id)}
									>
										{industry.name}
									</Button>
								))}
							</CardContent>
						</Card>
					</Grid>
				</StyledGrid>
			);
		}
	}
);

export default IndustrySelector;
