import AsyncStorageHelper from '../auth/AsyncStorageHelper';
import config from '../config/config';

export type FetchWrapperResponse = {
	data: any;
	statusCode: number;
};

export type FetchWrapperTypedResponse<T> = {
	data: T;
	statusCode: number;
};

export enum FetchError {
	// eslint-disable-next-line no-unused-vars
	BadRequest = 'BadRequest',
	// eslint-disable-next-line no-unused-vars
	NoError = 'NoError',
	// eslint-disable-next-line no-unused-vars
	Unauthorized = 'Unauthorized',
	// eslint-disable-next-line no-unused-vars
	ServerError = 'ServerError',
	// eslint-disable-next-line no-unused-vars
	NotFound = 'NotFound',
	// eslint-disable-next-line no-unused-vars
	Teapot = 'Teapot',
	// eslint-disable-next-line no-unused-vars
	Conflict = 'Conflict',
	// eslint-disable-next-line no-unused-vars
	Forbidden = 'Forbidden',
}

export class FetchWrapper {
	static async typedGet<T>(url: string, requestOptions = {}): Promise<FetchWrapperTypedResponse<T>> {
		const result = await this.get(url, requestOptions);
		switch (result.statusCode) {
			case 200:
				break;
			case 400:
				throw new Error(FetchError.BadRequest);
			case 401:
				throw new Error(FetchError.Unauthorized);
			case 403:
				throw new Error(FetchError.Forbidden);
			case 404:
				throw new Error(FetchError.NotFound);
			case 409:
				throw new Error(FetchError.Conflict);
			case 418:
				throw new Error(FetchError.Teapot);
			case 500:
				throw new Error(FetchError.ServerError);
			default:
				throw new Error(FetchError.BadRequest);
		}

		return result;
	}

	static async typedPut<T>(url: string, requestOptions = {}): Promise<FetchWrapperTypedResponse<T>> {
		const result = await this.put(url, requestOptions);
		switch (result.statusCode) {
			case 200:
				break;
			case 400:
				throw new Error(FetchError.BadRequest);
			case 401:
				throw new Error(FetchError.Unauthorized);
			case 403:
				throw new Error(FetchError.Forbidden);
			case 404:
				throw new Error(FetchError.NotFound);
			case 409:
				throw new Error(FetchError.Conflict);
			case 418:
				throw new Error(FetchError.Teapot);
			case 500:
				throw new Error(FetchError.ServerError);
			default:
				throw new Error(FetchError.BadRequest);
		}

		return result;
	}

	static async typedDelete<T>(url: string, requestOptions = {}): Promise<FetchWrapperTypedResponse<T>> {
		const result = await this.delete(url, requestOptions);
		switch (result.statusCode) {
			case 200:
				break;
			case 400:
				throw new Error(FetchError.BadRequest);
			case 401:
				throw new Error(FetchError.Unauthorized);
			case 403:
				throw new Error(FetchError.Forbidden);
			case 404:
				throw new Error(FetchError.NotFound);
			case 409:
				throw new Error(FetchError.Conflict);
			case 418:
				throw new Error(FetchError.Teapot);
			case 500:
				throw new Error(FetchError.ServerError);
			default:
				throw new Error(FetchError.BadRequest);
		}

		return result;
	}

	/**
	 * @deprecated use typedGet instead
	 */
	static async get(url: string, requestOptions = {}): Promise<FetchWrapperResponse> {
		const defaultOptions = {
			method: 'GET',
		};

		return FetchWrapper.performFetch(url, defaultOptions, requestOptions);
	}

	static async typedPost<T>(url: string, requestOptions = {}): Promise<FetchWrapperTypedResponse<T>> {
		const result = await this.post(url, requestOptions);
		switch (result.statusCode) {
			case 200:
				break;
			case 400:
				throw new Error(FetchError.BadRequest);
			case 401:
				throw new Error(FetchError.Unauthorized);
			case 403:
				throw new Error(FetchError.Forbidden);
			case 404:
				throw new Error(FetchError.NotFound);
			case 409:
				throw new Error(FetchError.Conflict);
			case 418:
				throw new Error(FetchError.Teapot);
			case 500:
				throw new Error(FetchError.ServerError);
			default:
				throw new Error(FetchError.BadRequest);
		}

		return result;
	}

	/**
	 * @deprecated use typedPost instead
	 */
	static async post(url: string, requestOptions = {}): Promise<FetchWrapperResponse> {
		const defaultOptions = {
			method: 'POST',
		};

		return FetchWrapper.performFetch(url, defaultOptions, requestOptions);
	}

	static async put(url: string, requestOptions = {}): Promise<FetchWrapperResponse> {
		const defaultOptions = {
			method: 'PUT',
		};

		return FetchWrapper.performFetch(url, defaultOptions, requestOptions);
	}

	static async patch(url: string, requestOptions = {}): Promise<FetchWrapperResponse> {
		const defaultOptions = {
			method: 'PATCH',
		};

		return FetchWrapper.performFetch(url, defaultOptions, requestOptions);
	}

	static async delete(url: string, requestOptions = {}): Promise<FetchWrapperResponse> {
		const defaultOptions = {
			method: 'DELETE',
		};

		return FetchWrapper.performFetch(url, defaultOptions, requestOptions);
	}

	static async performFetch(url: string, defaultOptions: any, requestOptions: any): Promise<FetchWrapperResponse> {
		try {
			const fetchOptions = await FetchWrapper.buildFetchOptions(defaultOptions, requestOptions);
			if (requestOptions.requireAuth) {
				// if jwt is missing auth fail by default
				if (!(fetchOptions.headers && (fetchOptions.headers as any).Authorization)) {
					return {
						statusCode: 401,
						data: null,
					};
				}
			}

			const response = await fetch(url, fetchOptions);
			let data: any = null;
			if (requestOptions.blobResponse) {
				const blob = await response.blob();
				data = await blob.text();
			} else {
				data = await response.json();
			}

			return {
				statusCode: response.status,
				data: data,
			};
		} catch (err) {
			return {
				statusCode: 500,
				data: null,
			};
		}
	}

	// eslint-disable-next-line no-undef
	static async buildFetchOptions(defaultOptions: any, requestOptions: any): Promise<RequestInit> {
		const headers = await FetchWrapper.fetchHeaders();

		return {
			method: defaultOptions.method,
			credentials: 'include',
			headers: Object.assign(headers, defaultOptions.headers, requestOptions.headers),
			body: requestOptions.body,
		};
	}

	static async fetchHeaders() {
		const authJWT = await AsyncStorageHelper.getCachedBefareJWT();
		return {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': authJWT ? `Bearer ${authJWT}` : null,
			'x-app-id': config.appId,
			'application-token': 'bef-web',
		};
	}
}
