import { makeObservable, observable, computed, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { MeetingForm } from '../../types';
import FullScreenDialog from '../common/FullScreenDialog';
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import './NewEventDialog.scss';
import ProfileInputAutocomplete from '../common/ProfileInputAutocomplete';
import { Profile } from '../../stores/ProfileStore';
import { EventDTO, EventParticipantDTO, EventParticipantStatus, MeetingType } from '../../dto/event.types';

type Props = {
	channelId: string;
	participants?: Profile[];
	// eslint-disable-next-line no-unused-vars
	confirmingAction: (event: Partial<EventDTO>) => void;
	dismissiveAction: () => void;
};

const NewEventDialog = observer(
	class NewEventDialog extends React.Component<Props> {
		static readonly contextType = StoreContext;

		meetingForm: MeetingForm = {
			title: {
				value: 'Videobefaring',
				error: false,
			},
			date: {
				value: '',
				error: false,
			},
			time: {
				value: '12:00',
				error: false,
			},
			type: MeetingType.VideoInspection,
			description:
				'La oss ta en kjapp videobefaring. Det tar 5-10 minutter og fungerer fra smarttelefonen din. På den måten får vi en god forståelse av hva som skal gjøres.',
			participants: [],
		};

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				meetingForm: observable,
				rootStore: computed,
				eventStore: computed,
				isEachMeetingDetailPresent: computed,
				dateValue: computed,
				timeValue: computed,
				handleParticipantsChange: action,
				handleTitleChange: action,
				handleDateChange: action,
				handleTimeChange: action,
				handleDescriptionChange: action,
				handleTypeChange: action,
				handleSubmit: action,
				init: action,
			});

			this.handleSubmit = this.handleSubmit.bind(this);
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get eventStore() {
			return this.rootStore.eventStore;
		}

		get isEachMeetingDetailPresent() {
			this.meetingForm.title.error = this.meetingForm.title.value.length < 1;
			this.meetingForm.time.error = this.meetingForm.time.value.length < 1;
			this.meetingForm.date.error = this.meetingForm.date.value.length < 1;
			return this.meetingForm.title.error || this.meetingForm.time.error || this.meetingForm.date.error;
		}

		get dateValue() {
			return this.meetingForm.date.value;
		}

		get timeValue() {
			return this.meetingForm.time.value;
		}

		componentDidMount(): void {
			this.init();
		}

		init() {
			const d = new Date();
			d.setDate(d.getDate() + 1);
			const month = d.getMonth() + 1;
			this.meetingForm.date.value = `${d.getFullYear()}-${month < 10 ? '0' + month : month}-${
				d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
			}`;

			const { participants } = this.props;
			if (participants) {
				this.meetingForm.participants = participants.map((profile) => profile.asJson);
			}
		}

		handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.meetingForm.title.value = event.currentTarget.value;
		};

		handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.meetingForm.date.value = event.currentTarget.value;
		};

		handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.meetingForm.time.value = event.currentTarget.value;
		};

		handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.meetingForm.description = event.currentTarget.value;
		};

		handleTypeChange = (event: SelectChangeEvent<string>) => {
			this.meetingForm.type = event.target.value as MeetingType;
		};

		handleParticipantsChange = (newValue: any) => {
			console.log(newValue);
		};

		handleSubmit(e?: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}

			if (this.isEachMeetingDetailPresent) {
				return;
			} else {
				const { confirmingAction } = this.props;
				try {
					const setDate = new Date(`${this.meetingForm.date.value}T${this.meetingForm.time.value}`);
					console.log(setDate, this.meetingForm);
					if (setDate && setDate.getTime() > Date.now()) {
						confirmingAction({
							title: this.meetingForm.title.value,
							type: this.meetingForm.type,
							description: this.meetingForm.description,
							start: new Date(`${this.meetingForm.date.value}T${this.meetingForm.time.value}`),
							inviterId: this.rootStore.profileStore.currentUserProfile!.userId,
							invitees: (this.meetingForm.participants?.map((participant) => ({
								userId: participant.userId,
								accepted: participant.userId === this.rootStore.profileStore.currentUserProfile!.userId,
								required: true,
								status: EventParticipantStatus.Pending,
							})) ?? []) as unknown as EventParticipantDTO[],
						});
					} else {
						this.meetingForm.date.error = true;
					}
				} catch (err) {
					this.meetingForm.date.error = true;
				}
			}
		}

		renderDialogContent() {
			const { title, date, time, description, type, participants } = toJS(this.meetingForm);

			const values: any =
				this.props.participants
					?.map((profile) => profile.asJson)
					.filter((profile) =>
						participants?.find((p) => p.userId.toString() === profile.userId.toString())
					) ?? [];

			return (
				<Box sx={{ mt: '1rem' }}>
					<FormControl fullWidth sx={{ marginBottom: '1rem' }}>
						<InputLabel id="meeting-type-label">Type</InputLabel>
						<Select
							labelId="meeting-type-label"
							id="meeting-type"
							value={type}
							label="Type"
							onChange={this.handleTypeChange}
						>
							<MenuItem value={MeetingType.VideoInspection}>Videomøte</MenuItem>
							<MenuItem value={MeetingType.Inspection}>Inspeksjon med oppmøte</MenuItem>
							<MenuItem value={MeetingType.Worksite}>Oppmøte på oppdragssted</MenuItem>
							<MenuItem value={MeetingType.Meeting}>Møte</MenuItem>
							<MenuItem value={MeetingType.Event}>Annet</MenuItem>
						</Select>
					</FormControl>
					<TextField
						required
						label="Tittel"
						value={title.value}
						onChange={this.handleTitleChange}
						className="title-field"
						InputLabelProps={{
							shrink: true,
						}}
						error={title.error}
						helperText={title.error ? 'Påkrevd' : undefined}
					/>
					<div style={{ display: 'flex', marginBottom: 16 }}>
						<TextField
							required
							label="Dato"
							type="date"
							value={this.dateValue}
							onChange={this.handleDateChange}
							className="date-field"
							InputLabelProps={{
								shrink: true,
							}}
							error={date.error}
							helperText={date.error ? 'Påkrevd' : undefined}
						/>
						<TextField
							required
							label="Klokkeslett"
							type="time"
							value={this.timeValue}
							onChange={this.handleTimeChange}
							className="time-field"
							InputLabelProps={{
								shrink: true,
							}}
							error={time.error}
							helperText={time.error ? 'Påkrevd' : undefined}
						/>
					</div>
					<TextField
						multiline
						rows={5}
						fullWidth
						className="input--description"
						variant={'outlined'}
						label="Beskrivelse"
						onChange={this.handleDescriptionChange}
						value={description}
						inputProps={{
							maxLength: 255,
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>

					<ProfileInputAutocomplete
						sx={{ marginTop: '1rem' }}
						profiles={this.props.participants?.slice() ?? []}
						selectedProfiles={values}
						label="Velg deltakere"
						placeholder="Velg..."
						multiple
						onChange={this.handleParticipantsChange}
					/>
				</Box>
			);
		}

		render() {
			const { dismissiveAction } = this.props;
			return (
				<FullScreenDialog
					open
					title="Ny avtale"
					confirmingAction={{
						title: 'Send',
						action: this.handleSubmit,
					}}
					onClose={dismissiveAction}
				>
					<DialogContent>{this.renderDialogContent()}</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="primary"
							disabled={this.isEachMeetingDetailPresent}
							onClick={this.handleSubmit}
						>
							Send Invitasjon
						</Button>
					</DialogActions>
				</FullScreenDialog>
			);
		}
	}
);

export default NewEventDialog;
