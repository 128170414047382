import { makeAutoObservable, runInAction } from 'mobx';
import React from 'react';
import APIWorkspace from '../api/endpoints/APIWorkspace';
import { PricingPlanDTO } from '../dto/pricingPlan.types';
import { RootStore } from './RootStore';
import LiteEvent from '../helpers/LiteEvent';

export class PricingPlanStore {
	availablePricingPlans: PricingPlan[] = [];
	currentPricingPlan: PricingPlan | null = null;
	upcomingPricingPlan: PricingPlan | null = null;
	isLoading = true;
	rootStore: RootStore;
	private readonly onPricingPlanUpdated = new LiteEvent<any>();

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
		});

		this.rootStore = rootStore;

		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.reset();
			this.loadPricingPlans();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			runInAction(() => {
				this.reset();
			});
		});
	}

	reset() {
		this.availablePricingPlans = [];
		this.currentPricingPlan = null;
		this.upcomingPricingPlan = null;
	}

	public get PricingPlanUpdated() {
		return this.onPricingPlanUpdated.expose();
	}

	// Fetches all PricingPlans from the server.
	async loadPricingPlans() {
		this.isLoading = true;

		const response = await APIWorkspace.getPricingPlans();

		if (response.statusCode === 200 && Array.isArray(response.data)) {
			(response.data as PricingPlanDTO[]).forEach((json: any) => this.updatePricingPlanFromServer(json));
		} else {
			// TODO Handle error
		}

		runInAction(() => {
			this.isLoading = false;
			this.onPricingPlanUpdated.trigger();
		});
	}

	setCurrentPricingPlan(pricingPlanId: number) {
		this.currentPricingPlan =
			this.availablePricingPlans.find((availablePricingPlan) => availablePricingPlan.id === pricingPlanId) ||
			null;
	}

	setUpcomingPricingPlan(pricingPlanId: number, validFrom: Date) {
		this.upcomingPricingPlan =
			this.availablePricingPlans.find((availablePricingPlan) => availablePricingPlan.id === pricingPlanId) ||
			null;

		if (this.upcomingPricingPlan) {
			this.upcomingPricingPlan.validFrom = validFrom;
		}
	}

	async changePricingPlan(pricingPlan: PricingPlan) {
		if (this.isLoading) {
			return {
				statusCode: 418,
				data: null,
			};
		}

		this.isLoading = true;

		const response: {
			statusCode: number;
			data?: { currentPricingPlan?: PricingPlanDTO; upcomingPricingPlan?: PricingPlanDTO };
		} = await APIWorkspace.setPricingPlan(pricingPlan);

		if (response.statusCode === 200) {
			if (response.data?.currentPricingPlan?.id) {
				this.setCurrentPricingPlan(response.data.currentPricingPlan.id);

				if (this.currentPricingPlan) {
					const { validFrom, validTo } = response.data.currentPricingPlan;
					runInAction(() => (this.currentPricingPlan!.validFrom = new Date(validFrom!)));

					if (validTo) {
						runInAction(() => (this.currentPricingPlan!.validTo = new Date(validTo)));
					}
				}
			}

			if (response.data?.upcomingPricingPlan?.id) {
				const { id, validFrom, validTo } = response.data.upcomingPricingPlan;
				this.setUpcomingPricingPlan(id, new Date(validFrom!));

				if (this.upcomingPricingPlan) {
					runInAction(() => (this.upcomingPricingPlan!.validFrom = new Date(validFrom!)));

					if (validTo) {
						runInAction(() => (this.upcomingPricingPlan!.validTo = new Date(validTo)));
					}
				}
			} else {
				runInAction(() => (this.upcomingPricingPlan = null));
			}

			this.rootStore.workspaceStore.loadWorkspace();
		} else {
			// TODO Handle error
		}

		runInAction(() => (this.isLoading = false));
		return response;
	}

	// Update a PricingPlan with information from the server. Guarantees a PricingPlan only
	// exists once. Might either construct a new PricingPlan or update an existing one.
	updatePricingPlanFromServer(json: PricingPlanDTO) {
		let pricingPlan = this.availablePricingPlans.find(
			(availablePricingPlan) => availablePricingPlan.id === json.id
		);

		if (!pricingPlan) {
			pricingPlan = new PricingPlan();
			this.availablePricingPlans.push(pricingPlan);
		}

		pricingPlan.updateFromJson(json);
	}

	findByTitle(title: string): PricingPlan | undefined {
		return this.availablePricingPlans.find((pricingPlan) => pricingPlan.title === title);
	}
}

// Domain object PricingPlan.

// eslint-disable-next-line no-unused-vars
enum PricingPlanType {
	// eslint-disable-next-line no-unused-vars
	Dynamic = 'DYNAMIC',
	// eslint-disable-next-line no-unused-vars
	Fixed = 'FIXED',
}

export type Feature = { id: number; description: string; subfeatures?: Feature[] };

const ESTIMATED_MINUTE_COUNT_PER_VIDEO_CALL = 10;
const ESTIMATED_SMS_NOTIFICATION_COUNT_PER_PROJECT = 5;
const ESTIMATED_GB_USAGE_PER_PROJECT = 0.1;

export class PricingPlan {
	id: number | null = null;
	type: PricingPlanType | null = null;
	title: string = '';
	description: React.ReactNode | null = null;
	currency: string = 'NOK';
	_discount: number = 0;
	gigabytesIncludedCount: number = 0;
	features: Feature[] = [];
	_projectCreationPrice: number = 0;
	_videoMinutePrice: number = 0;
	_smsNotificationPrice: number = 0;
	projectsIncludedMonthlyCount: number = 0;
	_fixedMonthlyFee: number = 0;
	_projectPrice: number = 100;
	validFrom?: Date;
	validTo?: Date;

	constructor() {
		makeAutoObservable(this);
	}

	get discount() {
		return Math.max(0, Math.min(this._discount, 100));
	}

	set discount(discount: number) {
		this._discount = discount;
	}

	get projectCreationPrice() {
		return this.roundPrice((this._projectCreationPrice * (100 - this.discount)) / 100);
	}

	get videoMinutePrice() {
		return this.roundPrice((this._videoMinutePrice * (100 - this.discount)) / 100);
	}

	get smsNotificationPrice() {
		return this.roundPrice((this._smsNotificationPrice * (100 - this.discount)) / 100);
	}

	get fixedMonthlyFee() {
		return (this._fixedMonthlyFee * (100 - this.discount)) / 100;
	}

	get projectPrice() {
		return (this._projectPrice * (100 - this.discount)) / 100;
	}

	get includedProjectPrice() {
		return this.fixedMonthlyFee / this.projectsIncludedMonthlyCount;
	}

	get estimatedProjectPrice() {
		return this.roundPrice(
			this.projectPrice +
				this.projectCreationPrice +
				this.videoMinutePrice * ESTIMATED_MINUTE_COUNT_PER_VIDEO_CALL +
				this.smsNotificationPrice * ESTIMATED_SMS_NOTIFICATION_COUNT_PER_PROJECT
		);
	}

	get asJson() {
		return {
			id: this.id,
			type: this.type,
			title: this.title,
			description: this.description,
			currency: this.currency,
			discount: this.discount,
			gigabytesIncludedCount: this.gigabytesIncludedCount,
			features: this.features,
			projectCreationPrice: this.projectCreationPrice,
			videoMinutePrice: this.videoMinutePrice,
			smsNotificationPrice: this.smsNotificationPrice,
			projectsIncludedMonthlyCount: this.projectsIncludedMonthlyCount,
			fixedMonthlyFee: this.fixedMonthlyFee,
			projectPrice: this.projectPrice,
		};
	}

	updateFromJson(json: PricingPlanDTO) {
		this.id = json.id;
		this.type = json.planType as any;
		this.title = json.title;
		this.description = json.description;
		this.currency = json.currency;
		this._discount = json.discount;
		this.gigabytesIncludedCount = json.gigabytesIncludedCount;
		this._projectCreationPrice = json.projectCreationPrice;
		this._videoMinutePrice = json.videoMinutePrice;
		this._smsNotificationPrice = json.smsNotificationPrice;
		this.projectsIncludedMonthlyCount = json.projectsIncludedMonthlyCount;
		this._fixedMonthlyFee = json.fixedMonthlyFee;
		this._projectPrice = json.projectPrice;

		this.features =
			json.features.map((feature) => {
				feature.description = this.populateFeatureDescription(feature.description);

				feature.subfeatures?.forEach(
					(subfeature) => (subfeature.description = this.populateFeatureDescription(subfeature.description))
				);

				return feature;
			}) || [];
	}

	roundPrice(price: number) {
		return parseFloat(parseFloat(price.toString()).toFixed(1));
	}

	calculateEstimatedMonthlyPrice(projectCount: number) {
		if (this.type === PricingPlanType.Dynamic) {
			const EXTRA_STORAGE_PRICE_100_GB = 350;
			const EXTRA_STORAGE_PRICE_500_GB = 650;
			const EXTRA_STORAGE_PRICE_1000_GB = 1000;

			const estimatedGigabytesUsedCount = projectCount * ESTIMATED_GB_USAGE_PER_PROJECT;
			let estimatedTotalMonthlyPrice = this.roundPrice(this.estimatedProjectPrice * projectCount);

			if (estimatedGigabytesUsedCount > this.gigabytesIncludedCount) {
				if (estimatedGigabytesUsedCount <= this.gigabytesIncludedCount + 100) {
					estimatedTotalMonthlyPrice += EXTRA_STORAGE_PRICE_100_GB;
				} else if (estimatedGigabytesUsedCount <= this.gigabytesIncludedCount + 500) {
					estimatedTotalMonthlyPrice += EXTRA_STORAGE_PRICE_500_GB;
				} else {
					estimatedTotalMonthlyPrice += EXTRA_STORAGE_PRICE_1000_GB;
				}
			}

			return estimatedTotalMonthlyPrice;
		} else if (this.type === PricingPlanType.Fixed) {
			if (projectCount <= this.projectsIncludedMonthlyCount) {
				return this.fixedMonthlyFee;
			}

			const projectsToPayCount = Math.ceil(projectCount - this.projectsIncludedMonthlyCount);
			return this.fixedMonthlyFee + projectsToPayCount * this.projectPrice;
		}
	}

	populateFeatureDescription(description: string) {
		const formatPrice = (price: number) => new Intl.NumberFormat().format(price);

		const values: { [key: string]: string } = {
			gigabytesIncludedCount: '' + this.gigabytesIncludedCount,
			estimatedProjectPrice: formatPrice(this.estimatedProjectPrice),
			projectCreationPrice: formatPrice(this.projectCreationPrice),
			videoMinutePrice: formatPrice(this.videoMinutePrice),
			smsNotificationPrice: formatPrice(this.smsNotificationPrice),
			projectPrice: formatPrice(this.projectPrice),
			fixedMonthlyFee: formatPrice(this.fixedMonthlyFee),
			projectsIncludedMonthlyCount: '' + this.projectsIncludedMonthlyCount,
		};

		const dynamicValues = description.match(/[^{}}}]+(?=}})/gm);

		dynamicValues?.forEach((dynamicValue) => {
			description = description.replace(
				new RegExp(`{{${dynamicValue}}}`, 'gm'),
				(values as { [key: string]: string })[dynamicValue]
			);
		});

		return description;
	}
}
