import {
	Alert,
	Avatar,
	Box,
	Card,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { Workspace } from '../../types';
import SearchInput from '../common/SearchInput';
import './WorkspaceList.scss';
import { toast } from 'react-toastify';
import { Company } from '../../stores/company/Company';
import LoginIcon from '@mui/icons-material/Login';

type WorkspaceListItemProps = {
	workspace: Workspace;
	company?: Company;
	dense?: boolean;
	onClick: () => void;
};
const WorkspaceListItem = observer(
	class WorkspaceListItem extends React.Component<WorkspaceListItemProps> {
		render() {
			const { workspace, company, onClick, dense } = this.props;
			return (
				<ListItem
					dense={dense}
					sx={{ width: '100%' }}
					secondaryAction={
						<IconButton onClick={onClick}>
							<LoginIcon />
						</IconButton>
					}
				>
					<ListItemButton onClick={onClick}>
						<ListItemAvatar>
							<Avatar src={company?.logo} variant="square" />
						</ListItemAvatar>
						<ListItemText
							primary={company?.name || workspace.name || 'Ukjent'}
							secondary={company?.orgNumber || `#${workspace.id}`}
						/>
					</ListItemButton>
				</ListItem>
			);
		}
	}
);

type Props = {
	preventAutoNavigate?: boolean;
	onWorkspaceSelected?: () => void;
};
const WorkspaceList = observer(
	class WorkspaceList extends React.Component<Props> {
		static readonly contextType = StoreContext;
		workspaces: Workspace[] = [];

		search: string = '';

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				workspaces: observable,
				search: observable,
				userStore: computed,
				companyStore: computed,
				uiState: computed,
				filteredWorkspaces: computed,
				onChange: action,
				handleWorkspaceClick: action,
			});

			this.workspaces = AsyncStorageHelper.getCache(`@BefWeb:login:workspaces`) || [];
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get filteredWorkspaces() {
			const workspaces = this.workspaces.slice().filter((w) => w?.name && w?.id);
			if (!this.search) {
				return workspaces;
			}

			return workspaces.filter((w) => w.name?.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);
		}

		onChange = (value: string) => {
			this.search = value;
		};

		handleWorkspaceClick = (workspace: Workspace) => async () => {
			AsyncStorageHelper.setCache(`@BefWeb:login:redirect`, '/app/dashboard');
			const result = await this.userStore.switchWorkspace(workspace, !this.props.preventAutoNavigate);
			if (result.statusCode === 401) {
				console.error('Unauthorized', result.data?.error);
				toast.warning('Du har ikke tilgang til denne bedriften', {
					autoClose: 10000,
				});
				return;
			} else if (result.statusCode === 200) {
				if (this.props.onWorkspaceSelected) {
					this.props.onWorkspaceSelected();
				}
			}
		};

		renderListItems() {
			if (!this.filteredWorkspaces.length) {
				return null;
			}

			return this.filteredWorkspaces.map((workspace) => {
				const company = this.companyStore.findCompanyByWorkspaceId(workspace.id);

				return (
					<WorkspaceListItem
						key={workspace.id}
						workspace={workspace}
						company={company}
						onClick={this.handleWorkspaceClick(workspace)}
						dense={this.uiState.isComputer}
					/>
				);
			});
		}

		render() {
			return (
				<Card className="WorkspaceList">
					{this.workspaces.length < 1 && (
						<Alert severity="info">
							Du har ikke tilgang til noen workspaces på {window.location.host} og kan derfor ikke
							autorisere denne appen.
						</Alert>
					)}

					{this.workspaces.length > 5 && (
						<Box sx={{ margin: '1rem' }}>
							<SearchInput value={this.search} onChange={this.onChange} />
						</Box>
					)}
					<List sx={{ width: '100%' }}>{this.renderListItems()}</List>
				</Card>
			);
		}
	}
);

export default WorkspaceList;
