import { IconButton, Paper, Stack, styled, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { observer } from 'mobx-react';
import React from 'react';

// icons
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import StoreContext from '../../../../stores/StoreContext';
import { RootStore } from '../../../../stores/RootStore';
import { Channel } from '../../../../stores/ChatStore';
import ChatInputActions, { ChatInputActionType } from './ChatInputActions';
import ChatMessageContentPreview from './ChatMessageContentPreview';
import { MessageContent } from '../../../../types';
import CircularProgressWithLabel from '../../../common/CircularProgressWithLabel';
import ChatInputPurifyMessage from './ChatInputPurifyMessage';
import { throttle } from 'lodash';
import { ChannelTypeDTO } from '../../../../dto/channel.types';
import CraftsmanGuard from '../../../../utils/CraftsmanGuard';

const StyledGrid2 = styled(Grid2)(({ theme }) => ({
	px: 2,
	width: '100%',
	[theme.breakpoints.down('sm')]: {
		width: '100%',
	},
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
	border: '1px solid rgba(0,0,0, 0.2)',
	borderRadius: '8px',
	padding: '1rem',
	width: '90%',
	maxWidth: '800px',
	[theme.breakpoints.down('sm')]: {
		borderRadius: 0,
		width: '100%',
	},
	[theme.breakpoints.up('sm')]: {
		marginBottom: '0.5rem',
	},
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	'position': 'relative',
	// scale to zero with and prevent pointer events when class is applied
	'&.hidden': {
		transform: 'scale(0)',
		width: 0,
		padding: 0,
		margin: 0,
		overflow: 'hidden',
		pointerEvents: 'none',
	},
	'width': '40px',
	'transition': 'transform 0.5s ease-in-out',
	'&:hover': {
		backgroundColor: 'transparent',
	},
	'&.mobile-only': {
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	'&.desktop-only': {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

type Props = {
	channel: Channel;
	// eslint-disable-next-line no-unused-vars
	onActionClick?: (action: ChatInputActionType) => void;
	isUploading?: boolean;
	customerServiceMode?: boolean;
};

const ChatInputTextarea = observer(
	class ChatInputTextarea extends React.Component<Props> {
		static readonly contextType = StoreContext;

		messageInputRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

		inputActionsOpen: boolean = false;
		delayedTypingIndicator: () => void;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				message: computed,
				messageInputRef: observable,
				inputActionsOpen: observable,

				channel: computed,
				isCraftsman: computed,
				hasMessage: computed,
				uploadProgress: computed,
				isCustomerService: computed,
				project: computed,

				sendMessage: action,
				onMessageChange: action,
				toggleInputActions: action,
				onActionClick: action,
				sendThumbUpReaction: action,
				removeMessageContent: action,
				setTypingIndicator: action,
			});

			this.delayedTypingIndicator = throttle(this.setTypingIndicator, 2500);
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get chatStore() {
			return this.rootStore.chatStore;
		}

		get projectStore() {
			return this.rootStore.projectStore;
		}

		get uploadProgress() {
			return this.userStore.isUploadingFile ? this.userStore.uploadProgress : 1;
		}

		get isCraftsman() {
			return this.rootStore.profileStore.isCraftsman;
		}

		get channel() {
			return this.props.channel;
		}

		get isCustomerService() {
			return this.props.customerServiceMode;
		}

		get hasMessage() {
			const content = this.message?.content ?? [];
			return this.message && (this.message?.text.trim().length > 0 || content.length > 0);
		}

		get message() {
			return this.channel.draftMessage;
		}

		get project() {
			return this.projectStore.getProjectByChannelId(this.channel.id);
		}

		toggleInputActions = () => {
			this.inputActionsOpen = !this.inputActionsOpen;
		};

		onActionClick = (action: ChatInputActionType) => {
			const { onActionClick } = this.props;
			if (onActionClick) {
				onActionClick(action);
			}
		};

		handleChatMessageKeyDown = (event: any) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				event.stopPropagation();
				this.sendMessage();
			}

			this.delayedTypingIndicator();
		};

		setTypingIndicator = () => {
			this.chatStore.isTyping(this.channel.id);
		};

		sendThumbUpReaction = () => {
			// using utf-8 thumbs up emoji
			if (!this.message) return;
			this.message.text = '👍';
			this.sendMessage().catch((error) => {
				console.error('Error sending thumb up reaction', error);
			});
		};

		sendMessage = async () => {
			if (!this.channel) return;

			if (!this.hasMessage) return;

			// todo: replace this patch with a proper solution
			if (this.isCustomerService && this.rootStore.sessionId) {
				await this.channel.sendCustomerServiceMessage(this.rootStore.sessionId);
			} else {
				await this.channel.sendDraftMessage();
			}

			runInAction(() => {
				this.channel.resetDraftMessage();
			});
		};

		onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
			this.message.text = event.target.value;
		};

		attachImage = () => {
			this.onActionClick(ChatInputActionType.AttachImage);
		};

		attachFile = () => {
			this.onActionClick(ChatInputActionType.AttachFile);
		};

		addReaction = () => {
			this.onActionClick(ChatInputActionType.Reaction);
		};

		renderStartActions() {
			const className = this.hasMessage ? 'hidden' : '';
			return (
				<Stack direction="row" spacing={0} alignItems="center" sx={{ position: 'relative' }}>
					{!this.isCustomerService && (
						<StyledIconButton onClick={this.toggleInputActions} id="chat-input---actions-menu--trigger">
							<AddCircleIcon fontSize="inherit" />
							<ChatInputActions
								onActionClick={this.onActionClick}
								open={this.inputActionsOpen}
								anchorElementId="chat-input---actions-menu--trigger"
								isCraftsman={this.isCraftsman}
								enablePurifyMessage={this.isCraftsman}
								enableShare={false}
							/>
						</StyledIconButton>
					)}
					<StyledIconButton className={[className].join(' ')} onClick={this.attachImage}>
						<PhotoCameraIcon fontSize="inherit" />
					</StyledIconButton>
					<StyledIconButton
						className={[className, 'desktop-only'].join(' ')}
						aria-label="attach"
						disabled={false}
						onClick={this.attachFile}
					>
						<AttachFileIcon fontSize="inherit" />
					</StyledIconButton>
				</Stack>
			);
		}

		renderEndActions() {
			// thumb up reaction if message is empty
			const { isUploading } = this.props;

			const icons = [
				<StyledIconButton key="add-reaction-btn" className="desktop-only" onClick={this.addReaction}>
					<InsertEmoticonIcon fontSize="inherit" />
				</StyledIconButton>,
			];

			if (!this.hasMessage && !isUploading) {
				icons.push(
					<StyledIconButton
						key="send-thumb-up-btn"
						aria-label="Tommel opp"
						onClick={this.sendThumbUpReaction}
						color="primary"
					>
						<ThumbUpIcon />
					</StyledIconButton>
				);
			} else {
				icons.push(
					<StyledIconButton
						key="send-message-btn"
						aria-label="send"
						onClick={this.sendMessage}
						disabled={isUploading ?? !this.hasMessage}
						color="primary"
					>
						{isUploading ? (
							<CircularProgressWithLabel size={24} value={this.uploadProgress * 100} />
						) : (
							<SendIcon />
						)}
					</StyledIconButton>
				);
			}
			return (
				<Stack direction="row" spacing={0} alignItems="center">
					{icons}
				</Stack>
			);
		}

		removeMessageContent = (content: MessageContent) => {
			this.channel.draftMessage.removeContent(content);
		};

		renderMessageContent() {
			if (!this.channel.draftMessage.content) {
				return null;
			}
			const onActionClick = () => {
				this.onActionClick(ChatInputActionType.Attach);
			};
			return (
				<ChatMessageContentPreview
					content={this.channel.draftMessage.content}
					onAdd={onActionClick}
					onRemove={this.removeMessageContent}
				/>
			);
		}

		render() {
			const { message, onMessageChange, channel } = this;
			let visibleTo = undefined;
			if (channel.channelType === ChannelTypeDTO.ProjectCustomer) {
				visibleTo = 'for kunden';
			} else if (channel.channelType === ChannelTypeDTO.ProjectExternal) {
				visibleTo = 'for partnere på jobben';
			} else if (channel.channelType === ChannelTypeDTO.ProjectInternal) {
				visibleTo = 'internt i bedriften';
			}

			return (
				<StyledGrid2
					xs="auto"
					container
					justifyContent="center"
					alignItems="center"
					sx={{ position: 'relative', zIndex: 2 }}
				>
					<Stack direction="column" spacing={0} alignItems="center" sx={{ width: '100%' }}>
						<ChatInputPurifyMessage channel={channel} />
						<PaperStyled elevation={3}>
							<Stack direction="row" spacing={1} alignItems="center">
								{this.renderStartActions()}
								<Stack direction="column" spacing={1} alignItems="flex-start" flexGrow={1}>
									{this.renderMessageContent()}
									<TextareaAutosize
										ref={this.messageInputRef}
										value={message.text}
										onChange={onMessageChange}
										onKeyDown={this.handleChatMessageKeyDown}
										style={{
											display: 'flex',
											flexGrow: 1,
											backgroundColor: 'transparent',
											border: 'none',
											fontSize: '1rem',
											resize: 'none',
											outline: 'none',
											color: 'inherit',
											width: '100%',
										}}
										aria-label={'Skriv en melding...'}
										minRows={1}
										maxRows={8}
										placeholder="Skriv en melding..."
									/>
								</Stack>
								{this.renderEndActions()}
							</Stack>
						</PaperStyled>
						<Typography
							variant="caption"
							color="textSecondary"
							sx={(theme) => ({
								textAlign: 'right',
								paddingBottom: '0.5rem',
								[theme.breakpoints.down('sm')]: {
									display: 'none',
								},
							})}
						>
							{message.text.length > 0 || this.isCustomerService
								? 'Enter for å sende, Shift+Enter for ny linje.'
								: 'Trykk på + for å legge til bilder og filer, avtaler eller ringe.'}

							{message.text.length > 0 && visibleTo && (
								<CraftsmanGuard>
									{' '}
									Meldinger som skrives her er synlig <strong>{visibleTo}</strong>
								</CraftsmanGuard>
							)}
						</Typography>
					</Stack>
				</StyledGrid2>
			);
		}
	}
);

export default ChatInputTextarea;
