/* eslint eqeqeq: "off" */

import { makeAutoObservable, runInAction } from 'mobx';
import { Trigger, TriggerType } from '../Trigger.types';

import LogUtil from '../helpers/LogUtil';
import { RootStore } from './RootStore';
import { TrakEvent } from '../api/endpoints/TrakApi';
import InsightApi, {
	JobsBySource,
	JobsBySourcePeriod,
	OrderChatByDay,
	TComputedChildWorkspaceStats,
} from '../api/endpoints/InsightApi';
import APIWorkspace from '../api/endpoints/APIWorkspace';
import StatsFactory from './insight/StatsFactory';

/**
 * ProjectStore
 */
export class InsightStore {
	rootStore: RootStore;
	trakEvents: TrakEvent[] = [];
	orderChatStats: OrderChatByDay[] = [];
	jobsBySourcePeriod: JobsBySourcePeriod | undefined;
	jobsBySource: JobsBySource | undefined;
	computedStats: { stats: TComputedChildWorkspaceStats; from: number; to: number } | undefined;

	heatPoints: any[] = []; // count, lat, lng, title, weight
	maskPoints: any[] = [];
	partnerLocations: any[] = [];
	popularHeatmapTags: string[] = [];

	stats: WorkspaceStats[] = [];
	isLoading: { [key: string]: boolean } = {
		jobsBySourcePeriod: false,
		jobsBySource: false,
		orderChatStats: false,
		computedStats: false,
	};

	statsFactory: StatsFactory;

	constructor(rootStore: RootStore) {
		this.reset = this.reset.bind(this);
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;

		this.statsFactory = new StatsFactory(rootStore);
		this.init();
	}

	init() {
		this.rootStore.userStore.SignedOut.on(() => this.reset());
	}

	reset() {
		this.trakEvents = [];
		this.orderChatStats = [];
		this.jobsBySourcePeriod = undefined;
		this.jobsBySource = undefined;
		this.computedStats = undefined;
		this.heatPoints = [];
		this.maskPoints = [];
		this.partnerLocations = [];
		this.stats = [];
		this.isLoading = {
			jobsBySourcePeriod: false,
			jobsBySource: false,
			orderChatStats: false,
			computedStats: false,
		};
	}

	get recentTrakEvents() {
		return this.trakEvents.slice().sort((a, b) => {
			if ((a.updated ?? 0) > (b.updated ?? 0)) return -1;
			if ((a.updated ?? 0) < (b.updated ?? 0)) return 1;
			return 0;
		});
	}

	async loadHeatmap(tags: string[] = []) {
		const result = await InsightApi.jobsHeatmap(tags);
		if (result.statusCode === 200) {
			runInAction(() => {
				this.heatPoints = Array.isArray(result.data.heatPoints) ? result.data.heatPoints : [];
				this.maskPoints = Array.isArray(result.data.maskPoints) ? result.data.maskPoints : [];
				this.partnerLocations = Array.isArray(result.data.partnerLocations) ? result.data.partnerLocations : [];
			});
		}
	}

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	async processTrakTrigger(trigger: Trigger<TrakEvent>) {
		try {
			switch (trigger.urn) {
				case TriggerType.TRAK_EVENT_CREATED:
				case TriggerType.TRAK_EVENT_UPDATED:
					this.updateTrakEventFromServer(trigger.event.data);
					this.updateOrderChatStats(trigger.event.data);
					break;
				default:
					console.log(`Processing trak triggers are unsupported ${trigger.urn}`);
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	async loadPopularTagsAndCategories() {
		const result = await InsightApi.jobsHeatmapTags();
		if (result.statusCode === 200) {
			runInAction(() => {
				this.popularHeatmapTags = result.data ?? [];
			});
		}
	}

	updateTrakEventFromServer = (trakEvent: TrakEvent) => {
		let eventIndex = this.trakEvents.findIndex((t) => t.id === trakEvent.id);
		if (eventIndex < 0) {
			this.trakEvents.push(trakEvent);
		} else {
			this.trakEvents[eventIndex] = trakEvent;
		}

		if (this.trakEvents.length > 100) {
			this.trakEvents.unshift();
		}
	};

	async getJobsBySourcePeriod(from?: Date, includeChildWorkspaces: boolean = false) {
		if (this.isLoading.jobsBySourcePeriod) return;
		this.isLoading.jobsBySourcePeriod = true;
		const timestamp = from ? from.getTime() / 1000 : new Date(2023, 1, 1).getTime() / 1000;
		const to = Date.now() / 1000;
		const result = await InsightApi.jobsBySourcePeriod(
			Math.floor(timestamp),
			Math.floor(to),
			includeChildWorkspaces
		).finally(() => {
			this.isLoading.jobsBySourcePeriod = false;
		});
		if (result.statusCode === 200) {
			runInAction(() => {
				this.jobsBySourcePeriod = result.data.stats ?? [];
			});
		}
	}

	async getOrderChatStats(from?: Date, includeChildWorkspaces: boolean = false) {
		if (this.isLoading.orderChatStats) return;
		this.isLoading.orderChatStats = true;
		const timestamp = from ? from.getTime() / 1000 : new Date(2023, 1, 1).getTime() / 1000;
		const to = Date.now() / 1000;
		const result = await InsightApi.orderChatStats(
			Math.floor(timestamp),
			Math.floor(to),
			includeChildWorkspaces
		).finally(() => {
			this.isLoading.orderChatStats = false;
		});
		if (result.statusCode === 200) {
			runInAction(() => {
				this.orderChatStats = result.data;
			});
		}
	}

	async getComputedStats(from?: Date) {
		if (this.isLoading.computedStats) return;
		this.isLoading.computedStats = true;
		const timestamp = from ? from.getTime() / 1000 : new Date(2023, 1, 1).getTime() / 1000;
		const to = Date.now() / 1000;
		const result = await InsightApi.computedStats(Math.floor(timestamp), Math.floor(to)).finally(() => {
			this.isLoading.computedStats = false;
		});
		if (result.statusCode === 200) {
			runInAction(() => {
				this.computedStats = result.data;
			});
		}
	}

	async getRecentOrderChatTrakEvents(includeChildWorkspaces: boolean = false) {
		if (this.isLoading.recentOrderChatTrakEvents) return;
		this.isLoading.recentOrderChatTrakEvents = true;
		const result = await InsightApi.recentOrderChatTrakEvents(includeChildWorkspaces).finally(() => {
			this.isLoading.recentOrderChatTrakEvents = false;
		});
		if (result.statusCode === 200 && result.data?.length > 0) {
			result.data.forEach(this.updateTrakEventFromServer);
		}
	}

	async getJobsBySource(from?: Date, includeChildWorkspaces: boolean = false) {
		if (this.isLoading.jobsBySource) return;
		this.isLoading.jobsBySource = true;
		const timestamp = from ? from.getTime() / 1000 : new Date(2023, 1, 1).getTime() / 1000;
		const to = Date.now() / 1000;
		const result = await InsightApi.jobsBySource(
			Math.floor(timestamp),
			Math.floor(to),
			includeChildWorkspaces
		).finally(() => {
			this.isLoading.jobsBySource = false;
		});
		if (result.statusCode === 200) {
			runInAction(() => {
				this.jobsBySource = result.data;
			});
		}
	}

	// eslint-disable-next-line no-unused-vars
	updateOrderChatStats = (trakEvent: TrakEvent, prevState?: TrakEvent) => {
		// Remove prev update

		// add new upadte

		// Patch
		if (trakEvent.type === 'OrderChat') {
			this.getOrderChatStats();
		}
	};

	async getWorkspaceStats(workspaceId: string, periodDays: number = 30) {
		if (!workspaceId) return undefined;
		const result = await InsightApi.childWorkspaceStats(workspaceId, periodDays);
		if (result.statusCode === 200) {
			if (result.data) {
				this.updateWorkspaceStatsFromJson(result.data);
			}
			return result.data;
		}
		return undefined;
	}

	async getChildWorkspaceStats(periodDays: number = 30) {
		const result = await InsightApi.childWorkspacesStats(periodDays);
		if (result.statusCode === 200) {
			if (result.data.stats?.length > 0) {
				runInAction(() => {
					result.data.stats.forEach(this.updateWorkspaceStatsFromJson);
				});
			}
			return result.data;
		}
		return undefined;
	}

	async getChildWorkspaceUsage() {
		const d = new Date();
		const result = await APIWorkspace.getChildrenUsage(d.getFullYear(), d.getMonth());
		if (result.statusCode === 200) {
			if (result.data?.length > 0) {
				runInAction(() => {
					result.data.forEach(this.updateWorkspaceUsageFromJson);
				});
			}
			return result.data;
		}
		return undefined;
	}

	findWorkspaceStats = (workspaceId: string) => {
		return this.stats.find((s) => s.workspaceId == '' + workspaceId);
	};

	updateWorkspaceStatsFromJson = (json: any) => {
		let stats = this.findWorkspaceStats(json.workspaceId);
		if (!stats) {
			stats = new WorkspaceStats(json.workspaceId, json.name);
			this.stats.push(stats);
		}
		stats.updateFromJson(json);
	};

	updateWorkspaceUsageFromJson = (json: any) => {
		let stats = this.findWorkspaceStats(json.id);
		if (!stats) {
			stats = new WorkspaceStats(json.id, json.name);
			this.stats.push(stats);
		}
		stats.updateFromJson(json);
	};
}

type VideoSeconds = {
	sessions: number;
	seconds: number;
};

type CostType = {
	discount: number;
	discountPts: number;
	fixed: number;
	projects: number;
	sms: number;
	storage: number;
	sum: number;
	total: number;
	totalInclVat: number;
	vat: number;
	video: number;
};

export class WorkspaceStats {
	workspaceId: string;
	name?: string;
	domain?: string;
	numMessages: number = 0;
	numUnreadCustomerMessages: number = 0;
	customerResponseTime?: number;
	numCustomerCreatedProjects: number = 0;
	numCraftsmanCreatedProjects: number = 0;
	numUnprocessedProjects: number = 0;
	billableSms: number = 0;
	videoSeconds: VideoSeconds = {
		sessions: 0,
		seconds: 0,
	};
	viewsCompanyPage: number = 0;
	hitsInRadius?: {
		views: number;
		foundCompany: number;
		jobCreated: number;
		radius: number;
	};
	hitsInExtendedRadius?: {
		views: number;
		foundCompany: number;
		jobCreated: number;
		radius: number;
	};
	numBillableSmsSent: number = 0;
	avgRating: number = 0;
	numReviews: number = 0;
	computedScore?: string;
	periodDays?: number;

	pricingPlanId?: string;
	searchRadius?: number;
	searchDisabled: boolean = true;
	fileStorage?: number = 0;
	archiveDuration?: number = 0;
	numProjects?: number = 0;
	numSms?: number = 0;
	videoChatSeconds?: number = 0;
	cost: CostType = {
		discount: 0,
		discountPts: 0,
		fixed: 0,
		projects: 0,
		sms: 0,
		storage: 0,
		sum: 0,
		total: 0,
		totalInclVat: 0,
		vat: 0,
		video: 0,
	};

	constructor(workspaceId: string, name?: string) {
		this.workspaceId = '' + workspaceId;
		this.name = name;
	}

	updateFromJson(json: any) {
		this.name = json.name ?? this.name;
		this.domain = json.domain ?? this.domain;
		this.numMessages = json.numMessages ?? this.numMessages;
		this.numUnreadCustomerMessages = json.numUnreadCustomerMessages ?? this.numUnreadCustomerMessages;
		this.customerResponseTime = json.customerResponseTime ?? this.customerResponseTime;
		this.numCustomerCreatedProjects = json.numCustomerCreatedProjects ?? this.numCustomerCreatedProjects;
		this.numCraftsmanCreatedProjects = json.numCraftsmanCreatedProjects ?? this.numCraftsmanCreatedProjects;
		this.numUnprocessedProjects = json.numUnprocessedProjects ?? this.numUnprocessedProjects;
		this.billableSms = json.billableSms ?? this.billableSms;
		this.videoSeconds = json.videoSeconds ?? this.videoSeconds;
		this.viewsCompanyPage = json.viewsCompanyPage ?? this.viewsCompanyPage;
		this.hitsInRadius = json.hitsInRadius ?? this.hitsInRadius;
		this.hitsInExtendedRadius = json.hitsInExtendedRadius ?? this.hitsInExtendedRadius;
		this.numBillableSmsSent = json.numBillableSmsSent ?? this.numBillableSmsSent;
		this.avgRating = json.avgRating ?? this.avgRating;
		this.numReviews = json.numReviews ?? this.numReviews;
		this.computedScore = json.computedScore ?? this.computedScore;
		this.periodDays = json.periodDays ?? this.periodDays;
		this.pricingPlanId = json.pricingPlanId ?? this.pricingPlanId;
		this.searchRadius = json.searchRadius ?? this.searchRadius;
		this.searchDisabled = json.searchDisabled ?? this.searchDisabled;
		this.fileStorage = json.fileStorage ?? this.fileStorage;
		this.archiveDuration = json.archiveDuration ?? this.archiveDuration;
		this.numProjects = json.numProjects ?? this.numProjects;
		this.numSms = json.numSms ?? this.numSms;
		this.videoChatSeconds = json.videoChatSeconds ?? this.videoChatSeconds;

		this.updateCostFromJson(json.cost);
	}

	updateCostFromJson(cost?: any) {
		if (!cost) return;
		this.cost = {
			discount: cost.discount ?? this.cost.discount,
			discountPts: cost.discountPts ?? this.cost.discountPts,
			fixed: cost.fixed ?? this.cost.fixed,
			projects: cost.projects ?? this.cost.projects,
			sms: cost.sms ?? this.cost.sms,
			storage: cost.storage ?? this.cost.storage,
			sum: cost.sum ?? this.cost.sum,
			total: cost.total ?? this.cost.total,
			totalInclVat: cost.totalInclVat ?? this.cost.totalInclVat,
			vat: cost.vat ?? this.cost.vat,
			video: cost.video ?? this.cost.video,
		};
	}

	// eslint-disable-next-line no-unused-vars
	updateUsageFromJson(json: any) {}
}
