import { makeAutoObservable } from 'mobx';
import KobbrQuotesApi from '../../api/endpoints/KobbrApi';
import { KobbrQuoteDTO, PartialKobbrQuoteDTO } from '../../dto/kobbr.types';
import { RootStore } from '../RootStore';
import KobbrQuote from './KobbrQuote';
import { Trigger, TriggerType } from '../../Trigger.types';

class KobbrQuotesStore {
	rootStore: RootStore;
	quotes: KobbrQuote[] = [];

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
	}

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	processTrigger = async (trigger: Trigger<KobbrQuoteDTO>) => {
		try {
			switch (trigger.urn) {
				case TriggerType.KOBBR_QUOTE_CREATED:
				case TriggerType.KOBBR_QUOTE_UPDATED:
					this.updateQuote(trigger.event.data.quoteId, trigger.event.data);
					break;
			}
		} catch (err) {
			console.warn(err);
		}
	};

	getQuote(quoteId?: string): KobbrQuote | undefined {
		if (!quoteId) {
			return;
		}
		const existingQuote = this.quotes.find((q) => q.quoteId === quoteId);
		if (existingQuote) {
			return existingQuote;
		}
		return;
	}

	async fetchQuote(quoteId: string): Promise<KobbrQuote | undefined> {
		if (!quoteId) {
			return;
		}
		try {
			const response = await KobbrQuotesApi.getQuote(quoteId);
			if (response.data) {
				const quote = this.updateQuote(quoteId, response.data);
				return quote;
			}
		} catch (error) {
			// Handle error (e.g., log or throw)
		}
	}

	async fetchQuotes(): Promise<void> {
		try {
			const response = await KobbrQuotesApi.getQuotes();
			if (response.statusCode === 200 && response.data) {
				response.data.forEach((quote: any) => this.updateQuote(quote.quoteId, quote));
			}
		} catch (error) {
			// Handle error (e.g., log or throw)
		}
	}

	updateQuote(quoteId: string, updateData: PartialKobbrQuoteDTO): KobbrQuote | undefined {
		try {
			const existingQuote = this.quotes.find((q) => q.quoteId === quoteId);
			if (existingQuote) {
				existingQuote.updateFromDTO(updateData);
				// mobx hack
				this.quotes = [...this.quotes];
				return existingQuote;
			} else {
				const quote = new KobbrQuote(updateData);
				this.quotes.push(quote);
			}

			return this.quotes.find((q) => q.quoteId === quoteId);
			// If the quote does not exist in the store, it could be fetched or handled accordingly
		} catch (error) {
			// Handle error
		}
	}

	// Methods for fetching/editing/opening links can be added here
}

export default KobbrQuotesStore;
