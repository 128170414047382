/* eslint-disable no-unused-vars */

import { ChatWidgetConfigDTO, DesktopSize, MobileSize } from './chatWidget.types';
import { ProjectStatusDTO } from './project.types';

/**
 * Enums for Campaign
 */
export enum CampaignStatusEnum {
	// eslint-disable-next-line no-unused-vars
	Active = 'ACTIVE',
	// eslint-disable-next-line no-unused-vars
	Paused = 'PAUSED',
	// eslint-disable-next-line no-unused-vars
	Stopped = 'STOPPED',
}

export enum CampaignDynamicStatusReason {
	// eslint-disable-next-line no-unused-vars
	BudgetSpent = 'BudgetSpent',
	// eslint-disable-next-line no-unused-vars
	CampaignDeleted = 'CampaignDeleted',
	// eslint-disable-next-line no-unused-vars
	CampaignNotStarted = 'CampaignNotStarted',
	// eslint-disable-next-line no-unused-vars
	CampaignEnded = 'CampaignEnded',
	// eslint-disable-next-line no-unused-vars
	CampaignManuallyPaused = 'CampaignManuallyPaused',
	// eslint-disable-next-line no-unused-vars
	CampaignManuallyStopped = 'CampaignManuallyStopped',
	// eslint-disable-next-line no-unused-vars
	CampaignRunning = 'CampaignRunning',
	// eslint-disable-next-line no-unused-vars
	NotCreated = 'NotCreated',
}

export enum BudgetCurrencyEnum {
	// eslint-disable-next-line no-unused-vars
	NOK = 'NOK',
	// eslint-disable-next-line no-unused-vars
	SEK = 'SEK',
	// eslint-disable-next-line no-unused-vars
	DKK = 'DKK',
	// eslint-disable-next-line no-unused-vars
	EUR = 'EUR',
	// eslint-disable-next-line no-unused-vars
	USD = 'USD',
}

export enum CampaignTypeEnum {
	// eslint-disable-next-line no-unused-vars
	Basic = 'BASIC',
	// eslint-disable-next-line no-unused-vars
	Premium = 'PREMIUM',
}

/**
 * Enum for Campaign Lead Status
 * @enum {string}
 */
export enum CampaignLeadStatusEnum {
	Open = 'Open',
	InProgress = 'InProgress',
	Resolved = 'Resolved',
	Closed = 'Closed',
	Converted = 'Converted',
	Failed = 'Failed',
}

export enum PlatformTypeEnum {
	Facebook = 'Facebook',
	Instagram = 'Instagram',
	Google = 'Google',
	LinkedIn = 'LinkedIn',
	Direct = 'Direct', // Direct traffic
}

export enum CampaignLeadLogActionTypeEnum {
	Impressions = 'impressions', // When a lead sees the campaign
	Click = 'click', // When a lead clicks on the campaign
	InteractionStarted = 'interaction_started', // When a lead starts interacting (e.g., chat initiation)
	Conversion = 'conversion', // When a lead completes the desired action (e.g., purchase, signup)
	MessageSent = 'message_sent', // When a message is sent by the lead
	ResponseSent = 'response_sent', // When a response is sent by the campaign or AI
	TaskCompleted = 'task_completed', // When a task in the conversation is completed
	Failed = 'failed', // When an action fails (e.g., failed task)
	FormSubmitted = 'form_submitted', // When a form submission happens
	Bounce = 'bounce', // When a lead bounces without interaction
	QuickActionClicked = 'quick_action_clicked', // When a quick action (e.g., a button) is clicked in the chat
	ChatOpened = 'chat_opened', // When a chat is opened by the user
	ChatClosed = 'chat_closed', // When the chat window is closed by the user
	// Add more action types as needed
}

/**
 * Enum for User Sentiment
 * @enum {string}
 */
export enum UserSentimentEnum {
	Positive = 'positive',
	Neutral = 'neutral',
	Negative = 'negative',
	Mixed = 'mixed',
	Confused = 'confused',
	Frustrated = 'frustrated',
	Satisfied = 'satisfied',
	Excited = 'excited',
	Angry = 'angry',
	Disappointed = 'disappointed',
	Raging = 'raging',
	Apathetic = 'apathetic',
	Curious = 'curious',
	Enthusiastic = 'enthusiastic',
	Concerned = 'concerned',
	Afraid = 'afraid',
}

/**
 * Campaign Data Transfer Object (DTO)
 */
export interface CampaignDTO {
	id?: number;
	workspaceId: number;
	created?: Date;
	updated?: Date;
	deleted?: Date | null;
	name: string;
	description?: string;
	goal?: string;
	status: CampaignStatusEnum;
	budgetCurrency?: BudgetCurrencyEnum;
	budget?: number;
	publishTime?: Date | null;
	startTime?: Date | null;
	endTime?: Date | null;
	type: CampaignTypeEnum;
	url?: string;
	costPerResolution: number;
	costPerConversion: number;
	costPerClick: number;
	showListing: boolean; // Whether the campaign should be listed
	listingDetails?: string; // Detailed info for chain members
	listingTerms?: string; // Terms for joining the campaign
	limitToChildrenOfWorkspaceId?: number | null; // Restrict listing to child workspaces
	acceptanceDeadline?: Date | null; // Deadline for accepting the campaign
	isMandatory?: boolean; // Whether it's mandatory to join
	chatWidgetConfigDto?: ChatWidgetConfigDTO;
	dynamicStatus: CampaignStatusEnum;
	dynamicStatusReasons: CampaignDynamicStatusReason[];
}

export type CampaignSpendDTO = {
	budgetSpent: number;
	totalBudget: number;
	spentAmount: number;
	clickCost: number;
	resolutionCost: number;
	conversionCost: number;
};

export type CampaignLeadDeviceMetadataDTO = {
	origin?: string;
	browser?: string;
	os?: string;
	platform?: string;
	isMobile?: boolean;
	isDesktop?: boolean;
	isBot?: boolean;
};

export type CampaignLeadInitialState = {
	initialMessage?: string;
	initialWidgetMobileSize?: MobileSize;
	initialWidgetDesktopSize?: DesktopSize;
};

/**
 * Campaign Lead Data Transfer Object
 * @typedef {Object} CampaignLeadDTO
 */
export interface CampaignLeadDTO {
	id?: number;
	created?: string; // iso date string
	campaignId: number;
	channelId?: number;
	profileId: number;
	userId: number;
	sessionId: string;
	status: CampaignLeadStatusEnum;
	resolved?: Date;
	converted?: Date;
	leadScore?: number;
	sentiment?: UserSentimentEnum;
	numUserMessages?: number;
	numResponses?: number;
	latitude?: number;
	longitude?: number;
	postCode?: string;
	postArea?: string;
	country?: string;
	billedDate?: Date;
	workspaceId?: number;
	projectId?: number;
	utmSource?: string;
	utmMedium?: string;
	utmCampaign?: string;
	utmTerm?: string;
	utmContent?: string;
	tags: string[]; // Assuming JSON format
	category: string[]; // Assuming JSON format
	insightSuggestion?: string;
	postProcessingDateTime?: Date;
	project?: CampaignProjectDTO;
	isTest: boolean;
	deviceMetadata?: CampaignLeadDeviceMetadataDTO;
	initialState?: CampaignLeadInitialState;
}

export interface CampaignProjectDTO {
	projectId: number;
	name: string;
	status: ProjectStatusDTO;
	created: Date;
	updated: Date;
	workspaceId: number;
	companyName: string;
}

/**
 * Campaign Lead Log Data Transfer Object
 * @typedef {Object} CampaignLeadLogDTO
 */
export interface CampaignLeadLogDTO {
	id?: number;
	campaignLeadId: number;
	agentTaskId?: number;
	actionType: string;
	created?: Date;
	status?: CampaignLeadStatusEnum;
	sentiment?: UserSentimentEnum;
	numUserMessages?: number;
	numResponses?: number;
	url?: string;
	leadScore?: number;
	taskTimeSpent?: number;
	tokensUsed?: number;
	details?: string;
	utmSource?: string;
	utmMedium?: string;
	utmCampaign?: string;
	utmContent?: string;
}

export interface PlatformStat {
	type: PlatformTypeEnum | string; // Enum for common platforms, string for custom ones
	value: number; // The stat value (e.g., impressions, clicks)
}

export interface ShallowStatsDTO {
	status: string; // Campaign status, e.g., "ACTIVE", "PAUSED"

	impressions: number; // Total impressions across all platforms
	clicks: number; // Total clicks across all platforms
	conversions: number; // Total conversions across all platforms
	resolutions: number; // Total resolved leads
	bounces: number; // Total bounces (leads that didn’t engage meaningfully)

	ctr: number; // Click-through rate (CTR)
	resolutionRate: number; // Resolution rate
	conversionRate: number; // Conversion rate
	numConversations: number; // Number of conversions

	cpc: number; // Cost-per-click (CPC)
	budgetSpent: number; // Percentage of budget spent
	totalBudget: number; // The total budget for the campaign
	spentAmount: number; // The amount of budget spent so far

	platformImpressions: PlatformStat[]; // Impressions per platform
	platformClicks: PlatformStat[]; // Clicks per platform
	platformConversions: PlatformStat[]; // Conversions per platform

	costPerResolution: number; // Cost per resolution
	costPerConversion: number; // Cost per conversion
}

/**
 * DTO for deep campaign statistics
 */
export interface DeepStatsDTO {
	topTags: TagStats[]; // Top 10 tags and their statistics
	topCategories: CategoryStats[]; // Top 10 categories and their statistics
	sentimentDistribution: SentimentStats[]; // Overall sentiment distribution
	bounceCount: number; // Total bounced conversations
	resolvedCount: number; // Total resolved conversations
	convertedCount: number; // Total converted conversations
	insights: string[]; // List of insights or recommendations
}

/**
 * Tag statistics including sentiment and conversion breakdown
 */
export interface TagStats {
	tag: string;
	conversationCount: number;
	averageSentiment: number;
	convertedCount: number;
	resolvedCount: number;
	bouncedCount: number;
}

/**
 * Category statistics including sentiment and conversion breakdown
 */
export interface CategoryStats {
	category: string;
	conversationCount: number;
	averageSentiment: number;
	convertedCount: number;
	resolvedCount: number;
	bouncedCount: number;
}

/**
 * Sentiment distribution for the campaign
 */
export interface SentimentStats {
	sentiment: string; // e.g. "positive", "negative"
	count: number; // Number of occurrences of this sentiment
}

export interface CampaignFAQDTO {
	id: number;
	campaignId: number;
	question: string;
	answer: string | null;
	relevanceScore: number;
	isConnectedToRAG: boolean;
	leadCount: number; // Number of leads who asked this question
	created: Date;
	updated: Date;
}

export type CampaignAssignableWorkspaceDTO = {
	workspaceId: number;
	name: string;
	domain: string;
	campaignParticipantId: number;
	approved?: string;
	rejected?: string;
	rejectReason?: string;
	isSelected: boolean;
	visible: boolean;
	pricingPlanId?: number;
	pricingPlanName?: string;
	operatingRadius?: number;
	parentWorkspaceId?: number;
};

export type CampaignListing = {
	campaignId: number;
	name: string;
	description: string;
	workspaceId: number;
	listingDetails: string;
	listingTerms: string;
	limitToChildrenOfWorkspaceId: number | null;
	isMandatory: boolean;
	approved: Date | null;
	rejected: Date | null;
	rejectReason: string;
	campaignParticipantId?: number;
};
