import { createTheme, Theme } from '@mui/material';

const smartTheme = (baseTheme: Theme, options: any): Theme => {
	const { isDarkMode } = options;
	const theme = createTheme(
		{
			...baseTheme,
			palette: options.isDarkMode
				? {
					mode: 'dark',
					background: {
						default: '#212121',
						paper: '#212121',
					},
					divider: 'rgba(255, 255, 255, 0.1)',
					error: {
						main: '#d44141',
					},
					info: {
						main: '#3a91bb',
					},
					secondary: {
						main: '#140a36',
					},
					primary: {
						main: '#4fb0b2',
					},
					success: {
						main: '#6eb351',
					},
					text: {
						primary: '#f6f6f6',
						secondary: '#00bcd4',
						disabled: 'rgba(23, 57, 99, 0.38)',
						// hint: 'rgba(23, 57, 99, 0.38)',
					},
					warning: {
						main: '#fdb714',
					},
				}
				: {
					mode: 'light',
					background: {
						default: '#fafafa',
						paper: '#f1f1f1',
					},
					divider: 'rgba(23, 57, 99, 0.12)',
					error: {
						main: '#d44141',
					},
					info: {
						main: '#3a91bb',
					},
					primary: {
						main: '#140a36',
						contrastText: '#fff',
					},
					secondary: {
						main: '#4fb0b2',
						contrastText: '#fff',
					},
					success: {
						main: '#6eb351',
					},
					text: {
						primary: 'rgb(23, 57, 99, 0.87)',
						secondary: 'rgba(23, 57, 99, 0.60)',
						disabled: 'rgba(23, 57, 99, 0.38)',
						// hint: 'rgba(23, 57, 99, 0.38)',
					},
					warning: {
						main: '#fdb714',
					},
				},
			shape: {
				borderRadius: 8,
			},
			typography: {
				fontFamily: 'Inter, Helvetica, sans-serif',
			},
		},
		[isDarkMode]
	);
	return theme;
};

export default smartTheme;
