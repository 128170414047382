import { Grid, Typography, Alert, Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import ReactCodeInput from 'react-verification-code-input';
import ResendVerificationCodeButton from '../login/ResendVerificationCodeButton';
import { action, makeObservable, observable, runInAction } from 'mobx';
import AuthManager from '../../auth/AuthManager';

type Props = {
	phone: string;
	// eslint-disable-next-line no-unused-vars
	onSuccess: (code: string) => void;
	// eslint-disable-next-line no-unused-vars
	onError?: (error: string) => void;
};

const OrderChatVerificationCode = observer(
	class OrderChatVerificationCode extends React.Component<Props> {
		error: string | undefined;
		loading: boolean = false;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				error: observable,
				loading: observable,
				setError: action,
			});
		}

		setError = (error: string) => {
			this.error = error;

			if (this.props.onError) {
				this.props.onError(error);
			}
		};

		onVerificationCodeComplete = async (code: string) => {
			const { phone } = this.props;
			this.loading = true;

			try {
				const response = await AuthManager.phoneAuthVerify(undefined, phone, code);

				if (response.statusCode !== 200) {
					this.setError('Feil kode');
				} else {
					console.log('Moving on to checking login status');
					// Check login status. If userId has changed this fires triggers that loads everything needed
					this.props.onSuccess(code);
				}
			} catch (error) {
				this.setError('Feil kode');
			}

			runInAction(() => {
				this.loading = false;
			});
		};

		render() {
			const { phone } = this.props;
			return (
				<Grid container alignItems="center" justifyContent="center" sx={{ minHeight: '90vh' }}>
					<Grid
						container
						item
						xs={12}
						md={8}
						lg={6}
						alignItems="center"
						justifyContent="center"
						sx={{ margin: '2rem' }}
						direction="column"
					>
						<Typography align="center" gutterBottom variant={'h3'}>
							Vi har sendt en bekreftelseskode til{' '}
							<span className="text--bold text--italic text--red">{phone}</span>
						</Typography>

						{this.error && (
							<Alert severity="error" sx={{ margin: '1rem' }}>
								{this.error}
							</Alert>
						)}

						<ReactCodeInput
							title="Bekreftelseskode"
							autoFocus
							loading={this.loading}
							onComplete={this.onVerificationCodeComplete}
						/>
						<Box sx={{ marginTop: '3rem' }}>
							<ResendVerificationCodeButton phoneNumber={phone} />
						</Box>
					</Grid>
				</Grid>
			);
		}
	}
);

export default OrderChatVerificationCode;
