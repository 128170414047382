import ArrowDownward from '@mui/icons-material/ArrowDownward';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Alert, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { TypeAnimation } from 'react-type-animation';
import FeatureGuard from '../../../../utils/FeatureGuard';
import PricingPlanGuard from '../../../../utils/PricingPlanGuard';
import DotTypingAnimation from '../../../common/DotTypingAnimation';
import { Channel } from '../../../../stores/ChatStore';

type Props = {
	channel: Channel;
};

const ChatInputPurifyMessage = observer((props: Props) => {
	const { channel } = props;
	const { draftMessage } = channel;

	const clearMessageSuggestion = () => {
		draftMessage.clearMessageSuggestion();
	};

	const useMessageSuggestion = () => {
		draftMessage.useMessageSuggestion();
	};

	const purifyMessage = () => {
		draftMessage.purify().catch((error) => {
			console.error('Failed to purify message', error);
		});
	};

	const show = draftMessage?.text && draftMessage?.text.length > 10;

	if (!show) {
		return null;
	}

	return (
		<FeatureGuard feature="ai.chat">
			<PricingPlanGuard plans={['pro', 'elite']}>
				{draftMessage?.text && (
					<Alert
						severity="info"
						onClose={clearMessageSuggestion}
						sx={{
							position: 'relative',
							marginBottom: '1rem',
							width: '90%',
							maxWidth: '800px',
							boxShadow: '0 2px 4px rgba(0,0,0,.26)',
						}}
						action={
							draftMessage.hasPurifiedCurrentMessage ? (
								<Button color="inherit" size="small" onClick={useMessageSuggestion}>
									<ArrowDownward /> Bruk
								</Button>
							) : (
								<Button color="inherit" size="small" onClick={purifyMessage}>
									<AutoFixHighIcon /> Formaliser
								</Button>
							)
						}
					>
						{draftMessage.loading ? (
							<DotTypingAnimation />
						) : (
							(draftMessage.hasPurifiedCurrentMessage && (
								<TypeAnimation
									sequence={[draftMessage.messageSuggestion]}
									wrapper="span"
									cursor={false}
									repeat={0}
									speed={99}
								/>
							)) || (
								<Typography variant="body2">
									Vår ChatAI kan formalisere språket til meldingen din. Trykk på{' '}
									<AutoFixHighIcon fontSize="inherit" />.
								</Typography>
							)
						)}
					</Alert>
				)}
			</PricingPlanGuard>
		</FeatureGuard>
	);
});

export default ChatInputPurifyMessage;
