import { Button, Container, Grid, StyledComponentProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import Footer from '../footer/Footer';
import { HowItWorksSteps } from './HowItWorksCarousel';
import Step from './Step';

const PREFIX = 'HowItWorks';

const classes = {
	root: `${PREFIX}-root`,
};

const Root = styled('div')({
	[`& .${classes.root}`]: {
		marginTop: 32,
		marginBottom: 56,
	},
});

type Props = StyledComponentProps & {
	hideFooter?: boolean;
};

const HowItWorks = observer(
	class HowItWorks extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				uiState: computed,
				showFooter: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get showFooter() {
			return Boolean(!this.props.hideFooter);
		}

		renderSteps() {
			return HowItWorksSteps.map((step, index) => (
				<Step
					key={index}
					imageURL={step.imageURL}
					number={index + 1}
					title={step.title}
					description={step.description}
				/>
			));
		}

		render() {
			const { isComputer, serviceName } = this.uiState;

			return (
				<Root>
					<Container classes={{ root: classes.root }} className="HowItWorks">
						<Grid
							container
							direction="column"
							alignItems="center"
							spacing={6}
							sx={{
								maxWidth: 1024,
								margin: 'auto',
							}}
						>
							<Grid item xs={12} sm={9} md={6} lg={6}>
								<Typography align="center" paragraph variant={isComputer ? 'h4' : 'h5'}>
									Med <span className="text--bold text--italic">{serviceName}</span> kan du chatte med
									en faglært {this.uiState.entityName} som hjelper deg fra idé til gjennomføring:
								</Typography>
							</Grid>

							<Grid container item spacing={6}>
								{this.renderSteps()}
							</Grid>

							<Grid item>
								<Button color="primary" component={Link} to="/quote/FreeInspection" variant="contained">
									Chat med en {this.uiState.entityName}
								</Button>
							</Grid>
						</Grid>
					</Container>
					{this.showFooter && <Footer />}
				</Root>
			);
		}
	}
);

export default HowItWorks;
