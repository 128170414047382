import React from 'react';
import Article from './Article';
import { Button, Stack, Typography } from '@mui/material';
import BefareIllustration from '../common/illustrations/BefareIllustration';
import { ServicesEnum } from '../home/generic/GenericServices';
import { Link } from 'react-router-dom';

export default class LightingArticle extends React.Component {
	render() {
		return (
			<Article>
				<Typography variant="h3" gutterBottom>
					Skap stemning med lys
				</Typography>
				<Typography variant="body1" gutterBottom>
					Når det kommer til belysning i hjemmet ditt, hjelper det ikke å bare kaste lys på situasjonen.
					Riktig valg og montering av lamper, downlights og til og med smartbelysning kan skape den ideelle
					atmosfæren og forbedre funksjonaliteten i både innendørs og utendørs områder.
				</Typography>
				<BefareIllustration
					illustration="result"
					sx={{
						margin: '1rem auto',
						maxWidth: '300px',
						background: '#ccc',
						padding: '2rem',
						borderRadius: '2rem',
					}}
				/>
				<Typography variant="body1" gutterBottom>
					Her er noen grunner til hvorfor det er en smart idé å involvere en profesjonell elektriker når du
					tar beslutninger om belysning.
				</Typography>
				<Typography variant="h5" gutterBottom>
					1. Ekspertise i valg
				</Typography>

				<Typography variant="body1" gutterBottom>
					Profesjonelle elektrikere er erfarne i å forstå forskjellige belysningsbehov. De kan hjelpe deg med
					å velge lamper, downlights og til og med smartbelysning som passer best til rommet ditt, tar hensyn
					til lysstyrke, fargetemperatur og stil.
				</Typography>

				<Typography variant="h5" gutterBottom>
					2. Optimal plassering
				</Typography>
				<Typography variant="body1" gutterBottom>
					Plasseringen av belysningselementer spiller en avgjørende rolle i å skape den ønskede atmosfæren. En
					elektriker kan analysere rommet og gi råd om hvor belysningen bør plasseres for å oppnå den beste
					balansen mellom funksjonalitet og estetikk.
				</Typography>
				<Typography variant="h5" gutterBottom>
					3. Elektrisk sikkerhet
				</Typography>
				<Typography variant="body1" gutterBottom>
					Elektriske installasjoner krever nøyaktighet for å unngå farlige situasjoner. En elektriker sørger
					for at alle lyskomponenter blir montert i samsvar med alle nødvendige sikkerhetsstandarder, noe som
					reduserer risikoen for elektriske problemer eller brann.
				</Typography>
				<Typography variant="h5" gutterBottom>
					4. Smartbelysning
				</Typography>
				<Typography variant="body1" gutterBottom>
					Med utviklingen av smartbelysning har du nå muligheten til å kontrollere belysningen fra
					smarttelefonen din. En profesjonell elektriker kan hjelpe deg med å velge og installere smarte
					lysløsninger som gir deg full kontroll over stemningen og energiforbruket i hjemmet ditt.
				</Typography>

				<Typography variant="h5" gutterBottom>
					5. Stemning i høstmørket
				</Typography>
				<Typography variant="body1" gutterBottom>
					Når høstmørket senker seg, kan riktig utebelysning skape en varm og innbydende atmosfære utendørs.
					En profesjonell elektriker kan hjelpe deg med å velge utendørsbelysning som fremhever arkitekturen,
					lyser opp stier og skaper en behagelig stemning.
				</Typography>

				<Typography variant="h5" gutterBottom>
					6. Energieffektivitet
				</Typography>
				<Typography variant="body1" gutterBottom>
					Profesjonelle elektrikere er oppdatert på de nyeste teknologiene og kan hjelpe deg med å velge
					energieffektive alternativer. Dette kan bidra til å redusere strømforbruket og spare penger på lang
					sikt.
				</Typography>

				<Typography variant="h5" gutterBottom>
					7. Estetisk sammenheng
				</Typography>
				<Typography variant="body1" gutterBottom>
					En profesjonell elektriker forstår viktigheten av å opprettholde estetisk sammenheng i hjemmet. De
					kan hjelpe deg med å velge belysning som komplementerer interiørdesignet og eksteriøret.
				</Typography>

				<Typography variant="h5" gutterBottom>
					8. Tids- og kostnadsbesparelse
				</Typography>
				<Typography variant="body1" gutterBottom>
					Å prøve å montere belysning selv kan føre til tidkrevende feilsøking og til og med ekstra utgifter
					hvis noe går galt. En profesjonell elektriker sikrer riktig montering fra starten av, noe som sparer
					deg tid og unødvendige kostnader.
				</Typography>

				<Typography variant="body1" gutterBottom sx={{ marginTop: '2rem' }}>
					Å involvere en profesjonell elektriker i valg og montering av lamper, downlights, smartbelysning og
					utendørsbelysning, gir deg trygghet og garanterer at belysningen i hjemmet ditt er både funksjonell
					og vakker. Med ekspertise innen valg av belysning, nøyaktig montering og sikkerhet, kan en
					elektriker hjelpe deg med å skape den ønskede atmosfæren og stemningen i alle områder av hjemmet
					ditt.
				</Typography>

				<Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{
							borderRadius: 16,
							letterSpacing: '1px',
							boxShadow: '0 2px 4px rgba(0,0,0,.26)',
						}}
						component={Link}
						to={`/quote/${ServicesEnum.Lighting}`}
					>
						Få pristilbud
					</Button>
					<Button
						variant="outlined"
						color="primary"
						component={Link}
						to={`/quote/${ServicesEnum.FreeInspection}`}
					>
						Gratis befaring
					</Button>
				</Stack>
			</Article>
		);
	}
}
