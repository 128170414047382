import { makeAutoObservable, runInAction } from 'mobx';
import Files from '../api/endpoints/Files';
import { FetchWrapperResponse } from '../fetch/FetchWrapper';
import { JsonMap } from '../types';
import { RootStore } from './RootStore';

export class FileStore {
	rootStore: RootStore;
	files: File[] = [];
	isLoading = true;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;

		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			//this.loadFiles();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			runInAction(() => {
				this.files = [];
			});
		});
	}

	// Fetches all Files from the server.
	loadFiles() {
		this.isLoading = true;
		Files.fetchFiles().then((response: FetchWrapperResponse) => {
			runInAction(() => {
				response.data.files.forEach((json: JsonMap) => this.updateFileFromServer(json));
				this.isLoading = false;
			});
		});
	}

	// Update a File with information from the server. Guarantees a File only
	// exists once. Might either construct a new File, update an existing one,
	// or remove a File if it has been deleted on the server.
	updateFileFromServer(json: JsonMap) {
		let file = this.files.find((file) => '' + file.id === '' + json.id);

		if (!file) {
			file = new File(this, json.id as string);
			this.files.push(file);
		}

		if (json.isDeleted) {
			this.removeFile(file);
		} else {
			file.updateFromJson(json);
		}
	}

	// Creates a fresh File on the client and the server.
	createFile() {
		const file = new File(this);
		this.files.push(file);
		return file;
	}

	// A File was somehow deleted, clean it from the client memory.
	removeFile(file: File) {
		this.files.splice(this.files.indexOf(file), 1);
		// file.dispose();
	}
}

// Domain object File.
export class File {
	id;
	userId: string = '';
	name: string = '';
	created: Date = new Date();
	mimetype: string = '';
	path: string = '';
	preview?: string;
	size: number = 0;
	store;
	autoSave = true; // Indicator for submitting changes in this File to the server.
	// saveHandler; // Disposer of the side effect auto-saving this File (dispose).

	constructor(store: FileStore, id?: string) {
		makeAutoObservable(this, {
			id: false,
			store: false,
			autoSave: false,
			// saveHandler: false,
			// dispose: false,
		});

		this.store = store;
		this.id = '' + id;

		// this.saveHandler = reaction(
		// 	() => this.asJson, // Observe everything that is used in the JSON.
		// 	(json) => {
		// 		// If autoSave is true, send JSON to the server.
		// 		if (this.autoSave) {
		// 			Files.saveFile(json);
		// 		}
		// 	}
		// );
	}

	// // Remove this File from the client and the server.
	// delete() {
	// 	Files.deleteFile(this.id);
	// 	this.store.removeFile(this);
	// }

	get asJson() {
		return {
			id: this.id,
			userId: this.userId,
			name: this.name,
			preview: this.preview,
			created: this.created,
			mimetype: this.mimetype,
			size: this.size,
			path: this.path,
		};
	}

	// Update this File with information from the server.
	updateFromJson(json: JsonMap) {
		this.autoSave = false; // Prevent sending of our changes back to the server.
		this.userId = '' + json.userId;
		this.name = json.name as string;
		this.created = json.created as any;
		this.mimetype = json.mimetype as string;
		this.path = json.path as string;
		this.preview = json.preview as string;
		this.size = json.size as number;
		this.autoSave = true;
	}

	// // Clean up the observer.
	// dispose() {
	// 	this.saveHandler();
	// }
}
