/* eslint-disable no-unused-vars */
import { Alert, Button } from '@mui/material';
import React from 'react';
import LogUtil from '../helpers/LogUtil';
import { Link } from 'react-router-dom';

type Props = {
	children: React.ReactNode | React.ReactNode[] | undefined;
	disableLink?: boolean;
};

class ErrorBoundary extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		LogUtil.error(error, errorInfo);
	}

	render() {
		if ((this.state as any).hasError) {
			// You can render any custom fallback UI
			return (
				<Alert severity="error">
					Noe gikk galt <br />
					{!this.props.disableLink && (
						<Button component={Link} to="/home" color="inherit">
							Gå til startsiden
						</Button>
					)}
				</Alert>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
