import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	StyledComponentProps,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HookTypes, withLocation } from '../../utils/withHooks';
import { matchPath } from 'react-router-dom';
import { behindLoginWallPaths } from '../top-app-bar/TopAppBar';

const PREFIX = 'GdprDialog';

const classes = {
	dialogContentTextRoot: `${PREFIX}-dialogContentTextRoot`,
};

const StyledDialog = styled(Dialog)({
	[`& .${classes.dialogContentTextRoot}`]: {
		'& :first-of-type': {
			marginTop: 0,
		},
	},
});

type Props = StyledComponentProps &
	HookTypes & {
		isOpen?: boolean;
	};

const GdprDialog = observer(
	class GdprDialog extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get location() {
			return this.props.location!;
		}

		blockedPaths = ['/elektriker'];

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				rootStore: computed,
				userStore: computed,
				uiState: computed,
				isOpen: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get isOpen() {
			const { isLoggedIn, hasGivenConsent, gdprStatus, gdprStatusIsLoading } = this.userStore;

			const allowedPath = Boolean(
				behindLoginWallPaths.some((path: string) => matchPath(path, this.location.pathname))
			);

			if (!allowedPath || gdprStatusIsLoading || !gdprStatus?.termsOfUse) {
				return false;
			}

			return Boolean((isLoggedIn && !hasGivenConsent) || (this.props.isOpen && !hasGivenConsent));
		}

		render() {
			const { gdprStatus, acceptTermsOfUse } = this.userStore;
			const host = window.location.host;
			return (
				<StyledDialog open={this.isOpen} fullWidth>
					<DialogContent dividers>
						<Typography variant="h6" paragraph>
							Bruk av {host}
						</Typography>
						<Typography variant="body2" paragraph>
							Følg Kardemommeloven når du bruker denne tjenesten så skal vi gjøre det vi kan for å sørge
							for at alle andre gjør det også :)
						</Typography>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography variant="body2">Personvernerklæring og tjenestevilkår</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<DialogContentText
									sx={{ fontSize: '0.7rem' }}
									classes={{ root: classes.dialogContentTextRoot }}
									dangerouslySetInnerHTML={{ __html: gdprStatus?.termsOfUse }}
								/>
							</AccordionDetails>
						</Accordion>
					</DialogContent>
					<DialogActions>
						{/* <Button>Slett brukeren min</Button> */}
						<Button variant="contained" color="primary" onClick={acceptTermsOfUse}>
							Aksepter
						</Button>
					</DialogActions>
				</StyledDialog>
			);
		}
	}
);

export default withLocation(GdprDialog);
