import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

import { Project } from '../../stores/Project';
import { ProjectDTO, ProjectStatusDTO } from '../../dto/project.types';
import config from '../../config/config';
import { Address } from '../../types';
import { AddressDTO } from '../../dto/address.types';
import { FileEntryDTO } from '../../dto/file.types';
import { ChecklistDTO } from '../../stores/checklist/Checklist';
import { ProfileDTO } from '../../dto/profile.types';
import { GranteeAccess, ProjectAccessOverview } from '../../dto/sharing.types';

/**
 * Projects api methods
 */
export default class ProjectApi {
	static async getChecklists(projectId: number): Promise<FetchWrapperTypedResponse<ChecklistDTO[]>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/checklists`;
		return FetchWrapper.typedGet<ChecklistDTO[]>(url, {
			requireAuth: true,
		});
	}

	static async updateProjectFileMetadata(
		projectId: number,
		fileId: number,
		metadata: Pick<
			FileEntryDTO,
			'contactAccess' | 'customerAccess' | 'internalAccess' | 'partnerAccess' | 'folderPath'
		>
	): Promise<FetchWrapperTypedResponse<FileEntryDTO>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/file/${fileId}/metadata`;
		return FetchWrapper.typedPost<FileEntryDTO>(url, {
			body: JSON.stringify({ metadata }),
			requireAuth: true,
		});
	}

	static async inviteCustomer(projectId: number, message: string): Promise<FetchWrapperTypedResponse<ProjectDTO>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/invite-customer`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ message }),
			requireAuth: true,
		});
	}

	static async inviteContactPerson(
		projectId: number,
		message: string
	): Promise<FetchWrapperTypedResponse<ProjectDTO>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/invite-contact-person`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ message }),
			requireAuth: true,
		});
	}

	static async rejectProject(projectId: number, reason: string): Promise<FetchWrapperTypedResponse<ProjectDTO>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/reject`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ reason }),
			requireAuth: true,
		});
	}

	static async saveAddress(address: AddressDTO): Promise<FetchWrapperTypedResponse<AddressDTO>> {
		const url = `//${config.hosts.api}/v1/project/address`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ address }),
			requireAuth: true,
		});
	}

	static async AddressSearch(address: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/address-lookup?sok=${address}`;
		return FetchWrapper.get(url);
	}

	/**
	 *
	 * @param {ProjectDTO} project
	 * @param {string} source
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async craftsmanCreateProject(
		project: ProjectDTO,
		notifyCustomer: boolean = true,
		notifyContactPerson: boolean = true
	): Promise<FetchWrapperTypedResponse<ProjectDTO>> {
		const url = `//${config.hosts.api}/v1/project/create-v2`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ project, notifyContactPerson, notifyCustomer }),
			requireAuth: true,
		});
	}

	/**
	 *
	 * @param project
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async updateAddress(
		projectId: number,
		address: Address
	): Promise<FetchWrapperTypedResponse<ProjectDTO | undefined>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/address`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ address }),
			requireAuth: true,
		});
	}

	/**
	 *
	 * @param project
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async createKobbrOffer(projectId: number): Promise<FetchWrapperTypedResponse<any>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/offer/create`;
		return FetchWrapper.put(url, {
			requireAuth: true,
		});
	}

	/**
	 *
	 * @param project
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async sendCustomSMS(
		projectId: number,
		message: string,
		phoneNumber: string,
		customerUserId?: string
	): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/sms/send`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				projectId,
				message,
				phoneNumber,
				customerUserId,
			}),
			requireAuth: true,
		});
	}

	/**
	 *
	 * @param projectID
	 * @param pinned
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async pinProject(projectId: number, pinned: boolean): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/pin`;
		return FetchWrapper.put(url, {
			body: JSON.stringify({
				pinned,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @param {any} project {name: string, status: <'REQUESTED', 'PLANNED', 'HOLD', 'ACTIVE', 'COMPLETED', 'ABORTED', 'UNKNOWN'>, address: string, postalCode: string}
	 * @returns {Promise<Project[]>} chats
	 */
	static async createProject(project: Project, source?: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/create`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ project, source }),
		});
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async getProjects(statusFilter: ProjectStatusDTO[] = []): Promise<FetchWrapperTypedResponse<ProjectDTO[]>> {
		const url = `//${config.hosts.api}/v1/project`;

		return FetchWrapper.typedPost<ProjectDTO[]>(url, {
			body: JSON.stringify({ statusFilter }),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async getProjectsArchive(offset: number = 0): Promise<FetchWrapperTypedResponse<ProjectDTO[]>> {
		const url = `//${config.hosts.api}/v1/project/archive/${offset}`;

		return FetchWrapper.typedGet<ProjectDTO[]>(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async getProject(projectId: number): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async grabProject(projectId: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/requested/${projectId}/grab`;

		return FetchWrapper.post(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} chats
	 */
	static async archiveProject(projectId: number): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/archive`;

		return FetchWrapper.post(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} updated project
	 */
	static async updateProject(project: ProjectDTO): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${project.id}`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({ project: project }),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} project
	 */
	static async updateProjectMembers(projectId: number, members: any[]): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/members`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ members }),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} project
	 */
	static async addProjectMemberByPhoneNumber(
		projectId: number,
		phoneNumber: string,
		countryCode: string,
		role: string
	): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/members/add/phone`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ projectId, phoneNumber, countryCode, role }),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} project
	 */
	static async addProjectMemberByUserId(
		projectId: number,
		userId: string,
		role: string
	): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/members/add/userId`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ projectId, userId, role }),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} projects
	 */
	static async getRequestedProjects(): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/requested`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @param {String} projectId
	 * @returns {Promise<Files[]>} Files
	 */
	static async addFile(projectId: number, fileId: string, comment?: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/add/file`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				fileId: fileId,
				comment: comment,
			}),
			requireAuth: true,
		});
	}

	static async removeFile(projectId: number, fileId: number): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/remove/file`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				fileId: fileId,
			}),
			requireAuth: true,
		});
	}

	static async renameFile(projectId: number, fileId: number, name: string): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/rename/file`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				fileId: fileId,
				name: name,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @param {String} projectId
	 * @returns {Promise<Files[]>} Files
	 */
	static async getFiles(projectId: number): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/file/project/${projectId}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	// Shared access related methods

	/**
	 * @param {String} projectId
	 * @returns {Promise<ProjectAccessOverview>} SharedAccess
	 */
	static async getSharedAccess(projectId: number): Promise<FetchWrapperTypedResponse<ProjectAccessOverview>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/shared`;

		return FetchWrapper.typedGet<ProjectAccessOverview>(url, {
			requireAuth: true,
		});
	}

	/**
	 * @param {GranteeAccess} access
	 * @returns {Promise<GranteeAccess>} GranteeAccess
	 */
	static async addSharedAccess(
		projectId: number,
		access: GranteeAccess
	): Promise<FetchWrapperTypedResponse<GranteeAccess>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/shared/add`;

		return FetchWrapper.typedPost<GranteeAccess>(url, {
			body: JSON.stringify({ access }),
			requireAuth: true,
		});
	}

	/**
	 * @param {GranteeAccess} access
	 * @returns {Promise<GranteeAccess>} GranteeAccess
	 */
	static async updateSharedAccess(
		projectId: number,
		access: GranteeAccess
	): Promise<FetchWrapperTypedResponse<GranteeAccess>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/shared/update`;

		return FetchWrapper.typedPost<GranteeAccess>(url, {
			body: JSON.stringify({ access }),
			requireAuth: true,
		});
	}

	/**
	 * @param {GranteeAccess} access
	 * @returns {Promise<GranteeAccess>} SharedAccess
	 */
	static async removeSharedAccess(
		projectId: number,
		access: GranteeAccess
	): Promise<FetchWrapperTypedResponse<GranteeAccess>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/shared/remove`;

		return FetchWrapper.typedDelete<GranteeAccess>(url, {
			body: JSON.stringify({ access }),
			requireAuth: true,
		});
	}

	/**
	 * @param {string} query
	 * @returns {Promise<ProfileDTO[]>} Matching profiles
	 */
	static async searchSharedAccess(
		projectId: number,
		query: string
	): Promise<FetchWrapperTypedResponse<ProfileDTO[]>> {
		const url = `//${config.hosts.api}/v1/project/${projectId}/shared/search`;

		return FetchWrapper.typedPost<ProfileDTO[]>(url, {
			body: JSON.stringify({ query }),
			requireAuth: true,
		});
	}
}
