import { Grid, StyledComponentProps, Typography } from '@mui/material';
import { computed, makeObservable } from 'mobx';

import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';

const PREFIX = 'Step';

const classes = {
	root: `${PREFIX}-root`,
	image: `${PREFIX}-image`,
};

const StyledGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
	[`& .${classes.root}`]: {
		[breakpoints.up('sm')]: {
			'&:nth-of-type(odd)': {
				flexDirection: 'row-reverse',
			},
		},
	},

	[`& .${classes.image}`]: {
		width: '100%',
		maxHeight: '330px',
	},
}));

type Props = StyledComponentProps & {
	imageURL: string;
	number: number;
	title: React.ReactNode;
	description: React.ReactNode;
};

const Step = observer(
	class Step extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				uiState: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		render() {
			const { imageURL, number, title, description } = this.props;
			const { isMobile, isComputer } = this.uiState;

			return (
				<StyledGrid
					className={`Step ${classes.root}`}
					container
					item
					alignItems="center"
					justifyContent={isMobile ? 'center' : 'space-evenly'}
					spacing={2}
				>
					<Grid item xs={10} sm={5} md={4}>
						<img alt="" className={classes?.image} src={imageURL} />
					</Grid>

					<Grid item xs={10} sm={5} md={4}>
						<Typography align="center" gutterBottom variant={isComputer ? 'h5' : 'h6'}>
							{number}. {title}
						</Typography>
						<Typography align="center" variant={isComputer ? 'h6' : 'subtitle1'}>
							{description}
						</Typography>
					</Grid>
				</StyledGrid>
			);
		}
	}
);

export default Step;
