import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { observer } from 'mobx-react';
import React from 'react';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import './ChatMessageText.scss';
import ErrorBoundary from '../../../utils/ErrorBoundary';
import 'katex/dist/katex.min.css';

type Props = {
	text?: string;
};

const ChatMessageText = observer(
	class ChatMessageText extends React.Component<Props> {
		get rawText() {
			return this.props.text ?? '';
		}

		get text() {
			let text = this.userMentionReplacer(this.rawText);
			text = this.TeXpatch(text);
			return this.linkReplacer(text);
		}

		userMentionReplacer = (text: string) => {
			const regex = /@\[(.*?)\]\((user):(.*?)\)/gi;
			return text.replace(regex, `[@$1](/profile/$3)`);
		};

		TeXpatch = (text: string) => {
			// replace \text{Arbeidskostnader} with ```Arbeidskostnader```
			const regex = /\\text{(.*?)}/gi;
			// replace \times with &times;
			text = text.replace(/\\times/g, '&times; ');
			return text.replace(regex, '`$1`');
		};

		linkReplacer = (text: string) => {
			try {
				const urlRegex = /(?:(\()?)(https?:\/\/[^\s]+)/g;
				return text.replace(urlRegex, (match) => {
					if (match.indexOf('(') != 0) {
						return `[${match}](${match})`;
					}
					return match;
				});
			} catch (err) {
				console.error(err);
				return text;
			}
		};

		linkTargetProcessor = (href: string) => {
			if (href.indexOf('react:') === 0) {
				return '';
			}

			return '_blank';
		};

		render() {
			if (!this.rawText) {
				return null;
			}

			return (
				<ErrorBoundary>
					<ReactMarkdown
						data-hj-suppress
						skipHtml
						rehypePlugins={[rehypeKatex]}
						remarkPlugins={[remarkGfm, remarkMath]}
						components={{ h1: 'h3', h2: 'h3' }}
						className="chat-message__text noselect data-hj-suppress"
					>
						{this.text}
					</ReactMarkdown>
				</ErrorBoundary>
			);
		}
	}
);

export default ChatMessageText;
