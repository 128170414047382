import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import config from '../../config/config';
import { AgentDTO } from '../../components/dashboard2/assistant/agent.types';

/**
 * API for handling operations related to Agents.
 */
export default class AgentsApi {
	/**
	 * Gets all assistants.
	 * @returns {Promise<FetchWrapperTypedResponse<Assistant[]>>} The response from the API.
	 */
	static async getAgents(): Promise<FetchWrapperTypedResponse<AgentDTO[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/sidekick/list/my`;
		return FetchWrapper.get(url, { requireAuth: true });
	}

	/**
	 * Creates a new agent.
	 * @param {AgentDTO} agentData - The data for the new agent.
	 * @returns {Promise<FetchWrapperTypedResponse<Assistant | undefined>>} The response from the API.
	 */
	static async createAgent(agentData: AgentDTO): Promise<FetchWrapperTypedResponse<AgentDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/sidekick/agent`;
		return FetchWrapper.post(url, { requireAuth: true, body: JSON.stringify(agentData) });
	}

	/**
	 * Gets an agent by its ID.
	 * @param {string} agentId - The ID of the agent.
	 * @returns {Promise<FetchWrapperTypedResponse<Assistant>>} The response from the API.
	 */
	static async getAgent(agentId: number): Promise<FetchWrapperTypedResponse<AgentDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/sidekick/agent/${agentId}`;
		return FetchWrapper.get(url, { requireAuth: true });
	}

	/**
	 * Updates an agent.
	 * @param {string} agentId - The ID of the agent.
	 * @param {AssistantDTO} agentData - The updated data for the agent.
	 * @returns {Promise<FetchWrapperTypedResponse<AssistantDTO>>} The response from the API.
	 */
	static async updateAgent(
		agentId: number,
		agentData: AgentDTO
	): Promise<FetchWrapperTypedResponse<AgentDTO | undefined>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/sidekick/agent/${agentId}`;
		return FetchWrapper.put(url, { requireAuth: true, body: JSON.stringify(agentData) });
	}

	/**
	 * Deletes an agent.
	 * @param {string} agentId - The ID of the agent to delete.
	 * @returns {Promise<FetchWrapperTypedResponse<undefined>>} The response from the API.
	 */
	static async deleteAgent(agentId: string): Promise<FetchWrapperTypedResponse<undefined>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/sidekick/agent/${agentId}`;
		return FetchWrapper.delete(url, { requireAuth: true });
	}
}
