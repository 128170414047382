import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';
import { Address, MessageContent } from '../../types';

/**
 * Profile api
 */
export default class CustomerServiceApi {
	static async createRequest(
		sessionId: string,
		options: { name?: string; topic?: string; description?: string } = {}
	): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/customer-service/request/${sessionId}`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				sessionId,
				name: options.name,
				topic: options.topic,
				description: options.description,
			}),
		});
	}

	static async sendMessage(
		sessionId: string,
		message: string,
		options: { channelId?: string; name?: string; topic?: string; description?: string; content?: MessageContent[] }
	): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/customer-service/request/${sessionId}/message`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				sessionId,
				message,
				...options,
			}),
		});
	}

	static async getContact(): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/customer-service/contact`;
		return FetchWrapper.get(url);
	}

	static async tryCreateJob(
		sessionId: string,
		address: Address,
		description: string,
		companyId?: string
	): Promise<FetchWrapperResponse> {
		const url = `//${config.hosts.api}/v1/customer-service/request/${sessionId}/job`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				address,
				companyId,
				description,
			}),
		});
	}
}
