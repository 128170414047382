import { responsiveFontSizes, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import config from '../config/config';
import { AppId } from '../config/appId.enum';
import StoreContext from '../stores/StoreContext';
import baseTheme from './base/base.theme';
import defaultTheme from './default/default.theme';
import minelektrikerTheme from './minelektriker/minelektriker.theme';
import minrorleggerTheme from './minrorlegger/minrorlegger.theme';
import smartTheme from './smart/smart.theme';

type Props = {
	children: React.ReactNode;
};

const ThemeProvider = observer((props: Props) => {
	const rootStore = useContext(StoreContext);
	const { isDarkMode } = rootStore.uiState;
	const themeOptions = {
		isDarkMode,
	};
	const base = baseTheme({});

	// @todo: remove  dark mode  toggle - it duplicates css  and causes page to be unresponsive

	let theme: Theme;
	switch (config.appId) {
		case AppId.Befare:
			theme = defaultTheme(base, themeOptions);
			break;
		case AppId.MinElektriker:
			theme = minelektrikerTheme(base, themeOptions);
			break;
		case AppId.MinRorlegger:
			theme = minrorleggerTheme(base, themeOptions);
			break;
		case AppId.Smart:
			theme = smartTheme(base, themeOptions);
			break;
		default:
			theme = defaultTheme(base, themeOptions);
	}

	return <MuiThemeProvider theme={responsiveFontSizes(theme)}>{props.children}</MuiThemeProvider>;
});

export default ThemeProvider;
