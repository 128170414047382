import { TextField, TextFieldProps } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

type Props = TextFieldProps;

const VerificationCodeTextField = observer(
	class VerificationCodeTextField extends React.Component<Props> {
		render() {
			const { inputProps, ...rest } = this.props;

			return (
				<TextField
					type="text"
					autoComplete="one-time-code"
					inputMode="numeric"
					inputProps={{ minLength: 6, maxLength: 6, pattern: '[0-9]{6}', ...inputProps }}
					label="Bekreftelseskode"
					name="verificationCode"
					variant="filled"
					{...rest}
				/>
			);
		}
	}
);

export default VerificationCodeTextField;
