import { useTheme, Theme } from '@mui/material';
import { Map } from 'leaflet';
import React from 'react';
import { useMap } from 'react-leaflet';
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

const useQueryStringParser = () => {
	const locationobj = useLocation();
	try {
		let search = locationobj.search;
		if (search) {
			if (search.charAt(0) === '?') {
				search = search.replace('?', '');
			}
			const query: any = {};
			const parts = search.split('&');
			parts.forEach((part: string) => {
				const p = part.split('=');
				query[p[0]] = p[1] ? decodeURI(p[1]) : true;
			});

			return query;
		}
	} catch (e) {
		// do nothing
	}
	return {};
};

export function withHooks(Component: any) {
	return (props: any) => (
		<Component
			{...props}
			navigate={useNavigate()}
			location={useLocation()}
			params={useParams()}
			queryObj={useQueryStringParser()}
		/>
	);
}

export function withNavigation(Component: any) {
	return (props: any) => <Component {...props} navigate={useNavigate()} />;
}

export function withLocation(Component: any) {
	return (props: any) => <Component {...props} location={useLocation()} />;
}

export function withTheme(Component: any) {
	return (props: any) => <Component {...props} theme={useTheme()} />;
}

export function withParams(Component: any) {
	return (props: any) => <Component {...props} params={useParams()} />;
}

export function withLeafletMap(Component: any) {
	return (props: any) => <Component {...props} map={useMap()} />;
}

export function withQueryString(Component: any) {
	return (props: any) => <Component {...props} queryObj={useQueryStringParser()} />;
}

export type HookTypes = {
	location?: Location;
	navigate?: NavigateFunction;
	params?: Params<string>;
	leafletMap?: Map;
	theme?: Theme;
	queryObj?: any;
};
