import { makeAutoObservable } from 'mobx';
import { Emoji, SettingDto, UserSettingKey, UserSettingsCollection } from '../types';
import {
	DEFAULT_USER_SETTINGS,
	DEFAULT_USER_EMOJI,
	DEFAULT_WORKSPACE_EMOJI,
	DEFAULT_APPLICATION_EMOJI,
} from '../constants/DefaultSettings';
import { RootStore } from './RootStore';
import ProfileApi from '../api/endpoints/Profile';
import LogUtil from '../helpers/LogUtil';

/**
 * SettingsStore
 */
export class SettingsStore {
	rootStore: RootStore;

	userSettings: UserSettingsCollection = { ...DEFAULT_USER_SETTINGS };

	userEmoji: Emoji[] = [...DEFAULT_USER_EMOJI];
	workspaceEmoji: Emoji[] = [...DEFAULT_WORKSPACE_EMOJI];
	applicationEmoji: Emoji[] = [...DEFAULT_APPLICATION_EMOJI];

	settingsUpdated: any = {
		userSettings: false,
		workspaceSettings: false,
		applicationSettings: false,
	};

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.syncSettings();
		});
	}

	async setSetting(setting: SettingDto) {
		return this.setSettings([setting]);
	}

	async setSettings(settings: SettingDto[]) {
		const upsertResponse = await ProfileApi.upsertSettings({ settings });

		if (upsertResponse.statusCode === 200) {
			this.assignSettingsFromDto(upsertResponse.data.settings, true);
		} else {
			LogUtil.error('Noe galt skjedde under lagring av innstillinger. Prøv igjen senere.');
		}
	}

	getSetting(key: UserSettingKey): SettingDto {
		return this.userSettings[key];
	}

	syncSettings() {
		this.fetchSettings();
	}

	fetchSettings() {
		this.fetchUserSettings();
		this.fetchWorkspaceSettings();
	}

	async fetchUserSettings() {
		const userSettingsResponse = await ProfileApi.loadSettings();

		if (userSettingsResponse.statusCode === 200) {
			this.assignSettingsFromDto(userSettingsResponse.data.settings, true);
		} else {
			console.error('Failed getting user settings');
		}
	}

	resetSettings() {
		this.userSettings = Object.assign({}, DEFAULT_USER_SETTINGS);
	}

	assignSettingsFromDto(settings: SettingDto[], resetSettings: boolean = false) {
		if (resetSettings) {
			this.resetSettings();
		}

		settings.forEach((setting) => {
			this.userSettings[setting.key] = setting;
		});
	}

	fetchWorkspaceSettings() {}

	/* UTILS */
	get allEmojis(): Emoji[] {
		return [...this.userEmoji, ...this.workspaceEmoji, ...this.applicationEmoji];
	}

	applyEmojiSettingsToText(text: string | null | undefined) {
		// skip parsing if we don't need to do anything
		if (!text || text.indexOf(':') < 0) {
			return text;
		}
		let enhanced = text;
		this.allEmojis
			.filter((e: Emoji) => e.utf8Emoji)
			.forEach((emoji: Emoji) => {
				if (emoji.utf8Emoji) {
					enhanced = enhanced.replaceAll(`:${emoji.name}:`, emoji.utf8Emoji);
				}
			});

		return enhanced;
	}
}
