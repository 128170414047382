import { AppId } from '../../config/appId.enum';
import React from 'react';
import config from '../../config/config';

const GenericHome = React.lazy(() => import('./generic/GenericHome'));
const HomeSmart = React.lazy(() => import('./smart/HomeSmart'));

type Props = {};

class Home extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		switch (config.appId) {
			case AppId.Smart:
				return <HomeSmart />;
			default:
				return <GenericHome />;
		}
	}
}

export default Home;
