import React from 'react';
import Article from './Article';
import { Button, Stack, Typography } from '@mui/material';
import BefareIllustration from '../common/illustrations/BefareIllustration';
import { ServicesEnum } from '../home/generic/GenericServices';
import { Link } from 'react-router-dom';

export default class ElectricOutletArticle extends React.Component {
	render() {
		return (
			<Article>
				<Typography variant="h3" gutterBottom>
					Stikkontakter med stil
				</Typography>
				<Typography variant="body1" gutterBottom>
					Å legge til nye strømuttak i hjemmet kan virke som enkelt gjort med forgreinere, men det kan utgjøre
					en alvorlig brannfare. Med profesjonelle elektrikere kan du få veggmonterte stikkontakter som ikke
					bare gir økt bekvemmelighet, men også trygghet for deg og dine kjære.
				</Typography>
				<BefareIllustration illustration="inspection2" sx={{ margin: '1rem auto', maxWidth: '300px' }} />
				<Typography variant="body1" gutterBottom>
					Når du får en profesjonell elektriker til å installere en veggmontert stikkontakt, eliminerer du
					potensielle brannfarer forbundet med bruk av forgreinere. Her er noen grunner til hvorfor det er
					avgjørende å søke profesjonell hjelp:
				</Typography>
				<Typography variant="h5" gutterBottom>
					1. Riktig installasjon
				</Typography>

				<Typography variant="body1" gutterBottom>
					En profesjonell elektriker vil utføre en korrekt og trygg installasjon, inkludert riktig kabling,
					jording og beskyttelse mot overbelastning.
				</Typography>

				<Typography variant="h5" gutterBottom>
					2. Sikkerhetsstandarder
				</Typography>
				<Typography variant="body1" gutterBottom>
					Profesjonelle elektrikere kjenner til de nyeste sikkerhetsstandardene og retningslinjene. De vil
					sørge for at alt arbeid utføres i henhold til disse standardene.
				</Typography>
				<Typography variant="h5" gutterBottom>
					3. Brannforebygging
				</Typography>
				<Typography variant="body1" gutterBottom>
					Med riktig installasjon av veggmonterte stikkontakter minimeres risikoen for brann, overoppheting og
					elektriske farer.
				</Typography>
				<Typography variant="h5" gutterBottom>
					4. Råd og veiledning
				</Typography>
				<Typography variant="body1" gutterBottom>
					Ikke bare installerer profesjonelle elektrikere stikkontakter, de hjelper deg også med å velge
					riktig produkt for dine behov. Med deres ekspertise kan du velge stikkontakter med ekstra funksjoner
					som USB-ladere eller innebygde brytere.
				</Typography>

				<Typography variant="body1" gutterBottom>
					Stikkontakter kommer i forskjellige farger og stiler for å passe inn i ditt hjem. Profesjonelle
					elektrikere kan hjelpe deg med å velge stikkontakter som kompletterer interiøret og gir en elegant
					touch.
				</Typography>

				<Typography variant="body1" gutterBottom sx={{ marginTop: '2rem' }}>
					Å installere nye stikkontakter handler om mer enn bare strøm – det handler om stil, funksjon og
					sikkerhet. Med profesjonelle elektrikere kan du være trygg på at installasjonen er utført på en
					trygg og pålitelig måte, samtidig som du får muligheten til å velge produkter som passer din
					personlige stil. Gjør ditt hjem enda mer funksjonelt og estetisk tiltalende med nye stikkontakter
					installert av eksperter. Valget er klart: stilfull sikkerhet med profesjonelle elektrikere!
				</Typography>

				<Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{
							borderRadius: 16,
							letterSpacing: '1px',
							boxShadow: '0 2px 4px rgba(0,0,0,.26)',
						}}
						component={Link}
						to={`/quote/${ServicesEnum.Lighting}`}
					>
						Få pristilbud
					</Button>
					<Button
						variant="outlined"
						color="primary"
						component={Link}
						to={`/quote/${ServicesEnum.FreeInspection}`}
					>
						Gratis befaring
					</Button>
				</Stack>
			</Article>
		);
	}
}
