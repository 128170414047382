import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import config from '../../config/config';
import { KobbrQuoteDTO } from '../../dto/kobbr.types';


// Assuming these response types for the magic links
type KobbrQuoteEditLinkResponse = { editLink: string };
type KobbrQuoteOpenLinkResponse = { openLink: string };

/**
 * API for handling operations related to KobbrQuotes.
 */
export default class KobbrQuotesApi {
	/**
	 * Gets all quotes.
	 * @returns {Promise<FetchWrapperTypedResponse<KobbrQuoteDTO[] | undefined>>} The response from the API.
	 */
	static async getQuotes(): Promise<FetchWrapperTypedResponse<KobbrQuoteDTO[] | undefined>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/kobbr/quotes`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * Gets a quote by its quoteId.
	 * @param quoteId The ID of the quote to retrieve.
	 * @returns {Promise<FetchWrapperTypedResponse<KobbrQuoteDTO | undefined>>} The response from the API.
	 */
	static async getQuote(quoteId: string): Promise<FetchWrapperTypedResponse<KobbrQuoteDTO | undefined>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/kobbr/quotes/${quoteId}`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * Gets a magic link for editing a specific quote.
	 * @param quoteId The ID of the quote to edit.
	 * @returns {Promise<FetchWrapperTypedResponse<KobbrQuoteEditLinkResponse | undefined>>} The response from the API.
	 */
	static async getEditQuoteMagicLink(quoteId: string): Promise<FetchWrapperTypedResponse<KobbrQuoteEditLinkResponse | undefined>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/kobbr/quotes/${quoteId}/editlink`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * Gets a magic link for opening a specific quote.
	 * @param quoteId The ID of the quote to open.
	 * @returns {Promise<FetchWrapperTypedResponse<KobbrQuoteOpenLinkResponse | undefined>>} The response from the API.
	 */
	static async getOpenQuoteMagicLink(quoteId: string): Promise<FetchWrapperTypedResponse<KobbrQuoteOpenLinkResponse | undefined>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/kobbr/quotes/${quoteId}/openlink`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}
}
