import { Button, Container, Grid, Link, StyledComponentProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import AuthManager from '../../auth/AuthManager';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { Workspace } from '../../types';
import { HookTypes, withHooks } from '../../utils/withHooks';
import VerificationCodeTextField from '../common/VerificationCodeTextField';
import Footer from '../footer/Footer';
import ResendVerificationCodeButton from './ResendVerificationCodeButton';

const PREFIX = 'VerificationCode';

const classes = {
	root: `${PREFIX}-root`,
	resendVerificationCodeButton: `${PREFIX}-resendVerificationCodeButton`,
	compact: `${PREFIX}-compact`,
};

const Root = styled('div')(({ theme: { mixins, breakpoints } }) => ({
	[`& .${classes.root}`]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '69vh',
		marginBottom: mixins.toolbar.minHeight,
		[breakpoints.up('lg')]: {
			minHeight: '83vh',
		},
	},

	[`& .${classes.compact}`]: {
		minHeight: 'auto',
	},

	[`& .${classes.resendVerificationCodeButton}`]: {
		marginTop: 8,
	},
}));

type Props = HookTypes &
	StyledComponentProps & {
		phoneNumber?: string;
		onSuccess?: () => void;
		isCompact?: boolean;
		actionLabel?: string;
	};

const VerificationCode = observer(
	class VerificationCode extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get params() {
			return this.props.params!;
		}

		get location() {
			return this.props.location!;
		}

		get history() {
			return this.props.navigate!;
		}

		verificationCode: string = '';
		errorMessage: string = '';
		ac: AbortController | undefined;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				verificationCode: observable,
				errorMessage: observable,
				profileStore: computed,
				userStore: computed,
				uiState: computed,
				actionLabel: computed,
				phoneNumber: computed,
				handleSubmit: action,
				handleChange: action,
				checkLoginStatus: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get phoneNumber() {
			return this.props?.phoneNumber ?? this.location.state?.phoneNumber;
		}

		get actionLabel() {
			return this.props?.actionLabel ?? 'Logg inn';
		}

		componentDidMount(): void {
			if (!this.ac) {
				this.initOTP();
			}
		}

		componentWillUnmount(): void {
			try {
				if (this.ac) this.ac.abort();
			} catch (error) {
				console.log(error);
			}
		}

		initOTP = async () => {
			try {
				if ('OTPCredential' in window) {
					// Cancel the WebOTP API if the form is submitted manually.
					this.ac = new AbortController();
					// Invoke the WebOTP API
					navigator.credentials
						.get({
							otp: { transport: ['sms'] },
							signal: this.ac.signal,
						} as any)
						.then((otp: any) => {
							runInAction(() => (this.verificationCode = otp.code));
							this.handleSubmit();
						})
						.catch((err) => {
							console.log(err);
						});
				}
			} catch (error) {
				console.log(error);
			}
		};

		handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
			event?.preventDefault();
			event?.stopPropagation();

			try {
				const response = await AuthManager.phoneAuthVerify(undefined, this.phoneNumber, this.verificationCode);

				if (response.statusCode !== 200) {
					runInAction(() => (this.errorMessage = 'Feil kode'));
				} else {
					await this.checkLoginStatus();
				}
			} catch (error) {
				this.errorMessage = 'Feil kode';
				console.error(error);
			}
		};

		checkLoginStatus = async () => {
			console.log('Moving on to checking login status');
			try {
				// Check login status. If userId has changed this fires triggers that loads everything needed
				const workspaces: Workspace[] = AsyncStorageHelper.getCache(`@BefWeb:login:workspaces`) ?? [];
				if (workspaces.length < 1) {
					console.info('Login status No workspaces found, setting to customer view');
					this.userStore.setWorkspace(null);
				}

				await this.userStore.checkLoginStatus();

				if (this.props.onSuccess) {
					this.props.onSuccess();
					return;
				}

				console.info('Verifying login status, onSuccess not set, using default flow with redirect', workspaces);

				if (workspaces.length) {
					console.log('redirect to workspace picker');
					// @TODO:ANALYTICS
					// dataLayer.push({'event': 'signin'});
					this.history('/sign-in/workspace');
				} else {
					this.history('/dashboard');
					console.log('redirect to dash');
				}
			} catch (error) {
				console.error(error);
			}
		};

		handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.errorMessage = '';
			this.verificationCode = event.target.value;
		};

		render() {
			const { isMobile, isTablet, isComputer } = this.uiState;
			const { isCompact } = this.props;

			if (!this.phoneNumber) {
				return null;
			}

			const classNames = [classes.root, 'VerificationCode'];
			if (isCompact) {
				classNames.push(classes.compact);
			}

			let headerVariant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h3';
			if (isMobile) {
				headerVariant = 'h5';
			} else if (isTablet) {
				headerVariant = 'h4';
			}

			if (isCompact) {
				headerVariant = 'h6';
			}

			return (
				<Root className={classes.root}>
					<Container className={classNames.join(' ')}>
						<Grid container direction="column" alignItems="center" justifyContent="center">
							{this.phoneNumber ? (
								<>
									<Grid item xs={12} lg={8}>
										<Typography align="center" gutterBottom variant={headerVariant}>
											Vi har sendt en bekreftelseskode til <br />
											<span className="text--bold text--italic text--red">
												+47{this.phoneNumber}
											</span>
										</Typography>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={3}>
										<form onSubmit={this.handleSubmit}>
											<VerificationCodeTextField
												autoFocus={isComputer}
												error={Boolean(this.errorMessage)}
												fullWidth
												helperText={this.errorMessage}
												margin="normal"
												onChange={this.handleChange}
												required
												value={this.verificationCode}
											/>
											<Button color="primary" fullWidth type="submit" variant="contained">
												{this.actionLabel}
											</Button>
											<ResendVerificationCodeButton
												className={classes?.resendVerificationCodeButton}
												phoneNumber={this.phoneNumber}
											/>
										</form>
									</Grid>
								</>
							) : (
								<Grid item xs={12} lg={8}>
									<Typography align="center" gutterBottom variant={headerVariant}>
										Det ser ut til at vi dessverre ikke har ditt mobilnummer registrert.
									</Typography>
									<Button
										component={Link}
										href="/sign-in"
										color="primary"
										fullWidth
										variant="contained"
									>
										Gå til innlogging
									</Button>
								</Grid>
							)}
						</Grid>
					</Container>
					{!isCompact && <Footer />}
				</Root>
			);
		}
	}
);

export default withHooks(VerificationCode);
