import 'leaflet/dist/leaflet.css';
import './MapComponent.scss';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import L, { LatLngTuple, Map, MarkerOptions, latLngBounds } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, Circle, useMap, LayersControl, LayerGroup } from 'react-leaflet';
import { action, computed, makeObservable, observable } from 'mobx';

import ErrorBoundary from '../../utils/ErrorBoundary';
import { HookTypes } from '../../utils/withHooks';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import HeatmapLayer, { FogOfWarLayerPoint, HeatmapLayerPoint } from './HeatmapLayer';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenDialog from '../common/FullScreenDialog';

// Helper function to create div icons based on state
const getDivIcon = (state: string) => {
	return L.divIcon({
		className: `icon-dot ${state}`, // Apply state-specific CSS class
		iconSize: [12, 12], // Size of the dot
		iconAnchor: [6, 6], // Center the icon
	});
};

// Export different icon instances for each lead state
export const NewLeadDivMarkerIcon = getDivIcon('new');
export const LeadDivMarkerIcon = getDivIcon('lead');
export const ResolvedLeadDivMarkerIcon = getDivIcon('resolved');
export const ConvertedLeadDivMarkerIcon = getDivIcon('converted');

const markerCommonProps: Partial<L.IconOptions> = {
	shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41],
};

export const BlueMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
	...markerCommonProps,
});

export const GoldMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
	...markerCommonProps,
});

export const RedMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
	...markerCommonProps,
});

export const GreenMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
	...markerCommonProps,
});

export const OrangeMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
	...markerCommonProps,
});

export const YellowMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
	...markerCommonProps,
});

export const VioletMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
	...markerCommonProps,
});

export const GreyMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
	...markerCommonProps,
});

export const BlackMarkerIcon = new L.Icon({
	iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
	...markerCommonProps,
});

export type MapPoint = {
	lat: number;
	lon: number;
	name?: string;
	description?: string | React.ReactNode;
	radius?: number;
	color?: string;
	stroke?: boolean;
	onClick?: () => void;
	markerOptions?: MarkerOptions;
};

type PointButtonProps = {
	point: MapPoint;
};

class PointButton extends React.Component<PointButtonProps> {
	onClick = () => {
		const { onClick } = this.props.point;
		if (onClick) {
			onClick();
		}
	};

	render() {
		return (
			<Button variant="contained" color="primary" onClick={this.onClick}>
				Les mer
			</Button>
		);
	}
}

type GetCoordinatesProps = {
	// eslint-disable-next-line no-unused-vars
	onClick?: (latlng: L.LatLng) => void;
};

const GetCoordinates = (props: GetCoordinatesProps) => {
	const map = useMap();
	let hasAdded = false;

	useEffect(() => {
		if (!map) return;
		if (hasAdded) return;
		hasAdded = true;

		const { onClick } = props;
		const info = L.DomUtil.create('div', 'legend');

		const positon = L.Control.extend({
			options: {
				position: 'bottomleft',
			},

			onAdd: function () {
				info.textContent = 'Posisjon';
				return info;
			},
		});
		const handleClick = (e: L.LeafletMouseEvent) => {
			info.textContent = '' + e.latlng;
			onClick?.(e.latlng);
		};

		map.on('click', handleClick);

		map.addControl(new positon());
	}, [map]);

	return null;
};

type Props = HookTypes & {
	height?: string;
	points?: MapPoint[];
	circles?: MapPoint[];
	darkMode?: boolean;
	heatmapPoints?: HeatmapLayerPoint[];
	fogOfWarPoints?: FogOfWarLayerPoint[];
	showHeatmap?: boolean;
	preventFitBoundsOnUpdate?: boolean;
	// eslint-disable-next-line no-unused-vars
	onClick?: (latlng: L.LatLng) => void;
	disableZoomControl?: boolean;
	disableLayersControl?: boolean;
	maxZoom?: number;
};

const MapComponent = observer(
	class MapComponent extends React.Component<Props> {
		mapRef = React.createRef<Map>();
		DEFAULT_ZOOM: number | undefined = 6;
		initiated: boolean = false;
		dialogOpen: boolean = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				dialogOpen: observable,
				mapCenter: computed,
				markerBounds: computed,
				isDarkMode: computed,
				points: computed,
				circles: computed,
				toggleDialog: action,
				fitBounds: action,
			});
		}

		get mapCenter() {
			if (this.markerBounds === null) {
				const defaultCenter: LatLngTuple = [63, 10];
				return defaultCenter;
			}

			const boundCenter = this.markerBounds.getCenter();
			const center: LatLngTuple = [boundCenter.lat, boundCenter.lng];

			return center;
		}

		get points() {
			return this.props.points?.filter((p) => p.lat && p.lon);
		}

		get circles() {
			return this.props.circles?.filter((p) => p.lat && p.lon);
		}

		get isDarkMode() {
			return Boolean(this.props.darkMode);
		}

		get markerBounds() {
			if (!this.points || this.points?.length < 1) {
				return null;
			}

			let markerBounds = latLngBounds([]);
			this.points?.forEach((marker) => {
				if (marker?.lat && marker?.lon) {
					markerBounds.extend([marker.lat, marker.lon]);
				}
			});

			// this.circles?.forEach((marker) => {
			// 	if (marker?.lat && marker?.lon) {
			// 		markerBounds.extend([marker.lat, marker.lon]);
			// 	}
			// });

			return markerBounds ?? null;
		}

		componentDidMount(): void {
			if (this.initiated) {
				return;
			}

			this.initiated = true;
			setTimeout(() => {
				this.fitBounds();
			}, 500);
		}

		componentDidUpdate(prevProps: Readonly<Props>): void {
			if (prevProps != this.props && !this.props.preventFitBoundsOnUpdate) {
				this.fitBounds();
			}
		}

		renderPoints() {
			const markers = this.points?.map((point: MapPoint, index: number) => {
				const position: LatLngTuple = [point.lat, point.lon];
				// limit description to 100 characters

				let descriptionElement = null;
				if (typeof point.description === 'string') {
					descriptionElement = (
						<Stack>
							{point.name && <Typography variant="subtitle2">{point.name}</Typography>}
							{point.description && (
								<Typography
									variant="body2"
									gutterBottom
									className="text-ellipsis"
									sx={{ fontSize: '0.8rem', whiteSpace: 'pre-wrap', overflow: 'hidden' }}
								>
									{point.description}
								</Typography>
							)}
							{point.onClick && <PointButton point={point} />}
						</Stack>
					);
				} else {
					descriptionElement = point.description;
				}

				const { icon, ...rest } = point.markerOptions ?? {};
				const customMarker = icon ?? BlueMarkerIcon;
				return (
					<Marker position={position} key={`marker-${index}`} icon={customMarker} {...rest}>
						<Popup>{descriptionElement}</Popup>
					</Marker>
				);
			});

			return <React.Fragment>{markers}</React.Fragment>;
		}

		renderPointsWithRadius() {
			return this.circles?.map((point: MapPoint, index: number) => {
				const color = point.color ?? '#e91e63';
				// limit description to 100 characters
				let descriptionElement = null;
				if (typeof point.description === 'string') {
					descriptionElement = (
						<Stack>
							{point.name && <Typography variant="subtitle2">{point.name}</Typography>}
							{point.description && (
								<Typography
									variant="body2"
									gutterBottom
									className="text-ellipsis"
									sx={{ fontSize: '0.8rem', whiteSpace: 'pre-wrap', overflow: 'hidden' }}
								>
									{point.description}
								</Typography>
							)}
							{point.onClick && <PointButton point={point} />}
						</Stack>
					);
				} else {
					descriptionElement = point.description;
				}
				return (
					<Circle
						key={`map-circle-${index}`}
						center={{
							lat: point.lat,
							lng: point.lon,
						}}
						radius={point.radius ?? 10000}
						color={color}
						stroke={!!point.stroke}
					>
						{' '}
						{point.name && <Popup>{descriptionElement}</Popup>}
					</Circle>
				);
			});
		}

		fitBounds = () => {
			if (this.markerBounds === null) {
				return null;
			}
			const leafletMap = this.mapRef?.current;

			if (leafletMap) {
				leafletMap.flyToBounds(this.markerBounds, { duration: 1.5, maxZoom: 15 });
			}
		};

		onClick = (latlng: L.LatLng) => {
			if (this.props.onClick) {
				this.props.onClick(latlng);
			}
		};

		toggleDialog = () => {
			this.dialogOpen = !this.dialogOpen;
		};

		renderHeatmap() {
			const { heatmapPoints, showHeatmap, fogOfWarPoints } = this.props;
			if (!heatmapPoints || !showHeatmap) {
				return null;
			}

			return <HeatmapLayer points={heatmapPoints} fogOfWarPoints={fogOfWarPoints} />;
		}

		renderMap() {
			let height = this.props.height ?? '400px';
			if (this.dialogOpen) {
				height = '100%';
			}
			const mobileHeight = this.dialogOpen ? '100%' : this.props.height ?? '250px';
			return (
				<Box
					className="MapComponent"
					sx={(theme) => ({
						height: height,
						[theme.breakpoints.down('sm')]: {
							height: mobileHeight,
						},
					})}
				>
					<ErrorBoundary>
						<MapContainer
							center={this.mapCenter}
							zoom={this.DEFAULT_ZOOM}
							scrollWheelZoom={false}
							className="map-container"
							bounceAtZoomLimits={true}
							maxBoundsViscosity={0.95}
							maxBounds={[
								[-180, -90],
								[180, 90],
							]}
							maxZoom={this.props.maxZoom ?? 17}
							ref={this.mapRef}
							zoomControl={this.props.disableZoomControl ? false : true}
						>
							<TileLayer
								className={this.isDarkMode ? 'map-tiles map-tiles--dark' : 'map-tiles'}
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							{this.props.disableLayersControl ? (
								<>
									{this.renderPoints()}
									{this.renderPointsWithRadius()}
									{this.renderHeatmap()}
								</>
							) : (
								<LayersControl position="topright">
									<LayersControl.Overlay name="Punkter" checked>
										<LayerGroup>{this.renderPoints()}</LayerGroup>
									</LayersControl.Overlay>
									<LayersControl.Overlay name="Sirkler" checked>
										<LayerGroup>{this.renderPointsWithRadius()}</LayerGroup>
									</LayersControl.Overlay>
									<LayersControl.Overlay name="Heatmap">{this.renderHeatmap()}</LayersControl.Overlay>
								</LayersControl>
							)}
							{this.props.onClick && <GetCoordinates onClick={this.onClick} />}
						</MapContainer>
					</ErrorBoundary>
					<IconButton
						onClick={this.toggleDialog}
						sx={{
							position: 'absolute',
							bottom: '1rem',
							left: '1rem',
							zIndex: 500,
						}}
					>
						<FullscreenIcon />
					</IconButton>
				</Box>
			);
		}

		render() {
			if (!this.dialogOpen) {
				return this.renderMap();
			}

			return (
				<FullScreenDialog open={this.dialogOpen} onClose={this.toggleDialog} title="Kart">
					{this.renderMap()}
				</FullScreenDialog>
			);
		}
	}
);

export default MapComponent;
