import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

export type CustomerSupportChatAdornmentProps = {
	service?: string;
};
export default class CustomerSupportChatAdornment extends React.Component<CustomerSupportChatAdornmentProps> {
	adornments: any = {
		enua: {
			title: 'Én smart lader, mange muligheter',
			description: 'Bruk den samme laderen hjemme, på hytten eller overalt - hvor du er.',
			image: '/illustrations/enua/header.jpeg',
			logo: '/illustrations/enua/logo-white.svg',
		},
		elkosmartDISABLED: {
			title: 'ELKO Smart Strømsparer',
			description: 'Denne løsningen sparer strøm, styrer varmen og gir inntil 10 000 kroner i Enovastøtte',
			image: '/illustrations/elkosmart/header.webp',
			logo: '/illustrations/elkosmart/elko-red.svg',
		},
	};

	render() {
		const { service } = this.props;
		if (!service) return null;

		const selectedAdornment = this.adornments[service.toLowerCase()];
		if (!selectedAdornment) return null;

		return (
			<Box
				sx={{
					width: '100%',
					backgroundColor: '#424242',
					backgroundImage: `linear-gradient(rgba(19,30,42,.5),rgba(19,30,42,.5)),url(${selectedAdornment.image})`,
					backgroundPosition: '50% 53%',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'auto,cover',
					height: 200,
					color: '#fafafa',
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'flex-start',
					padding: '3rem',
					position: 'relative',
				}}
			>
				<Stack direction="column" spacing={1}>
					<Typography variant="h4">{selectedAdornment.title}</Typography>
					<Typography variant="body2">{selectedAdornment.description}</Typography>
				</Stack>
				<img
					src={selectedAdornment.logo}
					alt={service}
					style={{
						position: 'absolute',
						top: 8,
						right: 8,
						width: 75,
						marginLeft: 'auto',
					}}
				/>
			</Box>
		);
	}
}
