import { Container, styled } from '@mui/material';
import React from 'react';
import Footer from '../footer/Footer';

const PREFIX = 'Article';

const classes = {
	root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
	[`& .${classes.root}`]: {
		marginBottom: '6rem',
		maxWidth: 800,
		marginTop: 40,
		[`& h3, & h4, & h5`]: {
			marginTop: 40,
		},
	},
});

type Props = {
	children: React.ReactNode[] | React.ReactNode;
};

export default class Article extends React.Component<Props> {
	render() {
		return (
			<Root>
				<Container className={`Article ${classes.root}`}>
					<article className="article">{this.props.children}</article>
				</Container>
				<Footer />
			</Root>
		);
	}
}
