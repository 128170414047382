/* eslint-disable no-unused-vars */
export type EventDTO = {
	id?: string;
	title: string;
	created: string;
	updated: string;
	deleted: string | undefined;
	duration: number;
	channelId: string;
	workspaceId: string;
	start: Date;
	inviterId: string;
	invitees: EventParticipantDTO[];
	videoChatSessionId?: string;
	description?: string;
	type?: MeetingType;
};

export type EventParticipantDTO = {
	comment?: string;
	created?: string;
	deleted?: string;
	eventId: string;
	id?: string;
	required: boolean;
	status: EventParticipantStatus;
	updated?: string;
	userId: string;
};

export enum EventParticipantStatus {
	Declined = 'DECLINED',
	Accepted = 'ACCEPTED',
	Pending = 'PENDING',
	Reschedule = 'RESCHEDULE',
}

export enum MeetingType {
	VideoInspection = 'VIDEO-INSPECTION',
	Inspection = 'INSPECTION',
	Worksite = 'WORKSITE',
	Event = 'EVENT',
	Meeting = 'MEETING',
}
