import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { Emoji } from '../../../types';
import Reaction from './Reaction';
import StoreContext from '../../../stores/StoreContext';
import { RootStore } from '../../../stores/RootStore';

type ReactionsDialogProps = {
	open: boolean;
	// eslint-disable-next-line no-unused-vars
	onSelect: (reaction?: any, emoji?: Emoji) => void;
	onClose: () => void;
};
const ReactionsDialog = observer(
	class ReactionsDialog extends React.Component<ReactionsDialogProps> {
		static readonly contextType = StoreContext;
		search: string = '';

		constructor(props: ReactionsDialogProps) {
			super(props);

			makeObservable(this, {
				open: computed,
				handleSearchChange: action,
				clearSearch: action,
				search: observable,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get settingsStore() {
			return this.rootStore.settingsStore;
		}

		get open() {
			return this.props.open;
		}

		get reactions() {
			if (!this.search) {
				return this.settingsStore.allEmojis;
			}

			return this.settingsStore.allEmojis.filter((emoji: Emoji) => emoji.name.includes(this.search));
		}

		handleClose = () => {
			this.props.onClose();
		};

		renderReactionsSelection() {
			return this.reactions.map((emoji: Emoji) => (
				<Reaction key={emoji.name} emoji={emoji} onClick={() => this.props.onSelect(emoji)} />
			));
		}

		handleSearchChange = (event: any) => {
			this.search = event.target.value;
		};

		clearSearch = () => {
			this.search = '';
		};

		render() {
			return (
				<Dialog
					fullWidth
					maxWidth="sm"
					onClose={this.handleClose}
					aria-labelledby="customized-dialog-title"
					open={this.open}
				>
					<DialogContent dividers>
						<FormControl variant="outlined" fullWidth>
							<InputLabel htmlFor="outlined-adornment-password">Søk</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								onChange={this.handleSearchChange}
								value={this.search}
								size="small"
								startAdornment={
									<InputAdornment position="start">
										<IconButton aria-label="Søk" edge="start">
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								endAdornment={
									this.search?.length > 0 && (
										<InputAdornment position="end">
											<IconButton aria-label="Tøm" edge="end" onClick={this.clearSearch}>
												<ClearIcon />
											</IconButton>
										</InputAdornment>
									)
								}
								label="Søk..."
							/>
						</FormControl>
						<Divider sx={{ margin: '0.5rem 0' }} />
						<div className="reactions">{this.renderReactionsSelection()}</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Avbryt
						</Button>
					</DialogActions>
				</Dialog>
			);
		}
	}
);

export default ReactionsDialog;
