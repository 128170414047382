import { makeAutoObservable } from 'mobx';
import PartnershipApi from '../../api/endpoints/PartnershipApi';
import { PartnershipRequestDTO, PartnershipRequestStatus } from './Partnership.types';

export default class PartnershipRequest {
	requestId!: number;
	requestorWorkspaceId!: number;
	targetWorkspaceId: number | null = null;
	targetOrganizationNumber: string | null = null;
	status!: PartnershipRequestStatus;
	createdAt!: Date;
	updatedAt!: Date;
	requestMessage: string | null = null;
	reviewedBy: number | null = null;
	reviewedOn: Date | null = null;
	tags: string[] = [];

	constructor(partnershipRequest: PartnershipRequestDTO) {
		makeAutoObservable(this);
		this.update(partnershipRequest);
	}

	async accept() {
		// Accept the request
		const result = await PartnershipApi.acceptRequest(this.requestId);
		if (result.statusCode === 200) {
			this.update(result.data);
			return true;
		}
		return false;
	}

	async decline() {
		// Decline the request
		const result = await PartnershipApi.declineRequest(this.requestId);
		if (result.statusCode === 200) {
			this.update(result.data);
			return true;
		}
		return false;
	}

	async cancel() {
		// Cancel the request
		const result = await PartnershipApi.cancelRequest(this.requestId);
		if (result.statusCode === 200) {
			this.update(result.data);
			return true;
		}
		return false;
	}

	update(partnershipRequest: PartnershipRequestDTO) {
		this.requestId = partnershipRequest.requestId!;
		this.requestorWorkspaceId = partnershipRequest.requestorWorkspaceId;
		this.targetWorkspaceId = partnershipRequest.targetWorkspaceId;
		this.targetOrganizationNumber = partnershipRequest.targetOrganizationNumber;
		this.status = partnershipRequest.status;
		this.createdAt = new Date(partnershipRequest.created);
		this.updatedAt = new Date(partnershipRequest.created);
		this.requestMessage = partnershipRequest.requestMessage;
		this.reviewedBy = partnershipRequest.reviewedBy;
		this.reviewedOn = partnershipRequest.reviewedOn ? new Date(partnershipRequest.reviewedOn) : null;
		this.tags = partnershipRequest.tags ?? [];
	}
}
