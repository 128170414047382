import React from 'react';
import { Grid, Icon, IconButton, Stack, styled, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import ElevatedCard from '../common/ElevatedCard';
import EvStationIcon from '@mui/icons-material/EvStation';
import TungstenIcon from '@mui/icons-material/Tungsten';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import './ProductBuilder.scss';
import { action, computed, makeObservable, observable } from 'mobx';

type Props = {
	//eslint-disable-next-line
	onChange: (chosenProducts: any) => void;
};

const charging = [
	createData('Easee lader', 0, 'ev_station_icon'),
	createData('Defa lader', 0, 'ev_station_icon'),
	createData('Har lader', 0, 'ev_station_icon'),
];
const lights = [
	createData('Downlight inne', 0, 'tungsten_icon'),
	createData('Downlight ute', 0, 'tungsten_icon'),
	createData('Utelampe', 0, 'tungsten_icon'),
	createData('Pendellampe', 0, 'tungsten_icon'),
	createData('Taklampe', 0, 'tungsten_icon'),
];
const electricity = [
	createData('Electricity 1', 0, 'electrical_services_icon'),
	createData('Electricity 2', 0, 'electrical_services_icon'),
	createData('Electricity 3', 0, 'electrical_services_icon'),
	createData('Electricity 4', 0, 'electrical_services_icon'),
	createData('Electricity 5', 0, 'electrical_services_icon'),
	createData('Electricity 6', 0, 'electrical_services_icon'),
];
const other = [
	createData('Other 1', 0, 'other_houses_icon'),
	createData('Other 2', 0, 'other_houses_icon'),
	createData('Other 3', 0, 'other_houses_icon'),
	createData('Other 4', 0, 'other_houses_icon'),
	createData('Other 5', 0, 'other_houses_icon'),
	createData('Other 6', 0, 'other_houses_icon'),
];

function createData(name: string, quantity: number, icon: string) {
	return {
		name,
		quantity,
		icon,
	};
}

const StyledMainIconButton = styled(IconButton)(({ theme: { palette } }) => ({
	color: palette.primary.main,
	transition: 'color .2s ease',
	['&:hover']: {
		color: palette.secondary.main,
	},
	['&.selected']: {
		color: palette.secondary.light,
	},
}));

const StyledSubCategoryIcon = styled(Icon)(({ theme: { palette } }) => ({
	color: palette.primary.light,
	transition: 'color .2s ease',
	['&.selected']: {
		color: palette.secondary.main,
	},
}));

const ProductBuilder = observer(
	class ProductBuilder extends React.Component<Props> {
		showProducts: boolean = false;
		chosenCategory: number = -1;
		description: string = '';

		_charging: Array<any> = charging;
		_lights: Array<any> = lights;
		_electricity: Array<any> = electricity;
		_other: Array<any> = other;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				_charging: observable,
				_lights: observable,
				_electricity: observable,
				_other: observable,
				showProducts: observable,
				chosenCategory: observable,
				description: observable,
				chosenProducts: computed,
				charging: computed,
				lights: computed,
				electricity: computed,
				other: computed,
				setDescription: action,
				handleChange: action,
				handleAddProduct: action,
				handleRemoveProduct: action,
				handleDeleteChip: action,
			});
		}

		get charging() {
			return this._charging;
		}
		get lights() {
			return this._lights;
		}
		get electricity() {
			return this._electricity;
		}
		get other() {
			return this._other;
		}

		get chosenProducts() {
			return {
				lights: this.lights,
				charging: this.charging,
				electricity: this.electricity,
				other: this.other,
			};
		}

		handleCategoryClick = (event: any) => {
			console.log('clicked: ' + event.currentTarget.id);
			this.chosenCategory = event.currentTarget.id;
			this.showProducts = true;
		};

		handleAddProduct = (event: any, category: Array<any>) => {
			const index = category.findIndex((p) => {
				return p.name === event.currentTarget.id;
			});

			if (index !== -1) {
				category[index].quantity += 1;
			}

			this.props.onChange(this.chosenProducts);
		};

		handleRemoveProduct = (event: any, category: Array<any>) => {
			const index = category.findIndex((p) => {
				return p.name === event.currentTarget.id;
			});

			if (index !== -1 && category[index].quantity > 0) {
				category[index].quantity -= 1;
			}

			this.props.onChange(this.chosenProducts);
		};

		handleDeleteChip = (event: any, name: string) => {
			console.log(name);
			console.log(this.chosenProducts);

			const index = this._lights.findIndex((p) => {
				return p.name === name;
			});

			if (index !== -1 && this._lights[index].quantity > 0) {
				this._lights[index].quantity = 0;
			}

			this.props.onChange(this.chosenProducts);
		};

		handleChange = (event: any) => {
			console.log('clicked: ' + event.currentTarget);
			console.log(this.chosenProducts);
		};

		setDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
			this.description = e.target.value;
		};

		renderSubCategory(category: any[]) {
			return (
				<Grid
					container
					spacing={1}
					sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1 }}
				>
					{category.map((product, index) => (
						<Grid item md={3} sx={{ p: 1 }} key={`product-${index}`}>
							<ElevatedCard style={{ background: '#fafafa' }}>
								<Stack
									direction="column"
									spacing={1}
									sx={{
										textAlign: 'center',
										fontSize: '3.5rem',
										display: 'flex',
									}}
								>
									<StyledSubCategoryIcon
										fontSize="inherit"
										sx={{ m: 'auto' }}
										className={product.quantity > 0 ? 'selected' : ''}
									>
										{product.icon}
									</StyledSubCategoryIcon>
									<Typography variant="caption">{product.name}</Typography>
									<Stack
										direction="row"
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<IconButton
											aria-label="remove"
											sx={{
												backgroundColor: 'primary.main',
												color: 'white',
												m: 1,
											}}
											id={product.name}
											onClick={(e) => this.handleRemoveProduct(e, category)}
										>
											<RemoveIcon sx={{ fontSize: '.6em' }} />
										</IconButton>
										<Typography sx={{ m: 1 }}>{product.quantity}</Typography>
										<IconButton
											aria-label="add"
											sx={{
												backgroundColor: 'primary.main',
												color: 'white',
												m: 1,
											}}
											id={product.name}
											onClick={(e) => this.handleAddProduct(e, category)}
										>
											<AddIcon sx={{ fontSize: '.6em' }} />
										</IconButton>
									</Stack>
								</Stack>
							</ElevatedCard>
						</Grid>
					))}
				</Grid>
			);
		}

		render() {
			return (
				<div>
					<Grid container spacing={1} sx={{ textAlign: 'center' }}>
						<Grid item md={3}>
							<ElevatedCard>
								<StyledMainIconButton
									className={this.chosenCategory == 0 ? 'selected' : ''}
									aria-label="electricity"
									color="primary"
									id="0"
									onClick={this.handleCategoryClick}
								>
									<EvStationIcon fontSize="inherit" sx={{ fontSize: '5rem' }} />
								</StyledMainIconButton>
								<Typography variant="h6">Elbil</Typography>
							</ElevatedCard>
						</Grid>
						<Grid item md={3}>
							<ElevatedCard>
								<StyledMainIconButton
									className={this.chosenCategory == 1 ? 'selected' : ''}
									aria-label="electricity"
									color="primary"
									id="1"
									onClick={this.handleCategoryClick}
								>
									<TungstenIcon fontSize="inherit" sx={{ fontSize: '5rem' }} />
								</StyledMainIconButton>
								<Typography variant="h6">Lys</Typography>
							</ElevatedCard>
						</Grid>
						<Grid item md={3}>
							<ElevatedCard>
								<StyledMainIconButton
									className={this.chosenCategory == 2 ? 'selected' : ''}
									aria-label="electricity"
									color="primary"
									id="2"
									onClick={this.handleCategoryClick}
								>
									<ElectricalServicesIcon fontSize="inherit" sx={{ fontSize: '5rem' }} />
								</StyledMainIconButton>
								<Typography variant="h6">Strøm</Typography>
							</ElevatedCard>
						</Grid>
						<Grid item md={3}>
							<ElevatedCard>
								<StyledMainIconButton
									className={this.chosenCategory == 3 ? 'selected' : ''}
									aria-label="electricity"
									color="primary"
									id="3"
									onClick={this.handleCategoryClick}
								>
									<OtherHousesIcon fontSize="inherit" sx={{ fontSize: '5rem' }} />
								</StyledMainIconButton>
								<Typography variant="h6">Smarthus</Typography>
							</ElevatedCard>
						</Grid>
					</Grid>
					{this.showProducts && (
						<ElevatedCard>
							{this.chosenCategory == 0 && this.renderSubCategory(this.charging)}
							{this.chosenCategory == 1 && this.renderSubCategory(this.lights)}
							{this.chosenCategory == 2 && this.renderSubCategory(this.electricity)}
							{this.chosenCategory == 3 && this.renderSubCategory(this.other)}
						</ElevatedCard>
					)}
					{/* <Box sx={{ position: 'relative' }}>
						<Box sx={{ position: 'aboslute', bottom: '1rem' }}>
							{this.chosenProducts?.charging
								?.filter((c: any) => c.quantity > 0)
								.map((p: any, index: number) => (
									<Chip
										key={`product-${index}`}
										label={p.quantity + ' ' + p.name}
										onDelete={(e) => this.handleDeleteChip(e, p.name)}
										sx={{ m: 1 }}
									/>
								))}
							{this.chosenProducts?.lights
								?.filter((c: any) => c.quantity > 0)
								.map((p: any, index: number) => (
									<Chip
										key={`product-${index}`}
										label={p.quantity + ' ' + p.name}
										onDelete={(e) => this.handleDeleteChip(e, p.name)}
										sx={{ m: 1 }}
									/>
								))}
							{this.chosenProducts?.electricity
								?.filter((c: any) => c.quantity > 0)
								.map((p: any, index: number) => (
									<Chip
										key={`product-${index}`}
										label={p.quantity + ' ' + p.name}
										onDelete={(e) => this.handleDeleteChip(e, p.name)}
										sx={{ m: 1 }}
									/>
								))}
							{this.chosenProducts?.other
								?.filter((c: any) => c.quantity > 0)
								.map((p: any, index: number) => (
									<Chip
										key={`product-${index}`}
										label={p.quantity + ' ' + p.name}
										onDelete={(e) => this.handleDeleteChip(e, p.name)}
										sx={{ m: 1 }}
									/>
								))}
						</Box> 
					</Box> */}
				</div>
			);
		}
	}
);

export default ProductBuilder;
