import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import FeatureGuard from '../../../../utils/FeatureGuard';
import PricingPlanGuard from '../../../../utils/PricingPlanGuard';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ShareIcon from '@mui/icons-material/Share';

export enum ChatInputActionType {
	// eslint-disable-next-line no-unused-vars
	Attach,
	// eslint-disable-next-line no-unused-vars
	AttachImage,
	// eslint-disable-next-line no-unused-vars
	AttachFile,
	// eslint-disable-next-line no-unused-vars
	VideoRecorder,
	// eslint-disable-next-line no-unused-vars
	PurifyMessage,
	// eslint-disable-next-line no-unused-vars
	EventInvite,
	// eslint-disable-next-line no-unused-vars
	VideoCall,
	// eslint-disable-next-line no-unused-vars
	Reaction,
	// eslint-disable-next-line no-unused-vars
	Share,
}

type Props = {
	open?: boolean;
	isCraftsman?: boolean;
	enablePurifyMessage?: boolean;
	enableShare?: boolean;
	anchorElementId: string;
	// eslint-disable-next-line no-unused-vars
	onActionClick: (action: ChatInputActionType) => void;
};

const ChatInputActions = (props: Props) => {
	const { isCraftsman, enablePurifyMessage, enableShare, onActionClick, open, anchorElementId } = props;
	const anchorElement = document.getElementById(anchorElementId);
	if (!anchorElement) {
		return null;
	}

	const attachImage = () => {
		onActionClick(ChatInputActionType.AttachImage);
	};

	const attachFile = () => {
		onActionClick(ChatInputActionType.AttachFile);
	};

	const videoRecorder = () => {
		onActionClick(ChatInputActionType.VideoRecorder);
	};

	const eventInvite = () => {
		onActionClick(ChatInputActionType.EventInvite);
	};

	const purifyMessage = () => {
		onActionClick(ChatInputActionType.PurifyMessage);
	};

	const videoCall = () => {
		onActionClick(ChatInputActionType.VideoCall);
	};

	const share = () => {
		onActionClick(ChatInputActionType.Share);
	};

	return (
		<Menu
			open={!!open}
			anchorEl={anchorElement}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<MenuItem onClick={attachImage}>
				<ListItemIcon>
					<InsertPhotoIcon />
				</ListItemIcon>
				<ListItemText>Bilde</ListItemText>
			</MenuItem>
			<MenuItem onClick={attachFile}>
				<ListItemIcon>
					<AttachFileIcon />
				</ListItemIcon>
				<ListItemText>Vedlegg</ListItemText>
			</MenuItem>
			<FeatureGuard feature="video.recorder">
				<MenuItem onClick={videoRecorder}>
					<ListItemIcon>
						<VideoCallIcon />
					</ListItemIcon>
					<ListItemText>Lag video</ListItemText>
				</MenuItem>
			</FeatureGuard>
			<FeatureGuard feature="event.invite">
				<PricingPlanGuard plans={['pro', 'elite']}>
					<MenuItem onClick={eventInvite}>
						<ListItemIcon>
							<InsertInvitationIcon />
						</ListItemIcon>
						<ListItemText>Planlegg møte</ListItemText>
					</MenuItem>
				</PricingPlanGuard>
			</FeatureGuard>
			{isCraftsman && enablePurifyMessage && (
				<FeatureGuard feature="ai.chat">
					<PricingPlanGuard plans={['pro', 'elite']}>
						<MenuItem onClick={purifyMessage}>
							<ListItemIcon>
								<AutoFixHighIcon />
							</ListItemIcon>
							<ListItemText>AI autofix</ListItemText>
						</MenuItem>
					</PricingPlanGuard>
				</FeatureGuard>
			)}
			{isCraftsman && (
				<MenuItem onClick={videoCall}>
					<ListItemIcon>
						<VideoChatIcon />
					</ListItemIcon>
					<ListItemText>Ring</ListItemText>
				</MenuItem>
			)}

			{enableShare && (
				<MenuItem onClick={share}>
					<ListItemIcon>
						<ShareIcon />
					</ListItemIcon>
					<ListItemText>Del</ListItemText>
				</MenuItem>
			)}
		</Menu>
	);
};

export default ChatInputActions;
