import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import StoreContext from '../../stores/StoreContext';
import Footer from '../footer/Footer';
import OrderChatConfirmationComponent from './OrderChatConfirmationComponent';
import './OrderChatConfirmationScreen.scss';

type Props = {};

const OrderChatConfirmationScreen = observer(
	class OrderChatConfirmationScreen extends React.Component {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {});
		}

		render() {
			return (
				<>
					<div className="orderChatConfirmationComponent__wrapper">
						<OrderChatConfirmationComponent />
					</div>

					<Footer />
				</>
			);
		}
	}
);

export default OrderChatConfirmationScreen;
