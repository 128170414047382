import { Workspace } from '../../types';
import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';

export type WorkspaceApiRequest = Workspace | { message: string };

/**
 * App API
 */
export default class AppApi {
	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getAppInfo(appKey: string, appVersionKey: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/app/info/${appKey}/${appVersionKey}`;
		return FetchWrapper.get(url);
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async givePermission(appKey: string, appVersionKey: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/app/accept/${appKey}/${appVersionKey}`;
		return FetchWrapper.get(url);
	}
}
