import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { IconButton, Typography } from '@mui/material';
import './Reaction.scss';
import { Emoji } from '../../../types';

type ReactionProps = {
	emoji?: Emoji;
	userIds?: string[];
	currentUserId?: string;
	// eslint-disable-next-line no-unused-vars
	onClick?: (emoji?: Emoji) => void;
};
const Reaction = observer(
	class Reaction extends React.Component<ReactionProps> {
		constructor(props: ReactionProps) {
			super(props);

			makeObservable(this, {});
		}

		get count() {
			if (!this.props.userIds || this.props.userIds.length < 1) {
				return null;
			}
			// <Tooltip title={profile?.name ?? ''}></Tooltip>
			return (
				<Typography variant="overline" className="count">
					{this.props.userIds?.length}
				</Typography>
			);
		}

		get userReacted() {
			if (this.props.userIds && this.props.currentUserId) {
				const currentUserReacted = this.props.userIds.find(
					(userId) => userId.toString() === this.props.currentUserId?.toString()
				);
				return Boolean(currentUserReacted);
			}

			return false;
		}

		onClick = () => {
			const { emoji, onClick } = this.props;
			if (onClick) {
				onClick(emoji);
			}
		};

		render() {
			const { emoji } = this.props;

			if (!emoji) {
				return null;
			}

			let className = `Reaction`;
			if (this.userReacted) {
				className += ' user-reacted';
			}

			return (
				<IconButton className={className} size="small" key={emoji.name} onClick={this.onClick}>
					{emoji.utf8Emoji}
					{this.count}
				</IconButton>
			);
		}
	}
);

export default Reaction;
