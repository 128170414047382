import { Theme, createTheme } from '@mui/material';

const minrorleggerTheme = (baseTheme: Theme, options: any): Theme => {
	const { isDarkMode } = options;
	const theme = createTheme(
		{
			...baseTheme,
			palette: {
				background: {
					default: '#fafafa',
					paper: '#f1f1f1',
				},
				divider: 'rgba(23, 57, 99, 0.12)',
				error: {
					main: '#d44141',
				},
				info: {
					main: '#3a91bb',
				},
				primary: {
					main: '#e6228a',
				},
				secondary: {
					main: '#191c33',
				},
				success: {
					main: '#6eb351',
				},
				text: {
					primary: 'rgb(23, 57, 99, 0.87)',
					secondary: 'rgba(23, 57, 99, 0.60)',
					disabled: 'rgba(23, 57, 99, 0.38)',
					// hint: 'rgba(23, 57, 99, 0.38)',
				},
				warning: {
					main: '#fdb714',
				},
			},
			shape: {
				borderRadius: 16,
			},
			typography: {
				fontFamily: 'Inter, Helvetica, sans-serif',
			},
		},
		[isDarkMode]
	);

	return theme;
};

export default minrorleggerTheme;
