import { Button, Typography } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import './OrderChatConfirmationComponent.scss';

type Props = {
	onClose?: () => void;
};

const OrderChatConfirmationComponent = observer(
	class OrderChatConfirmationComponent extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				uiState: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		onClose = () => {
			if (this.props.onClose) {
				this.props.onClose();
			}
		};

		render() {
			const { workspaceTheme } = this.uiState;
			const { pathname } = window.location;

			return (
				<>
					<div className="OrderChatConfirmationComponent flex-container" id="order-chat-confirmation">
						<img src={`/illustrations/${workspaceTheme}/chill.svg`} alt="" />
						<Typography color="textSecondary" variant="overline">
							Bestillingen din er sendt
						</Typography>
						<Typography variant="h4">Tusen takk!</Typography>
						<Typography color="textSecondary" variant="subtitle1" className="order-submitted__text">
							Len deg tilbake og slapp av – vi sender en melding så fort noen plukker opp jobben din og
							sender deg et svar.
						</Typography>
						<div className="flexContainer">
							<Button
								variant="outlined"
								color="secondary"
								component={Link}
								to={pathname}
								onClick={this.onClose}
							>
								Lukk
							</Button>
						</div>
						{/* <Typography use="subtitle2" className="download-headline">
							Last ned <span className="text--italic">Befare</span> for en bedre
							opplevelse
						</Typography>
	
						<div className="flexContainer">
							<img
								className="clickable"
								src="/app-store-badge.svg"
								alt="App Store Badge"
							/>
							<img
								className="clickable"
								src="/google-play-badge.png"
								height={60}
								alt="Google Play badge"
							/>
						</div> */}
					</div>
				</>
			);
		}
	}
);

export default OrderChatConfirmationComponent;
