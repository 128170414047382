import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import DocumentSigningApi, {
	BefareDocument,
	BefareDocumentSigner,
	BefareDocumentSigningLog,
	SignicatSignatureMechanism,
} from '../api/endpoints/DocumentSigningApi';
import { Trigger, TriggerType } from '../Trigger.types';
import LogUtil from '../helpers/LogUtil';

export class DocumentSigningStore {
	rootStore: RootStore;
	documents: Document[] = [];
	isLoading = true;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore; // Store that can resolve authors.
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.loadDocuments();
		});
		this.rootStore.userStore.SignedOut.on(() => (this.documents = []));
	}

	get workspaceStore() {
		return this.rootStore.workspaceStore;
	}

	get pricingPlanStore() {
		return this.rootStore.pricingPlanStore;
	}

	get profileStore() {
		return this.rootStore.profileStore;
	}

	get projectStore() {
		return this.rootStore.projectStore;
	}

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	processTrigger = async (trigger: Trigger<any>) => {
		try {
			switch (trigger.urn) {
				case TriggerType.DOCUMENT_SIGNING_DOCUMENT_CREATED:
				case TriggerType.DOCUMENT_SIGNING_DOCUMENT_UPDATED:
					// got user online trigger, let's see if we can update a profiles online status
					this.updateDocumentFromServer(trigger.event.data);
					break;
				case TriggerType.DOCUMENT_SIGNING_LOG_CREATED:
					this.updateDocumentLogFromServer(trigger.event.data);
					break;
				case TriggerType.DOCUMENT_SIGNING_SIGNER_UPDATED:
					this.updateDocumentSignerFromServer(trigger.event.data);
					break;
			}
		} catch (err) {
			LogUtil.error(err);
		}
	};

	removeDocumentById = (documentId: number) => {
		runInAction(() => {
			const index = this.documents.findIndex((t) => t.id === documentId);
			if (index >= 0) {
				this.documents.splice(index, 1);
			}
		});
	};

	// Fetches all Todos from the server.
	loadDocuments = async () => {
		this.isLoading = true;
		const result = await DocumentSigningApi.getDocuments();
		if (result.statusCode === 200) {
			result.data?.forEach((doc) => this.updateDocumentFromServer(doc));
		}
		this.isLoading = false;
	};

	// Update a Todo with information from the server. Guarantees a Todo only
	// exists once. Might either construct a new Todo, update an existing one,
	// or remove a Todo if it has been deleted on the server.
	updateDocumentFromServer(json: BefareDocument) {
		runInAction(() => {
			let document = this.documents.find((document) => document.id === json.id);

			if (!document && json.id) {
				document = new Document(this, json.id);
				this.documents.push(document);
			} else if (!document) {
				return;
			}

			if (json.deleted) {
				this.removeDocument(document);
			} else {
				document.updateFromJson(json);
			}
		});
	}

	// A Ddocument was somehow deleted, clean it from the client memory.
	removeDocument(document: Document) {
		this.documents.splice(this.documents.indexOf(document), 1);
		// todo.dispose();
	}

	updateDocumentLogFromServer = (log: BefareDocumentSigningLog) => {
		const document = this.documents.find((document) => document.id === log.documentSigningId);
		if (document) {
			document.updateLogFromJson(log);
		}
	};

	updateDocumentSignerFromServer = (signer: BefareDocumentSigner) => {
		const document = this.documents.find((document) => document.id === signer.documentSigningId);
		if (document) {
			document.updateSignerFromJson(signer);
		}
	};
}

export class Document {
	id: number; // Unique id of this Todo, immutable.
	store: DocumentSigningStore;
	autoSave: boolean = true; // Indicator for submitting changes in this Todo to the server.
	// saveHandler: IReactionDisposer; // Disposer of the side effect auto-saving this Todo (dispose).

	created?: string;
	updated?: string;
	deleted?: string;
	workspaceId?: string;
	createdByUserId?: string;
	title?: string;
	description?: string;
	contactEmail?: string;
	contactUrl?: string;
	status: 'unsigned' | 'signed' | 'expired' | 'cancelled' = 'unsigned';
	documentId?: string;
	deadline?: string;
	filename?: string;
	fileId?: string;
	signedFileId?: string;
	mechanism?: SignicatSignatureMechanism;
	signers: BefareDocumentSigner[] = [];
	url?: string;
	urlExpiresAt?: string;
	logs: BefareDocumentSigningLog[] = [];

	constructor(store: DocumentSigningStore, id: number) {
		makeAutoObservable(this, {
			id: false,
			store: false,
			autoSave: false,
			// saveHandler: false,
			// dispose: false,
		});

		this.store = store;
		this.id = id;

		// this.saveHandler = reaction(
		// 	() => this.asJson, // Observe everything that is used in the JSON.
		// 	(json) => {
		// 		// If autoSave is true, send JSON to the server.
		// 		if (this.autoSave) {
		// 			this.store.transportLayer.saveTodo(json);
		// 		}
		// 	}
		// );
	}

	// Remove this Todo from the client and the server.
	delete() {
		// this.store.transportLayer.deleteTodo(this.id);
		this.store.removeDocument(this);
	}

	updateLogFromJson = (log: BefareDocumentSigningLog) => {
		const index = this.logs.findIndex((l) => l.id === log.id);
		if (index >= 0) {
			this.logs[index] = log;
		} else {
			this.logs.push(log);
		}
	};

	updateSignerFromJson = (signer: BefareDocumentSigner) => {
		const index = this.signers.findIndex((s) => s.id === signer.id);
		if (index >= 0) {
			this.signers[index] = signer;
		} else {
			this.signers.push(signer);
		}
	};

	getLogs = async () => {
		if (!this.documentId) return [];
		const result = await DocumentSigningApi.getLogs(this.id);
		if (result.statusCode === 200) {
			this.logs = result.data || [];
		}
		return this.logs;
	};

	get asJson() {
		return {
			id: this.id,
			created: this.created,
			updated: this.updated,
			deleted: this.deleted,
			workspaceId: this.workspaceId,
			createdByUserId: this.createdByUserId,
			title: this.title,
			description: this.description,
			contactEmail: this.contactEmail,
			contactUrl: this.contactUrl,
			status: this.status,
			documentId: this.documentId,
			deadline: this.deadline,
			filename: this.filename,
			fileId: this.fileId,
			signedFileId: this.signedFileId,
			mechanism: this.mechanism,
			url: this.url,
			urlExpiresAt: this.urlExpiresAt,
		};
	}

	// Update this Todo with information from the server.
	updateFromJson(json: BefareDocument) {
		this.autoSave = false; // Prevent sending of our changes back to the server.

		this.created = json.created;
		this.updated = json.updated;
		this.deleted = json.deleted;
		this.workspaceId = json.workspaceId;
		this.createdByUserId = json.createdByUserId;
		this.title = json.title;
		this.description = json.description;
		this.contactEmail = json.contactEmail;
		this.contactUrl = json.contactUrl;
		this.status = json.status;
		this.documentId = json.documentId;
		this.deadline = json.deadline;
		this.filename = json.filename;
		this.fileId = json.fileId;
		this.signedFileId = json.fileId;
		this.mechanism = json.mechanism;
		this.url = json.url;
		this.urlExpiresAt = json.urlExpiresAt;
		this.signers = json.signers || [];

		this.autoSave = true;
	}

	// // Clean up the observer.
	// dispose() {
	// 	this.saveHandler();
	// }
}
