import config from '../../config/config';
import { FetchWrapperResponse, FetchWrapper } from '../../fetch/FetchWrapper';

export default class Files {
	static async fetchFiles(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/file/my`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}
}
