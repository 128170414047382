/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import LogUtil from '../helpers/LogUtil';
import { RootStore } from '../stores/RootStore';
import StoreContext from '../stores/StoreContext';

type Props = {
	children?: React.ReactNode;
};

const SignedInGuard = observer(
	class SignedInGuard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				rootStore: computed,
				userStore: computed,
			});

			this.state = { hasError: false };
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		static getDerivedStateFromError(error: any) {
			// Update state so the next render will show the fallback UI.
			return { hasError: true };
		}

		componentDidCatch(error: any, errorInfo: any) {
			// You can also log the error to an error reporting service
			// @todo change this to error
			LogUtil.debug(error, errorInfo);
		}

		render() {
			const isSignedIn = this.userStore.isLoggedInPromise().read();

			return <Box sx={{ display: 'none' }}>{isSignedIn ? 'true' : 'false'}</Box>;
		}
	}
);

export default SignedInGuard;
