import config from '../../config/config';
import { FetchWrapperResponse, FetchWrapper } from '../../fetch/FetchWrapper';

export default class GdprApi {
	static async getConsentStatus(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/gdpr`;

		return FetchWrapper.get(url);
	}

	static async acceptTerms(consentVersionId: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/gdpr/accept`;

		return FetchWrapper.put(url, {
			body: JSON.stringify({
				consentVersionId,
			}),
		});
	}
}
