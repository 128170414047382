import { makeAutoObservable } from 'mobx';
import PartnershipApi from '../../api/endpoints/PartnershipApi';
import { PremiumPartnershipListingDTO } from './Partnership.types';

export default class PremiumPartnershipListing {
	listingId!: number;
	workspaceId!: number;
	title!: string;
	description!: string;
	terms: string | null = null;
	availableUntil: Date | null = null;
	isActive!: boolean;
	created!: Date;
	updated!: Date;
	listingTags: string[] = [];
	availablePartnershipTags: string[] = [];

	constructor(listing: PremiumPartnershipListingDTO) {
		makeAutoObservable(this);
		this.update(listing);
	}

	async requestPartnership(requestMessage?: string, tags?: string[]) {
		// Request the partnership
		const result = await PartnershipApi.requestPartnership(this.listingId, requestMessage, tags ?? []);
		if (result.statusCode === 200) {
			return true;
		}
		return false;
	}

	update(listing: PremiumPartnershipListingDTO) {
		this.listingId = listing.listingId!;
		this.workspaceId = listing.workspaceId;
		this.title = listing.title;
		this.description = listing.description;
		this.availableUntil = listing.availableUntil ? new Date(listing.availableUntil) : null;
		this.isActive = listing.isActive;
		this.created = new Date(listing.created);
		this.updated = new Date(listing.updated);
		this.terms = listing.terms;
		this.listingTags = listing.listingTags ?? [];
		this.availablePartnershipTags = listing.availablePartnershipTags ?? [];
	}
}
