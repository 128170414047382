import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link, Outlet, Route, RouteProps, Routes } from 'react-router-dom';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { HookTypes, withLocation } from '../../utils/withHooks';
import { Alert, AlertTitle, Button } from '@mui/material';

type Props = HookTypes &
	RouteProps & {
		isAllowed: boolean;
	};

const PrivateRoute = observer(
	class PrivateRoute extends React.Component<Props> {
		get location() {
			return this.props.location!;
		}

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				isAllowed: computed,
			});
		}

		get isAllowed() {
			return this.props.isAllowed;
		}

		render() {
			const { children, path, ...rest } = this.props;

			if (!this.isAllowed) {
				return (
					<Alert severity="warning">
						<AlertTitle>401</AlertTitle>You are not allowed to access this page.
						<br />
						<Button component={Link} to="/home" color="inherit">
							Go back to home
						</Button>
					</Alert>
				);
			}

			return (
				<>
					<Routes>
						<Route {...rest} path={path ?? '/*'} element={<ErrorBoundary>{children}</ErrorBoundary>} />
					</Routes>
					<Outlet />
				</>
			);
		}
	}
);

export default withLocation(PrivateRoute);
