import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { CompanyDTO } from '../../dto/company.types';
import { Company } from '../../stores/company/Company';

type Props = {
	company?: Company | CompanyDTO;
	className?: string;
	maxHeight?: number | string;
};

const CompanyLogo = observer(
	class CompanyLogo extends React.Component<Props> {
		render() {
			const { company, className, maxHeight, ...rest } = this.props;

			if (company) {
				const { logo, name } = company;

				if (!logo) {
					return (
						<Typography data-hj-suppress variant="subtitle2" className={className}>
							{name}
						</Typography>
					);
				}

				return (
					<img
						style={{ maxWidth: '100%', objectFit: 'contain', maxHeight: maxHeight }}
						alt="Company logo"
						className={`CompanyLogo ${className}`}
						data-hj-suppress
						src={logo}
						{...rest}
					/>
				);
			}

			return (
				<Typography className={className} variant="subtitle2">
					Firma mangler
				</Typography>
			);
		}
	}
);

export default CompanyLogo;
