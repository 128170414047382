import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import anime from 'animejs';

import './PageLoading.scss';

type ItemBoxProps = {
	index: number;
	// eslint-disable-next-line no-unused-vars
	onClick: (index: number) => void;
};

class ItemBox extends React.Component<ItemBoxProps> {
	onClick = () => {
		const { index, onClick } = this.props;
		onClick(index);
	};

	render() {
		return <Box className="item" onClick={this.onClick}></Box>;
	}
}

type Props = {
	gridSize?: number;
	autoplay?: boolean;
	title?: string;
	hideCircularProgress?: boolean;
};

class PageLoading extends React.Component<Props> {
	selfRef = React.createRef<HTMLDivElement>();

	GRID_ITEM_SIZE = 64;
	toggled: boolean = false;
	isRunning: boolean = false;
	randomAnimTimeout: any = undefined;
	autoplay: boolean = false;
	lastAnimation: number = 0;

	constructor(props: Props) {
		super(props);

		if (this.props.autoplay) {
			this.autoplay = this.props.autoplay;
		}

		if (this.props.gridSize) {
			this.GRID_ITEM_SIZE = this.props.gridSize;
		}
	}

	get width() {
		return document.querySelector('.PageLoading')?.clientWidth || 0;
	}

	get height() {
		return document.querySelector('.PageLoading')?.clientHeight || 0;
	}

	get columns() {
		return Math.floor(this.width / this.GRID_ITEM_SIZE);
	}

	get rows() {
		return Math.floor(this.height / this.GRID_ITEM_SIZE);
	}

	get title() {
		return this.props.title || 'Laster ' + document.querySelector('title')?.innerText + '...' || 'Laster Befare...';
	}

	componentDidMount(): void {
		this.randomAnim();
		this.setCssVariables();
	}

	setCssVariables = () => {
		this.selfRef.current?.style.setProperty('--columns', '' + this.columns);
		this.selfRef.current?.style.setProperty('--rows', '' + this.rows);
	};

	randomAnim = () => {
		clearTimeout(this.randomAnimTimeout);

		if (!this.isRunning && this.columns && this.rows) {
			if (!document.querySelector('.PageLoading .container .item')) {
				this.forceUpdate();
			} else if (this.autoplay && this.lastAnimation + 5000 < Date.now()) {
				const index = Math.floor(this.columns * this.rows * Math.random());
				this.animate(index);
			}
		}

		this.randomAnimTimeout = setTimeout(() => {
			this.randomAnim();
		}, 50);
	};

	animate = (index: number = 0) => {
		if (this.isRunning) {
			return;
		}
		this.toggled = !this.toggled;
		this.isRunning = true;
		this.lastAnimation = Date.now();
		const { columns, rows } = this;
		anime({
			targets: '.PageLoading .container .item',
			opacity: this.toggled ? 0.1 : 1,
			delay: anime.stagger(50, {
				grid: [columns, rows],
				from: index,
			}),
			complete: () => {
				this.isRunning = false;
			},
		});
	};

	onItemClick = (index: number) => {
		this.autoplay = false;
		this.animate(index);
	};

	renderItems() {
		if (!this.height) {
			return;
		}
		const items = [];

		this.setCssVariables();

		for (let i = 0; i < this.columns * this.rows; i++) {
			items.push(<ItemBox key={`item-${i}`} index={i} onClick={this.onItemClick} />);
		}

		return items;
	}

	render() {
		const { hideCircularProgress } = this.props;
		return (
			<Box className="PageLoading" ref={this.selfRef}>
				<Box className="loading-overlay">
					{!hideCircularProgress && <CircularProgress size={48} />}
					<Typography variant="h6">{this.title}</Typography>
				</Box>

				<Box className="container">{this.renderItems()}</Box>
			</Box>
		);
	}
}

export default React.memo(PageLoading);
