import { Button, ButtonProps } from '@mui/material';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AuthManager from '../../auth/AuthManager';
import './ResendVerificationCodeButton.scss';

type Props = ButtonProps & {
	phoneNumber: string;
};

const ALLOW_CODE_RESEND_THRESHOLD = 30;

const ResendVerificationCodeButton = observer(
	class ResendVerificationCodeButton extends React.Component<Props> {
		timer: ReturnType<typeof setInterval> | undefined;
		secondsElapsed: number = 0;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				timer: observable,
				secondsElapsed: observable,
				enableCodeResend: computed,
				countdown: action,
				init: action,
			});
		}

		componentDidMount() {
			this.init();
		}

		init() {
			clearInterval(this.timer);
			this.timer = setInterval(this.countdown, 1000);
		}

		get enableCodeResend() {
			return this.secondsElapsed >= ALLOW_CODE_RESEND_THRESHOLD;
		}

		countdown = () => {
			this.secondsElapsed++;

			if (this.secondsElapsed === ALLOW_CODE_RESEND_THRESHOLD) {
				clearInterval(this.timer);
				this.timer = undefined;
			}
		};

		resendCode = async () => {
			try {
				await AuthManager.phoneAuth(undefined, this.props.phoneNumber);

				runInAction(() => {
					this.secondsElapsed = 0;
					this.timer = setInterval(this.countdown, 1000);
				});
			} catch (err) {
				console.error(err);
			}
		};

		render() {
			// eslint-disable-next-line no-unused-vars
			const { phoneNumber, className, ...rest } = this.props;

			return (
				<Button
					className={`ResendVerificationCodeButton ${className}`}
					color="secondary"
					disabled={!this.enableCodeResend}
					fullWidth
					onClick={this.resendCode}
					variant="outlined"
					{...rest}
				>
					Send kode på nytt
					{!this.enableCodeResend && (
						<>
							<span className="vertical-bar">|</span>
							<span className="text--bold">{ALLOW_CODE_RESEND_THRESHOLD - this.secondsElapsed} s</span>
						</>
					)}
				</Button>
			);
		}
	}
);

export default ResendVerificationCodeButton;
