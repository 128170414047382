import { Box, Grid, Typography, Button, Alert, AlertTitle, styled, Paper } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import MapComponent, { MapPoint } from '../map/MapComponent';
import ChatIcon from '@mui/icons-material/Chat';
import StoreContext from '../../stores/StoreContext';
import { action, computed, makeObservable } from 'mobx';
import { RootStore } from '../../stores/RootStore';
import { toast } from 'react-toastify';

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
	position: 'absolute',
	top: spacing(7),
	left: 0,
	right: 0,
	bottom: 0,
}));

const StyledPaper = styled(Paper)(({ theme: { spacing, palette } }) => ({
	background: palette.primary.main,
	padding: spacing(3),
	color: '#fafafa',
	textAlign: 'center',
	paddingTop: '7rem',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignItems: 'center',
}));

const PaperCircle = styled(Paper)(({ theme: { spacing, palette } }) => ({
	height: 200,
	width: 200,
	position: 'absolute',
	left: '50%',
	top: -100,
	transform: 'translateX(-50%)',
	zIndex: 1,
	borderRadius: '50%',
	padding: spacing(1),
	background: palette.primary.main,
	['&::before']: {
		content: '""',
		display: 'block',
		width: '120%',
		height: '55%',
		top: '50%',
		left: '-10%',
		position: 'absolute',
		background: palette.primary.main,
	},
	['& .progress-with-caption']: {
		width: '100% !important',
		height: '100% !important',
		borderRadius: '50%',
		backgroundColor: '#fafafa',
		padding: spacing(1),
		['& .MuiCircularProgress-root.MuiCircularProgress-determinate']: {
			width: '100% !important',
			height: '100% !important',
			color: palette.secondary.main,
		},
		['& .progress-with-caption--caption']: {
			width: '100% !important',
			height: '100% !important',
			padding: '1rem',
			['& .progress-with-caption--caption-value']: {
				color: palette.secondary.main,
				fontSize: '1.5rem',
			},
			['& .progress-with-caption--caption-caption']: {
				color: palette.primary.main,
				fontSize: '1rem',
			},
		},
	},
}));

type Props = {
	points: MapPoint[];
	caption: string;
	progress: number;
	url: string;
};

const OrderChatCreatingProject = observer(
	class OrderChatCreatingProject extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				rootStore: computed,
				profileStore: computed,
				isPushEnabled: computed,
				activatePushNotifications: action,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get isPushEnabled() {
			return this.profileStore.isPushEnabled;
		}

		activatePushNotifications = async () => {
			const success = await this.profileStore.requestPushPermission();
			if (success) {
				toast('Du vil nå få varsler når du har fått en ny melding i chatten.');
			} else {
				toast('Du må godta push varsler for å få varsler når du har fått en ny melding i chatten.');
			}
		};

		render() {
			const { points, caption, progress, url } = this.props;
			return (
				<StyledBox>
					<Grid container sx={{ height: '100%', zIndex: 2, position: 'relative' }}>
						<Grid item xs={12} sx={{ background: 'rgba(0,0,0,.3)', minHeight: 230, zIndex: 1 }}>
							<MapComponent points={points} height="100%" />
						</Grid>
						<Grid item xs={12} sx={{ position: 'relative', zIndex: 2 }}>
							<StyledPaper elevation={4} square>
								<Grid container alignContent="center" direction="column">
									<Grid item xs={12} sm={6} md={4} alignItems="center" direction="column">
										<Typography variant="h4" gutterBottom>
											{caption}
										</Typography>
										<Typography variant="body2" gutterBottom>
											Etter at jobben er opprettet kan du gå inn i chatten og legge til mer
											informasjon. F.eks. laste opp noen bilder.
										</Typography>
										<Typography variant="body2" gutterBottom>
											Bedriften vil svare deg så fort som mulig.
										</Typography>
										<Button
											variant="contained"
											color="secondary"
											size="large"
											disabled={progress < 70}
											startIcon={<ChatIcon />}
											sx={{ marginTop: '2rem' }}
											component={Link}
											to={url}
											id="order-chat-2-completed"
										>
											Åpne Chat
										</Button>
										{!this.isPushEnabled && (
											<Alert
												sx={{ textAlign: 'left', marginTop: '3rem' }}
												action={
													<Button
														color="inherit"
														size="small"
														onClick={this.activatePushNotifications}
													>
														Aktiver
													</Button>
												}
											>
												<AlertTitle>Tips:</AlertTitle>
												Aktiver push–notifikasjoner slik at du får beskjed straks bedriften
												svarer deg. Hvis ikke sender vi deg en SMS når du får et svar.
											</Alert>
										)}
									</Grid>
								</Grid>
							</StyledPaper>
							<PaperCircle elevation={4}>
								<CircularProgressWithLabel value={progress} caption={caption} />
							</PaperCircle>
						</Grid>
					</Grid>
				</StyledBox>
			);
		}
	}
);

export default OrderChatCreatingProject;
