/* eslint-disable no-unused-vars */
import { Alert } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import features from '../config/features.config';
import LogUtil from '../helpers/LogUtil';
import { RootStore } from '../stores/RootStore';
import StoreContext from '../stores/StoreContext';

type Props = {
	feature: string;
	children: React.ReactNode | React.ReactNode[] | undefined;
	fallback?: React.ReactNode;
};

const FeatureGuard = observer(
	class FeatureGuard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				rootStore: computed,
			});
			this.state = { hasError: false };
		}

		get rootStore() {
			return this.context as RootStore;
		}

		static getDerivedStateFromError(error: any) {
			// Update state so the next render will show the fallback UI.
			return { hasError: true };
		}

		componentDidCatch(error: any, errorInfo: any) {
			// You can also log the error to an error reporting service
			// @todo change this to error
			LogUtil.debug(error, errorInfo);
		}

		render() {
			if ((this.state as any).hasError) {
				// You can render any custom fallback UI
				return <Alert severity="error">Noe gikk galt</Alert>;
			}

			if (features[this.props.feature] === true) {
				return this.props.children;
			}

			if (
				features[this.props.feature] === 'alpha' &&
				this.rootStore.workspaceStore.getSetting('alpha', 'disabled') === false
			) {
				return this.props.children;
			}

			if (
				features[this.props.feature] === 'beta' &&
				this.rootStore.workspaceStore.getSetting('beta', 'disabled') === false
			) {
				return this.props.children;
			}

			if (this.props.fallback) {
				return this.props.fallback;
			}
		}
	}
);

export default FeatureGuard;
