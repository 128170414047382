import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, IconButton, Menu, Tooltip, Badge } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import StoreContext from '../../stores/StoreContext';
import { RootStore } from '../../stores/RootStore';
import Notifications from '../notification/Notifications';

type Props = {};

const NotificationsMenu = observer(
	class NotificationsMenu extends React.Component<Props> {
		static readonly contextType = StoreContext;

		anchorEl: null | HTMLElement = null;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				anchorEl: observable,
				open: computed,
				handleClose: action,
				handleOpen: action,
				rootStore: computed,
				profileStore: computed,
				workspaceStore: computed,
				notificationStore: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get notificationStore() {
			return this.rootStore.notificationStore;
		}

		get open() {
			return Boolean(this.anchorEl);
		}

		handleClose = () => {
			this.anchorEl = null;
		};

		handleOpen = (e: React.MouseEvent<HTMLElement>) => {
			this.anchorEl = e.currentTarget;
		};

		render() {
			return (
				<>
					<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
						<Tooltip title="Notifikasjoner">
							<IconButton
								onClick={this.handleOpen}
								size="small"
								sx={{ mr: 1 }}
								aria-controls={this.open ? 'account-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={this.open ? 'true' : undefined}
							>
								<Badge badgeContent={this.notificationStore.unread} color="secondary">
									<NotificationsIcon sx={{ color: '#fafafa' }} />
								</Badge>
							</IconButton>
						</Tooltip>
					</Box>
					<Menu
						anchorEl={this.anchorEl}
						id="account-menu"
						open={this.open}
						onClose={this.handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								'overflow': 'visible',
								'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								'mt': 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<Notifications />
					</Menu>
				</>
			);
		}
	}
);

export default NotificationsMenu;
