import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type LayoutContentProps = {
	children: ReactNode;
};

const LayoutContent = ({ children }: LayoutContentProps) => {
	return (
		<Stack
			spacing={2}
			direction="column"
			sx={(theme) => ({
				mt: 2,
				mb: 2,
				width: '100%',
				borderRadius: 1,
				border: 1,
				borderColor: 'grey.200',
				backgroundColor: 'white',
				flexGrow: 1,
				[theme.breakpoints.down('sm')]: {
					padding: 0,
				},
			})}
		>
			<Stack
				spacing={2}
				direction="column"
				sx={{
					padding: 0,
					width: '100%',
				}}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default LayoutContent;
