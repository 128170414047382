import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AppApi from '../../api/endpoints/AppApi';
import LoginComponent from '../../components/common/login/LoginComponent';
import ProfilePicture from '../../components/common/ProfilePicture';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import './AuthorizeApp.scss';
import { HookTypes, withLocation } from '../../utils/withHooks';

type Props = HookTypes;

const AuthorizeApp = observer(
	class AuthorizeApp extends React.Component<Props> {
		static readonly contextType = StoreContext;
		get location() {
			return this.props.location!;
		}

		appInfo: any = null;
		isLoading: boolean = false;
		signInDone: boolean = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				appInfo: observable,
				isLoading: observable,
				signInDone: observable,
				showLogin: computed,
				queryParams: computed,
				appKey: computed,
				appVersionKey: computed,
				redirectPath: computed,
				userStore: computed,
				uiState: computed,
				workspaceStore: computed,
				loadAppInfo: action,
				accept: action,
				cancel: action,
				onSignInDone: action,
			});
		}

		get showLogin() {
			return !(this.userStore.isLoggedIn && this.workspaceStore.workspace?.id && this.signInDone);
		}

		componentDidMount() {
			this.loadAppInfo();
		}

		async loadAppInfo() {
			if (this.appKey && this.appVersionKey) {
				this.isLoading = true;
				const result = await AppApi.getAppInfo(this.appKey, this.appVersionKey);
				if (result.statusCode === 200) {
					runInAction(() => {
						this.appInfo = result.data;
					});
				}
				runInAction(() => {
					this.isLoading = false;
				});
			}
		}

		get appKey() {
			return this.queryParams?.appKey;
		}

		get appVersionKey() {
			return this.queryParams?.appVersionKey;
		}

		get redirectPath() {
			const requestedRedirect = this.queryParams?.redirect;

			if (requestedRedirect && this.appInfo?.redirectUrls) {
				let isAllowed = false;
				this.appInfo.redirectUrls.some((url: string) => {
					if (requestedRedirect.indexOf(url) === 0) {
						isAllowed = true;
					}
					return isAllowed;
				});

				if (isAllowed) {
					return requestedRedirect;
				}
			}

			return null;
		}

		get queryParams() {
			try {
				let search = this.location.search;
				if (search) {
					if (search.startsWith('?')) {
						search = search.replace('?', '');
					}
					const query: any = {};
					const parts = search.split('&');
					parts.forEach((part: string) => {
						const p = part.split('=');
						query[p[0]] = p[1] || true;
					});

					return query;
				}
			} catch (e) {
				// do nothing
			}
			return {};
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get profile() {
			return this.profileStore.currentUserProfile;
		}

		onSignInDone = () => {
			this.signInDone = true;
		};

		accept = async () => {
			this.isLoading = true;
			const result = await AppApi.givePermission(this.appKey, this.appVersionKey);
			runInAction(() => {
				this.isLoading = false;
			});
			if (result.statusCode === 200) {
				const url = this.redirectPath;
				if (this.redirectPath) {
					this.openUrl(`${url}?expires=${result.data.expires}&token=${result.data.token}`);
				} else {
					this.openUrl(`${this.queryParams?.redirect}?error=url-not-allowed`);
				}
			} else if (result.statusCode === 401) {
				console.log(`Not allowed`);
			} else {
				console.log(`An error occured ${result.statusCode}`);
			}
		};

		cancel = () => {
			this.openUrl(this.queryParams.redirect);
		};

		changeWorkspace = () => {
			this.signInDone = false;
		};

		openUrl = (url: string) => {
			const windowOpenTimeout = setTimeout(() => {
				window.location.href = url;
			}, 5000);
			if (window.opener) {
				const url2 = new URL(document.referrer);
				const target = url2.protocol + '//' + url2.host;
				//opened window
				window.addEventListener('message', (ev: MessageEvent<any>) => {
					if (ev.origin == target && ev.data == 'AUTH-RECEIVED') {
						clearTimeout(windowOpenTimeout);
						window.close();
					}
				});
				window.opener.postMessage(url, target);
			} else {
				window.location.href = url;
			}
		};

		renderGrants() {
			const grants = this.appInfo?.grants?.map((grant: string) => {
				let translatedGrant = grant;
				switch (grant) {
					case 'project.create':
						translatedGrant = 'Opprette jobber';
						break;
					case 'project.read':
						translatedGrant = 'Lese data fra jobber';
						break;
					case 'profile.read':
						translatedGrant = 'Se profiler';
						break;
					case 'channels.read.project.all':
						translatedGrant = 'Lese meldinger og innhold i prosjektsamtaler';
						break;
				}

				return <li key={grant}>{translatedGrant}</li>;
			});

			return <ul>{grants}</ul>;
		}

		renderGrantRequest() {
			const { workspace, hasAdminAccess } = this.workspaceStore;
			const { serviceName } = this.uiState;

			return (
				<>
					<Typography variant="h6">Tilgangsforespørsel</Typography>
					<Card>
						<CardHeader title={this.appInfo?.name} subtitle={this.appInfo?.approved} />
						{this.appInfo?.logo && (
							<CardMedia
								style={{ backgroundSize: '50%', height: '100px' }}
								image={this.appInfo.logo!}
								title={this.appInfo.name}
							/>
						)}
						<CardContent>
							<Typography variant="body2">{this.appInfo?.description}</Typography>
						</CardContent>
					</Card>
					{hasAdminAccess ? (
						<>
							<Typography variant="overline">Denne appen er ikke utgitt av Norwegian Blue AS</Typography>
							<Typography variant="body2">Denne appen ønsker tilgang til:</Typography>
							{this.renderGrants()}
							<Typography variant="body2">
								Å gi denne appen tilgang betyr at du aksepterer at den kan lese og/eller opprette
								informasjon på {serviceName} på vegne av deg.
							</Typography>
						</>
					) : (
						<Alert severity="warning">
							Du må være administrator i {workspace?.name} for å legge til en app. Spør om en
							administrator kan legge denne til for deg.
						</Alert>
					)}
				</>
			);
		}

		render() {
			let icon = '/favicon.ico';
			const iconMeta = document.querySelector('meta[rel="icon]');
			if (iconMeta) {
				console.log(iconMeta);
				icon = iconMeta.getAttribute('href') ?? icon;
			}

			let title = 'Min-Elektriker';
			const titleElm = document.querySelector('title');
			if (titleElm) {
				title = titleElm.innerText ?? title;
			}

			const { isMobile } = this.uiState;
			const { workspace, hasAdminAccess } = this.workspaceStore;

			if (this.isLoading || this.workspaceStore.isLoading) {
				return (
					<Dialog open={true} className="AuthorizeApp" fullScreen={isMobile} fullWidth maxWidth="sm">
						<DialogTitle>
							<div className="dialogTitle">
								<Avatar src={icon}></Avatar>
								{title}
							</div>
						</DialogTitle>
						<DialogContent>
							<div className="loading">
								<CircularProgress />
							</div>
						</DialogContent>
					</Dialog>
				);
			}

			if (this.showLogin) {
				return (
					<Dialog open={true} className="AuthorizeApp" fullScreen={isMobile}>
						<DialogTitle>
							<div className="dialogTitle">
								<Avatar src={icon}></Avatar>
								{title}
							</div>
						</DialogTitle>
						<DialogContent>
							<Alert severity="info" style={{ marginBottom: '2rem' }}>
								Du må logge inn for å fortsette med godkjenning av App.
							</Alert>
							{this.isLoading || this.workspaceStore.isLoading ? (
								<div className="loading">
									<CircularProgress />
								</div>
							) : (
								<LoginComponent onSignInDone={this.onSignInDone} />
							)}
						</DialogContent>
					</Dialog>
				);
			}

			return (
				<Dialog open={true} className="AuthorizeApp" fullScreen={isMobile} fullWidth maxWidth="sm">
					<DialogTitle>
						<div className="dialogTitle">
							<Avatar src={icon}></Avatar>
							{title}
						</div>
					</DialogTitle>
					<DialogContent>
						<div className="profile">
							{this.profile?.name && <ProfilePicture profile={this.profile} />}
							<div>
								<Typography variant="body2">{this.profile?.name}</Typography>
								<Typography variant="overline">{workspace?.name}</Typography>
							</div>
							<Button variant="outlined" onClick={this.changeWorkspace}>
								Endre bedrift
							</Button>
						</div>
						{this.isLoading ? (
							<div className="loading">
								<CircularProgress />
							</div>
						) : (
							<>
								{!this.appInfo && <Alert severity="info">Denne appen finnes ikke</Alert>}
								{this.appInfo && this.renderGrantRequest()}
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" color="secondary" onClick={this.cancel}>
							Avbryt
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={this.accept}
							disabled={!(hasAdminAccess && this.appInfo)}
						>
							Godkjenn
						</Button>
					</DialogActions>
				</Dialog>
			);
		}
	}
);

export default withLocation(AuthorizeApp);
