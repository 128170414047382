/* eslint eqeqeq: "off" */

import { makeAutoObservable, runInAction } from 'mobx';
import { ProjectDTO, ProjectStatusDTO } from '../dto/project.types';
import { Trigger, TriggerType } from '../Trigger.types';

import LiteEvent from '../helpers/LiteEvent';
import LogUtil from '../helpers/LogUtil';
import ProjectApi from '../api/endpoints/ProjectApi';
import { RootStore } from './RootStore';
import { SoundEffect } from './UiState';
import { Profile } from './ProfileStore';
import { Project } from './Project';

export enum ProjectSorting {
	CreatedDESC = 'CreatedDESC', // eslint-disable-line no-unused-vars
	CreatedASC = 'CreatedASC', // eslint-disable-line no-unused-vars
	LastActivity = 'LastActivity', // eslint-disable-line no-unused-vars
	UnreadMessages = 'UnreadMessages', // eslint-disable-line no-unused-vars
}
/**
 * ProjectStore
 */
export class ProjectStore {
	rootStore: RootStore;
	projects: Project[] = [];
	requestedProjects: Project[] = [];
	isLoadingProjects?: ProjectStatusDTO[];
	lastLoadedProjectsTime: number = Date.now();
	isLoadingProjectsArchive: boolean = false;
	draftProject: Project = new Project(this);
	removedProjectIds: number[] = [];

	private readonly hasProjectsReloaded = new LiteEvent<any>();

	static readonly ACTIVE_PROJECT_STATUSES: ProjectStatusDTO[] = [
		ProjectStatusDTO.Active,
		ProjectStatusDTO.Assigned,
		ProjectStatusDTO.OfferSent,
		ProjectStatusDTO.OfferAccepted,
		ProjectStatusDTO.OfferRejected,
		ProjectStatusDTO.OfferExpired,
		ProjectStatusDTO.Planned,
		ProjectStatusDTO.Received,
		ProjectStatusDTO.Requested,
		ProjectStatusDTO.Hold,
	];

	static readonly ACTIVE_NOT_REQUESTED_PROJECT_STATUSES: ProjectStatusDTO[] =
		ProjectStore.ACTIVE_PROJECT_STATUSES.filter((status) => status !== ProjectStatusDTO.Requested);

	static readonly COMPLETED_PROJECT_STATUSES: ProjectStatusDTO[] = [
		ProjectStatusDTO.Completed,
		ProjectStatusDTO.Aborted,
		ProjectStatusDTO.ProjectLost,
	];

	private readonly onProjectsLoaded = new LiteEvent<any>();
	private readonly onProjectUpdated = new LiteEvent<any>();
	private isInitiated = false;
	constructor(rootStore: RootStore) {
		this.processTrigger = this.processTrigger.bind(this);
		this.updateProjectFromServer = this.updateProjectFromServer.bind(this);
		this.getProject = this.getProject.bind(this);
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.init();
	}

	init() {
		if (this.isInitiated) {
			console.warn('ProjectStore already initiated');
			return;
		}
		this.isInitiated = true;
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.reset();
			this.loadProjects();
			this.hasProjectsReloaded.trigger();
		});
		// Something might happen when the page is in the backgrond
		// Some browsers suspend websocket connections
		// We need to make sure we have the data
		this.rootStore.Online.on(() => {
			// onlyu load if it's been more than 10 seconds since last load
			if (this.lastLoadedProjectsTime < Date.now() - 1000 * 10) {
				this.loadProjects();
			}
		});
		this.rootStore.PageVisible.on(() => {
			// onlyu load if it's been more than 10 seconds since last load
			if (this.lastLoadedProjectsTime < Date.now() - 1000 * 10) {
				this.loadProjects();
			}
		});
		this.rootStore.userStore.SignedOut.on(() => {
			this.reset();
		});
	}

	reset() {
		console.warn('Resetting project store');
		this.projects = [];
		this.requestedProjects = [];
		this.isLoadingProjects = undefined;
		this.lastLoadedProjectsTime = 0;
	}

	/* Getters */
	get projectsSortedByDate(): Project[] {
		try {
			return [...this.projects].filter((p) => !!p).sort((a, b) => b?.created?.getTime() - a?.created?.getTime());
		} catch (err) {
			LogUtil.error(err);
			return this.projects;
		}
	}

	get projectsSortedByLastActivity(): Project[] {
		return [...this.projects].sort((a, b) => {
			const unreadA = a.lastActivity;

			const unreadB = b.lastActivity;

			if (unreadA > unreadB) return -1;
			if (unreadA < unreadB) return 1;
			return 0;
		});
	}

	get requestedProjectsSortedByDate(): Project[] {
		try {
			return [...this.requestedProjects]
				.filter((p) => !!p)
				.sort((a, b) => b?.created?.getTime() - a?.created?.getTime());
		} catch (err) {
			LogUtil.error(err);
			return this.requestedProjects;
		}
	}

	public get ProjectsReloaded() {
		return this.hasProjectsReloaded.expose();
	}

	get latestProject(): Project | null {
		if (!this.projects.length) {
			return null;
		}

		let latestProject: Project = this.projects[0];

		for (const project of this.projects) {
			if (project.created > latestProject.created) {
				latestProject = project;
			}
		}

		return latestProject;
	}

	sortProjects(
		projectSort: ProjectSorting,
		queryFilter?: string,
		statusFilter?: ProjectStatusDTO[],
		memberUserIdFilter?: string[],
		dateFilter?: {
			from: Date;
			to: Date;
		}
	) {
		if (!Array.isArray(this.projects)) {
			return [];
		}
		let filteredProjects = this.projects.slice();

		if (memberUserIdFilter && memberUserIdFilter.length > 0) {
			filteredProjects = filteredProjects.filter((project) =>
				project.projectMembers
					.map((member) => '' + member.userId)
					.some((m) => memberUserIdFilter.includes('' + m))
			);
		}

		if (queryFilter && queryFilter.length > 0) {
			filteredProjects = this.returnQueryResults(queryFilter, filteredProjects);
		}

		if (statusFilter && statusFilter.length > 0) {
			filteredProjects = filteredProjects.filter((project: any) => statusFilter.indexOf(project.status) >= 0);
		}

		if (dateFilter) {
			try {
				filteredProjects = filteredProjects.filter((project) => {
					return project.created >= dateFilter.from && project.created <= dateFilter.to;
				});
			} catch (err) {
				console.warn(err);
			}
		}

		try {
			if (projectSort === ProjectSorting.CreatedDESC) {
				return filteredProjects.sort((a, b) => {
					if (a.created > b.created) return -1;
					if (a.created < b.created) return 1;
					return 0;
				});
			}
			if (projectSort === ProjectSorting.CreatedASC) {
				return filteredProjects.sort((a, b) => {
					if (a.created > b.created) return 1;
					if (a.created < b.created) return -1;
					return 0;
				});
			}
			if (projectSort === ProjectSorting.UnreadMessages) {
				return filteredProjects.sort((a, b) => {
					const unreadA = a.numUnread;

					const unreadB = b.numUnread;

					if (unreadA > unreadB) return -1;
					if (unreadA < unreadB) return 1;
					return 0;
				});
			}
			if (projectSort === ProjectSorting.LastActivity) {
				return filteredProjects.sort((a, b) => {
					const unreadA = a.lastActivity;

					const unreadB = b.lastActivity;

					if (unreadA > unreadB) return -1;
					if (unreadA < unreadB) return 1;
					return 0;
				});
			} else {
				return filteredProjects;
			}
		} catch (err) {
			console.error(err);
			return filteredProjects;
		}
	}

	sortPinProjects(queryFilter?: string) {
		if (!Array.isArray(this.projects)) {
			return [];
		}

		let filteredProjects = this.projects.slice();

		if (queryFilter && queryFilter.length > 0) {
			filteredProjects = this.returnQueryResults(queryFilter, filteredProjects);
		}

		try {
			return filteredProjects;
		} catch (err) {
			console.error(err);
			return filteredProjects;
		}
	}

	returnQueryResults(queryFilter: string, filteredProjects: Array<Project>) {
		filteredProjects = filteredProjects.filter((p) => {
			const company = this.rootStore.companyStore.findCompanyByWorkspaceId(p.workspaceId);
			const profile = this.rootStore.profileStore.getProfile(p.ownerId);
			let hit = p.description?.toLowerCase().includes(queryFilter.toLowerCase());
			if (!hit) {
				hit = p.name?.toLowerCase().includes(queryFilter.toLowerCase());
			}
			if (!hit && profile?.name) {
				hit = profile.name?.toLowerCase().includes(queryFilter.toLowerCase());
			}
			if (!hit && p.address?.street) {
				hit = p.address?.street?.toLowerCase().includes(queryFilter.toLowerCase());
			}
			if (!hit && p.address?.postArea) {
				hit = p.address?.postArea?.toLowerCase().includes(queryFilter.toLowerCase());
			}
			if (!hit && p.address?.postCode) {
				hit = p.address?.postCode?.toLowerCase().includes(queryFilter.toLowerCase());
			}
			if (!hit && p.id) {
				hit = ('' + p.id).toLowerCase().includes(queryFilter.toLowerCase());
			}
			if (!hit && company?.name) {
				hit = company.name?.toLowerCase().includes(queryFilter.toLowerCase());
			}
			const flatTags = p.tags?.map((tag) => tag).join(' ');
			if (!hit && flatTags) {
				hit = flatTags.toLowerCase().includes(queryFilter.toLowerCase());
			}
			// category
			if (!hit && p.category) {
				hit = p.category.toLowerCase().includes(queryFilter.toLowerCase());
			}
			return hit;
		});
		return filteredProjects;
	}

	/**
	 * @deprecated
	 * @param projectId
	 * @returns
	 */
	getLastRelevantMessage(projectId: number) {
		const project = this.findProject(projectId);

		if (!project) {
			return null;
		}

		const channelsWithUnreadMessages = project.channels?.filter(
			(channel) => (this.rootStore.chatStore.findChannel(channel.id)?.unreadNum ?? 0) > 0
		);

		if (channelsWithUnreadMessages.length === 0) {
			return this.rootStore.chatStore.getLastMessage(
				project.channels
					.slice()
					.sort(
						(a, b) =>
							(this.rootStore.chatStore.getLastMessage(b.id)?.created.getTime() ?? 0) -
							(this.rootStore.chatStore.getLastMessage(a.id)?.created.getTime() ?? 0)
					)[0]?.id
			);
		}

		return this.rootStore.chatStore.getLastMessage(
			channelsWithUnreadMessages.sort(
				(a, b) =>
					(this.rootStore.chatStore.getLastMessage(b.id)?.created.getTime() ?? 0) -
					(this.rootStore.chatStore.getLastMessage(a.id)?.created.getTime() ?? 0)
			)[0]?.id
		);
	}

	public get ProjectsLoaded() {
		return this.onProjectsLoaded.expose();
	}

	public get ProjectUpdated() {
		return this.onProjectUpdated.expose();
	}

	/* API calls */

	/** Returns project, if it's not loaded yet we try to load it
	 * @param  {String} id
	 * @returns {Project} project
	 */
	getProject(id: string | number): Project {
		const project = this.findProject(+id);
		if (project) {
			return project;
		}

		// ok, we don't have the project, but we can assume that we can load it - at least try
		// First - add it to the store to prevent a loop
		const newProject = this.createProject();
		newProject.id = +id;
		this.loadProject(newProject.id).catch((err) => {
			console.warn(`Error trying to load project ${id}`, err);
		});

		return newProject;
	}

	getProjectByChannelId(channelId?: string): Project | undefined {
		if (!channelId) {
			return undefined;
		}
		const project = this.projects.find((project: Project) => {
			return project.channels.find((channel: any) => channel.id?.toString() === channelId.toString());
		});

		return project;
	}

	/**
	 * Loads a project from server
	 * @param id
	 */
	async loadProject(id: string | number) {
		// check if w have removed the project
		if (this.removedProjectIds.includes(+id)) {
			return;
		}
		const result = await ProjectApi.getProject(+id);

		if (result.statusCode === 200) {
			runInAction(() => {
				this.updateProjectFromServer(result.data);
				this.onProjectsLoaded.trigger();
			});

			return this.findProject(+id);
		} else if (result.statusCode === 404 || result.statusCode === 401 || result.statusCode === 500) {
			// Something is wrong, either project does not exist, we don't have access or server crashed
			// We should remove the project from the store
			const project = this.findProject(+id);
			if (project) {
				this.removeProject(project);
			}
		}
	}

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	async processTrigger(trigger: Trigger<ProjectDTO>) {
		try {
			const { data } = trigger.event;
			if (!data?.id) {
				return;
			}
			this.rootStore.notificationStore.onProjectTrigger(trigger);
			switch (trigger.urn) {
				case TriggerType.PROJECT_CREATED:
					// @todo : cannot read property id  of undefined
					this.rootStore.uiState.playSound(SoundEffect.NEW_PROJECT);
					this.updateProjectFromServer(data);
					break;
				case TriggerType.PROJECT_UPDATED:
				case TriggerType.PROJECT_ASSIGNED:
				case TriggerType.PROJECT_CHANNEL_CREATED:
				case TriggerType.PROJECT_MEMBER_UPDATED:
				case TriggerType.PROJECT_STATUS_UPDATED:
					// Just update the project in place
					this.updateProjectFromServer(data);
					break;
				case TriggerType.PROJECT_FILE_ADDED:
				case TriggerType.PROJECT_FILE_REMOVED:
					// Reload project files
					this.loadFiles(data.id);
					break;
				case TriggerType.PROJECT_ARCHIVED:
					this.removeProject(this.getProject(data.id));
					break;
				default:
					console.log(`Processing project triggers are unsupported ${trigger.urn}`);
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	loadArchivedProjects = async (offset?: number) => {
		if (this.isLoadingProjectsArchive) {
			return;
		}
		this.isLoadingProjectsArchive = true;
		const computedOffset =
			offset ??
			this.projects.filter((p) => p.statusCategory === ProjectStatusCategory.Done || p.isArchived).length;
		const result = await ProjectApi.getProjectsArchive(computedOffset);
		if (result.statusCode === 200 && result.data) {
			runInAction(() => {
				result.data.forEach((json: any) => this.updateProjectFromServer(json));
			});
		}

		runInAction(() => (this.isLoadingProjectsArchive = false));
	};

	/**
	 * Load projects
	 */
	loadProjects = async (statusFilter: ProjectStatusDTO[] = []) => {
		// if isLoadingProjects is not undefined and it contains all statuses in filter
		// we can assume that we already have the projects
		if (this.isLoadingProjects && statusFilter.every((status) => this.isLoadingProjects?.includes(status))) {
			return;
		}
		// update statusfilter to only contain missing statuses to loadd
		statusFilter = statusFilter.filter((status) => !this.isLoadingProjects?.includes(status));

		this.isLoadingProjects = statusFilter;
		this.lastLoadedProjectsTime = Date.now();

		try {
			const result = await ProjectApi.getProjects(statusFilter);
			runInAction(() => {
				if (result.statusCode === 200 && result.data) {
					result.data.forEach((json: any) => this.updateProjectFromServer(json));
				}

				this.onProjectsLoaded.trigger();
			});
		} catch (e) {
			LogUtil.error(e);
		} finally {
			runInAction(() => (this.isLoadingProjects = undefined));
		}
	};

	/* Below is API operations on a project */

	async loadFiles(projectId: string | number) {
		const project = this.getProject(projectId);

		if (project) {
			const result = await ProjectApi.getFiles(+projectId);

			if (result.statusCode === 200) {
				runInAction(() => {
					project.updateFilesFromJson(result.data);
				});
			} else {
				// 404, 401 or perhaps 500?
			}
		}
	}

	/**
	 * @deprecated
	 * @param  {String} projectId
	 * @param  {String} fileRef
	 * @param  {String} comment?
	 */
	async addFile(projectId: number, fileId: string, comment?: string) {
		const result = await ProjectApi.addFile(projectId, fileId, comment);
		if (result.statusCode === 200) {
			return result.data;
		} else {
			// 404, 401 or 500? -> do something with it plz
		}

		return null;
	}

	/**
	 * Archive project
	 * @deprecated
	 */
	async archiveProject(projectId: string | number) {
		try {
			const result = await ProjectApi.archiveProject(+projectId);
			if (result.statusCode === 200) {
				const project = this.getProject(projectId);
				if (project) {
					this.removeProject(project);
				}

				return result.data;
			}

			return null;
		} catch (e) {
			LogUtil.error(e);
			return false;
		}
	}

	async grabProject(projectId: string) {
		const result = await ProjectApi.grabProject(projectId);
		if (result.statusCode === 200) {
			this.removeRequestedProject(this.getProject(projectId)!);
			this.updateProjectFromServer(result.data);

			return true;
		}

		return null;
	}

	/* Create a new project */

	async newProject(projectData: any, source?: string): Promise<Project | null> {
		// Missing workspaceId = project it up for grabs - first company/workspace to respond get's the project
		// If it's for a specific company make sure to attach the workspace id!

		const result = await ProjectApi.createProject(projectData, source);

		if (result.statusCode === 200) {
			const project = this.updateProjectFromServer(result.data);
			await this.loadProject(result.data.id);
			return project ?? null;
		}

		return null;
	}

	// @todo fix this: Cannot read properties of undefined
	async craftsmanCreateProject(project: any) {
		const result = await ProjectApi.craftsmanCreateProject(project);
		if (result.statusCode === 200 && result.data?.id) {
			try {
				runInAction(() => {
					this.updateProjectFromServer(result.data);
				});
			} catch (err) {
				LogUtil.error(err);
			}
		}

		return result;
	}

	/* Below methods does not use api calls - it's only operations in current store */
	// eslint-disable-next-line no-unused-vars
	getProjectRoleName(role?: string, _lang: string = 'no', profile?: Profile) {
		if (!role) {
			return '';
		}

		switch (role) {
			case 'INTERNAL':
				return profile?.organizationTitle ?? 'Ansatt';
			case 'EXTERNAL':
				return 'Samarbeidspartner';
			case 'ADMIN':
				return 'Ansvarlig';
			case 'OWNER':
				return 'Eier';
			case 'CUSTOMER':
				return 'Sluttkunde';
			case 'CONTACTPERSON':
				return 'Kontaktperson';
			default:
				return role;
		}
	}
	/**
	 * @deprecated use profileStore instead
	 * @param ownerId
	 * @returns
	 */
	getProjectOwnerProfile(ownerId: string | number) {
		return this.rootStore.profileStore.getProfile(ownerId);
	}

	/**
	 * @deprecated
	 * @param projectId
	 * @returns
	 */
	getNextMeeting(projectId: string) {
		const upcomingMeetings = this.getProject(projectId)?.meetings;

		if (!upcomingMeetings?.length) {
			return null;
		}

		let nextMeeting = upcomingMeetings[0];

		for (const meeting of upcomingMeetings) {
			if (meeting.start < nextMeeting.start) {
				nextMeeting = meeting;
			}
		}

		return nextMeeting;
	}

	createProject(id?: number) {
		const project = new Project(this, id);
		this.projects.push(project);
		return project;
	}

	findProject(id: number) {
		return this.projects.find((project) => project.id === +id);
	}

	updateProjectFromServer(json: any) {
		let project = this.findProject(json.id);

		if (!project) {
			project = new Project(this, json.id);
			this.projects.push(project);
		}

		project.updateFromJson(json);
		this.onProjectUpdated.trigger();

		return project;
	}

	updateRequestedProjectFromServer(json: any) {
		let project = this.requestedProjects.find((project: any) => project.id === '' + json.id);

		if (!project) {
			project = new Project(this, json.id);
			this.requestedProjects.push(project);
		}

		project.updateFromJson(json);
	}

	removeProject(project: Project) {
		if (project.id) {
			this.removedProjectIds.push(project.id);
		}
		console.info('Removing project', project.id, project.toDTO);
		project.deleted = new Date();
		const projectIndex = this.projects.findIndex((p) => p.id === project.id);
		if (projectIndex >= 0) {
			this.projects.splice(projectIndex, 1);
		}
		project.dispose();
	}

	removeRequestedProject(project: Project) {
		this.requestedProjects.splice(this.requestedProjects.indexOf(project), 1);
		project.dispose();
	}
}

export enum ProjectStatusCategory {
	// eslint-disable-next-line no-unused-vars
	Unprocessed = 'UNPROCESSED',
	// eslint-disable-next-line no-unused-vars
	ToDo = 'TODO',
	// eslint-disable-next-line no-unused-vars
	InProgress = 'IN_PROGRESS',
	// eslint-disable-next-line no-unused-vars
	Done = 'DONE',
}
