import React from 'react';
import Article from './Article';
import { Button, Stack, Typography } from '@mui/material';
import CompaniesNearUser from '../common/company/CompaniesNearUser';
import BefareIllustration from '../common/illustrations/BefareIllustration';
import { ServicesEnum } from '../home/generic/GenericServices';
import { Link } from 'react-router-dom';

export default class ElectricCarChargerArticle extends React.Component {
	render() {
		return (
			<Article>
				<Typography variant="h3" gutterBottom>
					Elbilladere og viktigheten av profesjonell elektrikerinstallasjon
				</Typography>
				<Typography variant="body1" gutterBottom>
					I dagens stadig mer miljøbevisste samfunn har elektriske kjøretøy (elbiler) blitt et attraktivt
					alternativ for mange bilentusiaster. Med lave driftskostnader og nullutslipp under kjøring, har
					elbiler blitt en fremtidsrettet transportløsning. Men for å holde disse strømdrevne kjøretøyene på
					veien, trenger vi pålitelige elbilladere. Og mens det kan være fristende å utføre installasjonen
					selv, er det flere gode grunner til å søke profesjonell hjelp fra en kvalifisert elektriker.
				</Typography>
				<Typography variant="h4" gutterBottom>
					Fremtidens løsning for ladning
				</Typography>
				<Typography variant="body1" gutterBottom>
					Elbilladere har blitt selve hjertet av elektrisk transport. Disse ladesystemene fungerer som
					"bensinstasjoner" for elbiler, og de finnes i ulike typer for å imøtekomme forskjellige behov.
					Hjemmeladere gir eierne muligheten til å lade elbilene sine i komforten av eget hjem, mens
					offentlige ladestasjoner gir muligheten til å lade opp på farten. Hurtigladere gir rask påfylling
					for lengre reiser.
				</Typography>
				<CompaniesNearUser />
				<Typography variant="h4" gutterBottom>
					Profesjonell hjelp
				</Typography>

				<Typography variant="body1" gutterBottom>
					Å få en elbillader installert er en investering i din elbil-ladeopplevelse, men det er viktig å
					erkjenne at elektriske installasjoner kan være komplekse og potensielt farlige.
				</Typography>
				<BefareIllustration illustration="electrician" sx={{ margin: '1rem auto', maxWidth: '300px' }} />
				<Typography variant="body1" gutterBottom>
					Her er noen grunner til hvorfor det er avgjørende å søke profesjonell hjelp fra en elektriker når du
					installerer en hjemmelader:
				</Typography>
				<Typography variant="h5" gutterBottom>
					Sikkerhet
				</Typography>
				<Typography variant="body1" gutterBottom>
					Elbilladere opererer med høy elektrisk strøm, og feilaktig installasjon kan føre til farlige
					situasjoner som elektriske støt eller brann. En elektriker har nødvendig kompetanse for å sikre at
					ladesystemet er trygt og korrekt installert.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Overholdelse av forskrifter
				</Typography>
				<Typography variant="body1" gutterBottom>
					Elektriske installasjoner er underlagt strenge forskrifter og standarder. En elektriker er kjent med
					disse retningslinjene og vil sørge for at installasjonen følger alle nødvendige krav.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Optimal ytelse
				</Typography>
				<Typography variant="body1" gutterBottom>
					Feilaktig installasjon kan føre til ineffektiv lading og lengre ladetider. En elektriker vil sikre
					riktig kabling og konfigurasjon for å oppnå best mulig ladeeffektivitet.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Garantibeskyttelse
				</Typography>
				<Typography variant="body1" gutterBottom>
					Mange hjemmeladere leveres med garantier som kan bli ugyldige hvis installasjonen ikke er utført av
					en profesjonell. Ved å bruke en elektriker, sikrer du at garantien forblir gyldig.
				</Typography>
				<Typography variant="h5" gutterBottom>
					Skreddersydde løsninger
				</Typography>
				<Typography variant="body1" gutterBottom>
					En elektriker kan tilpasse installasjonen etter dine spesifikke behov, inkludert plassering,
					ladeteknologi og tilpasning til eksisterende elektriske systemer.
				</Typography>

				<Typography variant="body1" gutterBottom>
					Å ha en elbillader er en smart investering for elbil-eiere, men det er avgjørende å velge en
					profesjonell elektriker for installasjonen. Dette sikrer både optimal ytelse og viktigst av alt, din
					egen sikkerhet. Med en profesjonell elektriker ved roret, kan du lade din elbil med trygghet og
					tillit, mens du bidrar til en mer bærekraftig fremtid.
				</Typography>

				<Stack spacing={2} direction="row" sx={{ marginTop: '2rem' }}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{
							borderRadius: 16,
							letterSpacing: '1px',
							boxShadow: '0 2px 4px rgba(0,0,0,.26)',
						}}
						component={Link}
						to={`/quote/${ServicesEnum.CarCharger}`}
					>
						Få pristilbud
					</Button>
					<Button
						variant="outlined"
						color="primary"
						component={Link}
						to={`/quote/${ServicesEnum.FreeInspection}`}
					>
						Gratis befaring
					</Button>
				</Stack>
			</Article>
		);
	}
}
