/* eslint-disable no-unused-vars */
export enum ESingleValueStatKey {
	// Project-related stats
	ProjectsUnprocessed = 'project.unprocessed', // Count
	ProjectsCompleted = 'project.completed', // Count
	ProjectsUnhandledCustomerMessages = 'project.unhandledCustomerMessages', // Count
	ProjectsInProgress = 'project.inProgress', // Count
	ProjectsDelegated = 'project.delegated', // Count
	ProjectsUnprocessedValue = 'project.unprocessedValue', // Value
	ProjectsInProgressValue = 'project.inProgressValue', // Value
	ProjectsHoursLeft = 'project.hoursLeft', // Hours
	ProjectsAtRisk = 'project.atRisk', // Count
	AverageProjectCompletionTime = 'project.averageCompletionTime', // Hours or Days
	UnprocessedJobs = 'project.unprocessedJobs', // Count
	PotentialLossValue = 'project.potentialLossValue', // Value

	// Chat-related stats
	ChatPartnerChannels = 'chat.partnerChannels', // Count
	ChatCustomerChannels = 'chat.customerChannels', // Count
	ChatUnreadPartnerMessages = 'chat.unreadPartnerMessages', // Count

	// Customer-related stats
	NewCustomers = 'customer.newCustomers', // Count
	CustomerSatisfaction = 'customer.satisfaction', // Rating (e.g., out of 5 or 10)
	UnhandledCustomerMessages = 'customer.unhandledMessages', // Count
	AverageResponseTime = 'customer.averageResponseTime', // Hours or Days

	// Employee-related stats
	EmployeeUtilizationRate = 'employee.utilizationRate', // Percentage
	TopPerformingEmployees = 'employee.topPerformers', // Count

	// Financial stats
	RevenueGenerated = 'finance.revenueGenerated', // Value
	ValueOfCompletedJobs = 'finance.valueOfCompletedJobs', // Value
	ValueOfLostJobs = 'finance.valueOfLostJobs', // Value
	ValueOfOffersSent = 'finance.valueOfOffersSent', // Value
	ValueOfOffersRejected = 'finance.valueOfOffersRejected', // Value
	ValueOfJobsInLimbo = 'finance.valueOfJobsInLimbo', // Value
	EstimatedBudget = 'finance.estimatedBudget', // Value
	EstimatedHoursOfWork = 'finance.estimatedHoursOfWork', // Hours
	RevenueForecast = 'finance.revenueForecast', // Value

	// Traffic-related stats
	TotalJobsInArea = 'traffic.totalJobsInArea', // Count
	JobsForCompany = 'traffic.jobsForCompany', // Count
	ConversionRate = 'traffic.conversionRate', // Percentage
	TotalLeads = 'traffic.totalLeads', // Count

	// Partner-related stats
	DelegatedProjectsInProgress = 'partner.delegatedProjectsInProgress', // Count
	DelegatedProjectsCompleted = 'partner.delegatedProjectsCompleted', // Count
	DelegatedProjectsValue = 'partner.delegatedProjectsValue', // Value

	// Chain-related stats
	TotalJobsForChain = 'chain.totalJobs', // Count
	TotalRevenueForChain = 'chain.totalRevenue', // Value
	TotalLeadsForChain = 'chain.totalLeads', // Count
	TotalUnprocessedJobsForChain = 'chain.totalUnprocessedJobs', // Count
}
