import { AppBar, Button, Link as MuiLink, Toolbar, useScrollTrigger } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import LoginButton from '../common/LoginButton';
import ProfilePicture from '../common/ProfilePicture';
import './NavigationBar.scss';
import { AppId } from '../../config/appId.enum';
import config from '../../config/config';
import { HookTypes, withLocation } from '../../utils/withHooks';

function ElevationScroll(props: {
	children: React.ReactElement;
	color?: 'default' | 'transparent' | 'primary' | 'secondary';
	defaultTextColor?: 'textPrimary' | 'textSecondary' | 'white';
}) {
	const { children, color, defaultTextColor } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	let textColor = defaultTextColor ?? 'textPrimary';
	if (trigger && ['primary', 'secondary'].includes(color ?? 'default')) {
		textColor = 'white';
	}

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
		color: trigger ? color ?? 'default' : 'transparent',
		sx: {
			color: textColor,
		},
	});
}

type Props = Pick<HookTypes, 'location'>;

const NavigationBar = observer(
	class NavigationBar extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get location() {
			return this.props.location!;
		}

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				workspaceStore: computed,
				companyStore: computed,
				userStore: computed,
				profileStore: computed,
				uiState: computed,
				isHomeScreen: computed,
				isCompanyPage: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get isHomeScreen() {
			const pathname = this.location.pathname;
			return pathname === '/' || pathname === '/home';
		}

		get isCompanyPage() {
			const pathname = this.location.pathname;
			// check if the current page matches /about/<company name>
			return pathname.startsWith('/about/');
		}

		renderLinks() {
			let companiesLinkLabel = `Finn ${this.uiState.entityName}`;
			let companiesLinkColor: 'primary' | 'secondary' = 'primary';
			if (config.appId === AppId.Smart) {
				companiesLinkLabel = 'Get in touch';
				companiesLinkColor = 'secondary';
			}

			if (config.appId === AppId.MinElektriker) {
				companiesLinkLabel = 'Chat med elektriker';
				companiesLinkColor = 'secondary';
			}

			const links: any = [
				{ label: 'Forside', to: '/home' },
				{ label: 'Slik fungerer det', to: '/how-it-works' },
				{ label: 'Send forespørsel', to: '/order-chat' },
			];

			if (this.userStore.isLoggedIn) {
				links.push({
					label: this.profileStore.isCraftsman ? 'Dashbord' : 'Min side',
					to: '/app/dashboard',
				});
			}

			if (!this.isCompanyPage) {
				links.push({ label: companiesLinkLabel, to: '/companies', type: 'button' });
			}

			return links.map((link: any, index: number) => {
				if (link.type === 'button') {
					return (
						<Button
							variant="contained"
							color={companiesLinkColor}
							component={Link}
							key={index}
							to={link.to}
							sx={{
								justifySelf: 'flex-end',
								borderRadius: 16,
								letterSpacing: '1px',
								boxShadow: '0 2px 4px rgba(0,0,0,.26)',
							}}
						>
							{link.label}
						</Button>
					);
				}
				return (
					<MuiLink
						color="inherit"
						component={Link}
						underline="hover"
						key={index}
						to={link.to}
						variant="subtitle1"
					>
						{link.label}
					</MuiLink>
				);
			});
		}

		render() {
			const { isLoggedIn } = this.userStore;
			const { currentUserProfile } = this.profileStore;

			let logo = '/logo/befare-black.png';
			switch (config.appId) {
				case AppId.Smart:
					if (this.isHomeScreen) {
						logo = '/logo/befare-white.png';
					}
					break;
				case AppId.Befare:
					logo = '/logo/befare-black.png';
					break;
				case AppId.MinElektriker:
					logo = '/logo/norgeseliten.png';
					break;
				case AppId.MinRorlegger:
					logo = '/logo/min-rorlegger.png';
					break;
			}

			const isBefareSmart = config.appId === AppId.Smart;
			let defaultTextColor: 'textPrimary' | 'textSecondary' | 'white' | undefined = 'textPrimary';
			if (isBefareSmart) {
				// if route is home or / then use white text
				if (this.isHomeScreen) {
					defaultTextColor = 'white';
				}
			}

			return (
				<ElevationScroll color={isBefareSmart ? 'primary' : 'default'} defaultTextColor={defaultTextColor}>
					<AppBar
						elevation={1}
						position="sticky"
						className="NavigationBar"
						sx={{
							color: 'inherit',
						}}
					>
						<Toolbar>
							<MuiLink component={Link} underline="none" to="/" className="NavigationBar__logo">
								<img src={logo} alt="logo" />
							</MuiLink>
							<div className="spacer"></div>
							{this.renderLinks()}
							<div className="spacer"></div>

							{isLoggedIn ? (
								<ProfilePicture component={Link} profile={currentUserProfile} to="/dashboard" />
							) : (
								<LoginButton />
							)}
						</Toolbar>
					</AppBar>
				</ElevationScroll>
			);
		}
	}
);

export default withLocation(NavigationBar);
