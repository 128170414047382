import { makeAutoObservable } from 'mobx';
import PartnershipApi from '../../api/endpoints/PartnershipApi';
import { PartnershipDTO } from './Partnership.types';

// based on PartnershipDTO
export default class Partnership {
	partnershipId!: number;
	workspaceId1!: number;
	workspaceId2!: number;
	status!: string;
	listingId: number | null = null;
	created!: Date;
	updated!: Date;
	approvalDate: Date | null = null;
	reviewedBy: number | null = null;
	reviewedOn: Date | null = null;
	validUntil: Date | null = null;
	tags: string[] = [];

	constructor(partnership: PartnershipDTO) {
		makeAutoObservable(this);
		this.update(partnership);
	}

	async reject() {
		// Reject the request
		const result = await PartnershipApi.rejectPartnership(this.partnershipId);
		if (result.statusCode === 200) {
			this.update(result.data);
			return true;
		}
		return false;
	}

	async saveTags() {
		// Save the tags
		const result = await PartnershipApi.saveTags(this.partnershipId, this.tags);
		if (result.statusCode === 200) {
			this.update(result.data);
			return true;
		}
		return false;
	}

	update(partnership: PartnershipDTO) {
		this.partnershipId = partnership.partnershipId!;
		this.workspaceId1 = partnership.workspaceId1;
		this.workspaceId2 = partnership.workspaceId2;
		this.status = partnership.status;
		this.listingId = partnership.listingId;
		this.created = new Date(partnership.created);
		this.updated = new Date(partnership.updated);
		this.approvalDate = partnership.approvalDate ? new Date(partnership.approvalDate) : null;
		this.reviewedBy = partnership.reviewedBy;
		this.reviewedOn = partnership.reviewedOn ? new Date(partnership.reviewedOn) : null;
		this.validUntil = partnership.validUntil ? new Date(partnership.validUntil) : null;
		this.tags = partnership.tags ?? [];
	}
}
