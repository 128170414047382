import { Button, Typography } from '@mui/material';
import { makeObservable, observable, computed, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import AuthManager from '../../../auth/AuthManager';
import { RootStore } from '../../../stores/RootStore';
import StoreContext from '../../../stores/StoreContext';
import ResendVerificationCodeButton from '../../login/ResendVerificationCodeButton';
import WorkspaceList from '../../login/WorkspaceList';
import PhoneNumberTextField from '../PhoneNumberTextField';
import VerificationCodeTextField from '../VerificationCodeTextField';

import './LoginComponent.scss';

type Props = {
	onSignInDone?: () => void;
};

const LoginComponent = observer(
	class LoginComponent extends React.Component<Props> {
		static readonly contextType = StoreContext;
		phoneNumber: string = '';
		verificationCode: string = '';
		step: string = 'phone';
		errorMessage: string = '';

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				step: observable,
				phoneNumber: observable,
				errorMessage: observable,
				verificationCode: observable,
				uiState: computed,
				userStore: computed,
				handlePhoneNumberChange: action,
				handleVerificationChange: action,
			});
		}

		componentDidMount() {
			if (this.userStore.isLoggedIn) {
				runInAction(() => {
					this.step = 'workspace';
				});
			} else {
				this.userStore.SignedIn.on(() => {
					runInAction(() => {
						this.step = 'workspace';
					});
				});
			}
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.phoneNumber = event.target.value;
		};

		handlePhoneSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			try {
				const loginStatus = await AuthManager.phoneAuth(undefined, this.phoneNumber);
				console.log(loginStatus);
				runInAction(() => {
					this.step = 'verify';
				});
			} catch (error) {
				console.error(error);
			}
		};

		handleVerificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.verificationCode = event.target.value;
		};
		handleVerificationSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			try {
				const loginStatus = await AuthManager.phoneAuthVerify(
					undefined,
					this.phoneNumber,
					this.verificationCode
				);
				console.log(loginStatus);
				this.companyStore.loadCompanies();
				runInAction(() => {
					this.step = 'workspace';
				});
			} catch (error) {
				console.error(error);
			}
		};

		renderPhoneNumberStep() {
			const { isComputer, isTablet, isPortraitOrientation, isMobile } = this.uiState;
			return (
				<>
					<Typography
						align="center"
						gutterBottom
						variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
					>
						<span className="text--bold text--italic text--red">Logg inn</span> for å aktivere denne appen
					</Typography>
					<Typography
						align="center"
						gutterBottom
						variant={isMobile || (isTablet && isPortraitOrientation) ? 'body2' : 'h6'}
					>
						Vi sender deg en bekreftelseskode for å bekrefte identiteten din
					</Typography>

					<form onSubmit={this.handlePhoneSubmit}>
						<PhoneNumberTextField
							autoFocus={isComputer}
							fullWidth
							margin="normal"
							onChange={this.handlePhoneNumberChange}
							required
							value={this.phoneNumber}
							className="login-input"
						/>
						<Button color="primary" fullWidth type="submit" variant="contained">
							Send kode
						</Button>
					</form>
				</>
			);
		}

		renderVerifyStep() {
			const { isComputer, isMobile, isTablet, isPortraitOrientation } = this.uiState;
			return (
				<>
					<Typography
						align="center"
						gutterBottom
						variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
					>
						Vi har sendt en bekreftelseskode til{' '}
						<span className="text--bold text--italic text--red">+47{this.phoneNumber}</span>
					</Typography>

					<form className="verification-code-form" onSubmit={this.handleVerificationSubmit}>
						<VerificationCodeTextField
							autoFocus={isComputer}
							error={Boolean(this.errorMessage)}
							fullWidth
							helperText={this.errorMessage}
							margin="normal"
							onChange={this.handleVerificationChange}
							required
							value={this.verificationCode}
							className="login-input"
						/>
						<div className="buttons-container">
							<Button color="primary" fullWidth type="submit" variant="contained">
								Logg inn
							</Button>
							<ResendVerificationCodeButton phoneNumber={this.phoneNumber} className="login-btn" />
						</div>
					</form>
				</>
			);
		}

		onWorkspaceSelected = () => {
			if (this.props.onSignInDone) {
				this.props.onSignInDone();
			}
		};

		renderWorkspaceStep() {
			return <WorkspaceList preventAutoNavigate={true} onWorkspaceSelected={this.onWorkspaceSelected} />;
		}

		render() {
			return (
				<div className="LoginComponent">
					{this.step == 'phone' && this.renderPhoneNumberStep()}
					{this.step == 'verify' && this.renderVerifyStep()}
					{this.step == 'workspace' && this.renderWorkspaceStep()}
				</div>
			);
		}
	}
);

export default LoginComponent;
