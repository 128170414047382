import { makeAutoObservable } from 'mobx';
import { CustomerDTO } from '../../api/endpoints/CustomerApi';

export default class Customer {
	customerId: number = 0;
	numProjects: number = 0;
	firstProjectDate: Date = new Date();
	avgRating: number | null = null;
	projectIds: number[] = []; // This will be a comma-separated string of project IDs
	totalEstimatedBudget: number = 0;
	doneProjects: number = 0;
	toDoProjects: number = 0;
	unprocessedProjects: number = 0;
	inProgressProjects: number = 0;
	lastInteractionDate: Date | null = null; // ISO 8601 date string
	conversionRate: number = 0; // Represented as a percentage
	unrealizedValue: number = 0;
	realizedValue: number = 0;
	lostValue: number = 0;

	constructor(data: CustomerDTO) {
		this.updateFromDTO(data);

		makeAutoObservable(this);
	}

	// make some computed properties
	get totalProjects() {
		return this.doneProjects + this.toDoProjects + this.unprocessedProjects + this.inProgressProjects;
	}

	get totalValue() {
		return this.unrealizedValue + this.realizedValue + this.lostValue;
	}

	updateFromDTO(data: CustomerDTO) {
		this.customerId = data.customerId;
		this.numProjects = data.numProjects;
		this.firstProjectDate = new Date(data.firstProjectDate);
		this.avgRating = data.avgRating ? +data.avgRating : null;
		this.projectIds = data.projectIds;
		this.totalEstimatedBudget = +data.totalEstimatedBudget;
		this.doneProjects = +data.doneProjects;
		this.toDoProjects = +data.toDoProjects;
		this.unprocessedProjects = +data.unprocessedProjects;
		this.inProgressProjects = +data.inProgressProjects;
		this.lastInteractionDate = data.lastInteractionDate ? new Date(data.lastInteractionDate) : null;
		this.conversionRate = +data.conversionRate;
		this.unrealizedValue = +data.unrealizedValue;
		this.realizedValue = +data.realizedValue;
		this.lostValue = +data.lostValue;
	}
}
