import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

export type TPeriodicalStats = {
	current: number;
	previous: number;
	delta: number;
	deltaPercent: number;
};

export type TComputedChildWorkspaceStats = {
	co2: TPeriodicalStats;
	digitalInspectionSavings: TPeriodicalStats;
	estimatedRevenue: TPeriodicalStats;
	estimatedLostRevenue: TPeriodicalStats;
	incomingJobs: TPeriodicalStats;
	completedJobs: TPeriodicalStats;
	ongoingJobs: TPeriodicalStats;
	lostJobs: TPeriodicalStats;
	unprocessedJobs: TPeriodicalStats;
	winRate: TPeriodicalStats;
	avgResponseTime: TPeriodicalStats;
	avgRating: TPeriodicalStats;
	numReviews: TPeriodicalStats;
};

export type YearMonthDayStats = {
	year: number;
	month: number;
	day: number;
};

export type OrderChatByDay = YearMonthDayStats & {
	jobCreated: number;
	jobLost: number;
	foundCompany: number;
	date: string;
};

export type JobsBySource = {
	befare: number;
	craftsman: number;
	customer: number;
	api: number;
};

export type JobsBySourcePeriod = [
	{
		num: number;
		label: string;
		period: string;
	}
];

export type TWorkspaceStats = {
	workspaceId: string;
	name?: string;
	domain?: string;
	numMessages: number;
	numUnreadCustomerMessages: number;
	customerResponseTime: number;
	numCustomerCreatedProjects: number;
	numCraftsmanCreatedProjects: number;
	numUnprocessedProjects: number;
	billableSms: number;
	videoSeconds: {
		sessions: number;
		seconds: number;
	};
	viewsCompanyPage: number;
	hitsInRadius: {
		views: number;
		foundCompany: number;
		jobCreated: number;
		radius: number;
	};
	hitsInExtendedRadius: {
		views: number;
		foundCompany: number;
		jobCreated: number;
		radius: number;
	};
	numBillableSmsSent: number;
	avgRating?: number;
	numReviews?: number;
	computedScore: string;
	periodDays: number;
};

type ChildWorkspaceStatsResponse = { hits: number; stats: TWorkspaceStats[] };

export type HeatmapPoint = {
	lat: number;
	lon: number;
	radius: number;
	title: string;
};

export type JobsHeatmapResponse = {
	workspaceId: string;
	heatPoints: HeatmapPoint[];
	maskPoints: HeatmapPoint[];
	partnerLocations: HeatmapPoint[];
};

/**
 * Insight
 */
export default class InsightApi {
	static async jobsHeatmap(tags: string[] = []): Promise<FetchWrapperTypedResponse<JobsHeatmapResponse>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/jobs/heatmap`;

		return FetchWrapper.typedPost<JobsHeatmapResponse>(url, {
			requireAuth: true,
			body: JSON.stringify({ tags }),
		});
	}

	static async jobsHeatmapTags(): Promise<FetchWrapperTypedResponse<string[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/jobs/heatmap/tags`;

		return FetchWrapper.typedGet<string[]>(url, {
			requireAuth: true,
		});
	}

	static async orderChatStats(
		from: number,
		to: number,
		includeChildWorkspaces: boolean = false
	): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/trak/orderChat`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				from,
				to,
				includeChildWorkspaces,
			}),
			requireAuth: true,
		});
	}

	static async recentOrderChatTrakEvents(includeChildWorkspaces: boolean = false): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/trak/orderChat/recent${
			includeChildWorkspaces ? '?includeChildWorkspaces=true' : ''
		}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	// eslint-disable-next-line no-unused-vars
	static async jobsBySourcePeriod(
		fromTimestamp: number,
		toTimestamp: number,
		// eslint-disable-next-line no-unused-vars
		includeChildWorkspaces: boolean = true
	): Promise<FetchWrapperTypedResponse<{ hits: number; stats: JobsBySourcePeriod }>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/workspaces/stats/project/period`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async jobsBySource(
		from: number,
		to: number,
		includeChildWorkspaces: boolean = false
	): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/jobs/source`;

		return FetchWrapper.post(url, {
			requireAuth: true,
			body: JSON.stringify({
				from,
				to,
				includeChildWorkspaces,
			}),
		});
	}

	static async computedStats(
		from: number,
		to: number
	): Promise<FetchWrapperTypedResponse<{ stats: TComputedChildWorkspaceStats; from: number; to: number }>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/workspaces/stats/computed`;

		return FetchWrapper.post(url, {
			requireAuth: true,
			body: JSON.stringify({
				from,
				to,
			}),
		});
	}

	static async childWorkspacesStats(
		periodDays: number = 30
	): Promise<FetchWrapperTypedResponse<ChildWorkspaceStatsResponse>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/workspaces/stats?periodDays=${periodDays}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async childWorkspaceStats(
		workspaceId: string,
		periodDays: number = 30
	): Promise<FetchWrapperTypedResponse<TWorkspaceStats>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/insight/workspaces/stats/${workspaceId}?periodDays=${periodDays}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}
}
