import KobbrQuotesApi from "../../api/endpoints/KobbrApi";
import { KobbrQuoteType, KobbrQuoteStatus, KobbrQuoteDTO, PartialKobbrQuoteDTO } from "../../dto/kobbr.types";


class KobbrQuote {
	id!: number;
	quoteId!: string;
	quoteNum!: string;
	projectId!: number;
	workspaceId!: number;
	quoteType!: KobbrQuoteType;
	name?: string;
	description?: string;
	template!: string;
	externalReference!: string;
	status!: KobbrQuoteStatus;
	numOpens!: number;
	sentDate?: Date;
	expiresDate?: Date;
	created!: Date;
	updated!: Date;
	totalAmountExVat?: number;

	constructor(quoteData: Partial<KobbrQuoteDTO>) {
		this.updateFromDTO(quoteData);
	}

	/**
	 * Updates the quote data from a given partial DTO.
	 * @param quoteData The partial data to update the quote.
	 */
	updateFromDTO(quoteData: PartialKobbrQuoteDTO): void {
		type QuoteKeys = keyof KobbrQuote; // This creates a type representing all keys of KobbrQuote

		Object.entries(quoteData).forEach(([key, value]) => {
			if (value !== undefined && key in this) {
				const quoteKey = key as QuoteKeys; // Type casting key to be one of the keys of KobbrQuote

				if (quoteKey === 'sentDate' || quoteKey === 'expiresDate' || quoteKey === 'created' || quoteKey === 'updated') {
					if (typeof value === 'string') {
						this[quoteKey] = new Date(value);
					}
				} else {
					this[quoteKey] = value as never; // Change the type of value to never
				}
			}
		});
	}

	/**
	 * Fetches the edit link for this quote.
	 * @returns {Promise<string | undefined>} The edit link or undefined if not available.
	 */
	async fetchEditLink(): Promise<string | undefined> {
		try {
			const response = await KobbrQuotesApi.getEditQuoteMagicLink(this.quoteId);
			return response.data?.editLink;
		} catch (error) {
			// Handle error (e.g., log or throw)
			console.error(error);
			return undefined;
		}
	}

	/**
	 * Fetches the open link for this quote.
	 * @returns {Promise<string | undefined>} The open link or undefined if not available.
	 */
	async fetchOpenLink(): Promise<string | undefined> {
		try {
			const response = await KobbrQuotesApi.getOpenQuoteMagicLink(this.quoteId);
			return response.data?.openLink;
		} catch (error) {
			// Handle error (e.g., log or throw)
			console.error(error);
			return undefined;
		}
	}

	/**
	 * Converts the KobbrQuote instance back to a DTO.
	 * @returns The DTO representation of the quote.
	 */
	toDTO(): KobbrQuoteDTO {
		return {
			id: this.id,
			quoteId: this.quoteId,
			quoteNum: this.quoteNum,
			projectId: this.projectId,
			workspaceId: this.workspaceId,
			quoteType: this.quoteType,
			name: this.name,
			description: this.description,
			template: this.template,
			externalReference: this.externalReference,
			status: this.status,
			numOpens: this.numOpens,
			sentDate: this.sentDate?.toISOString(),
			expiresDate: this.expiresDate?.toISOString(),
			created: this.created.toISOString(),
			updated: this.updated.toISOString(),
			totalAmountExVat: this.totalAmountExVat,
		};
	}
}

export default KobbrQuote;
