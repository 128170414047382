import { Button, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import StoreContext from '../../stores/StoreContext';
import { Address } from '../../types';
import ElevatedCard from './ElevatedCard';
import MapFrame from './MapFrame';
import './AddressCard.scss';
import AddressText from './AddressText';
import { RootStore } from '../../stores/RootStore';
import AddressAutoCompleteTextfield from './AddressAutocompleteTextfield';

type Props = {
	address?: Address;
	origin?: Address;
	fullWidth?: boolean;
	preventSave?: boolean;
	editOnInit?: boolean;
	onAddressSaved?: any;
	style?: React.CSSProperties;
	// eslint-disable-next-line no-unused-vars
	onChange?: (address: Address) => void;
};

const AddressCard = observer(
	class AddressCard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		_address?: Address;

		newAddress: Address = {
			country: 'Norge',
			municipality: '',
			county: '',
			region: '',
			coordinate: '',
			street: '',
			postCode: '',
			postArea: '',
			addressString: '',
		};
		editMode: boolean = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				_address: observable,
				editMode: observable,
				address: computed,
				rootStore: computed,
				profileStore: computed,
				editChipLabel: computed,
				toggleEdit: action,
				save: action,
				handleAddressChange: action,
				init: action,
			});

			this.toggleEdit = this.toggleEdit.bind(this);
			this.save = this.save.bind(this);
		}

		componentDidMount() {
			this.init();
		}

		componentDidUpdate(prevProps: Props) {
			if (prevProps.address !== this.props.address) {
				this.init();
			}
		}

		init() {
			if (this.props.editOnInit) {
				this.editMode = this.props.editOnInit;
			}

			if (this.props.address) {
				this._address = this.props.address;
			}
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get editChipLabel(): string {
			return this.address.street ? 'Endre' : 'Legg til';
		}

		get address(): Address {
			return this._address ?? this.props.address ?? this.newAddress;
		}

		toggleEdit() {
			this.editMode = !this.editMode;
		}

		async save() {
			try {
				let address = null;

				if (!this.props.preventSave) {
					address = await this.profileStore.saveUsersAddress(this.address);
				}

				this.editMode = false;

				if (this.props.onAddressSaved) {
					this.props.onAddressSaved(address || this.address);
				}
			} catch (e) {
				console.error('failed saving address', e);
			}
		}

		handleAddressChange = (address: Address) => {
			this._address = address;

			if (this.props.onChange) {
				this.props.onChange(address);
			}
		};

		renderAddressCardContent() {
			if (this.address.addressString || this.address.street) {
				return <AddressText address={this.address} />;
			}

			return (
				<Typography color="textSecondary" variant="body2">
					Legg til ny adresse
				</Typography>
			);
		}

		render() {
			const { fullWidth, style, origin } = this.props;

			return (
				<ElevatedCard className={fullWidth ? 'AddressCard fullWidth' : 'AddressCard'} style={style}>
					{this.editMode ? (
						<>
							<CardContent>
								<Stack spacing={2}>
									<AddressAutoCompleteTextfield
										value={this.address}
										onChange={this.handleAddressChange}
										disableSave
									/>
									<Typography variant="body2" gutterBottom>
										<strong>Tips:</strong> start med postnummer og husnummeret før du skriver
										gatenavnet.
									</Typography>
								</Stack>
							</CardContent>
							<CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									color="success"
									fullWidth
									onClick={this.save}
									variant="contained"
									disabled={!this.address.street}
								>
									Bekreft
								</Button>
							</CardActions>
						</>
					) : (
						<>
							{this.address.street && this.address.postCode && this.address.postCode && (
								<CardMedia>
									<MapFrame
										address={origin ?? this.address}
										destination={origin ? this.address : undefined}
									/>
								</CardMedia>
							)}
							<CardContent>{this.renderAddressCardContent()}</CardContent>
							<CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button color="primary" variant="outlined" onClick={this.toggleEdit}>
									{this.editChipLabel}
								</Button>
							</CardActions>
						</>
					)}
				</ElevatedCard>
			);
		}
	}
);

export default AddressCard;
