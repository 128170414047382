const features = {
	'demo': false,
	'ai.chat': true,
	'ai.email': false,
	'event.invite': true,
	'video.recorder': false,
	'create.workspace': 'alpha',
	'befare.next': 'alpha',
	'project.wizard': false,
	'document.signing': false,
	'minelektriker-norgeseliten': true,
	'sms.custom': true,
	'jobs.map': false,
	'project.checklist': true,
	'checklist.create': false,
	'project.checklist.execution.add': false,
};

export default features;
