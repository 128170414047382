import { Button, Container, Stack, StyledComponentProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { HookTypes, withHooks } from '../../utils/withHooks';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';

const PREFIX = 'LoginMagicLinkSent';

const classes = {
	root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme: { mixins, breakpoints } }) => ({
	[`& .${classes.root}`]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '50vh',
		marginBottom: mixins.toolbar.minHeight,
		[breakpoints.up('lg')]: {
			minHeight: '83vh',
		},
	},
}));

type Props = HookTypes & StyledComponentProps;

const LoginMagicLinkSent = observer(
	class LoginMagicLinkSent extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				ingressText: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get ingressText() {
			return this.userStore.isLoggedIn
				? 'Litt rart kanskje, men vi ser at du allerede er logget inn.'
				: 'Sjekk e-posten din for å logge inn.';
		}

		render() {
			const { isMobile, isTablet, isPortraitOrientation } = this.uiState;
			const { isLoggedIn } = this.userStore;

			return (
				<Root>
					<Container classes={{ root: classes.root }} className="LoginMagicLinkSent">
						<Stack spacing={2}>
							<Typography
								align="center"
								gutterBottom
								variant={isMobile || (isTablet && isPortraitOrientation) ? 'h5' : 'h3'}
							>
								Vi har sendt en <span className="text--bold text--italic text--red">Magisk Lenke</span>{' '}
								til deg!
							</Typography>
							<Typography
								align="center"
								color={'textSecondary'}
								gutterBottom
								variant={isMobile || (isTablet && isPortraitOrientation) ? 'body2' : 'h6'}
							>
								{this.ingressText}
							</Typography>
							{isLoggedIn && (
								<Button component={Link} to="/dashboard" variant="contained">
									Gå til dashbord
								</Button>
							)}
							<Typography variant="subtitle2" sx={{ marginTop: '.8rem' }} align="center">
								Logg inn med {''}
								<Typography component={Link} to={'/sign-in'} variant="subtitle2">
									mobil
								</Typography>
							</Typography>
						</Stack>
					</Container>
					<Footer />
				</Root>
			);
		}
	}
);

export default withHooks(LoginMagicLinkSent);
