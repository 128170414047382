import { observer } from 'mobx-react';
import { Address } from '../../types';
import React from 'react';
import { action, makeObservable, observable, toJS } from 'mobx';
import { Autocomplete, Stack, TextField } from '@mui/material';
import ProjectApi from '../../api/endpoints/ProjectApi';
import { debounce } from 'lodash';

type Props = {
	value?: Address;
	// eslint-disable-next-line no-unused-vars
	onChange: (address: Address) => void;
	disableSave?: boolean;
};

const AddressAutoCompleteTextfield = observer(
	class AddressAutoCompleteTextfield extends React.Component<Props> {
		value: Address | null = null;
		inputValue: string = '';
		options: Address[] = [];
		// eslint-disable-next-line no-unused-vars
		findAddressDebounced: (value: string) => void;

		constructor(props: Props) {
			super(props);

			this.findAddressDebounced = debounce((value: string) => this.findAddress(value), 500);

			makeObservable(this, {
				value: observable,
				inputValue: observable,
				options: observable,
				findAddress: action,
				findAddressDebounced: action,
				setOptions: action,
				setValue: action,
				updateFromProps: action,
				onFormChange: action,
				onInputChange: action,
				saveAddress: action,
			});

			this.updateFromProps();
		}

		componentDidUpdate(prevProps: Readonly<Props>): void {
			if (prevProps.value !== this.props.value && this.props.value?.id) {
				this.updateFromProps();
			}
		}

		updateFromProps = () => {
			const value = this.props.value;
			if (value?.id) {
				this.options = [value];
				this.inputValue = value?.addressString ? `${value.street}, ${value.postCode} ${value.postArea}` : '';
				this.value = value;
			}
		};

		findAddress = async (address: string) => {
			if (address.length > 3) {
				const response = await ProjectApi.AddressSearch(`${address}*`);
				if (response.statusCode === 200) {
					if (response.data.hits) {
						const options: Address[] = response.data.hits.map((hit: any) => ({
							street: hit.adressetekst,
							postCode: hit.postnummer,
							postArea: hit.poststed,
							lat: hit.representasjonspunkt?.lat,
							lon: hit.representasjonspunkt?.lon,
							municipality: hit.kommunenavn,
						}));

						this.setOptions(options);
						return;
					}
				}
			}

			this.setOptions([]);
		};

		setValue = (value: Address | null) => {
			this.value = value;
		};

		setOptions = (options: Address[]) => {
			this.options = options;
		};

		onFormChange = (event: any, address: Address | null) => {
			this.value = address;
			if (!this.props.disableSave) {
				this.saveAddress()
					.then(() => {
						this.props.onChange(this.value as Address);
					})
					.catch(console.error);
			} else {
				this.props.onChange(address as Address);
			}
		};

		saveAddress = async () => {
			if (this.value) {
				const result = await ProjectApi.saveAddress(this.value);
				if (result.statusCode === 200) {
					this.setValue(result.data);
				} else {
					console.error('Failed to save address', result);
					throw new Error('Failed to save address');
				}
			}
		};

		onInputChange = (event: any, newInputValue: string) => {
			if (this.inputValue === newInputValue) {
				return;
			}
			this.inputValue = newInputValue;
			this.value = null;
			this.findAddressDebounced(newInputValue);
		};

		render() {
			const hasError = this.value === null && this.inputValue.length > 0;
			const options = toJS(this.options);
			const value = toJS(this.value);
			return (
				<Stack spacing={1} sx={{ marginBottom: '2rem' }}>
					<Autocomplete
						data-hj-suppress
						id="address-lookup"
						fullWidth
						getOptionLabel={(option) =>
							typeof option === 'string'
								? option
								: `${option?.street}, ${option?.postCode} ${option?.postArea}`
						}
						filterOptions={(x) => x}
						options={options}
						autoComplete
						includeInputInList
						filterSelectedOptions
						value={value}
						isOptionEqualToValue={(option: any, value: any) => option?.street === value?.street}
						onChange={this.onFormChange}
						onInputChange={this.onInputChange}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Oppdragsadresse"
								fullWidth
								error={hasError}
								autoComplete="street-address"
								helperText="Søk opp adresse og klikk på riktig i nedtrekkslisten"
							/>
						)}
					/>
				</Stack>
			);
		}
	}
);

export default AddressAutoCompleteTextfield;
