import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type LayoutProps = {
	children: ReactNode;
	center?: boolean;
};

const Layout = ({ children, center }: LayoutProps) => {
	return (
		<Stack
			spacing={2}
			direction="column"
			sx={{
				padding: {
					xs: '0',
					sm: '2rem',
				},
				width: '100%',
				maxWidth: '1400px',
				paddingBottom: {
					xs: '3rem',
					sm: '1rem',
				},
				margin: center ? '0 auto' : '0',
			}}
		>
			<Stack
				spacing={2}
				direction="column"
				sx={{
					padding: 0,
					width: '100%',
				}}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default Layout;
