/* eslint-disable no-unused-vars */
import { Alert } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import LogUtil from '../helpers/LogUtil';
import { RootStore } from '../stores/RootStore';
import StoreContext from '../stores/StoreContext';

type Props = {
	plan?: string;
	plans?: string[];
	anyPlan?: boolean;
	children: React.ReactNode | React.ReactNode[] | undefined;
	fallback?: React.ReactNode | React.ReactNode[] | undefined;
};

const PricingPlanGuard = observer(
	class PricingPlanGuard extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				rootStore: computed,
			});
			this.state = { hasError: false };
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get pricingPlanStore() {
			return this.rootStore.pricingPlanStore;
		}

		get pricingPlan() {
			return this.pricingPlanStore.currentPricingPlan;
		}

		static getDerivedStateFromError(error: any) {
			// Update state so the next render will show the fallback UI.
			return { hasError: true };
		}

		componentDidCatch(error: any, errorInfo: any) {
			// You can also log the error to an error reporting service
			// @todo change this to error
			LogUtil.debug(error, errorInfo);
		}

		render() {
			if ((this.state as any).hasError) {
				// You can render any custom fallback UI
				return <Alert severity="error">Noe gikk galt</Alert>;
			}

			const { fallback, children, anyPlan } = this.props;
			let { plan, plans } = this.props;
			// plan and plans to lower case
			plan = plan?.toLowerCase();
			plans = plans?.map((p) => p.toLowerCase());

			if (plan && plan === (this.pricingPlan?.title ?? '').toLowerCase()) {
				return children;
			} else if ((plans ?? []).indexOf((this.pricingPlan?.title ?? '').toLowerCase()) >= 0) {
				return children;
			} else if (anyPlan && this.pricingPlan?.id) {
				return children;
			} else if (fallback) {
				return fallback;
			}
		}
	}
);

export default PricingPlanGuard;
