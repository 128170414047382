import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';

/**
 * Chats
 */
export default class VideoChat {
	/**
	 * @returns {Promise<any>} chats
	 */
	static async getSessionWithCode(sessionNumber: string, code: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/video-chat/session/get/${sessionNumber}`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ code }),
		});
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async joinSessionWithCode(sessionNumber: string, code: string, name: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/video-chat/session/join/${sessionNumber}`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ code, name }),
		});
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async getSession(channelId: string, sessionId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/video/get-session/${sessionId}`;

		return FetchWrapper.get(url);
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async getSessions(limit: number = 150, skip: number = 0): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/video/get-sessions/${skip}/${limit}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async createSession(channelId: string, notifyUserIds?: Array<any>): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/video/create-session`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ notifyUserIds }),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async endSession(channelId: string, sessionId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/video/end-session/${sessionId}`;

		return FetchWrapper.post(url);
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async getToken(channelId: string, sessionId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/video/get-token/${sessionId}`;

		return FetchWrapper.post(url);
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async startArchive(channelId: string, sessionId: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/video/start-archive/${sessionId}`;

		return FetchWrapper.post(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<any>} chats
	 */
	static async stopArchive(channelId: string, sessionId: string, archiveId: string): Promise<any> {
		const host = config.hosts.api;
		const url = `//${host}/v1/chat/channel/${channelId}/video/stop-archive/${sessionId}`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({ archiveId }),
			requireAuth: true,
		});
	}
}
