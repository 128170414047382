import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

type SearchInputProps = {
	value: string;
	className?: string;
	placeholder?: string;
	// eslint-disable-next-line no-unused-vars
	onChange: (value: string) => void;
};

const SearchInput = observer(
	class SearchInput extends React.Component<SearchInputProps> {
		_value: string = '';
		constructor(props: SearchInputProps) {
			super(props);

			makeObservable(this, {
				_value: observable,
				value: computed,
				handleValueChange: action,
				clearValue: action,
				updateValueFromProps: action,
			});
		}

		get value() {
			return this._value;
		}

		componentDidUpdate(prevProps: Readonly<SearchInputProps>): void {
			if (this.props.value !== prevProps.value) {
				this.updateValueFromProps();
			}
		}

		updateValueFromProps() {
			this._value = this.props.value ?? '';
		}

		handleValueChange = (event: any) => {
			this._value = event.target.value;
			this.props.onChange(this.value);
		};

		clearValue = () => {
			this._value = '';
			this.props.onChange(this.value);
		};

		render() {
			return (
				<FormControl variant="outlined" fullWidth className={this.props.className} margin="none">
					<InputLabel htmlFor="outlined-adornment-password">Søk</InputLabel>
					<OutlinedInput
						id="outlined-adornment-password"
						onChange={this.handleValueChange}
						value={this.value}
						placeholder={this.props.placeholder}
						startAdornment={
							<InputAdornment position="start">
								<IconButton aria-label="Søk" edge="start">
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						}
						endAdornment={
							this.value?.length > 0 && (
								<InputAdornment position="end">
									<IconButton aria-label="Tøm" edge="end" onClick={this.clearValue}>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							)
						}
						label="Søk"
						size="small"
					/>
				</FormControl>
			);
		}
	}
);
export default SearchInput;
