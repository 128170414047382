import { Theme, createTheme } from '@mui/material';

const baseTheme = (options: any): Theme => {
	const { isDarkMode } = options;
	const theme = createTheme(
		{
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					md: 905,
					lg: 1240,
					xl: 1440,
				},
			},
			mixins: {
				toolbar: {
					'minHeight': 56,
					'@media (min-width:600px)': {
						padding: '0 16px',
					},
				},
			},
			palette: {
				text: {
					secondary: 'rgba(0, 0, 0, 0.60)',
				},
			},
			// props: {
			// 	MuiTypography: {
			// 		variantMapping: {
			// 			caption: 'div',
			// 			overline: 'div',
			// 		},
			// 	},
			// },
			typography: {
				fontFamily: 'Poppins, sans-serif',
			},
			// overrides: {
			// 	MuiButton: {
			// 		containedPrimary: {
			// 			'backgroundColor': '#a8353a',
			// 			'&:hover': {
			// 				backgroundColor: '#ae4449',
			// 			},
			// 		},
			// 	},
			// 	MuiFab: {
			// 		primary: {
			// 			'backgroundColor': '#a8353a',
			// 			'&:hover': {
			// 				backgroundColor: '#ae4449',
			// 			},
			// 		},
			// 	},
			// },
		},
		[isDarkMode]
	);

	return theme;
};

export default baseTheme;
