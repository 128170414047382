import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from '../RootStore';
import Customer from './Customer';
import CustomerApi, { CustomerDTO } from '../../api/endpoints/CustomerApi';

export default class CustomerStore {
	rootStore: RootStore;

	customers: Customer[] = [];
	isInitialized = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		makeAutoObservable(this, {
			rootStore: false,
		});

		this.init();
	}

	init = async () => {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;

		this.rootStore.userStore.UserIdChanged.on(() => {
			this.reset();
			this.loadCustomers();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			this.reset();
		});
	};

	reset = () => {
		this.customers = [];
	};

	loadCustomers = async () => {
		// Load customers from the API
		const response = await CustomerApi.getCustomers();
		if (response.statusCode === 200) {
			if (Array.isArray(response.data.customers)) {
				runInAction(() => {
					response.data.customers.forEach((customer) => {
						this.addOrUpdateCustomerFromDTO(customer);
					});
				});
			}
		}
	};

	addOrUpdateCustomerFromDTO = (customer: CustomerDTO) => {
		const existingCustomer = this.customers.find((c) => c.customerId === customer.customerId);
		if (existingCustomer) {
			existingCustomer.updateFromDTO(customer);
		} else {
			this.customers.push(new Customer(customer));
		}
	};
}
