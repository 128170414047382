import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	DialogProps,
	DialogContentText,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

type Props = DialogProps & {
	title?: string;
	supportingText: React.ReactNode;
	confirmingAction: {
		title: string;
		action: () => void;
		isDisabled?: boolean;
	};
	onClose: () => void;
};

const AlertDialog = observer(
	class AlertDialog extends React.Component<Props> {
		render() {
			const { title, supportingText, confirmingAction, ...rest } = this.props;

			return (
				<Dialog {...rest}>
					{title && <DialogTitle>{title}</DialogTitle>}
					<DialogContent>
						<DialogContentText>{supportingText}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.onClose} color="primary">
							Avbryt
						</Button>
						<Button
							disabled={confirmingAction.isDisabled}
							onClick={confirmingAction.action}
							color="primary"
						>
							{confirmingAction.title}
						</Button>
					</DialogActions>
				</Dialog>
			);
		}
	}
);

export default AlertDialog;
