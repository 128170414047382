import { Grid, Typography, Button } from '@mui/material';
import React from 'react';

export default class MinElektrikerNorgeseliten extends React.Component {
	render() {
		return (
			<Grid container sx={{ marginTop: '6rem', marginBottom: '3rem' }}>
				<Grid container item xs={12} md={6} sx={{ padding: '1rem', alignItems: 'center' }}>
					<iframe
						width="200"
						height="150"
						src="https://www.youtube.com/embed/j1i4LpHN_oI"
						title="Norgeseliten 25 år"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
						style={{
							width: '100%',
							minHeight: '250px',
							height: 'auto',
							aspectRatio: 16 / 9,
						}}
					></iframe>
				</Grid>
				<Grid item xs={12} md={6} sx={{ paddingLeft: '1rem' }}>
					<Typography variant="h4" gutterBottom>
						Min Elektriker er Norgeseliten
					</Typography>
					<Typography variant="body1" gutterBottom>
						Norgeseliten er en landsdekkende elektrikerkjede med elektroentreprenører. Gjennom Min
						Elektriker kan du kommunisere med kjedens over 200 virksomheter med rundt 3.500 fagutdannede
						elektrikere.
					</Typography>
					<Typography variant="body1" gutterBottom>
						Alle elektrikere som jobber hos oss er faglærte. Det vil si at de har formell fagutdanning i
						henhold til lov om fagopplæring i arbeidslivet og er kvalifiserte til å installere og reparere
						elektriske anlegg og elektrisk utstyr.
					</Typography>
					<Typography variant="body1" gutterBottom>
						Et elektrisk anlegg som fungerer optimalt handler ikke bare om sikringsskap, kabler og
						koblinger. Det handler også om riktig temperatur til riktig tid, god belysning til alle oppgaver
						som skal utføres og sist, men ikke minst, produkter med en finish som løfter interiøret.
						Norgeselitens elektrikere har høy kompetanse på løsninger for lys- og varmestyring. Vi har også
						et bredt utvalg av produkter, fra lysbrytere og styringspanler til belysning, som passer inn i
						de aller fleste sammenhenger.
					</Typography>
					<Typography variant="body1" gutterBottom>
						Les mer om Norgeseliten på våre nettsider.
					</Typography>
					<Button
						variant="outlined"
						color="secondary"
						href="https://www.norgeseliten.no/om-oss/"
						target="_blank"
					>
						Norgeseliten.no
					</Button>
				</Grid>
			</Grid>
		);
	}
}
