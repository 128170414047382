import L from 'leaflet';
import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import React from 'react';
import Simpleheat from './Simpleheat';

export type HeatmapLayerPoint = {
	lat: number;
	lon: number;
	intensity?: number;
};

export type FogOfWarLayerPoint = {
	lat: number;
	lon: number;
	radius: number;
};

interface HeatmapLayerProps {
	points: HeatmapLayerPoint[];
	fogOfWarPoints?: FogOfWarLayerPoint[];
	radius?: number;
	blur?: number;
	max?: number;
	minOpacity?: number;
	gradient?: { [key: number]: string };
	fogColor?: string; // Color of the fog, default is black (#000)
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ({
	points,
	fogOfWarPoints,
	radius = 3,
	blur = 10,
	max = 1,
	minOpacity = 0.05,
	gradient = { 0.4: 'blue', 0.6: 'cyan', 0.7: 'lime', 0.8: 'yellow', 1.0: 'red' },
	fogColor = '#000',
}) => {
	const map = useMap();
	let canvasId = 'heatmap-canvas';
	let canvas = document.getElementById(canvasId) as HTMLCanvasElement;
	if (!canvas) {
		canvas = document.createElement('canvas');
		canvas.id = canvasId;
	}
	const canvasRef = useRef<HTMLCanvasElement>(canvas);
	const heatRef = useRef<any>(null);
	const overlayRef = useRef<L.ImageOverlay | null>(null);
	const svgOverlayRef = useRef<L.SVGOverlay | null>(null);

	useEffect(() => {
		if (!map) return;

		const canvas = canvasRef.current;
		heatRef.current = new Simpleheat(canvas);

		const calculateRadiusInPixels = (radiusKm: number) => {
			const zoomLevel = map.getZoom();
			const equatorLength = 40075000; // in meters
			const resolution = equatorLength / (256 * Math.pow(2, zoomLevel));
			return (radiusKm * 1000) / resolution;
		};

		heatRef.current.radius(calculateRadiusInPixels(radius), blur);
		heatRef.current.max(max);
		heatRef.current.gradient(gradient);

		const heatLayer = L.layerGroup().addTo(map);

		const updateHeatmap = () => {
			if (overlayRef.current) {
				overlayRef.current.remove(); // Remove the previous overlay
			}

			heatRef.current.radius(calculateRadiusInPixels(radius), calculateRadiusInPixels(blur));
			const bounds = map.getBounds();
			const topLeft = map.latLngToLayerPoint(bounds.getNorthWest());
			const bottomRight = map.latLngToLayerPoint(bounds.getSouthEast());

			const ctx = canvas.getContext('2d');
			if (ctx) {
				ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

				canvas.style.position = 'absolute';
				canvas.style.left = `${topLeft.x}px`;
				canvas.style.top = `${topLeft.y}px`;
				canvas.width = bottomRight.x - topLeft.x;
				canvas.height = bottomRight.y - topLeft.y;

				// Update heat points positions based on new canvas size
				heatRef.current.clear();
				const formattedPoints = points.map((p) => {
					const pointPos = map.latLngToLayerPoint(new L.LatLng(p.lat, p.lon));
					return [pointPos.x - topLeft.x, pointPos.y - topLeft.y, p.intensity || 0.5]; // adjust point positions
				});
				heatRef.current.data(formattedPoints);
				heatRef.current.draw(minOpacity);
				// SVG Overlay for Fog of War
				if (fogOfWarPoints) {
					// Draw fog of war

					const drawPoints = (points: FogOfWarLayerPoint[]) => {
						points?.forEach((point) => {
							const radiusPixels = calculateRadiusInPixels(point.radius);
							const center = map.latLngToLayerPoint(new L.LatLng(point.lat, point.lon));
							const centerX = center.x - topLeft.x;
							const centerY = center.y - topLeft.y;
							ctx.shadowBlur = radiusPixels / 2;
							ctx.shadowColor = 'black';
							ctx.beginPath();
							ctx.arc(centerX, centerY, radiusPixels, 0, Math.PI * 2, true);
							ctx.fill();
						});
						ctx.fill();
						ctx.clip();
						ctx.globalCompositeOperation = 'source-over'; // Reset composite operation
					};
					const heatmapData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					// Clear canvas and apply the fog
					ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
					ctx.fillStyle = fogColor;
					ctx.globalAlpha = 1;
					ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
					ctx.globalCompositeOperation = 'destination-out';
					drawPoints(fogOfWarPoints);
					// Capture the fog image data
					const fogData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);

					// Process image data to clip fog from heatmap
					for (let i = 0; i < fogData.data.length; i += 4) {
						if (fogData.data[i + 3] > 0) {
							// replace the heatmap pixel with the fog pixel
							heatmapData.data[i] = fogData.data[i];
							heatmapData.data[i + 1] = fogData.data[i + 1];
							heatmapData.data[i + 2] = fogData.data[i + 2];
							heatmapData.data[i + 3] = fogData.data[i + 3];
						}
					}

					// Redraw the processed fog and heatmap data
					ctx.putImageData(heatmapData, 0, 0);

					ctx.globalCompositeOperation = 'source-over'; // Reset composite mode
				}
			}

			if (overlayRef.current) {
				overlayRef.current.remove();
			}
			// Create a new overlay for the updated heatmap
			overlayRef.current = L.imageOverlay(canvas.toDataURL(), bounds, { opacity: 0.6 });
			overlayRef.current.addTo(map);
		};

		updateHeatmap();

		map.on('viewreset moveend zoomend', updateHeatmap);

		return () => {
			map.off('viewreset moveend zoomend', updateHeatmap);
			heatLayer?.remove();
			overlayRef.current?.remove();
			svgOverlayRef.current?.remove();
		};
	}, [points, fogOfWarPoints, map, radius, blur, max, gradient, minOpacity, fogColor]);

	return null;
};

export default HeatmapLayer;
