import { makeAutoObservable, runInAction } from 'mobx';
import NotificationApi from '../api/endpoints/NotificationApi';
import LogUtil from '../helpers/LogUtil';
import { Trigger, TriggerType } from '../Trigger.types';
import { RootStore } from './RootStore';
import { MessageDTO } from '../types';
import { SoundEffect } from './UiState';
import { toast } from 'react-toastify';
import { Channel } from './ChatStore';
import { ProjectDTO } from '../dto/project.types';

export class NotificationStore {
	rootStore: RootStore;
	notifications: any[] = []; // @todo fix type plz
	isLoading: boolean = false;

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;

		this.processTrigger = this.processTrigger.bind(this);
		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.loadNotifications();
		});

		this.rootStore.userStore.SignedOut.on(() => {
			runInAction(() => {
				this.notifications = [];
			});
		});
	}

	async processTrigger(trigger: Trigger<any>) {
		try {
			switch (trigger.urn) {
				case TriggerType.NOTIFICATION_CLEARED:
					if (trigger.event?.data?.notificationIds) {
						this.removeNotifications(trigger.event.data.notificationIds);
					}
					break;
				case TriggerType.NOTIFICATION_NEW:
					if (trigger.event?.data?.id) {
						this.addNotification(trigger.event.data);
					}
					break;
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	get unread() {
		return this.notifications.length;
	}

	async loadNotifications() {
		if (!this.isLoading) {
			this.isLoading = true;
			const result = await NotificationApi.getNotifications();
			if (result.statusCode === 200 && result.data) {
				runInAction(() => {
					this.notifications = result.data;
				});
			}

			runInAction(() => {
				this.isLoading = false;
			});
		}
	}

	async clearNotifications(notificationIds: string[]) {
		const result = await NotificationApi.clearNotifications(notificationIds);
		if (result.statusCode === 200) {
			this.removeNotifications(notificationIds);
		}
	}

	removeNotifications(notificationIds: string[]) {
		runInAction(() => {
			this.notifications = this.notifications.filter(
				(notification: any) => !notificationIds.includes(notification.id)
			);
		});

		if (this.unread < 10) {
			this.loadNotifications();
		}
	}

	addNotification(notification: any) {
		runInAction(() => {
			this.notifications.push(notification);
		});
	}

	onProjectTrigger = (trigger: Trigger<ProjectDTO>) => {
		try {
			const project = trigger.event?.data;
			if (project.id) {
				const data: {
					assignedTo: number;
					assignedBy: number;
					project: ProjectDTO;
					userId: number;
				} = trigger.event?.data as any;
				const currentUserId = this.rootStore.profileStore.currentUserProfile?.userId
					? +this.rootStore.profileStore.currentUserProfile?.userId
					: 0;

				switch (trigger.urn) {
					case TriggerType.PROJECT_CREATED:
						this.rootStore.uiState.playSound(SoundEffect.NEW_PROJECT);
						this.makeProjectToast(project, `Projektet ${project.name} ble opprettet`);
						break;
					case TriggerType.PROJECT_ASSIGNED_TO_EMPLOYEE:
						if (+data.assignedTo === currentUserId) {
							this.rootStore.uiState.playSound(SoundEffect.PROJECT_ASSIGNED);
							toast('Du har fått et nytt prosjekt tildelt', {
								onClick: () => {
									// Click action with navigate to the right project
									let link = `/app/pro/projects/${project.id}`;

									this.rootStore.navigate({
										path: link,
										historyOnly: true,
									});
								},
								toastId: `project:assigned:${project.id}`,
							});
						}
						break;
					case TriggerType.PROJECT_COMPLETED:
						toast.success(`🎉 Projektet ${project.name ?? project.id} ble fullført! 🥳`, {
							position: 'top-right',
							autoClose: 8000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							theme: 'colored',
							onClick: () => {
								// Click action with navigate to the right project
								let link = `/app/pro/projects/${project.id}`;

								this.rootStore.navigate({
									path: link,
									historyOnly: true,
								});
							},
							toastId: `PROJECT_COMPLETED-${project.id}`,
						});
						this.rootStore.uiState.playSound(SoundEffect.PROJECT_COMPLETED);
						break;
					case TriggerType.PROJECT_ARCHIVED:
						this.makeProjectToast(project, `Projektet ${project.name} ble arkivert`);
						break;
					case TriggerType.PROJECT_UPDATED:
						this.makeProjectToast(project, `Projektet ${project.name} ble oppdatert`);
						break;
				}
			}
		} catch (err) {
			LogUtil.info(err);
		}
	};

	makeProjectToast = (project: ProjectDTO, text: string, id?: string) => {
		try {
			if (project.id && text) {
				toast(text, {
					onClick: () => {
						// Click action with navigate to the right project
						let link = `/app/pro/projects/${project.id}`;

						this.rootStore.navigate({
							path: link,
							historyOnly: true,
						});
					},
					toastId: id ? id : `project:${project.id}`,
				});
			}
		} catch (err) {
			LogUtil.info(err);
		}
	};

	onNewMessage = (message: MessageDTO) => {
		try {
			if (
				message.channelId != this.rootStore.uiState.currentChannelId &&
				message.userId &&
				message.userId != this.rootStore.profileStore.currentUserProfile?.userId &&
				message.status === 'SENT'
			) {
				const { userId } = message;
				if (!userId) {
					console.warn('No userId in message', message);
					return;
				}

				// first check if the user is a channel member
				const channel = this.rootStore.chatStore.findChannel(message.channelId);
				if (!channel) {
					console.warn('Channel not found', message.channelId);
					return;
				}

				const currentUsersUserId = this.rootStore.profileStore.currentUserProfile?.userId;
				if (!currentUsersUserId) {
					console.warn('No current user id', message);
					return;
				}

				const isChannelMember = channel.groupMembers.find(
					(member: any) => +member.userId === +currentUsersUserId
				);
				if (!isChannelMember) {
					console.warn('User is not a member of the channel', message, channel.groupMembers);
					return;
				}

				if (this.isChannelMuted(channel)) {
					console.warn('Channel is muted', channel);
					return;
				}

				this.makeMessageToast(message);
			}
		} catch (err) {
			LogUtil.info(err);
		}
	};

	// eslint-disable-next-line no-unused-vars
	isChannelMuted = (channel: Channel) => {
		// todo: implement muting of channels and channel types via user settings
		return false;
	};

	makeMessageToast = (message: MessageDTO) => {
		try {
			if (!message.userId) return;

			const profile = this.rootStore.profileStore.getProfileByFilter({
				userId: +message.userId,
				profileId: message.profileId ? +message.profileId : undefined,
			});

			if (profile?.isAI) {
				this.rootStore.uiState.playSound(SoundEffect.NEW_AI_MESSAGE);
			} else {
				this.rootStore.uiState.playSound(SoundEffect.NEW_MESSAGE);
			}

			const text = this.rootStore.settingsStore.applyEmojiSettingsToText(message.text);
			// make sure we have the info we need and that it's not a widget
			// todo: find a way to show toast for widget types as well
			if (text && message.type === 'message') {
				// slice the text to avoid long toasts
				const shortenedText = text.length > 50 ? text.slice(0, 50) + '...' : text;
				const name = profile?.name ? `${profile.name}: ` : 'Noen: ';
				toast(`${name}${shortenedText}`, {
					onClick: () => {
						// Click action with navigate to the right channel/project
						if (message.channelId) {
							let link = `/app/pro/channels/${message.channelId}`;

							this.rootStore.navigate({
								path: link,
								historyOnly: true,
							});
						}
					},
					toastId: `message:${message.id}`,
				});
			}
		} catch (err) {
			LogUtil.info(err);
		}
	};
}
