export enum QuickActionType {
	// eslint-disable-next-line no-unused-vars
	TEXT = 'text',
	// eslint-disable-next-line no-unused-vars
	LINK = 'link',
}

export type QuickAction = {
	type: QuickActionType;
	text: string;
	href?: string;
};

export enum AssignMethod {
	// eslint-disable-next-line no-unused-vars
	AUTO = 'AUTO',
	// eslint-disable-next-line no-unused-vars
	TARGET_WORKSPACE = 'TARGET_WORKSPACES',
	// eslint-disable-next-line no-unused-vars
	WORKSPACE_CHILDREN = 'WORKSPACE_CHILDREN',
	// eslint-disable-next-line no-unused-vars
	SELF = 'SELF',
}

export enum ChatWidgetBuildSource {
	// eslint-disable-next-line no-unused-vars
	DB_DTO = 'DB_DTO',
	// eslint-disable-next-line no-unused-vars
	DTO = 'DTO',
}

export enum WidgetSizes {
	// eslint-disable-next-line no-unused-vars
	DEFAULT = 'default',
	// eslint-disable-next-line no-unused-vars
	SMALL = 'small',
	// eslint-disable-next-line no-unused-vars
	MEDIUM = 'medium',
	// eslint-disable-next-line no-unused-vars
	LARGE = 'large',
	// eslint-disable-next-line no-unused-vars
	HIDDEN = 'hidden',
}

export enum MobileSize {
	// eslint-disable-next-line no-unused-vars
	HIDDEN = WidgetSizes.HIDDEN,
	// eslint-disable-next-line no-unused-vars
	FAB_ONLY = WidgetSizes.SMALL,
	// eslint-disable-next-line no-unused-vars
	LARGE = WidgetSizes.LARGE,
}

export enum DesktopSize {
	// eslint-disable-next-line no-unused-vars
	HIDDEN = WidgetSizes.HIDDEN,
	// eslint-disable-next-line no-unused-vars
	FAB_ONLY = WidgetSizes.SMALL,
	// eslint-disable-next-line no-unused-vars
	MEDIUM = WidgetSizes.MEDIUM,
	// eslint-disable-next-line no-unused-vars
	LARGE = WidgetSizes.LARGE,
}

export type ChatWidgetThemeDTO = {
	logoUrl?: string;
	primaryColor: string;
	secondaryColor: string;
	accentColor: string;
	mobileInitialSize: MobileSize;
	desktopInitialSize: DesktopSize;
};

export type ChatWidgetConfig_Config = {
	theme?: ChatWidgetThemeDTO;
};

export type ChatWidgetConfigDB_DTO = {
	id: number;
	campaignId?: number;
	appId: string;
	agentId?: number;
	targetWorkspaces: string;
	workspaceId: number;
	companyId: number;
	companyName: string;
	assignMethod: AssignMethod;
	buildSource: ChatWidgetBuildSource;
	config?: ChatWidgetConfig_Config;
	widgetKey?: string;
	title: string;
	initialMessage?: string;
};

export type ChatWidgetConfigDTO = {
	chatWidgetId: number;
	campaignId?: number;
	agentId?: number;
	appId: string;
	targetWorkspaceIds: number[];
	quickActions: QuickAction[];
	workspaceId: number;
	companyId: number;
	companyName: string;
	assignMethod: AssignMethod;
	buildSource: ChatWidgetBuildSource;
	logoUrl?: string;
	theme?: ChatWidgetThemeDTO;
	widgetKey?: string;
	title: string;
	initialMessage?: string;
};

export type CreatingChatWidgetDTO = Omit<ChatWidgetConfigDTO, 'chatWidgetId' | 'campaignId' | 'buildSource'>;

export const DefaultChatWidgetTheme: ChatWidgetThemeDTO = {
	logoUrl: 'https://befare.no/icon-192x192.png',
	primaryColor: '#F5F5F5',
	secondaryColor: '#1976d2',
	accentColor: '#FF9800',
	mobileInitialSize: MobileSize.FAB_ONLY,
	desktopInitialSize: DesktopSize.FAB_ONLY,
};
