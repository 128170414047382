import { makeAutoObservable } from 'mobx';
import {
	AssignMethod,
	ChatWidgetBuildSource,
	ChatWidgetConfigDB_DTO,
	ChatWidgetConfigDTO,
	ChatWidgetThemeDTO,
	DefaultChatWidgetTheme,
	QuickAction,
} from '../dto/chatWidget.types';
import CampaignApi from '../api/endpoints/CampaignApi';

export default class ChatWidgetConfig {
	campaignId?: number;
	appId: string;
	agentId?: number;
	chatWidgetId: number;
	targetWorkspaceIds: number[];
	quickActions: QuickAction[];
	workspaceId: number;
	companyId: number;
	companyName: string;
	assignMethod: AssignMethod;
	theme?: ChatWidgetThemeDTO;
	widgetKey?: string;
	initialMessage?: string;
	title: string;

	constructor(dto: ChatWidgetConfigDTO | ChatWidgetConfigDB_DTO) {
		// check if dto is ChatWidgetConfigDB_DTO or ChatWidgetConfigDTO
		let config: ChatWidgetConfig;
		if (ChatWidgetConfig.isDB_DTO(dto)) {
			config = this.buildFromDB_DTO(dto);
		} else {
			config = this.buildFromDTO(dto);
		}

		// definitely assign class properties
		const {
			chatWidgetId,
			campaignId,
			appId,
			targetWorkspaceIds,
			quickActions,
			workspaceId,
			companyId,
			companyName,
			assignMethod,
			title,
			initialMessage,
		} = config;

		this.chatWidgetId = chatWidgetId;
		this.appId = appId;
		this.agentId = config.agentId;
		this.campaignId = campaignId;
		this.targetWorkspaceIds = targetWorkspaceIds;
		this.quickActions = quickActions;
		this.workspaceId = workspaceId;
		this.companyId = companyId;
		this.companyName = companyName;
		this.assignMethod = assignMethod;
		this.theme = config.theme;
		this.widgetKey = config.widgetKey;
		this.title = title;
		this.initialMessage = initialMessage;

		makeAutoObservable(this);
	}

	/**
	 * Build ChatWidgetConfig from db response
	 * @param config
	 * @returns {ChatWidgetConfig} instance
	 */
	buildFromDB_DTO(config: ChatWidgetConfigDB_DTO): ChatWidgetConfig {
		this.chatWidgetId = config.id;
		this.campaignId = config.campaignId;
		this.appId = config.appId;
		this.targetWorkspaceIds = config.targetWorkspaces.split(',').map((id) => parseInt(id));
		this.workspaceId = config.workspaceId;
		this.companyId = config.companyId;
		this.companyName = config.companyName;
		this.assignMethod = config.assignMethod;
		this.widgetKey = config.widgetKey;
		this.title = config.title;
		this.initialMessage = config.initialMessage;

		this.theme = config.config?.theme;

		return this;
	}

	/**
	 * Build ChatWidgetConfig from DTO
	 * @param config
	 * @returns {ChatWidgetConfig} instance
	 */
	buildFromDTO(config: ChatWidgetConfigDTO): ChatWidgetConfig {
		this.chatWidgetId = config.chatWidgetId;
		this.appId = config.appId;
		this.campaignId = config.campaignId;
		this.targetWorkspaceIds = config.targetWorkspaceIds;
		this.quickActions = config.quickActions;
		this.workspaceId = config.workspaceId;
		this.companyId = config.companyId;
		this.companyName = config.companyName;
		this.assignMethod = config.assignMethod;
		this.theme = config.theme;
		this.widgetKey = config.widgetKey;
		this.title = config.title;
		this.initialMessage = config.initialMessage;
		return this;
	}

	/**
	 * Get ChatWidgetConfig as DB_DTO
	 */
	get asDB_DTO(): ChatWidgetConfigDB_DTO {
		return {
			id: this.chatWidgetId,
			campaignId: this.campaignId,
			appId: this.appId,
			targetWorkspaces: this.targetWorkspaceIds.join(','),
			workspaceId: this.workspaceId,
			companyId: this.companyId,
			companyName: this.companyName,
			assignMethod: this.assignMethod,
			widgetKey: this.widgetKey,
			title: this.title,
			initialMessage: this.initialMessage,
			config: {
				theme: {
					logoUrl: this.theme?.logoUrl ?? DefaultChatWidgetTheme.logoUrl,
					primaryColor: this.theme?.primaryColor ?? DefaultChatWidgetTheme.primaryColor,
					secondaryColor: this.theme?.secondaryColor ?? DefaultChatWidgetTheme.secondaryColor,
					accentColor: this.theme?.accentColor ?? DefaultChatWidgetTheme.accentColor,
					mobileInitialSize: this.theme?.mobileInitialSize ?? DefaultChatWidgetTheme.mobileInitialSize,
					desktopInitialSize: this.theme?.desktopInitialSize ?? DefaultChatWidgetTheme.desktopInitialSize,
				},
			},
			buildSource: ChatWidgetBuildSource.DB_DTO,
		};
	}

	/**
	 * Get ChatWidgetConfig as DTO
	 */
	get asDTO(): ChatWidgetConfigDTO {
		// only accept color palette if all colors are present, else use default theme
		const validColorPalette: boolean = Boolean(
			this.theme?.primaryColor && this.theme?.secondaryColor && this.theme?.accentColor
		);

		const theme: ChatWidgetThemeDTO = {
			primaryColor: validColorPalette
				? (this.theme?.primaryColor as string)
				: DefaultChatWidgetTheme.primaryColor,
			secondaryColor: validColorPalette
				? (this.theme?.secondaryColor as string)
				: DefaultChatWidgetTheme.secondaryColor,
			accentColor: validColorPalette ? (this.theme?.accentColor as string) : DefaultChatWidgetTheme.accentColor,
			logoUrl: this.theme?.logoUrl ?? DefaultChatWidgetTheme.logoUrl,
			mobileInitialSize: this.theme?.mobileInitialSize ?? DefaultChatWidgetTheme.mobileInitialSize,
			desktopInitialSize: this.theme?.desktopInitialSize ?? DefaultChatWidgetTheme.desktopInitialSize,
		};

		return {
			chatWidgetId: this.chatWidgetId,
			campaignId: this.campaignId,
			appId: this.appId,
			targetWorkspaceIds: this.targetWorkspaceIds,
			quickActions: this.quickActions,
			workspaceId: this.workspaceId,
			companyId: this.companyId,
			companyName: this.companyName,
			assignMethod: this.assignMethod,
			widgetKey: this.widgetKey,
			title: this.title,
			initialMessage: this.initialMessage,
			theme,
			buildSource: ChatWidgetBuildSource.DTO,
		};
	}

	/**
	 * Check if param is ChatWidgetConfigDTO
	 * @param param
	 * @returns {boolean} is ChatWidgetConfigDTO
	 */
	static isDB_DTO(param: ChatWidgetConfigDTO | ChatWidgetConfigDB_DTO): param is ChatWidgetConfigDB_DTO {
		return param.buildSource === ChatWidgetBuildSource.DB_DTO;
	}

	async save() {
		if (!this.campaignId) {
			return;
		}
		// save to db
		console.log('saving chat widget config', this.asDTO);
		const result = await CampaignApi.saveChatWidgetConfig(this.campaignId, this.asDTO);
		if (result.statusCode === 200) {
			console.log('Chat widget config saved');
		}
	}
}
