import { Box, Typography } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Company } from '../../../stores/company/Company';
import { Profile } from '../../../stores/ProfileStore';
import { RootStore } from '../../../stores/RootStore';
import StoreContext from '../../../stores/StoreContext';
import ProfilePicture from '../ProfilePicture';
import { toNumber } from 'lodash';

type Props = {
	company: Company;
};

const CompanyEmployeeChips = observer(
	class CompanyEmployeeChips extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				employees: computed,
				rootStore: computed,
				profileStore: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get employees() {
			// TODO Remove this temporary patch to hide Jone and Hans Christian from employees
			return this.props.company.employees
				?.filter((employee) => '' + employee.userId !== '100001' && '' + employee.userId !== '100006')
				.map((employee) =>
					this.profileStore.getProfileByFilter({
						profileId: toNumber(employee.id),
						userId: toNumber(employee.userId),
						workspaceId: toNumber(employee.workspaceId),
					})
				)
				.filter(Boolean) as Profile[];
		}

		render() {
			if (!this.employees) {
				return null;
			}

			return (
				<Box display="flex" flexWrap="wrap" marginBottom={-1}>
					{this.employees?.slice(0, 3).map((employee) => (
						<Box
							bgcolor="background.default"
							display="inline-flex"
							key={employee.userId}
							marginBottom={1}
							marginRight={1}
							padding={1}
						>
							<ProfilePicture profile={employee} />
							<Box marginLeft={1.5}>
								<Typography variant="subtitle2">{employee.name}</Typography>
								<Typography color="textSecondary" variant="caption">
									{employee.organizationTitle}
								</Typography>
							</Box>
						</Box>
					))}
				</Box>
			);
		}
	}
);

export default CompanyEmployeeChips;
