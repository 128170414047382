import { Container, Typography } from '@mui/material';
import React from 'react';
import Footer from '../footer/Footer';
import CookieConsentToggles from './CookieConsentToggles';
import './CookieDeclarationScreen.scss';

class CookieDeclarationScreen extends React.Component {
	render() {
		return (
			<>
				<Container className="CookieDeclarationScreen">
					<Typography gutterBottom variant="h3">
						Informasjonskapsler
					</Typography>
					<Typography color="textSecondary" paragraph>
						Informasjonskapsler, også kjent som cookies, benyttes på denne nettsiden.
					</Typography>
					<Typography color="textSecondary" paragraph>
						Vi bruker informasjonskapsler kun der det er nødvendig for å tilby deg funksjonalitet og for å
						kunne forbedre brukeropplevelsen.
					</Typography>
					<CookieConsentToggles />
					<ul>
						<li>Informasjonskapsler benyttes for at du skal kunne logge inn.</li>
						<li>Informasjonskapsler benyttes for at du skal kunne gjennomføre videosamtaler.</li>
						<li>
							Informasjonskapsler benyttes for å lagre dine innstillinger når det kommer til
							push-notifikasjoner.
						</li>
						<li>
							Informasjonskapsler benyttes til analyseformål slik at vi kan samle anonyme data for å
							forbedre brukeropplevelsen.
						</li>
					</ul>

					<Typography gutterBottom variant="h5">
						Oversikt
					</Typography>
					<table>
						<tr>
							<td>_ga, _ga_84NH84K6K2</td>
							<td>Brukes av Google Analytics og hjelper oss med å analysere trafikk på nettstedet.</td>
						</tr>
						<tr>
							<td>_hj*</td>
							<td>Flere informasjonskapsler som brukes til analyse av brukeropplevelsen din.</td>
						</tr>
					</table>
				</Container>
				<Footer />
			</>
		);
	}
}

export default CookieDeclarationScreen;
