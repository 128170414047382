import { Address, GrantType, ProfileType } from '../types';
import { Trigger, TriggerType } from '../Trigger.types';
import { makeAutoObservable, runInAction } from 'mobx';

import { ConnectionMetadataExternal } from '../Connection.types';
import LogUtil from '../helpers/LogUtil';
import OneSignalReact from 'react-onesignal';
import ProfileApi from '../api/endpoints/Profile';
import { RootStore } from './RootStore';
import config from '../config/config';
import { debounce, toNumber } from 'lodash';
import { CompanyStore } from './company/CompanyStore';
import { toast } from 'react-toastify';
import { FetchError } from '../fetch/FetchWrapper';

export type ProfileFilter = {
	userId: number;
	profileId?: number;
	workspaceId?: number;
};

/**
 * ProfileStore
 */
export class ProfileStore {
	rootStore: RootStore;

	_currentUserProfile: Profile | undefined;
	currentUserAddress: Address[] = [];
	userProfiles: Profile[] = [];
	_colleagues: Profile[] = [];
	_isLoadingUserProfile: boolean = false;
	isLoadingProfiles: { userId: string; workspaceId?: string }[] = [];
	profilesToLoad: { userId: string; workspaceId?: string }[] = [];

	phoneNumberLookup: any = {};

	debouncedBatchLoadProfiles: any;
	debouncedLoadProfile: any;
	debouncedLoadProfilesByProfileIds: any;

	haveRequestedPushPermission: boolean = false;
	isPushEnabled: boolean = false;
	oneSignalIsInitialized: boolean = false;
	isInitialized: boolean = false;
	_hasTriedLoadingProfile: boolean = false;
	isLoadingColleagues: boolean = false;

	profileIdsToLoad: number[] = [];

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		this.setOnesignalExternalUserId = this.setOnesignalExternalUserId.bind(this);
		this.batchLoadProfiles = this.batchLoadProfiles.bind(this);
		this.loadProfile = this.loadProfile.bind(this);
		this.loadProfilesByProfileIds = this.loadProfilesByProfileIds.bind(this);

		this.debouncedBatchLoadProfiles = debounce(this.batchLoadProfiles, 500);
		this.debouncedLoadProfile = debounce(this.loadProfile, 500);
		this.debouncedLoadProfilesByProfileIds = debounce(this.loadProfilesByProfileIds, 500);

		makeAutoObservable(this, {
			rootStore: false,
		});
		this.init();
	}

	init() {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;
		this.rootStore.userStore.SignedIn.on(() => {
			this.debouncedLoadProfile()?.catch((err: any) => {
				LogUtil.warn(err);
			});
		});

		// DO NOT call loadProfile() here ->  it will create an infinite loop
		this.rootStore.userStore.UserIdChanged.on(this.setOnesignalExternalUserId);
		this.initOneSignal();

		this.rootStore.userStore.SignedOut.on(() => {
			runInAction(() => {
				this.currentUserAddress = [];
				this._currentUserProfile = undefined;
				this._colleagues = [];
				this.userProfiles = [];
				this.profilesToLoad = [];
				this.isLoadingProfiles = [];
				this.isInitialized = false;
			});
		});
	}

	get isLoadingUserProfile() {
		return this.rootStore.userStore.befJwt && this._isLoadingUserProfile;
	}

	get currentUserProfile(): Profile | undefined {
		return this.findProfile(this._currentUserProfile?.userId, this._currentUserProfile?.workspaceId);
	}

	get colleagues() {
		return this._colleagues
			.map((colleague) =>
				this.getProfileByFilter({
					profileId: colleague.id,
					userId: toNumber(colleague.userId),
					workspaceId: toNumber(colleague.workspaceId),
				})
			)
			.filter(Boolean) as Profile[];
	}

	get isCraftsman() {
		return Boolean(this.rootStore.workspaceStore.hasEmployeeAccess);
	}

	get hasInitialized() {
		return this.isInitialized;
	}

	get hasTriedLoadingProfile() {
		return this._hasTriedLoadingProfile;
	}

	async getIsOnline() {
		if (this.currentUserProfile?.userId) {
			return await ProfileApi.getIsOnline(this.currentUserProfile?.userId);
		}

		return null;
	}

	async saveUsersAddress(address: Address) {
		const response = await ProfileApi.saveUsersAddress(address);
		if (response.statusCode == 200) {
			// Add or update in the current users address list
			runInAction(() => {
				const addressIndex = this.currentUserAddress?.findIndex((a) => '' + a.id == '' + address.id);
				if (addressIndex && addressIndex >= 0) {
					this.currentUserAddress[addressIndex] = address;

					// @todo update projects that use this address
				}
			});
		}

		return response.data;
	}

	async deleteUsersAddress(address: Address) {
		const result = await ProfileApi.deleteUsersAddress(address);
		if (result.statusCode === 200) {
			runInAction(() => {
				const addressIndex = this.currentUserAddress?.findIndex((a) => '' + a.id == '' + address.id);
				if (addressIndex && addressIndex >= 0) {
					this.currentUserAddress?.splice(addressIndex, 1);

					// @todo update projects that use this address
				}
			});

			return true;
		}

		return false;
	}

	lookupPhoneNumber = async (phoneNumber: string) => {
		if (this.phoneNumberLookup[phoneNumber]) {
			return this.phoneNumberLookup[phoneNumber];
		} else {
			try {
				const result = await ProfileApi.lookupPhoneNumber(phoneNumber);
				this.phoneNumberLookup[phoneNumber] = result;
				return this.phoneNumberLookup[phoneNumber];
			} catch (e) {
				LogUtil.error(e);
				return null;
			}
		}
	};

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	processTrigger = async (trigger: Trigger<any>) => {
		try {
			switch (trigger.urn) {
				case TriggerType.PROFILE_UPDATED:
					break;
				case TriggerType.USER_ONLINE:
					// got user online trigger, let's see if we can update a profiles online status
					this.updateUserIsOnline(trigger.event.data);
					break;
			}
		} catch (err) {
			LogUtil.error(err);
		}
	};

	/**
	 * Process triggers
	 * @param {Trigger} trigger
	 */
	processUserTrigger = async (trigger: Trigger<any>) => {
		try {
			switch (trigger.urn) {
				case TriggerType.USER_ONLINE:
					// got user online trigger, let's see if we can update a profiles online status
					this.updateUserIsOnline(trigger.event.data);
					break;
			}
		} catch (err) {
			LogUtil.error(err);
		}
	};

	/**
	 * @param  {any} event
	 */
	updateUserIsOnline(data: any) {
		if (data?.userIds && Array.isArray(data.userIds)) {
			runInAction(() => {
				data.userIds.forEach((userId: string) => {
					const index = this.userProfiles.findIndex(
						(profile) => profile.userId?.toString() === userId?.toString()
					);
					if (this.userProfiles[index] && typeof this.userProfiles[index].setLastOnline == 'function') {
						if (index >= 0) {
							this.userProfiles[index].setLastOnline();
						}
					}
				});
			});
		}
	}

	logOnlineUsers() {
		const profiles = this.userProfiles.filter((p: Profile) => p.lastOnline);
		console.log(profiles);
	}

	getProfileImage(userId?: string) {
		if (!userId) {
			return undefined;
		}

		const profile = this.getProfile(userId);
		if (profile) {
			return profile.profilePicture;
		}
		return undefined;
	}

	getProfileName(userId?: string) {
		if (!userId) {
			return undefined;
		}

		const profile = this.getProfile(userId);
		if (profile) {
			return profile.name;
		}
		return undefined;
	}

	setCurrentUserProfile(profile: Profile) {
		this._currentUserProfile = profile;
		this._currentUserProfile.userId = profile.userId?.toString();
		this.addOrUpdateProfile(this._currentUserProfile);
		this._isLoadingUserProfile = false;
	}

	loadUsersProfile = async (force: boolean = false) => {
		if (!this.rootStore.userStore.befJwt) {
			// it's impossible to load this w/o jwt
			this._isLoadingUserProfile = false;
			this.rootStore.workspaceStore._isLoadingGrants = false;
			console.warn('Missing jwt, cannot load profile');
			return;
		}

		if (!force && this._isLoadingUserProfile) {
			console.log('Already loading profile');
			return Promise.resolve();
		}

		if (!force && this._currentUserProfile) {
			console.log('Already have profile');
			return Promise.resolve();
		}

		try {
			this._isLoadingUserProfile = true;
			// What we get back is ProfileDTO
			const response = await ProfileApi.loadUsersProfile();
			if (response.statusCode === 200) {
				this._hasTriedLoadingProfile = true;
				this.setCurrentUserProfile(response.data);

				return Promise.resolve();
			} else {
				console.log('ProfileStore.loadUsersProfile: Error loading user profile', response);
				runInAction(() => {
					this.rootStore.workspaceStore._isLoadingGrants = false;
					this._isLoadingUserProfile = false;
				});
				if ([401, 403].includes(response.statusCode)) {
					toast.error('Du er logget ut. Logg inn på nytt for å fortsette.');
					runInAction(() => {
						this.rootStore.userStore.logOut();
					});
				}
				if (response.statusCode === 500) {
					// back to root
					// reddirect if location is not home
					if (window.location.pathname !== '/home') {
						window.location.href = '/home';
					}
				}
				return Promise.resolve();
			}
		} catch (err) {
			if (err === FetchError.Forbidden || err === FetchError.Unauthorized) {
				toast.error('Du er logget ut. Logg inn på nytt for å fortsette.');
				runInAction(() => {
					this.rootStore.userStore.logOut();
				});
			}
			console.log('ProfileStore.loadUsersProfile: catch, Error loading user profile', err);
			runInAction(() => {
				this._isLoadingUserProfile = false;
			});
		}

		console.log(
			'ProfileStore.loadUsersProfile: Done loading user profile. Should not end up here. rejecing promise.'
		);
		return Promise.reject();
	};

	/**
	 * Load profile
	 * @param {boolean} force
	 */
	loadProfile = async (force: boolean = false): Promise<void> => {
		runInAction(async () => {
			if (!this.rootStore.userStore.befJwt) {
				// it's impossible to load this w/o jwt
				this._isLoadingUserProfile = false;
				this.rootStore.workspaceStore._isLoadingGrants = false;
				console.warn('Missing jwt, cannot load profile');
				return;
			}

			if (!force && this._isLoadingUserProfile) {
				console.log('Already loading profile');
				return Promise.resolve();
			}

			if (!force && this._currentUserProfile) {
				console.log('Already have profile');
				return Promise.resolve();
			}

			try {
				// What we get back is ProfileDTO
				await this.loadUsersProfile(force);
				if (!this.currentUserProfile) {
					console.log('ProfileStore.loadProfile: Error loading user profile, no profile found');
					toast.error('Noe gikk galt. Prøv på nytt', {
						toastId: 'loadProfileError',
					});
					runInAction(() => {
						this._isLoadingUserProfile = false;
						this.rootStore.userStore.debouncedCheckLoginStatus(true);
					});
					return Promise.resolve();
				}
				this._isLoadingUserProfile = true;
				// What we get back is AddressDTO
				const addressResponse = await ProfileApi.getUsersAddress();
				if (addressResponse.statusCode === 200 && Array.isArray(addressResponse.data)) {
					runInAction(() => {
						this.currentUserAddress = addressResponse.data;
					});
				}
				// Trigger a login status check - this will emit UserIdChanged event and trigger misc loads
				runInAction(() => {
					this._isLoadingUserProfile = false;
					this.rootStore.userStore.debouncedCheckLoginStatus(true);
				});
			} catch (err) {
				console.log('ProfileStore.loadProfile: Error loading user profile', err);
				runInAction(() => {
					this._isLoadingUserProfile = false;
					toast.warning('Noe gikk galt. Det kan være du må laste siden på nytt.', {
						toastId: 'loadProfileError',
					});
				});
			}
		});

		return Promise.resolve();
	};

	get mostRecentUserAddress() {
		if (this.currentUserAddress && this.currentUserAddress.length > 0) {
			return this.currentUserAddress[this.currentUserAddress.length - 1];
		}
		return undefined;
	}

	async editProfile(profile: Partial<ProfileType>): Promise<void> {
		try {
			const response = await ProfileApi.updateUsersProfile(profile);

			if (response.statusCode === 200) {
				this.addOrUpdateProfile(response.data);
			}
		} catch (err) {
			LogUtil.error(err);
		}
	}

	/**
	 * Load profile for colleagues
	 */
	async loadColleagueProfiles() {
		if (this.isLoadingColleagues) {
			return;
		}
		this.isLoadingColleagues = true;
		const response = await ProfileApi.loadColleagues().finally(() => {
			this.isLoadingColleagues = false;
		});
		if (response.statusCode == 200 && Array.isArray(response.data)) {
			runInAction(() => {
				response.data.forEach((profile: any) => {
					this.addOrUpdateProfile(profile);
					// @todo find a way to unify these two
					this.addColleagueProfile(profile);
				});
			});
		}
	}

	loadProfileByProfileId = async (profileId: number) => {
		if (!this.profileIdsToLoad.includes(profileId)) {
			this.profileIdsToLoad.push(profileId);
			console.log(`ProfileStore.loadProfileByProfileId: Added profileId ${profileId} to load queue`);
			this.debouncedLoadProfilesByProfileIds();
		}
	};

	loadProfilesByProfileIds = async () => {
		runInAction(() => {
			if (this.profileIdsToLoad.length < 1) {
				return;
			}
			console.log(`ProfileStore.loadProfilesByProfileIds: Loading ${this.profileIdsToLoad.length} profiles`);
			ProfileApi.loadProfilesByProfileIds(this.profileIdsToLoad).then((response) => {
				if (response.statusCode === 200) {
					runInAction(() => {
						response.data.forEach((profile: any) => {
							const index = this.profileIdsToLoad.findIndex((id) => id == profile?.id);
							this.profileIdsToLoad.splice(index, 1);
							if (profile?.id) {
								this.addOrUpdateProfile(profile);
							} else {
								console.warn('ProfileStore.loadProfilesByProfileIds: Profile missing id', profile);
							}
						});
					});
				}
			});
		});
	};

	/**
	 * Load profile
	 * @param {boolean} force
	 */
	loadProfiles = (profileIds: { userId: string; workspaceId?: string }[]) => {
		runInAction(() => {
			const profilesToLoad = profileIds.filter(
				(p) => !this.profilesToLoad.find((pL) => pL.userId == p.userId && pL.workspaceId == p.workspaceId)
			);

			if (profilesToLoad.length) {
				this.profilesToLoad = this.profilesToLoad.concat(...profilesToLoad);

				this.debouncedBatchLoadProfiles();
			}
		});
	};

	batchLoadProfiles = async () => {
		runInAction(async () => {
			const profileIds = this.profilesToLoad.filter(
				(p) => !this.isLoadingProfiles.find((pL) => pL.userId == p.userId && pL.workspaceId == p.workspaceId)
			);
			if (profileIds.length < 1) {
				return;
			}

			this.isLoadingProfiles = this.isLoadingProfiles.concat(...profileIds);

			const result = await ProfileApi.loadWorkspaceProfiles(profileIds.slice());

			if (result.statusCode !== 200) {
				console.error('Error loading profiles', result);
				return;
			}

			const profiles = result.data;
			// TODO Use type Profile instead
			runInAction(() => {
				if (Array.isArray(profiles)) {
					profiles.forEach((profile: any) => {
						// if profile exists we can update in place
						this.addOrUpdateProfile(profile);
						this.removeProfilesToLoad(profile);
					});
				}

				this.isLoadingProfiles.forEach((profileId) => {
					this.removeIsLoadingProfile(profileId);
				});
			});
		});
	};

	removeIsLoadingProfile = (profileId: { userId: string; workspaceId?: string }) => {
		this.isLoadingProfiles = this.isLoadingProfiles.filter(
			(p) => !(p.userId == profileId.userId && p.workspaceId == profileId.workspaceId)
		);
	};

	removeProfilesToLoad = (profileId: { userId: string; workspaceId?: string }) => {
		this.profilesToLoad = this.profilesToLoad.filter(
			(p) => !(p.userId == profileId.userId && p.workspaceId == profileId.workspaceId)
		);
	};

	addColleagueProfile = (profile: any) => {
		runInAction(() => {
			let exists = this.colleagues.find((p) => '' + p.userId === profile.userId?.toString());
			if (!exists) {
				exists = new Profile(
					profile.id?.toString(),
					profile.workspaceId?.toString(),
					this.rootStore.companyStore
				);
				this._colleagues.push(exists);
			}

			if (exists) {
				exists.updateFromJson(profile);
			}
		});

		this.addOrUpdateProfile(profile);
	};

	/**
	 * get Profile
	 * @param {String} userId
	 */
	getColleagueProfile = (userId: string): Profile | undefined => {
		if (!userId) {
			return;
		}
		if (this.colleagues.find((p) => p.userId.toString() === userId.toString())) {
			return this.getProfile(userId);
		}
	};

	getProfileByProfileId = (profileId: number): Profile | undefined => {
		const profile = this.userProfiles.find((p) => p.id == profileId);
		if (!profile) {
			this.loadProfileByProfileId(profileId).catch((err) => {
				LogUtil.warn(err);
			});
		}
		return profile;
	};

	/**
	 * get Profile
	 * @param {String} userId
	 * @deprecated use getProfileByFilter instead (preferably with profileId)
	 */
	getProfile = (userId: string | number, workspaceId?: string): Profile | undefined => {
		// todo: figure out why we need the undefined check
		if (!userId || userId === 'undefined') {
			return;
		}
		runInAction(() => {
			const profile = this.userProfiles.find(
				(profile: any) => profile.userId.toString() === userId.toString() && profile.workspaceId == workspaceId
			);

			if (profile?.loadedFromServer) {
				return profile;
			}

			// insert userId into store - prevents recurring loads
			const placeholderProfile = this.findProfile(userId, workspaceId);

			if (placeholderProfile?.id) {
				this.addOrUpdateProfile(
					{
						...placeholderProfile,
						workspaceId: workspaceId ? workspaceId.toString() : undefined,
					} as any,
					false
				);
			}

			// sice we don't have the profile we need to load it
			// @todo debounce and load multiple profiles at once
			// todo: update hack to support multiple workspaces
			this.loadProfiles([
				{ userId: userId.toString(), workspaceId: workspaceId ? workspaceId.toString() : undefined },
			]);
		});

		return this.findProfile(userId, workspaceId);
	};

	/**
	 * get Profile based on filter
	 * @param {ProfileFilter} profileFilter
	 */
	getProfileByFilter = (profileFilter: ProfileFilter): Profile | undefined => {
		if (profileFilter.profileId) {
			const profile = this.getProfileByProfileId(profileFilter.profileId);
			if (profile) {
				return profile;
			} else {
				return this.getProfile(
					profileFilter.userId,
					profileFilter.workspaceId ? String(profileFilter.workspaceId) : undefined
				);
			}
		} else {
			return this.getProfile(
				profileFilter.userId,
				profileFilter.workspaceId ? String(profileFilter.workspaceId) : undefined
			);
		}
	};

	findProfile(userId?: string | number, workspaceId?: string): Profile | undefined {
		if (!userId) {
			return undefined;
		}
		const workspaceProfile = this.userProfiles.find((profile: any) => {
			if (workspaceId) {
				return profile.userId.toString() === userId.toString() && profile.workspaceId == workspaceId;
			} else {
				return profile.userId.toString() === userId.toString() && !profile.workspaceId;
			}
		});

		const fallback = this.userProfiles.find((profile: any) => profile.userId.toString() === userId.toString());

		return workspaceProfile ?? fallback;
	}

	addOrUpdateProfile(profile: Profile, loadedFromServer: boolean = true) {
		if (!profile?.userId) {
			console.log('Missing profile userid, do not add');
			return;
		}
		runInAction(() => {
			let exists = this.userProfiles.find(
				(p) =>
					String(p.userId) === String(profile.userId) && String(p.workspaceId) === String(profile.workspaceId)
			);

			if (!exists) {
				exists = new Profile(profile.id, profile.workspaceId, this.rootStore.companyStore);
				exists.updateFromJson(profile, loadedFromServer);
				this.userProfiles.push(exists);
			}

			if (exists) {
				exists.updateFromJson(profile, loadedFromServer);
			}
		});
	}

	/**
	 * initialize OneSignal - call this when the request dialog should pop up.
	 */
	initOneSignal = () => {
		try {
			let appId = config.hosts.onesignal.appId;
			let safariWebId = config.hosts.onesignal.safari_web_id;

			// Push is locked to the domain, so we need to adjust to the min-elektriker case
			if (window.location.hostname.indexOf('min-elektriker') >= 0) {
				appId = config.hosts.onesignal.norgeseliten.appId;
				safariWebId = config.hosts.onesignal.norgeseliten.safari_web_id;
			}

			if (this.oneSignalIsInitialized) {
				return;
			}

			OneSignalReact.init({
				appId: appId,
				safari_web_id: safariWebId,
				notifyButton: {
					enable: true,
				},
				promptOptions: {
					actionMessage: 'Aktiver push-varsler for å få beskjed om nye jobber og meldinger.',
					acceptButton: 'Aktiver',
					cancelButton: 'Senere',
				},
			})
				.then(async () => {
					runInAction(async () => {
						this.isPushEnabled = OneSignalReact.Notifications.permission;
						this.oneSignalIsInitialized = true;
					});
				})
				.catch((error) => {
					LogUtil.error('OneSignal fecked', { error });
				});
		} catch (err) {
			LogUtil.error(err);
		}
	};

	// don't use directly!
	private setOnesignalExternalUserId() {
		if (this.currentUserProfile?.userId) {
			const userURN = `nb:befare:user:${this.currentUserProfile.userId}`;
			OneSignalReact.login(userURN);
		} else {
			console.warn('no user id to be used as OneSignal external user!');
		}
	}

	requestPushPermission = async (force: boolean = false) => {
		console.info('Requesting push permission');
		// only do this once per load to be less annoying
		// @todo do something with timestamp to check once per n minutes/hours
		if (this.haveRequestedPushPermission && !force) {
			console.info('Have already requested push permission');
			return false;
		}

		this.haveRequestedPushPermission = true;
		try {
			console.info(`Check if push enabled, force ${force ? 'YES' : 'NO'}`);
			this.isPushEnabled = OneSignalReact.Notifications.permission;

			if (!this.isPushEnabled) {
				if (force) {
					localStorage.removeItem('onesignal-notification-prompt');
				}
				console.warn('PUSH DISABLED');
				// not sure what this does
				// OneSignalReact.registerForPushNotifications();
				// slide up from bottom - if user clicks yes the native prompt is triggered
				// by default this is shown at most once per 3 days with additional back-off for each decline
				// Note: If you need to override this back off behavior to prompt the user again you can do so by passing {force: true}
				OneSignalReact.Slidedown.promptPush({ force });
				this.setOnesignalExternalUserId();
			} else {
				console.info('PUSH ENABLED');
			}
		} catch (err) {
			LogUtil.error('Request push failed', err);
			return false;
		}
		return true;
	};
}

/**
 * Profile
 * Domain object
 */
export class Profile {
	id: number | undefined;
	userId: string = '';
	workspaceId?: string;
	organizationTitle?: string;
	_name: string = '';
	dateOfBirth?: Date;
	bio?: string;
	profilePicture?: string;
	profilePictureFileId?: string;
	username?: string;
	phoneNumber?: string;
	email?: string;
	connectionMetadata?: ConnectionMetadataExternal;
	lastOnline?: number;
	loadedFromServer?: boolean;
	isCustomer?: boolean;
	isAI?: boolean;
	grant?: GrantType;

	created?: Date;
	updated?: Date;
	deleted?: Date;

	store?: CompanyStore;

	constructor(id?: number, workspaceId?: string, companyStore?: CompanyStore) {
		makeAutoObservable(this, {
			id: false,
		});

		this.id = toNumber(id);
		this.workspaceId = workspaceId ? '' + workspaceId : undefined;
		this.userId = '' + id;
		this.store = companyStore;
	}

	get asJson(): ProfileType {
		return {
			id: this.id,
			userId: this.userId,
			workspaceId: this.workspaceId,
			organizationTitle: this.organizationTitle,
			name: this.name,
			dateOfBirth: this.dateOfBirth,
			bio: this.bio,
			profilePicture: this.profilePicture,
			username: this.username,
			phoneNumber: this.phoneNumber,
			email: this.email,
			isAI: this.isAI,
			grant: this.grant,
			created: this.created?.toISOString(),
			updated: this.updated?.toISOString(),
			deleted: this.deleted?.toISOString(),
		};
	}

	get name() {
		if (!this.loadedFromServer) {
			return `#${this.userId}`;
		}

		return this._name;
	}

	get companyName() {
		if (!this.workspaceId || !this.store) {
			return '';
		}

		const company = this.store.findCompanyByWorkspaceId(this.workspaceId);
		if (company) {
			return company.name;
		}

		return '';
	}

	get isOnline() {
		return (this.lastOnline ?? 0) > Date.now() - 20000;
	}

	get firstName() {
		if (!(this.name && this.loadedFromServer)) {
			return '';
		}
		return this.name?.split(' ')[0];
	}

	get surname() {
		if (!(this.name && this.loadedFromServer)) {
			return '';
		}
		return this.name?.substr(this.name.indexOf(' ') + 1);
	}

	setLastOnline(timestamp?: number) {
		this.lastOnline = timestamp ?? Date.now();
	}

	/**
	 * @param  {any} json
	 */
	updateFromJson(json: ProfileType | Profile, loadedFromServer: boolean = true) {
		this.id = json.id ? toNumber(json.id) : undefined;
		this.userId = json.userId;
		this.workspaceId = json.workspaceId ? '' + json.workspaceId : undefined;
		this.organizationTitle = json.organizationTitle;
		this._name = json.name ?? json.userId;
		this.dateOfBirth = json.dateOfBirth;
		this.bio = json.bio;
		this.profilePicture = json.profilePicture;
		this.username = json.username;
		this.phoneNumber = json.phoneNumber;
		this.email = json.email;
		this.lastOnline = json.lastOnline;
		this.loadedFromServer = loadedFromServer;
		this.isCustomer = json.isCustomer;
		this.isAI = json.isAI;
		this.created = json.created ? new Date(json.created) : undefined;
		this.updated = json.updated ? new Date(json.updated) : undefined;
		this.deleted = json.deleted ? new Date(json.deleted) : undefined;
		this.grant = json.grant;
		if (!(json instanceof Profile)) {
			this.connectionMetadata = json.isOnline;
		}
	}
}

// TODO Create domain object
