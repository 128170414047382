import React from 'react';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Box, IconButton, Stack, Typography, styled, keyframes } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SendIcon from '@mui/icons-material/Send';
import { RainbowBorderBox } from '../common/AnimateUI';
import CustomerSupportChatFab from '../customer-support-chat/CustomerSupportChatFab';
import StoreContext from '../../stores/StoreContext';
import { RootStore } from '../../stores/RootStore';

// Keyframe animation for pulsing effect
// eslint-disable-next-line no-unused-vars
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

// Styled Box with dynamic styling for the chat bubble
const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'scrolled',
})<{ scrolled: boolean }>(({ scrolled, theme }) => ({
	position: 'fixed',
	bottom: '1rem',
	right: scrolled ? '1rem' : '50%',
	transform: scrolled ? 'translateX(0)' : 'translateX(50%)',
	width: scrolled ? '56px' : '400px',
	height: scrolled ? '56px' : 'auto',
	maxWidth: '90%',
	backgroundColor: scrolled ? theme.palette.primary.main : '#fafafa',
	borderRadius: scrolled ? '50%' : '20px',
	padding: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: scrolled ? 'white' : '#212121',
	transition: 'all 0.4s ease-in-out',
	boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
	zIndex: 1000,
	// Add pulsing effect when scrolled
	// animation: scrolled ? `${pulse} 2s infinite ease-in-out` : 'none',
}));

const ctaMessages = [
	'Er sikringsskapet mitt oppdatert?',
	2500,
	'Sikkerhet i hjemmet ditt! Oppgradere nå?',
	2500,
	'Hva koster en oppgradering?',
	2500,
	'Chat med oss – få svar på spørsmålene dine!',
	2500,
	'Vil du redusere strømregningen?',
	2500,
];

@observer
class CampaignChatCTA extends React.Component {
	static readonly contextType = StoreContext;
	scrolled: boolean = false;

	constructor(props: any) {
		super(props);
		makeObservable(this, {
			scrolled: observable,
			handleScroll: action,
		});
		this.handleScroll = this.handleScroll.bind(this);
	}

	get rootStore() {
		return this.context as RootStore;
	}

	get uiState() {
		return this.rootStore.uiState;
	}

	componentDidMount() {
		runInAction(() => {
			this.uiState.customerSupportChatOpen = true;
		});
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		const scrollTop = window.scrollY;
		if (scrollTop > 150 && !this.scrolled) {
			this.scrolled = true;
		} else if (scrollTop <= 150 && this.scrolled) {
			this.scrolled = false;
		}
	}

	render(): React.ReactNode {
		const { scrolled } = this;

		return (
			<StyledBox scrolled={!scrolled}>
				{scrolled ? (
					<RainbowBorderBox
						aiMode={!scrolled} // Disable the rainbow border when scrolled
						sx={{
							width: '100%',
							height: '100%',
							display: 'flex',
							flexGrow: 1,
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: scrolled ? '50%' : '20px',
						}}
					>
						<Stack
							spacing={1}
							direction="column"
							sx={{
								flexGrow: 1,
								padding: '0.5rem',
							}}
						>
							<Typography variant="overline" sx={{ marginRight: 2 }}>
								Hva lurer du på?
							</Typography>
							<Stack spacing={1} direction="row" alignItems="center">
								<Box
									sx={{
										flexGrow: 1,
										background: '#fafafa',
										border: '1px solid #ccc',
										borderRadius: 4,
										padding: '0.5rem',
									}}
								>
									<Typography variant="body2" sx={{ marginRight: 2, color: '#212121' }}>
										<TypeAnimation
											sequence={ctaMessages}
											wrapper="span"
											cursor={false}
											repeat={Infinity}
											speed={35}
										/>{' '}
									</Typography>
								</Box>
								<IconButton color="inherit">
									<SendIcon />
								</IconButton>
							</Stack>
						</Stack>
					</RainbowBorderBox>
				) : (
					<CustomerSupportChatFab />
				)}
			</StyledBox>
		);
	}
}

export default CampaignChatCTA;
