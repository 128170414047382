import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';

export type TrakEventDTO = {
	id?: number;
	apiKey: string;
	type: string;
	value?: string;
	experiment?: string;
	outcome?: string;
};

export type TrakEvent = TrakEventDTO & {
	created?: number;
	updated?: number;
	meta?: TrakMeta;
	session?: TrakSession;
};

export type TrakMeta = {
	id?: number;
	url: string;
	location?: TrakPosition;
};

export type TrakPosition = {
	country?: string;
	postCode?: string; // 0199 does not work well with number + some include letters
	postArea?: string;
	place?: string;
	lat?: number;
	lon?: number;
};

export type TrakSession = {
	id?: number;
	uid: string;
	sid: string;
	created: number;
	updated?: number;
};

/**
 * Trak
 */
export default class TrakApi {
	static async init(uid?: string, sid?: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/trak/init`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				uid,
				sid,
			}),
		});
	}

	static async track(uid: string, sid: string, event: TrakEventDTO, meta?: TrakMeta): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/trak/event`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				uid,
				sid,
				meta,
				event,
			}),
		});
	}
}
