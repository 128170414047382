import { observer } from 'mobx-react';
import { HookTypes, withHooks } from '../../utils/withHooks';
import React from 'react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import {
	Stack,
	Typography,
	Button,
	styled,
	Box,
	Paper,
	IconButton,
	Alert,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';

import PhoneNumberTextField from '../common/PhoneNumberTextField';
import VerificationCode from '../login/VerificationCode';
import Grid2 from '@mui/material/Unstable_Grid2';
import { services } from '../home/generic/GenericServices';
import CustomerSupportChatMessage from './CustomerSupportChatMessage';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StoreContext from '../../stores/StoreContext';
import CustomerServiceApi from '../../api/endpoints/CustomerServiceApi';
import { RootStore } from '../../stores/RootStore';
import AuthManager from '../../auth/AuthManager';
import { Api1881LookupContact } from '../../dto/profile.types';
import { CompanyDTO } from '../../dto/company.types';
import CompanyApi from '../../api/endpoints/CompanyApi';
import AddressCard from '../common/AddressCard';
import { Address, MessageType, MessageWidgetType } from '../../types';
import DotTypingAnimation from '../common/DotTypingAnimation';
import { TrakEvent } from '../../api/endpoints/TrakApi';
import ChatMessageText from '../chat/components/ChatMessageText';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import './CustomerSupportChatInput.scss';
import CustomerSupportChatAdornment from './CustomerSupportChatAdornment';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import ChatInput from '../chat/components/chat-input/ChatInput';
import ErrorBoundary from '../../utils/ErrorBoundary';

const classes = {
	root: 'FakeChat-root',
	contrast: 'FakeChat-contrast',
};

const StyledGrid2 = styled(Grid2)(({ theme: { palette } }) => ({
	flexGrow: 1,
	[`&.${classes.contrast}`]: {
		backgroundColor: palette.primary.main,
		color: palette.primary.contrastText,
		margin: '3rem 0',
	},
}));

const Section = styled(StyledGrid2)(() => ({
	width: '1024px',
	maxWidth: '100%',
	padding: '1rem',
	margin: '0 auto',
	flexGrow: 1,
}));

// eslint-disable-next-line no-unused-vars
enum TypingStatus {
	// eslint-disable-next-line no-unused-vars
	Done,
	// eslint-disable-next-line no-unused-vars
	Typing,
	// eslint-disable-next-line no-unused-vars
	Pending,
}

type Props = Pick<HookTypes, 'params' | 'navigate'> & {
	disableTopMargin?: boolean;
};

const CustomerSupportChat = observer(
	class CustomerSupportChat extends React.Component<Props> {
		static readonly contextType = StoreContext;

		chatMessagesEnd = React.createRef<HTMLDivElement>();
		scrollContainer = React.createRef<HTMLDivElement>();

		phoneNumber: string = '';
		showVerificationCode: boolean = false;

		typingStatus: TypingStatus = TypingStatus.Pending;

		message: string = '';

		address: Address | undefined;

		channel: any;

		contact: Api1881LookupContact | undefined;
		companies: CompanyDTO[] = [];
		firstMessageSent: boolean = false;

		job: any;

		trakEvent: TrakEvent | undefined;

		hasInitiated: boolean = false;
		numMessages: number = 0;
		showSignIn: boolean = false;
		showAiDisclaimer: boolean = true;
		aiDisclaimerTooltipOpen: boolean = false;

		openNewChatDialog: boolean = false;

		constructor(props: HookTypes) {
			super(props);
			makeObservable(this, {
				phoneNumber: observable,
				showVerificationCode: observable,
				typingStatus: observable,
				numMessages: observable,
				message: observable,
				channel: observable,
				address: observable,
				contact: observable,
				companies: observable,
				showSignIn: observable,
				job: observable,
				aiDisclaimerTooltipOpen: observable,
				openNewChatDialog: observable,
				showNewChatDialog: action,
				closeNewChatDialog: action,
				loadAddress: action,
				setAddress: action,
				sendMessage: action,
				setChannel: action,
				setMessage: action,
				setPhoneNumber: action,
				toggleVerificationCode: action,
				setTypingStatus: action,
				initTrak: action,
				init: action,
				checkIfShouldScrollToBottom: action,
				handleChatMessageKeyDown: action,
				onSendMessage: action,
				onToggleVerificationCode: action,
				handleAiTooltipClose: action,
				handleAiTooltipOpen: action,
				destroy: action,
				params: computed,
				history: computed,
				rootStore: computed,
				userStore: computed,
				profileStore: computed,
				chatStore: computed,
				channelMessages: computed,
				messages: computed,
				typingMessage: computed,
				service: computed,
				channelFromStore: computed,
			});
		}

		componentDidMount() {
			runInAction(() => {
				this.userStore.UserIdChanged.on(this.onInit);
				this.onInit();
			});
		}

		componentDidUpdate(): void {
			this.checkIfShouldScrollToBottom();
		}

		componentWillUnmount(): void {
			this.destroy();
		}

		destroy() {
			this.userStore.UserIdChanged.off(this.onInit);
		}

		checkIfShouldScrollToBottom = () => {
			if (this.channelMessages.length !== this.numMessages || this.typingMessage.length > 0) {
				this.numMessages = this.channelMessages.length;
				this.scrollToBottom();
			}
		};

		onInit = () => {
			this.init().catch((e) => console.error(e));
		};

		get params() {
			return this.props.params!;
		}

		get history() {
			return this.props.navigate!;
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get chatStore() {
			return this.rootStore.chatStore;
		}

		get channelFromStore() {
			if (!this.channel?.id) {
				return undefined;
			}
			return this.chatStore.findChannel(this.channel?.id, true);
		}

		get channelMessages() {
			return this.channelFromStore?.messages ?? [];
		}

		get messages() {
			return this.channelMessages.map((message) => {
				const profile = this.profileStore.getProfileByFilter({
					userId: parseInt(message.userId),
					profileId: message.profileId,
					workspaceId: this.channelFromStore?.workspaceId
						? parseInt(this.channelFromStore.workspaceId)
						: undefined,
				});
				const image = profile?.profilePicture ?? undefined;
				const name = profile?.name ?? undefined;

				return {
					message,
					image,
					right: message.sessionId === this.rootStore.sessionId,
					name: message.sessionId === this.rootStore.sessionId ? 'Deg' : name ?? 'Kundeservice',
					icon: message.sessionId === this.rootStore.sessionId ? <PersonIcon /> : <SupportAgentIcon />,
					profile,
				};
			});
		}

		get service() {
			if (this.params.service) {
				return services[this.params.service as unknown as keyof typeof services];
			}

			return undefined;
		}

		get typingMessage() {
			if (!this.channel?.id) {
				return [];
			}
			return this.chatStore.findChannel(this.channel?.id, true)?.typing ?? [];
		}

		async init() {
			if (this.hasInitiated) {
				return;
			}

			this.hasInitiated = true;
			if (this.rootStore.userStore.isLoggedIn) {
				this.loadAddress().catch((e) => console.error(e));
			}

			if (!this.channel && this.rootStore.sessionId) {
				const response = await CustomerServiceApi.createRequest(this.rootStore.sessionId, {
					name: 'Kundehenvendelse',
					topic: this.service?.title,
					description: this.service?.description,
				});
				if (response.statusCode === 200) {
					this.setChannel(response.data);
					this.loadCompaniesNearby().catch((e) => console.error(e));
				}
			} else if (!this.rootStore.sessionId) {
				console.log('Customer service - No session id, delaying re-init 1 sec');
				this.hasInitiated = false;
				setTimeout(() => {
					this.init();
				}, 1000);
				return;
			}

			this.initTrak();
		}

		async initTrak() {
			this.trakEvent = await this.rootStore.trakEvent({
				type: 'OrderChat',
				value: this.service?.safeText ?? 'loaded',
				experiment: `CustomerSupportChat`,
			});
		}

		showNewChatDialog = () => {
			this.openNewChatDialog = true;
		};

		closeNewChatDialog = () => {
			this.openNewChatDialog = false;
		};

		loadCompaniesNearby = async () => {
			const result = await CompanyApi.loadCompaniesNearUser();
			if (result.statusCode === 200) {
				runInAction(() => {
					this.companies = result.data;
				});
			}
		};

		setChannel = (channel: any) => {
			this.channel = channel;
			this.chatStore.addChannelsIfNotExists([channel]);
			if (channel.messages.length > 3) {
				// ask if user want to start a new chat or continue this one
				this.showNewChatDialog();
			}

			runInAction(() => {
				if (this.messages.length < 1 && !this.firstMessageSent && this.service?.safeText) {
					this.firstMessageSent = true;
					this.message = `Hei, jeg er interessert i ${this.service?.safeText ?? 'assistanse'}.`;
					setTimeout(() => {
						this.sendMessage();
					}, 1000);
				}
			});
		};

		setPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			this.phoneNumber = e.target.value;
		};

		setTypingStatus = (status: TypingStatus) => {
			this.typingStatus = status;
		};

		onToggleVerificationCode = () => {
			this.toggleVerificationCode().catch((e) => console.error(e));
		};

		toggleVerificationCode = async () => {
			this.showVerificationCode = !this.showVerificationCode;
			this.trakEvent = await this.rootStore.trakEvent({
				type: 'OrderChat',
				value: this.service?.safeText ?? 'loaded',
				experiment: `CustomerSupportChat`,
				outcome: 'PhoneAuthShown',
			});

			await AuthManager.phoneAuth(undefined, this.phoneNumber);
		};

		setMessage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			this.message = e.target.value;
		};

		handleChatMessageKeyDown = (event: any) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				event.stopPropagation();
				this.onSendMessage();
			}
		};

		onSendMessage = (e?: any) => {
			try {
				if (e) {
					e.preventDefault();
					e.stopPropagation();
				}
			} catch (e) {
				console.error(e);
			}
			this.sendMessage().catch((e) => console.error(e));
		};

		sendMessage = async () => {
			if (!this.rootStore.sessionId) {
				return;
			}
			if (this.message && this.message.length >= 1) {
				const response = await CustomerServiceApi.sendMessage(this.rootStore.sessionId, this.message, {
					channelId: this.channel?.id,
					name: this.channel?.name,
					topic: this.channel?.topic,
					description: this.channel?.description,
				});
				if (response.statusCode === 200) {
					const message = response.data;
					if (message.id && message.channelId) {
						this.chatStore.updateChannelMessagesFromServer(message.channelId, { messages: [message] });
					}
					runInAction(() => {
						this.message = '';
					});
				}
			}

			this.scrollToBottom();
		};

		loadAddress = async () => {
			const existingAddress = this.profileStore.mostRecentUserAddress;
			this.trakEvent = await this.rootStore.trakEvent({
				type: 'OrderChat',
				value: this.service?.safeText ?? 'loaded',
				experiment: `CustomerSupportChat`,
				outcome: 'LoadAddress',
			});
			if (existingAddress) {
				this.address = existingAddress;
				return;
			}

			const result = await CustomerServiceApi.getContact();
			if (result.statusCode === 200) {
				runInAction(() => {
					const { contact, companies } = result.data;
					this.contact = contact;
					this.companies = companies;

					if (this.contact?.geography.address) {
						const address = this.contact?.geography.address;
						this.address = {
							addressString: `${address.street} ${address.houseNumber}${address.entrance}`,
							street: address.street,
							houseNumber: address.houseNumber,
							entrance: address.entrance,
							postCode: address.postCode,
							postArea: address.postArea,
						};
					}
				});
			}
		};

		setAddress = async (address: Address) => {
			this.address = address;

			if (!this.rootStore.sessionId) {
				return;
			}

			//  let's try to create the job
			const result = await CustomerServiceApi.tryCreateJob(
				this.rootStore.sessionId,
				this.address,
				`Jeg har behov for ${this.service?.safeText ?? 'assistanse'}`
			);
			if (result.statusCode === 200) {
				runInAction(() => {
					try {
						if ((window as any).dataLayer) {
							(window as any).dataLayer.push({
								event: 'order-chat-customer-service',
								value: 25,
							});
						}
					} catch (e) {
						console.error(e);
					}

					this.job = result.data;
					if (this.job) {
						this.rootStore
							.trakEvent({
								type: 'OrderChat',
								outcome: 'JobCreated',
								value: `${this.job.company} - ${this.service?.safeText ?? 'assistanse'}`,
								experiment: `CustomerSupportChat`,
							})
							.catch((e) => console.error(e));
						this.history(`/app/projects/${this.job.id}/chat/${this.channel?.id}`);
					}
				});
			}
		};

		renderSignIn(widget?: any) {
			if (!this.userStore.isLoggedIn) {
				// check if widget.referenceId is an 8 digit number
				let phoneNumber = this.phoneNumber;
				if (
					!this.phoneNumber &&
					widget?.referenceId &&
					widget.referenceId.toString().length === 8 &&
					isFinite(widget.referenceId)
				) {
					runInAction(() => (this.phoneNumber = widget.referenceId));
				}
				return (
					<Stack direction="row-reverse">
						<Paper elevation={2} sx={{ padding: '1rem', background: '#fafafa' }}>
							<Stack direction="column" spacing={2}>
								<Alert severity="info">
									<Typography variant="body2" sx={{ flexGrow: 1 }}>
										Du får en SMS med lenke til samtalen når bedriften har sendt deg et svar.
									</Typography>
								</Alert>

								{this.showVerificationCode && this.phoneNumber ? (
									<>
										<Typography variant="body2" gutterBottom>
											Legg inn ditt mobilnummer.
										</Typography>
										<VerificationCode
											phoneNumber={phoneNumber}
											isCompact
											actionLabel="Verifiser"
											onSuccess={this.loadAddress}
										/>
									</>
								) : (
									<>
										<PhoneNumberTextField onChange={this.setPhoneNumber} value={phoneNumber} />

										<Typography variant="body2" gutterBottom>
											* Vi sender deg en SMS med en kode som du må skrive inn for å bekrefte at du
											er eier av mobilnummeret.
										</Typography>
										<Button
											variant="contained"
											onClick={this.onToggleVerificationCode}
											sx={{ mt: '1rem !important' }}
											disabled={this.phoneNumber.length < 8}
										>
											Neste
										</Button>
									</>
								)}
							</Stack>
						</Paper>
					</Stack>
				);
			} else {
				return (
					<Stack direction="column" sx={{ width: '400px', maxWidth: '90vw', mt: 2 }}>
						{!this.address?.addressString && (
							<Alert severity="warning">
								Beklager vi klarte ikke å finne adressen din. Vennligst fyll inn manuelt.
							</Alert>
						)}
						<AddressCard address={this.address} onAddressSaved={this.setAddress} editOnInit preventSave />
					</Stack>
				);
			}
		}

		scrollToBottom = () => {
			this.chatMessagesEnd.current?.scrollIntoView();
			if (this.scrollContainer.current) {
				this.scrollContainer.current.scrollTop = this.scrollContainer.current?.scrollHeight;
			}
		};

		handleAiTooltipClose = () => {
			this.aiDisclaimerTooltipOpen = false;
		};

		handleAiTooltipOpen = () => {
			this.aiDisclaimerTooltipOpen = true;
		};

		startNewChat = async () => {
			// we need to reset rootStore.sessionId and earse all chat history, or make up a new topic.
			AsyncStorageHelper.removeCache('sessionId');
			window.location.reload();
		};

		renderChat() {
			const { disableTopMargin } = this.props;

			return (
				<Section
					sx={{
						overflowX: 'hidden',
						overflowY: 'auto',
						position: 'relative',
						paddingTop: disableTopMargin ? 'none' : '3rem',
						boxSizing: 'border-box',
					}}
				>
					<CustomerSupportChatAdornment service={this.params?.service} />
					<Stack direction="column" spacing={2} ref={this.scrollContainer}>
						{this.showAiDisclaimer && (
							<Box>
								<div>
									<IconButton onClick={this.handleAiTooltipOpen}>
										<HelpOutlineIcon />
									</IconButton>
									<Typography
										variant="caption"
										sx={{ color: 'text.secondary' }}
										onClick={this.handleAiTooltipOpen}
									>
										Du snakker med vår KI. Trykk for mer info.
									</Typography>
								</div>

								<Dialog open={this.aiDisclaimerTooltipOpen} onClose={this.handleAiTooltipClose}>
									<DialogContent>
										<DialogContentText>
											<Typography variant="h5">KI kundemedarbeider</Typography>
											<Typography variant="body2" gutterBottom>
												Du snakker med vår KI kundemedarbeider. Den er tunet for å hjelpe deg
												med å finne riktig bedrift og sende forespørsler. Den forstår tekst og
												bilder.
											</Typography>
											<Typography variant="body2" gutterBottom>
												Samtalen mellom deg og KIen blir lagret og brukt til å opprette en
												beskrivelse av det du trenger hjelp med. Bedriften som mottar
												forespørselen vil se samtalen og kan svare på den.
											</Typography>
											<Typography variant="body2" gutterBottom>
												Den kunstige intelligensen er instruert til å hjelpe deg, og samle
												informasjonen den trenger for å finne den perfekt bedriften for deg.
												KIen kan svare feil og har ikke mulighet til å inngå bindende avtaler.
											</Typography>
											<Typography variant="body2" gutterBottom>
												Mennesker på kundeservice vil også kunne se samtalen og svare på den.
												Samtalen overvåkes for å forhindre misbruk og for å forbedre tjenesten.
											</Typography>
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={this.handleAiTooltipClose}>Lukk</Button>
									</DialogActions>
								</Dialog>
							</Box>
						)}

						{!this.channel && (
							<>
								<Typography variant="body2" gutterBottom>
									Kobler til kundeservice...
								</Typography>
								<DotTypingAnimation />
							</>
						)}

						{this.messages.map((message, index) => {
							const [widget] = message.message.widgets ?? [];
							if (
								message.message.type === MessageType.WIDGET &&
								widget?.type === MessageWidgetType.CustomerSupportChatLogin
							) {
								return <Box key={index}>{this.renderSignIn(widget)}</Box>;
							} else if (message.message.type && [MessageType.MESSAGE].includes(message.message.type)) {
								return (
									<CustomerSupportChatMessage
										key={index}
										name={message.name}
										icon={message.icon}
										image={message.image}
										right={message.right}
										message={message.message}
										profile={message.profile}
									>
										<ChatMessageText text={message.message.text} />
									</CustomerSupportChatMessage>
								);
							}
							return null;
						})}

						{this.renderTypingChatMessage()}
					</Stack>
					<div style={{ float: 'left', clear: 'both', height: 10 }} ref={this.chatMessagesEnd} />

					{this.showSignIn && this.renderSignIn()}
				</Section>
			);
		}

		renderTypingChatMessage() {
			const names = this.typingMessage
				.filter(
					(p: any) =>
						p.userId && p.userId.toString() !== this.profileStore.currentUserProfile?.userId.toString()
				)
				.map((p: any) => {
					const profile = this.profileStore.getProfile(p.userId);

					if (!profile) {
						return null;
					}

					return profile.name;
				})
				.filter((p: any) => p);

			if (names.length === 0) {
				return this.typingMessage.length > 0 ? this.renderTyping() : null;
			}

			return names.map((name: any, index: number) => {
				return (
					<CustomerSupportChatMessage
						key={index}
						name={name}
						icon={<SupportAgentIcon />}
						image={undefined}
						right={false}
					>
						<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
							<DotTypingAnimation />
						</Box>
					</CustomerSupportChatMessage>
				);
			});
		}

		renderTyping() {
			const names = this.typingMessage
				.filter(
					(p: any) =>
						p.userId && p.userId.toString() !== this.profileStore.currentUserProfile?.userId.toString()
				)
				.map((p: any) => {
					const profile = this.profileStore.getProfile(p.userId);

					if (!profile) {
						return null;
					}

					return profile.name;
				})
				.filter((p: any) => p);

			// compose a string with the names
			let text = '';
			if (names.length === 1) {
				text = `${names[0]} skriver`;
			} else if (names.length === 2) {
				text = `${names[0]} og ${names[1]} skriver`;
			} else if (names.length > 2) {
				text = `${names[0]}, ${names[1]} og ${names.length - 2} andre skriver`;
			}

			if (!text) {
				return null;
			}
			return (
				<Typography variant="body2" sx={{ color: 'text.secondary', ml: 2, mb: 1 }}>
					{text}
					<DotTypingAnimation />
				</Typography>
			);
		}

		render() {
			return (
				<Box
					sx={{
						display: 'flex',
						position: 'relative',
						flexDirection: 'column',
						height: 'calc(100% - 1rem)',
						backgroundColor: '#f9f9f9',
					}}
				>
					{this.renderChat()}

					{this.channelFromStore && (
						<ErrorBoundary>
							<ChatInput channel={this.channelFromStore} customerServiceMode imageUploadOnly />
						</ErrorBoundary>
					)}
					<Dialog open={this.openNewChatDialog} onClose={this.closeNewChatDialog}>
						<Box sx={{ p: 2 }}>
							<Typography variant="subtitle2" gutterBottom>
								Vil du fortsette samtalen eller starte en ny?
							</Typography>
							<Stack direction="row" spacing={2}>
								<Button variant="outlined" size="small" onClick={this.startNewChat}>
									Ny samtale
								</Button>
								<Button variant="contained" size="small" onClick={this.closeNewChatDialog}>
									Fortsett
								</Button>
							</Stack>
						</Box>
					</Dialog>
				</Box>
			);
		}
	}
);

export default withHooks(CustomerSupportChat);
