import { Workspace, WorkspaceDTO } from '../../types';
import config from '../../config/config';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import { PricingPlan } from '../../stores/PricingPlanStore';

export type WorkspaceApiRequest = Workspace | { message: string };

export enum AccountTypeHidden {
	// eslint-disable-next-line no-unused-vars
	ALL = 'ALL',
	// eslint-disable-next-line no-unused-vars
	NONE = 'NONE',
	// eslint-disable-next-line no-unused-vars
	PUBLIC = 'PUBLIC',
}

export enum AccountTypeKey {
	// eslint-disable-next-line no-unused-vars
	Admin = 'admin',
	// eslint-disable-next-line no-unused-vars
	Manager = 'manager',
	// eslint-disable-next-line no-unused-vars
	Employee = 'employee',
	// eslint-disable-next-line no-unused-vars
	WorkspaceAdmin = 'workspaceAdmin',
	// eslint-disable-next-line no-unused-vars
	SuperAdmin = 'superAdmin',
	// eslint-disable-next-line no-unused-vars
	Disabled = 'disabled',
}

export type AccountType = {
	id: number;
	key: AccountTypeKey;
	name: string;
	hidden: AccountTypeHidden;
	description: string;
};

export type ProjectLeadDto = {
	id: number;
	created: string;
	updated: string; // 2021-08-31T11:00:00.000Z
	status: string;
	workspaceId: string;
	workspaceName: string;
	name: string;
	tags?: string[];
	category?: string;
	urgency?: string;
	hasUnhandledCustomerMessages: boolean;
	estimatedBudget: number;
	estimatedHours: number;
};

export type LinkHitsByMonth = {
	year: number;
	month: number;
	value: number;
};

export type LinksHitsByMonth = {
	key: string;
	name: string;
	hits: LinkHitsByMonth[];
};

export type LinkCodeType = {
	key: string;
	name: string;
	code: string;
};

type getLinkHitsStatsResponse = {
	links: LinkCodeType[];
	hits: LinksHitsByMonth[];
};

/**
 * Workspace API
 */
export default class APIWorkspace {
	static async getLinkHitsStats(): Promise<FetchWrapperTypedResponse<getLinkHitsStatsResponse>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/hits/stats`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async getProjectLeads(
		skip: number = 0,
		limit: number = 25
	): Promise<FetchWrapperTypedResponse<ProjectLeadDto[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/projectLeads?skip=${skip}&limit=${limit}`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async getAccountTypes(): Promise<FetchWrapperTypedResponse<AccountType[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/accountTypes`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getCompanyInfoFromOrgNumber(orgNumber: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/companies/${orgNumber}`;
		return FetchWrapper.get(url);
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getHits(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/hits`;
		return FetchWrapper.get(url);
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getChildren(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/children`;
		return FetchWrapper.get(url);
	}

	static async removeChild(childId: string): Promise<FetchWrapperTypedResponse<{ removed: boolean }>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/children`;
		return FetchWrapper.delete(url, {
			body: JSON.stringify({
				childId,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getChildrenUsage(year?: number, month?: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/children/usage`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				month,
				year,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getChildrenUsageCsv(year?: number, month?: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/children/usage/csv`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				month,
				year,
			}),
			requireAuth: true,
			blobResponse: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async createWorkspace(workspaceInfo: any, dryRun: boolean = true): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/children/create`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				workspace: workspaceInfo,
				dryRun,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async getPricingPlans(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/plan`;
		return FetchWrapper.get(url);
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>}
	 */
	static async setPricingPlan(pricingPlan: PricingPlan): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/plan`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				pricingPlanId: pricingPlan.id,
			}),
			requireAuth: true,
		});
	}

	static async addWorkspaceMember(
		countryCode: string,
		phoneNumber: string,
		accountType: AccountType,
		profileData: any
	): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/member/add`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				countryCode,
				phoneNumber,
				accountType,
				profileData,
			}),
			requireAuth: true,
		});
	}

	static async findUserByPhoneNumber(countryCode: string = '47', phoneNumber: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/member/lookup/phone`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				countryCode,
				phoneNumber,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @param  {String} domain
	 * @returns {Promise<WorkspaceDto>} workspace
	 */
	static async getMetadata(domain: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/metadata/${domain}`;
		return FetchWrapper.get(url);
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} workspace
	 */
	static async getSettings(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/settings`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} workspace
	 */
	static async saveSettings(settings: any): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/settings`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				settings: settings,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<FetchWrapperResponse>} workspace
	 */
	static async saveSetting(category: string, key: string, value: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/setting`;
		return FetchWrapper.post(url, {
			body: JSON.stringify({
				category,
				key,
				value,
			}),
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<WorkspaceDto>} workspace
	 */
	static async getBill(): Promise<any> {
		try {
			const host = config.hosts.api;
			const url = `//${host}/v1/workspace/billing`;
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});

			if (response.status !== 200) {
				console.warn('Workspace billing issue: ', response);
			}

			return await response.json();
		} catch (error: any) {
			throw new Error(`issue with things ${error}`);
		}
	}

	/**
	 * @returns {Promise<WorkspaceDto>} workspace
	 */
	static async getWorkspace(): Promise<FetchWrapperTypedResponse<WorkspaceDTO | null>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<WorkspaceDto>} workspace
	 */
	static async getWorkspaceStats(): Promise<any | null> {
		try {
			const host = config.hosts.api;
			const url = `//${host}/v1/workspace/stats`;
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});

			if (response.status !== 200) {
				console.warn('Workspace stats issue: ', response);
			}

			return (await response.json()) as any;
		} catch (error) {
			return null;
		}
	}

	/**
	 * @returns {Promise<any>} workspace members
	 */
	static async getMembers(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/members`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	/**
	 * @returns {Promise<any>} workspace members
	 */
	static async updateMember(userId: string, data: any): Promise<any> {
		try {
			const host = config.hosts.api;
			const url = `//${host}/v1/workspace/member`;
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					userId,
					...data,
				}),
			});

			if (response.status !== 200) {
				console.warn('Workspace metadata issue: ', response);
			}

			return (await response.json()) as any[];
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * @returns {Promise<any>} workspace members
	 */
	static async saveCompany(company: any): Promise<any> {
		try {
			const host = config.hosts.api;
			const url = `//${host}/v1/workspace/company`;
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					company,
				}),
			});

			if (response.status !== 200) {
				console.warn('Workspace metadata issue: ', response);
			}

			return (await response.json()) as any[];
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * @returns {Promise<any>} workspace members
	 */
	static async saveCompanyLogo(companyId: string, fileId: string): Promise<any> {
		try {
			const host = config.hosts.api;
			const url = `//${host}/v1/workspace/company/logo`;
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					fileId,
					companyId,
				}),
			});

			if (response.status !== 200) {
				console.warn('Workspace logo issue: ', response);
			}

			return (await response.json()) as any[];
		} catch (error) {
			return error;
		}
	}

	/**
	 * @returns {Promise<WorkspaceDto>} workspace
	 */
	static async getGrants(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/workspace/grants`;
		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}
}
