import { makeAutoObservable } from 'mobx';
import { RootStore } from '../RootStore';
import Checklist from './Checklist';
import ChecklistApi from '../../api/endpoints/ChecklistApi';

export class ChecklistStore {
	rootStore: RootStore;

	checklists: Checklist[] = [];
	draftChecklist: Checklist | null = null;

	isInitialized = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this, {
			rootStore: false,
		});

		this.reset();
		this.init().catch((error) => {
			console.error('Failed to initialize ChecklistStore', error);
		});
	}

	async init() {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.reset();
			this.getChecklists().catch((error) => {
				console.info('Failed to get checklists', error);
			});
		});
		this.rootStore.userStore.SignedOut.on(() => {
			this.reset();
		});
	}

	reset() {
		this.checklists = [];
		this.setDraftChecklist();
	}

	async deleteChecklist(id: number) {
		const result = await ChecklistApi.deleteChecklist(id);
		if (result.statusCode === 200) {
			this.checklists = this.checklists.filter((checklist) => checklist.id !== id);
		} else {
			throw new Error('Failed to delete checklist');
		}
	}

	setDraftChecklist() {
		this.draftChecklist = new Checklist({
			id: 0,
			name: '',
			description: '',
			items: [],
			execution: null,
		});
	}

	async getChecklists() {
		// noo checklists for customers
		// todo: add block
		const result = await ChecklistApi.getChecklists();
		if (result.statusCode === 200) {
			this.checklists = result.data.map((dto) => new Checklist(dto));
		} else {
			throw new Error('Failed to get checklists');
		}
	}

	getChecklistById(id: number) {
		return this.checklists.find((checklist) => checklist.id === id);
	}
}
