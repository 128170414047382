import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Company } from '../../../stores/company/Company';

type Props = {
	company: Company;
};

const CompanyName = observer(
	class CompanyName extends React.Component<Props> {
		render() {
			return (
				<Typography
					gutterBottom
					variant="h5"
					sx={{
						wordBreak: 'break-word',
					}}
				>
					{this.props.company.name}
				</Typography>
			);
		}
	}
);

export default CompanyName;
