import { Box, BoxProps } from '@mui/material';
import React from 'react';

import './DotTypingAnimation.scss';

export default class DotTypingAnimation extends React.Component<BoxProps> {
	render() {
		const { ...rest } = this.props;
		return (
			<Box className="dot-typing" {...rest}>
				<span></span>
				<span></span>
				<span></span>
			</Box>
		);
	}
}
