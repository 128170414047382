import { observer } from 'mobx-react';
import { Project } from '../../../../stores/Project';
import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Slide,
	IconButton,
	Typography,
	Stack,
	Switch,
} from '@mui/material';
import ShareProjectForm from './ShareProjectForm';
import { TransitionProps } from '@mui/material/transitions';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { action, makeObservable, observable } from 'mobx';
import { ProfileDTO } from '../../../../dto/profile.types';
import ShareProjectInvite from './ShareProjectInvite';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

type ShareProjectDialogProps = {
	open: boolean;
	onClose: () => void;
	project: Project;
};

const ShareProjectDialog = observer(
	class ShareProjectDialog extends React.Component<ShareProjectDialogProps> {
		view: 'share' | 'settings' | 'info' | 'send-share-invite' = 'share';

		values: Array<ProfileDTO | string> = [];

		constructor(props: ShareProjectDialogProps) {
			super(props);

			makeObservable(this, {
				view: observable,
				values: observable,
				setView: action,
				setShareView: action,
				setSettingsView: action,
				setInfoView: action,
				init: action,
				onShareAccessChange: action,
			});
		}

		componentDidMount(): void {
			this.init();
		}

		init = async () => {
			this.setView('share');
			const { project } = this.props;
			await project.getSharedAccess();
		};

		setView = (view: 'share' | 'settings' | 'info' | 'send-share-invite') => {
			this.view = view;
		};

		setShareView = () => {
			this.setView('share');
		};

		setSettingsView = () => {
			this.setView('settings');
		};

		setInfoView = () => {
			this.setView('info');
		};

		setSendShareInviteView = () => {
			this.setView('send-share-invite');
		};

		renderTitle() {
			const { project } = this.props;

			if (this.view !== 'share') {
				let title = `Innstillinger for "${project.name ?? project.formattedAddress ?? project.id}"`;
				if (this.view === 'info') {
					title = 'Om deling';
				}
				if (this.view === 'send-share-invite') {
					title = `Del "${project.name ?? project.formattedAddress ?? project.id}"`;
				}

				return (
					<DialogTitle>
						<Grid2 container justifyContent="flex-start" alignItems="center">
							<Grid2>
								<IconButton onClick={this.setShareView}>
									<ArrowBackIcon />
								</IconButton>
							</Grid2>
							<Grid2>
								<Typography variant="h6" className="text-ellipsis">
									{title}
								</Typography>{' '}
							</Grid2>
						</Grid2>
					</DialogTitle>
				);
			}

			return (
				<DialogTitle>
					<Grid2 container justifyContent="space-between" alignItems="center">
						<Grid2>
							<Typography variant="h6" className="text-ellipsis">
								Del "{project.name ?? project.formattedAddress ?? project.id}"
							</Typography>{' '}
						</Grid2>
						<Grid2>
							<IconButton onClick={this.setInfoView}>
								<HelpOutlineOutlinedIcon />
							</IconButton>
							<IconButton onClick={this.setSettingsView}>
								<SettingsOutlinedIcon />
							</IconButton>
						</Grid2>
					</Grid2>
				</DialogTitle>
			);
		}

		onShareAccessChange = (values: Array<ProfileDTO | string>) => {
			console.log('auto: ShareProjectDialog: onSearchInputChange', values);
			this.values = values;
			if (values.length > 0) {
				this.setSendShareInviteView();
			}
		};

		saveShareAccess = async () => {
			console.log('auto: ShareProjectDialog: saveShareAccess', this.values);
		};

		renderContent() {
			const { project } = this.props;
			switch (this.view) {
				case 'send-share-invite':
					return <ShareProjectInvite project={project} onClose={this.saveShareAccess} values={this.values} />;
				case 'share':
					return (
						<>
							<Typography variant="body2" gutterBottom>
								Gi andre tilgang til samtaler i denne jobben.
							</Typography>
							<ShareProjectForm project={project} onShareAccessChange={this.onShareAccessChange} />
						</>
					);
				case 'settings':
					return (
						<Stack spacing={2}>
							<Stack spacing={1} direction="row">
								<Typography variant="body2">
									Vis prosjektbeskrivelse for de som har tilgang til samtaler knyttet til jobben
								</Typography>
								<Switch checked={true} />
							</Stack>
							<Stack spacing={1} direction="row">
								<Typography variant="body2">
									Vis kontaktinformasjon til kunde og kontaktperson for de som har tilgang til
									samtaler knyttet til jobben
								</Typography>
								<Switch checked={true} />
							</Stack>
							<Stack spacing={1} direction="row">
								<Typography variant="body2">
									Vis oppdragsadresse til de som har tilgang til samtaler knyttet til jobben
								</Typography>
								<Switch checked={true} />
							</Stack>
						</Stack>
					);
				case 'info':
					return (
						<Stack spacing={0}>
							<Typography variant="h6">Kundechat</Typography>
							<Typography variant="body2" gutterBottom>
								Når du deler med tilgang til kundechat, kan personen se alt innhold i samtale med kuden
								på denne jobben. Dette inkluderer meldinger, bilder, dokumenter og annet innhold.
							</Typography>
							<Typography variant="h6">Partnerchat</Typography>
							<Typography variant="body2" gutterBottom>
								Partnerchat brukes for å samarbeide med partnere og leverandører. Når du deler tilgang
								til partnerchat, kan personen se alt innhold i samtale med partnere og leverandører på
								denne jobben. Dette inkluderer meldinger, bilder, dokumenter og annet innhold.
							</Typography>
							<Typography variant="h6">Kunde- og partnerchat</Typography>
							<Typography variant="body2" gutterBottom>
								Tilgang til både kundechat og partnerchat gir personen eller bedriften tilgang til alt
								innhold i samtale med kunden og partnere/leverandører på denne jobben. Dette inkluderer
								meldinger, bilder, dokumenter og annet innhold. Denne tilgangen brukes for eksempel når
								flere bedrifter samarbeider om en jobb, eller når en bedrift har en underleverandør som
								skal ha tilgang til kundechat.
							</Typography>
						</Stack>
					);
				default:
					return null;
			}
		}

		render() {
			const { open, onClose } = this.props;
			return (
				<Dialog
					open={open}
					onClose={onClose}
					TransitionComponent={Transition}
					scroll="paper"
					fullScreen={false}
					fullWidth
					maxWidth="sm"
					sx={{
						transition: 'all 0.2s ease-in-out',
					}}
				>
					{this.renderTitle()}
					<DialogContent>{this.renderContent()}</DialogContent>
					<DialogActions>
						{this.view === 'send-share-invite' && (
							<>
								<Button
									variant="text"
									onClick={this.setShareView}
									sx={{
										margin: '1rem',
										borderRadius: '1rem',
									}}
								>
									Avbryt
								</Button>
								<Button
									variant="contained"
									onClick={onClose}
									color="primary"
									sx={{
										margin: '1rem',
										borderRadius: '1rem',
									}}
								>
									Inviter
								</Button>
							</>
						)}
						{this.view !== 'send-share-invite' && (
							<Button
								variant="contained"
								onClick={onClose}
								color="primary"
								sx={{
									margin: '1rem',
									borderRadius: '1rem',
								}}
							>
								Ferdig
							</Button>
						)}
					</DialogActions>
				</Dialog>
			);
		}
	}
);

export default ShareProjectDialog;
