import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import config from '../../config/config';
import {
	PartnershipDTO,
	PartnershipRequestDTO,
	PremiumPartnershipListingDTO,
} from '../../stores/partnership/Partnership.types';
import { PartnerWithNameAndDistance } from '../../stores/partnership/PartnershipManager';

/**
 * API for handling operations related to Agents.
 */
export default class PartnershipApi {
	static async fetchPartnersWithDistanceFromAddress(
		addressId: number
	): Promise<FetchWrapperTypedResponse<PartnerWithNameAndDistance[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/partners/address/${addressId}`;
		return FetchWrapper.get(url, { requireAuth: true });
	}

	/**
	 * Gets all partners.
	 * @returns {Promise<FetchWrapperTypedResponse<PartnershipDTO[]>>} The response from the API.
	 */
	static async getPartners(): Promise<FetchWrapperTypedResponse<PartnershipDTO[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/partners`;
		return FetchWrapper.get(url, { requireAuth: true });
	}

	static async getPartnershipRequests(): Promise<FetchWrapperTypedResponse<PartnershipRequestDTO[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/requests`;
		return FetchWrapper.get(url, { requireAuth: true });
	}

	static async getPremiumPartnershipListings(): Promise<FetchWrapperTypedResponse<PremiumPartnershipListingDTO[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/listings`;
		return FetchWrapper.get(url, { requireAuth: true });
	}

	static async acceptRequest(requestId: number): Promise<FetchWrapperTypedResponse<PartnershipRequestDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/request/${requestId}/accept`;
		return FetchWrapper.post(url, { requireAuth: true });
	}

	static async declineRequest(requestId: number): Promise<FetchWrapperTypedResponse<PartnershipRequestDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/request/${requestId}/decline`;
		return FetchWrapper.post(url, { requireAuth: true });
	}

	static async cancelRequest(requestId: number): Promise<FetchWrapperTypedResponse<PartnershipRequestDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/request/${requestId}/cancel`;
		return FetchWrapper.post(url, { requireAuth: true });
	}

	static async rejectPartnership(partnershipId: number): Promise<FetchWrapperTypedResponse<PartnershipDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/partnership/${partnershipId}`;
		return FetchWrapper.delete(url, { requireAuth: true });
	}

	static async saveTags(partnershipId: number, tags: string[]): Promise<FetchWrapperTypedResponse<PartnershipDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/partnership/${partnershipId}/tags`;
		return FetchWrapper.put(url, { requireAuth: true, body: JSON.stringify({ tags }) });
	}

	static async requestPartnership(
		listingId: number,
		requestMessage?: string,
		tags: string[] = []
	): Promise<FetchWrapperTypedResponse<PartnershipDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/listings/${listingId}/request`;
		return FetchWrapper.post(url, { requireAuth: true, body: JSON.stringify({ requestMessage, tags }) });
	}

	static async createRequest(
		request: PartnershipRequestDTO
	): Promise<FetchWrapperTypedResponse<PartnershipRequestDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/partnership/request`;
		return FetchWrapper.post(url, { requireAuth: true, body: JSON.stringify(request) });
	}
}
