import React from 'react';
import { HookTypes, withLocation } from '../utils/withHooks';

type Props = HookTypes;

class ScrollToTop extends React.Component<Props> {
	get location() {
		return this.props.location!;
	}
	componentDidUpdate(prevProps: any) {
		if (this.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return null;
	}
}

export default withLocation(ScrollToTop);
