import { Address } from '../types';
import { ChannelDTO } from './channel.types';

export enum ProjectStatusDTO {
	// eslint-disable-next-line no-unused-vars
	Requested = 'REQUESTED',
	// eslint-disable-next-line no-unused-vars
	Received = 'RECEIVED',
	// eslint-disable-next-line no-unused-vars
	Planned = 'PLANNED',
	// eslint-disable-next-line no-unused-vars
	Hold = 'HOLD',
	// eslint-disable-next-line no-unused-vars
	Active = 'ACTIVE',
	// eslint-disable-next-line no-unused-vars
	Completed = 'COMPLETED',
	// eslint-disable-next-line no-unused-vars
	Aborted = 'ABORTED',
	// eslint-disable-next-line no-unused-vars
	Unknown = 'UNKNOWN',
	// eslint-disable-next-line no-unused-vars
	Assigned = 'ASSIGNED',
	// eslint-disable-next-line no-unused-vars
	OfferSent = 'OFFER_SENT',
	// eslint-disable-next-line no-unused-vars
	OfferAccepted = 'OFFER_ACCEPTED',
	// eslint-disable-next-line no-unused-vars
	OfferRejected = 'OFFER_REJECTED',
	// eslint-disable-next-line no-unused-vars
	OfferExpired = 'OFFER_EXPIRED',
	// eslint-disable-next-line no-unused-vars
	ProjectLost = 'PROJECT_LOST',
}
export enum ProjectMemberRoleDTO {
	// eslint-disable-next-line no-unused-vars
	Owner = 'OWNER',
	// eslint-disable-next-line no-unused-vars
	Internal = 'INTERNAL',
	// eslint-disable-next-line no-unused-vars
	External = 'EXTERNAL',
	// eslint-disable-next-line no-unused-vars
	Admin = 'ADMIN',
	// eslint-disable-next-line no-unused-vars
	Customer = 'CUSTOMER',
}

export type ProjectMemberDTO = {
	userId: string;
	role: ProjectMemberRoleDTO;
	pinned: boolean;
};

export type ProjectDatabaseDTO = {
	id?: number; // bigint in SQL, but we'll use number here per your instruction
	created?: string; // ISO DateTime
	updated?: string; // ISO DateTime
	deleted: string | null; // ISO DateTime
	workspaceId: number | null;
	name: string | null;
	status: ProjectStatusDTO;
	constructionAddress: string | null;
	postalCode: string | null;
	description: string | null;
	ownerId: number | null;
	addressId: number | null;
	customerId: number | null;
	source: string | null;
	progress: number;
	urgency: ProjectUrgencyDTO;
	startDate: string | null; // ISO DateTime
	deadline: string | null; // ISO DateTime
	estimatedBudget: number | null; // decimal in SQL, but we'll use number here
	estimatedHours: number | null; // decimal in SQL, but we'll use number here
	budgetCurrency: string | null;
	category: string | null;
	priorities: string | null;
	specialRequirements: string | null;
	hasUnhandledCustomerMessages: boolean;
	templateId: number | null;
	industryId: number | null;
	termsId: number | null;
	creatingWorkspaceId: number | null;
	contactPersonId: number | null;
	requestReview: boolean;
	requireComplianceConfirmation: boolean;
	billableWorkspaceId: number | null;
	billingDatetime: string | null;
	billedDatetime: string | null;
};
export type ProjectDTO = ProjectDatabaseDTO & {
	// from old version - legacy support
	address?: Address;
	projectMembers: ProjectMemberDTO[];
	channels?: ChannelDTO[];
	pinned: boolean;
	tags?: string[];

	// archive
	archivedAt: string | null; // ISO DateTime
	archivedBy: number | null;
};

export type ProjectUrgencyDTO = 'minimal' | 'normal' | 'elevated' | 'urgent' | 'critical';
