import {
	CircularProgress,
	Container,
	Grid,
	InputAdornment,
	StyledComponentProps,
	TextField,
	Typography,
} from '@mui/material';
import { Company } from '../../stores/company/Company';
import { HookTypes, withNavigation } from '../../utils/withHooks';
import MapComponent, { MapPoint } from '../map/MapComponent';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import CompanyCard from '../common/company/CompanyCard';
import FeatureGuard from '../../utils/FeatureGuard';
import Footer from '../footer/Footer';
import IndustrySelector from '../industrySelector/IndustrySelector';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import SearchIcon from '@mui/icons-material/Search';
import StoreContext from '../../stores/StoreContext';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';

const PREFIX = 'AllCompaniesLandingScreen';

const classes = {
	root: `${PREFIX}-root`,
	companyCard: `${PREFIX}-companyCard`,
};

const Root = styled('div')({
	[`& .${classes.root}`]: {
		marginBottom: 40,
	},
	[`& .${classes.companyCard}`]: {
		height: '100%',
	},
});

type Props = HookTypes & StyledComponentProps;

const AllCompaniesLandingScreen = observer(
	class AllCompaniesLandingScreen extends React.Component<Props> {
		static readonly contextType = StoreContext;

		get params() {
			return this.props.params!;
		}
		get history() {
			return this.props.navigate!;
		}

		searchText: string = '';
		prevSearch: string = '';
		loading: boolean = false;
		companyDetails: any;
		observer: IntersectionObserver | undefined;
		maxItems: number = 10;
		isInitiated = false;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				loading: observable,
				companyDetails: observable,
				searchText: observable,
				maxItems: observable,
				workspaceStore: computed,
				companyStore: computed,
				companies: computed,
				mapPoints: computed,
				filteredCompanies: computed,
				companiesFilteredBySearch: computed,
				sortedCompaniesNearPostCode: computed,
				loadCompanies: action,
				init: action,
				dispose: action,
			});
		}

		componentDidMount() {
			this.init();
		}

		componentWillUnmount() {
			this.dispose();
		}

		init() {
			if (this.isInitiated) {
				return;
			}
			this.isInitiated = true;
			this.loadCompanies();

			this.observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0) {
						runInAction(() => {
							const valuesToCompare = [this.maxItems + 10];

							if (this.companies.length) {
								valuesToCompare.push(this.companies.length);
							}

							this.maxItems = Math.min(...valuesToCompare);
						});
					}
				});
			});

			let target = document.querySelector('#bottom-boundary');

			if (target) {
				this.observer.observe(target);
			}
		}

		dispose() {
			if (this.observer) {
				this.observer.disconnect();
			}
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get workspaceStore() {
			return this.rootStore.workspaceStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get companies() {
			// Since companies are pushed into store we can experience performance issues if we don't wait until all are done
			if (this.companyStore.isLoading) {
				return [];
			}
			const planToNum = (plan: any) => {
				if (plan == 'Pro') {
					return 1;
				} else if (plan == 'Elite') {
					return 2;
				}
				return 0;
			};
			return [...this.companyStore.filteredCompanies].sort((a, b) => {
				if (planToNum(a.plan) == planToNum(b.plan)) {
					if (a.name > b.name) {
						return 1;
					} else if (a.name < b.name) {
						return -1;
					}
					return 0;
				}

				return planToNum(a.plan) > planToNum(b.plan) ? -1 : 1;
			});
		}

		get mapPoints(): MapPoint[] {
			if (this.filteredCompanies && this.filteredCompanies.length > 0) {
				return this.filteredCompanies.map((company) => {
					return {
						lat: company.coordinates?.latitude,
						lon: company.coordinates?.longitude,
						name: company.name,
						onClick: this.goToCompany(company),
					};
				});
			}

			return [];
		}

		get filteredCompanies() {
			if (this.searchText) {
				if (this.searchText.length == 4 && RegExp(/^\d+$/).exec(this.searchText) != null) {
					if (this.prevSearch != this.searchText) {
						this.prevSearch = this.searchText;
						this.loadCompanies(this.searchText);
					}

					return this.sortedCompaniesNearPostCode;
				}

				return this.companiesFilteredBySearch;
			}

			return this.companies;
		}

		get sortedCompaniesNearPostCode() {
			const sortedCompanies = this.companyStore.getCompaniesNearPostCode(this.searchText) ?? [];

			return sortedCompanies.sort((a, b) => {
				const targetA = a.isNear(this.searchText);
				const targetB = b.isNear(this.searchText);

				if (!targetA) {
					return -1;
				}

				if (!targetB) {
					return 1;
				}

				if (targetA?.distanceKm > targetB?.distanceKm) return 1;
				if (targetA?.distanceKm < targetB?.distanceKm) return -1;

				return 0;
			});
		}

		get companiesFilteredBySearch() {
			return this.companies.filter(
				(company) =>
					company.name.toLocaleLowerCase().indexOf(this.searchText.toLowerCase()) >= 0 ||
					company.city?.toLocaleLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
			);
		}

		handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			this.searchText = event.target.value;
		};

		async loadCompanies(postCode?: string) {
			if (!this.companyStore.hasLoadedAll || this.companies.length < 1) {
				this.loading = true;
				await this.companyStore.loadCompanies();

				runInAction(() => (this.loading = false));
			} else if (postCode) {
				this.loading = true;
				await this.companyStore.loadCompanies(postCode);

				runInAction(() => (this.loading = false));
			}
		}

		goToOrderChat = (company: Company) => () => {
			this.history(`/order-chat/${company.postalCode}/${company.id}`, {
				state: {
					companyId: company.id,
				},
			});
		};

		goToCompany = (company: Company) => () => {
			console.log(`goto: ${company.domain}`);
			this.history(`/about/${company.domain}`);
		};

		renderCompanyCards() {
			return (
				<>
					{this.filteredCompanies.slice(0, this.maxItems).map((company) => (
						<Grid
							item
							key={company.id}
							xs={12}
							sm={!company.plan || company.plan === 'Basic' ? 6 : undefined}
							md={!company.plan || company.plan === 'Basic' ? 4 : undefined}
						>
							<CompanyCard
								buttons={{
									primaryButton: { label: 'Send forespørsel', onClick: this.goToOrderChat(company) },
									secondaryButton: {
										label: 'Les mer',
										onClick: this.goToCompany(company),
									},
								}}
								className={this.props.classes?.companyCard}
								company={company}
								hideEmailAddress
								hideMap={!company.plan || company.plan === 'Basic'}
								hidePhoneNumber
							/>
						</Grid>
					))}
					<div id="bottom-boundary"></div>
				</>
			);
		}

		render() {
			return (
				<Root>
					<Container className={`AllCompaniesLandingScreen ${classes.root}`}>
						<MapComponent points={this.mapPoints} />
						<TextField
							fullWidth
							style={{ margin: '1rem 0' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							label="Søk"
							margin="normal"
							placeholder="Firmanavn eller postnummer"
							onChange={this.handleSearchTextChange}
							value={this.searchText}
							variant="outlined"
						/>
						<FeatureGuard feature="companies.industries">
							<Grid item xs={12} sx={{ marginBottom: '1rem' }}>
								<IndustrySelector />
							</Grid>
						</FeatureGuard>
						<Grid container spacing={2} alignItems="stretch">
							{this.renderCompanyCards()}
						</Grid>

						{this.loading && (
							<>
								<CircularProgress color="secondary" />
								<Typography gutterBottom variant="body2">
									Laster informasjon om bedriften...
								</Typography>
							</>
						)}

						{!this.loading && this.filteredCompanies.length <= 0 && (
							<Typography gutterBottom variant="body2">
								Vi fant ingen bedrifter som matchet ditt søk.
							</Typography>
						)}
					</Container>
					<Footer />
				</Root>
			);
		}
	}
);

export default withNavigation(AllCompaniesLandingScreen);
