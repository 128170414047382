import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Company } from '../../../stores/company/Company';
import { RootStore } from '../../../stores/RootStore';
import StoreContext from '../../../stores/StoreContext';
import { HookTypes, withNavigation } from '../../../utils/withHooks';
import CompanyCard from './CompanyCard';
type Props = HookTypes;
const CompaniesNearUser = observer(
	class CompaniesNearUser extends React.Component<Props> {
		static readonly contextType = StoreContext;
		get history() {
			return this.props.navigate!;
		}

		constructor(props: Props) {
			super(props);
			makeObservable(this, {});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		goToOrderChat = (company: Company) => () => {
			this.history(`/order-chat/${company.postalCode}/${company.id}`, {
				state: {
					companyId: company.id,
				},
			});
		};

		goToCompany = (company: Company) => () => {
			this.history(`/about/${company.domain}`);
		};

		render() {
			if (!this.companyStore.userLocation || this.companyStore.companiesNearUser.length <= 0) {
				return;
			}

			const { postArea, postCode } = this.companyStore.userLocation;
			return (
				<Grid container sx={{ width: '100%', margin: '3rem 0' }} spacing={2}>
					<Grid xs={12}>
						<Typography
							variant="h6"
							color="primary"
							component={Link}
							to={`/near-me/elektriker/${postCode}/${postArea}`}
						>
							Bedrifter nær {this.companyStore.userLocation?.postArea}(
							{this.companyStore.companiesNearUser.length})
						</Typography>
					</Grid>
					{this.companyStore.companiesNearUser.slice(0, 3).map((company) => (
						<Grid
							key={company.id}
							xs={12}
							sm={!company.plan || company.plan === 'Basic' ? 6 : undefined}
							md={!company.plan || company.plan === 'Basic' ? 4 : undefined}
						>
							<CompanyCard
								buttons={{
									primaryButton: { label: 'Send  forespørsel', onClick: this.goToOrderChat(company) },
									secondaryButton: {
										label: 'Les mer',
										onClick: this.goToCompany(company),
									},
								}}
								company={company}
								hideEmailAddress
								hideMap={!company.plan || company.plan === 'Basic'}
								hidePhoneNumber
							/>
						</Grid>
					))}
					<div id="bottom-boundary"></div>
				</Grid>
			);
		}
	}
);

export default withNavigation(CompaniesNearUser);
