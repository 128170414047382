import { MenuItem } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import NotificationEntry from './NotificationEntry';

type Props = {};

const Notifications = observer(
	class Notifications extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				notificationStore: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get notificationStore() {
			return this.rootStore.notificationStore;
		}

		get notifications() {
			return this.notificationStore.notifications;
		}

		renderNotifications() {
			if (this.notifications.length === 0) {
				return <MenuItem sx={{ minWidth: '250px' }}>Ingen varsler</MenuItem>;
			}
			return this.notifications
				.slice(0, 10)
				.map((notification: any) => <NotificationEntry key={notification.id} notification={notification} />);
		}

		render() {
			return this.renderNotifications();
		}
	}
);

export default Notifications;
