import { observer } from 'mobx-react';
import React from 'react';
import { ProfileDTO } from '../../../../dto/profile.types';
import { Project } from '../../../../stores/Project';
import { action, makeObservable, observable } from 'mobx';
import { Stack, Switch, TextField, Typography } from '@mui/material';
import ShareProjectSearchAutocomplete from './ShareProjectSearchProfileAutocomplete';

type Props = {
	project: Project;
	// eslint-disable-next-line no-unused-vars
	onClose: () => void;
	values?: Array<ProfileDTO | string>;
};

const ShareProjectInvite = observer(
	class ShareProjectInvite extends React.Component<Props> {
		values: Array<ProfileDTO | string> = [];

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				values: observable,
				onChange: action,
			});
		}

		componentDidMount(): void {
			this.onChange(this.props.values ?? []);
		}

		componentDidUpdate(prevProps: Readonly<Props>): void {
			if (prevProps.values !== this.props.values) {
				this.onChange(this.props.values ?? []);
			}
		}

		onChange = (profiles: Array<ProfileDTO | string>) => {
			this.values = profiles;
		};

		render() {
			return (
				<Stack spacing={2} sx={{ marginTop: '1rem' }}>
					<ShareProjectSearchAutocomplete
						project={this.props.project}
						onChange={this.onChange}
						values={this.values}
					/>
					<Stack spacing={2} direction="row" alignItems="center">
						<Typography variant="body2">
							Gi beskjed på e-post/SMS til personer du gir tilgang til prosjektet.
						</Typography>
						<Switch checked={true} />
					</Stack>
					<TextField
						label="Melding"
						variant="outlined"
						fullWidth
						multiline
						rows={4}
						placeholder="Skriv en beskjed til de du gir tilgang *valgfritt*"
					/>
					<Typography variant="body2">
						*Hvis den du inviterer ikke har en konto registrert på e-post/mobilnummer vil en konto bli
						opprettet.
					</Typography>
				</Stack>
			);
		}
	}
);

export default ShareProjectInvite;
