import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';

import React from 'react';
import { observer } from 'mobx-react';

type CircularProgressWithLabelProps = CircularProgressProps & {
	value?: number;
	caption?: string;
};

const CircularProgressWithLabel = observer(
	class CircularProgressWithLabel extends React.Component<CircularProgressWithLabelProps> {
		render() {
			let { value, caption, ...rest } = this.props;

			if (!value) {
				value = 0;
			}

			return (
				<div style={{ position: 'relative', display: 'inline-flex' }} className="progress-with-caption">
					<CircularProgress variant="determinate" value={value} {...rest} />
					<div
						className="progress-with-caption--caption"
						style={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<Typography
							className="progress-with-caption--caption-value"
							variant="caption"
							component="div"
							color="textSecondary"
						>{`${Math.round(value)}%`}</Typography>
						{caption && (
							<Typography
								className="progress-with-caption--caption-caption"
								variant="h6"
								component="div"
								color="textSecondary"
							>
								{caption}
							</Typography>
						)}
					</div>
				</div>
			);
		}
	}
);

export default CircularProgressWithLabel;
