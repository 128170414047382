const config = {
	appId: process.env.REACT_APP_ID || 'default',
	hosts: {
		auth: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'auth.befare.no' : 'auth.stg.befare.no',
		api: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'api.befare.no' : 'api.stg.befare.no',
		socket: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'api.befare.no' : 'api.stg.befare.no',
		onesignal: {
			appId:
				process.env.REACT_APP_ENVIRONMENT === 'production'
					? 'dc1f6a47-3dcd-4806-b140-8b1d9ec7e3a5'
					: '13de7b87-182a-4b2d-8a5e-45e0bb365dbf',
			safari_web_id:
				process.env.REACT_APP_ENVIRONMENT === 'production'
					? 'web.onesignal.auto.3b9e77c1-5852-4edd-a278-c29c156a72b0'
					: 'web.onesignal.auto.3b9e77c1-5852-4edd-a278-c29c156a72b0',
			norgeseliten: {
				//  this is for min-elektriker.no
				appId:
					process.env.REACT_APP_ENVIRONMENT === 'production'
						? 'fd8742fb-8b3e-4a65-9984-048fe1608f41'
						: '13de7b87-182a-4b2d-8a5e-45e0bb365dbf', // we don't have a dev one for min-elektriker.no
				safari_web_id: 'web.onesignal.auto.3b9e77c1-5852-4edd-a278-c29c156a72b0',
			},
		},
	},
	maps: {
		apiKey: 'AIzaSyBh1dlLyTO9Jwr_aLeVAI6EVBuYk1sFkSI',
	},
	tokbox: {
		apiKey: process.env.REACT_APP_ENVIRONMENT === 'production' ? '47350081' : '47361521',
	},
	hotjar: {
		softwareVersion: 6,
		appId: {
			dev: null,
			befare: 2684577,
			minelektriker: 2684570,
		},
	},
};

export default config;
