// eslint complains about unused types
/* eslint-disable no-unused-vars */

import { ConnectionMetadataExternal } from './Connection.types';
import { CompanyDTO } from './dto/company.types';
import { PricingPlanDTO } from './dto/pricingPlan.types';

// TODO Delete Json types, we use DTOs instead
export type AnyJson = boolean | number | string | null | JsonArray | JsonMap;

export interface JsonMap {
	[key: string]: AnyJson;
}

export interface JsonArray extends Array<AnyJson> {}

export type User = {
	userId: string;
	phoneNumber?: string;
	email?: string;
};

export enum MeetingState {
	Pending = 'PENDING',
	Accepted = 'ACCEPTED',
	Declined = 'DECLINED',
	Reschedule = 'RESCHEDULE',
}

export type Invitee = ProfileType & {
	response: MeetingState;
};

export type Meeting = {
	id?: string;
	title: string;
	date: Date;
	inviter: ProfileType;
	invitees: Invitee[];
	description?: string;
	type?: any; // TODO Implement meeting types
};

/* eslint-disable no-unused-vars */

export type MessageContent = {
	contentType: string;
	file?: MessageContentFile;
	comment: string;
};

export enum MessageWidgetType {
	JoinChannel = 'JoinChannel',
	LeaveChannel = 'LeaveChannel',
	VideoChat = 'VideoChat',
	EventInvite = 'EventInvite',
	UpdatedProject = 'UpdatedProject',
	NewProject = 'NewProject',
	Bill = 'Bill',
	Offer = 'Offer',
	UserRating = 'UserRating',
	CraftsmanRating = 'CraftsmanRating',
	ProjectStarRating = 'ProjectStarRating',
	CustomerSupportChatLogin = 'CustomerSupportChatLogin',
	KobbrQuote = 'KobbrQuote',
	KobbrQuoteDraft = 'KobbrQuoteDraft',
	IncomingEmail = 'IncomingEmail',
}

export type MessageWidget = {
	id?: string;
	type: MessageWidgetType;
	messageId?: string;
	referenceId: string;
	userId: string;
	channelId: string;
};

export type Reaction = {
	id: string;
	name: string;
	reaction: string;
	userId?: string;
	user?: User;
	created?: Date;
	seenBy?: User[];
};

export type Address = {
	id?: string;
	userId?: number;
	country?: string;
	municipality?: string;
	county?: string;
	region?: string;
	coordinate?: string;
	street: string;
	houseNumber?: string;
	entrance?: string;
	postCode: string;
	postArea: string;
	addressString?: string;
	projectIds?: string[];
	lat?: number;
	lon?: number;
};

export type MessageContentFile = {
	id: number;
	workspaceId: string;
	userId: string;
	name: string;
	size: string;
	mimetype: string;
	s3path: string;
	md5Checksum: string;
	created: string;
	updated: string;
	deleted: null | string;
	path: string;
	preview: string;
};

// TODO Clean up type, create domain object?
export type MessageDTO = {
	id?: number;
	userId?: string;
	profileId?: string;
	channelId: string;
	type?: MessageType;
	content?: MessageContent[];
	text: string;
	isSeen: number;
	created: Date;
	updated?: Date;
	deleted?: Date;
	reactions?: Reaction[];
	sender: ProfileType;
	widgets?: any[];
	widget?: MessageWidget;
	attachments?: any[];
	pinnedBy?: string;
	sessionId?: string;
	status: 'SENT' | 'PENDING' | 'PROCESSING';
};

export type UpsertMessageDTO = Partial<MessageDTO>;

export enum MessageType {
	// eslint-disable-next-line no-unused-vars
	WIDGET = 'widget',
	// eslint-disable-next-line no-unused-vars
	MESSAGE = 'message',
	// eslint-disable-next-line no-unused-vars
	EMAIL = 'email',
	// eslint-disable-next-line no-unused-vars
	SMS = 'SMS',
	// eslint-disable-next-line no-unused-vars
	AI_MESSAGE = 'ai_message',
	// eslint-disable-next-line no-unused-vars
	AI_STATUS_MESSAGE = 'ai_status_message',
}

export type GrantKey = 'employee' | 'manager' | 'admin' | 'customer' | 'ai';
export type GrantType = {
	key: GrantKey;
	name: string;
	accesGroupId: string;
	hidden: string;
};

export type ProfileType = {
	id?: number | string;
	created?: string; // ISO timestamp
	updated?: string; // ISO timestamp
	deleted?: string; // ISO timestamp
	userId: string;
	workspaceId?: string;
	role?: string;
	organizationTitle?: string;
	name: string;
	dateOfBirth?: Date;
	bio?: string;
	profilePicture?: string;
	profilePictureFileId?: string;
	username?: string;
	phoneNumber?: string;
	email?: string;
	isOnline?: ConnectionMetadataExternal;
	lastOnline?: number;
	isCustomer?: boolean;
	isAI?: boolean;
	grant?: GrantType;
};

export enum UserSettingKey {
	ThemesMode = 'self:application:themesMode',

	RegionLanguage = 'self:application:regionLanguage',
	RegionTimezone = 'self:application:regionTimezone',
	RegionSpellcheck = 'self:application:regionSpellcheck',

	AccessibilityAllowAnimation = 'self:application:accessibilityAllowAnimation',
	AccessibilityZoom = 'self:application:accessibilityZoom',

	NotificationNotifyMain = 'self:notification:NotifyMain',
	NotificationNotifyThread = 'self:notification:NotifyThread',
	NotificationKeywords = 'self:notification:Keywords',
	NotificationPreview = 'self:notification:Preview',
	NotificationMute = 'self:notification:Mute',
	NotificationSound = 'self:notification:Sound',
	NotificationMethods = 'self:notification:methods',

	JobTrackingMethod = 'self:jobTracking:method',
	ProjectComplexity = 'self:project:complexity',

	BusinessSelfRole = 'business:self:role',
	BusinessSize = 'business:size',
	BusinessType = 'business:type',
	BusinessLeadsAcceptedOrigins = 'business:leads:acceptedOrigins',
	BusinessPlan = 'business:plan',
	BusinessFutureAutomation = 'business:future:automation',

	OnboardingCompletedV1 = 'onboarding:completed:v1',
}

export type SettingKey = UserSettingKey | WorkspaceSettingKey | ApplicationSettingKey;

export enum WorkspaceSettingKey {
	ThemesTheme = 'themesTheme', // eg. teal/mint
	ThemesMessages = 'themesMessages', // eg. teal/mint
}

export enum ApplicationSettingKey {
	Awesome = 'awesome',
}

export type SettingDto = {
	key: string;
	value: boolean | string | number;
	category: string;
	subCategory?: string;
	type?: string; // eg. color, on/off, number, date, etc -> UI helper
};

export type UserSettingsDto = {
	userId: number;
	settings: SettingDto[];
};

export type UserSettingsMinimalDto = Pick<UserSettingsDto, 'settings'>;

export type UserSettingsCollection = {
	[x: string]: SettingDto;
};

export type Workspace = {
	id: string;
	name: string;
	domain: string;
	loginMethods: ('sso' | 'phone')[];
	ssoMetadata?: {
		domain: string;
		clientId: string;
		objectId?: string;
	};
	parent?: Workspace;
};

export type WorkspaceDBRow = {
	id: string;
	parentWorkspaceId: string;
	name: string;
	domain: string;
	loginMethod?: string;
	loginDomain?: string;
	ssoClientId?: string;
	objectId?: string;
	created: string;
	updated: string;
	deleted: string;
	parentDomain?: string; // parent workspace domain FROM JOIN
	userId?: number;
};

export type WorkspaceDTO = WorkspaceDBRow & {
	company: CompanyDTO;
	currentPricingPlan: PricingPlanDTO | null;
	upcomingPricingPlan: PricingPlanDTO | null;
};

export type Emoji = {
	name: string;
	utf8Emoji?: string;
	url?: string;
	aliasFor?: string;
	created?: any;
	addedBy?: any;
	isAlias?: boolean;
	canDelete: boolean;
	synonyms?: string[];
	workspaceId?: string;
};

export type Order = {
	addressId?: string;
	workspaceId?: string;
	description: string;
	address: Address;
	status?: string;
	error?: string;
};

export type MeetingForm = {
	title: {
		value: string;
		error: boolean;
	};
	date: {
		value: string;
		error: boolean;
	};
	time: {
		value: string;
		error: boolean;
	};
	type: MeetingType;
	description: string;
	participants?: ProfileType[];
};

export enum MeetingType {
	VideoInspection = 'VIDEO-INSPECTION',
	Inspection = 'INSPECTION',
	Worksite = 'WORKSITE',
	Event = 'EVENT',
	Meeting = 'MEETING',
}

export type Action = {
	label: string;
	onClick: () => void | Promise<void>;
};
