import { Emoji, UserSettingsCollection } from '../types';

export const DEFAULT_USER_EMOJI: Emoji[] = [];
export const DEFAULT_WORKSPACE_EMOJI: Emoji[] = [];

export const DEFAULT_APPLICATION_EMOJI: Emoji[] = [
	{
		name: 'thumbsup',
		canDelete: false,
		utf8Emoji: '👍',
	},
	{
		name: 'smile',
		canDelete: false,
		utf8Emoji: '😄',
	},
	{
		name: 'smiley',
		canDelete: false,
		utf8Emoji: '😃',
	},
	{
		name: 'grin',
		canDelete: false,
		utf8Emoji: '😁',
	},
	{
		name: 'heart_eyes',
		canDelete: false,
		utf8Emoji: '😍',
	},
	{
		name: 'scared',
		canDelete: false,
		utf8Emoji: '😱',
	},
	{
		name: 'star_eyes',
		canDelete: false,
		utf8Emoji: '🤩',
	},
	{
		name: 'sweat_smile',
		canDelete: false,
		utf8Emoji: '😅',
	},
	{
		name: 'joy',
		canDelete: false,
		utf8Emoji: '😂',
	},
	{
		name: 'laughing',
		canDelete: false,
		utf8Emoji: '😆',
	},
	{
		name: 'angel',
		canDelete: false,
		utf8Emoji: '😇',
	},
	{
		name: 'blush',
		canDelete: false,
		utf8Emoji: '😊',
	},
	{
		name: 'sunglasses',
		canDelete: false,
		utf8Emoji: '😎',
	},
	{
		name: 'party',
		canDelete: false,
		utf8Emoji: '🥳',
	},
	{
		name: 'sad',
		canDelete: false,
		utf8Emoji: '😢',
	},
	{
		name: 'crying',
		canDelete: false,
		utf8Emoji: '😭',
	},
	{
		name: '+1',
		canDelete: false,
		utf8Emoji: '👍',
	},
	{
		name: 'thumbsdown',
		canDelete: false,
		utf8Emoji: '👎',
	},
	{
		name: '-1',
		canDelete: false,
		utf8Emoji: '👎',
	},
	{
		name: 'pray',
		canDelete: false,
		utf8Emoji: '🙏',
	},
	{
		name: 'horns',
		canDelete: false,
		utf8Emoji: '🤘',
	},
	{
		name: 'ok',
		canDelete: false,
		utf8Emoji: '👌',
	},
	{
		name: 'muscle',
		canDelete: false,
		utf8Emoji: '💪',
	},
	{
		name: 'wave',
		canDelete: false,
		utf8Emoji: '👋',
	},
	{
		name: 'clapping',
		canDelete: false,
		utf8Emoji: '👏',
	},
	{
		name: 'two-hands',
		canDelete: false,
		utf8Emoji: '🙌',
	},
	{
		name: 'slowboat',
		canDelete: false,
		utf8Emoji: '🚣',
	},
	{
		name: 'see-no-evil',
		canDelete: false,
		utf8Emoji: '🙈',
	},
	{
		name: 'frown',
		canDelete: false,
		utf8Emoji: '😦',
	},
	{
		name: 'angry',
		canDelete: false,
		utf8Emoji: '🤬',
	},
	{
		name: 'flower',
		canDelete: false,
		utf8Emoji: '🌻',
	},
	{
		name: 'heart',
		canDelete: false,
		utf8Emoji: '❤',
	},
	{
		name: 'hugging-face',
		canDelete: false,
		utf8Emoji: '🤗',
	},
	{
		name: 'shrug',
		canDelete: false,
		utf8Emoji: '🤷',
	},
	{
		name: 'face-palm',
		canDelete: false,
		utf8Emoji: '🤦',
	},
	{
		name: 'thinking',
		canDelete: false,
		utf8Emoji: '🤔',
	},
	{
		name: 'weary-face',
		canDelete: false,
		utf8Emoji: '😩',
	},
	{
		name: 'checkmark',
		canDelete: false,
		utf8Emoji: '✔️',
	},
	{
		name: 'heavy-checkmark',
		canDelete: false,
		utf8Emoji: '✅',
	},
];

export const DEFAULT_USER_SETTINGS: UserSettingsCollection = {};
