import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';
import { Link } from 'react-router-dom';

export default class LoginButton extends React.Component {
	render() {
		return (
			<Button
				color="primary"
				component={Link}
				size="small"
				startIcon={<ExitToAppIcon />}
				to="/sign-in"
				variant="outlined"
			>
				Logg inn
			</Button>
		);
	}
}
