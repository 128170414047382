import { Card, CardContent, Container, Typography } from '@mui/material';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import CompanyApi from '../../api/endpoints/CompanyApi';
import StoreContext from '../../stores/StoreContext';
import CompanyCard from '../common/company/CompanyCard';
import ProfilePicture from '../common/ProfilePicture';
import Footer from '../footer/Footer';
import './BefareLandingScreen.scss';

type Props = {};

const BefareLandingScreen = observer(
	class BefareLandingScreen extends React.Component<Props> {
		static readonly contextType = StoreContext;

		loading: boolean = true;
		companyDetails: any;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				loading: observable,
				companyDetails: observable,
				loadCompanyDetails: action,
			});
		}

		get workspaceName() {
			return 'befare';
		}

		componentDidMount() {
			this.loadCompanyDetails();

			try {
				console.log('BefareAiChat', (window as any).BefareAiChat);
				if ((window as any).BefareAiChat) {
					(window as any).BefareAiChat.init(this.workspaceName);
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		componentWillUnmount(): void {
			try {
				if ((window as any).BefareAiChat) {
					(window as any).BefareAiChat.destroy();
				}
			} catch (error) {
				console.log('BefareAiChat', error);
			}
		}

		async loadCompanyDetails() {
			if (this.workspaceName) {
				const response = await CompanyApi.getCompanyFromWorkspaceDomain(this.workspaceName);

				if (response.statusCode === 200) {
					runInAction(() => (this.companyDetails = response.data));
				}

				runInAction(() => (this.loading = false));
			}
		}

		renderEmployees() {
			return this.companyDetails?.employees?.map((profile: any) => (
				<Card key={profile.userId} className="employee-card">
					<ProfilePicture profile={profile} />
					<CardContent>
						<Typography variant="h5" component="h2">
							{profile?.name}
						</Typography>
						{profile?.organizationTitle && (
							<Typography variant="body2" color="textSecondary" component="p">
								{profile?.organizationTitle}
							</Typography>
						)}
					</CardContent>
				</Card>
			));
		}

		render() {
			return (
				<>
					<Container className="BefareLandingScreen">
						<Typography gutterBottom variant="h4">
							Om Befare
						</Typography>
						<Typography color="textSecondary" paragraph>
							Befare er laget av Norwegian Blue AS og drevet av et ønske om å gjøre det lettere å
							kommunisere med håndverkere både før, under og etter jobben er utført.
						</Typography>
						<Typography color="textSecondary" paragraph>
							Som så mange andre så jobber vi og er som regel opptatte mellom 7 og 15. Vi har derfor laget
							en løsning som gjør det mulig å snakke med håndverkere når det passer oss - enten vi er på
							jobb, sitter på hytta eller tar 5min fri i &quot;tenkeboksen&quot;
						</Typography>
						<Typography color="textSecondary" paragraph>
							For deg som kunde forenkler dette prosessen med å få fikset noe hjemme. For håndverkere
							betyr løsningen at de klarer å svare kunder kjappere, gi bedre hjelp og så blir det mindre
							kjøring. Det er bra for miljøet og bra for deg som vil ha ting fikset kjappest mulig. I
							stedet for å ta fri fra jobben og vente på en befaring neste uke kan du ta befaringen på
							video når det passer deg.
						</Typography>
						<Typography color="textSecondary" paragraph>
							Vi sitter i et fantastisk co-working space i Trondheim som heter Work-Work. Du finner oss i
							Munkegata 58, 7011 Trondheim.
						</Typography>
						<Typography color="textSecondary" paragraph>
							Lurer du på noe? Send oss en mail til{' '}
							<a href="mailto:support@norwegian-blue.no">support@norwegian-blue.no</a>
						</Typography>

						{this.companyDetails && (
							<>
								<CompanyCard company={this.companyDetails} />
								<Typography gutterBottom variant="h6">
									Noen av oss som lager Befare
								</Typography>
								<div className="BefareLandingScreen__employees">{this.renderEmployees()}</div>
							</>
						)}

						<Typography
							color="textSecondary"
							paragraph
							component="a"
							href="http://www.erikbolstad.no/postnummer/"
							target="_blank"
						>
							Postnummerdata frå Erik Bolstad
						</Typography>
					</Container>

					<Footer />
				</>
			);
		}
	}
);

export default BefareLandingScreen;
