import { Box, BoxProps, ListItem, keyframes, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { ReactNode } from 'react';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const slideUp = keyframes`
	from {
		transform: translateY(20px);
	}
	to {
		transform: translateY(0);
	}
`;

export const AnimateInGrid2 = styled(Grid2)(() => ({
	animation: `${fadeIn} 0.5s ease forwards, ${slideUp} 0.5s ease forwards`,
	animationDelay: 'var(--animation-delay)',
	animationFillMode: 'forwards', // Ensure the animation stays at the final state
	opacity: 0,
}));

export const AnimateInListItem = styled(ListItem)(() => ({
	animation: `${fadeIn} 0.5s ease forwards, ${slideUp} 0.5s ease forwards`,
	animationDelay: 'var(--animation-delay)',
	animationFillMode: 'forwards', // Ensure the animation stays at the final state
	opacity: 0,
}));

const rainbowBorderAnimation = keyframes`
	0% {
		background-position: 0 0;
	}

	50% {
		background-position: 100% 0;
	}

	100% {
		background-position: 0 0;
	}
`;

const animatedBorderProps: any = {
	'position': 'relative',
	'display': 'inline-block',
	'borderRadius': '10px',
	'zIndex': 1,
	'::before,::after': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		background: `linear-gradient(45deg, #ff9aa2, #ffb7b2, #ffdac1, #e2f0cb, #b5ead7, #c7ceea)`,
		backgroundSize: '300%',
		zIndex: -1,
		animation: `${rainbowBorderAnimation} 15s linear infinite`,
		width: '100%',
		borderRadius: '0.5rem',
	},
	'&.aiMode::before, &.aiMode::after': {
		background: 'linear-gradient(45deg, #98c1d9, #A0DDE6, #a0d8ef, #b4f8c8, #98c1d9, #c5a3ff)', // Pastel colors
		backgroundSize: '200% 200%',
		animation: `${rainbowBorderAnimation} 15s linear infinite`,
	},
	'&.addShadow': {
		'margin': '15px',
		':after': {
			filter: 'blur(15px)',
			transform: 'translate3d(0, 0, 0)',
		},
	},
	'&.noRainbow::before, &.noRainbow::after': {
		display: 'none',
	},
};

const AnimatedBorder = styled(Box)(animatedBorderProps);

const AnimatedInnerWrapper = styled(Box)({
	position: 'relative',
	zIndex: 1,
	margin: '2px',
	borderRadius: '0.5rem',
	backgroundColor: '#fafafa',
	flexGrow: 1,
});

type BorderButtonProps = BoxProps & {
	children: ReactNode;
	aiMode?: boolean;
	addShadow?: boolean;
	noRainbow?: boolean; // Disable rainbow border
};

export const RainbowBorderBox: React.FC<BorderButtonProps> = ({ children, aiMode, addShadow, noRainbow, ...rest }) => {
	let className = '';
	if (aiMode) {
		className = 'aiMode ';
	}
	if (addShadow) {
		className += 'addShadow';
	}
	if (noRainbow) {
		className += 'noRainbow';
	}
	return (
		<AnimatedBorder className={className} {...rest}>
			<AnimatedInnerWrapper>{children}</AnimatedInnerWrapper>
		</AnimatedBorder>
	);
};
