/* eslint eqeqeq: "off" */

import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import TemplateApi, { ProjectTemplateDTO } from '../api/endpoints/TemplateApi';
import { toNumber } from 'lodash';
import { ProjectStatusDTO } from '../dto/project.types';

/**
 * TemplateStore
 */
export class TemplateStore {
	rootStore: RootStore;
	templates: ProjectTemplate[] = [];

	draftTemplate: ProjectTemplate = new ProjectTemplate();

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.init();
	}

	init() {
		this.rootStore.userStore.SignedOut.on(this.reset);
		this.getTemplates();
	}

	reset = () => {
		this.templates = [];
	};

	setDraftTemplate() {
		this.draftTemplate = new ProjectTemplate();
	}

	async getTemplates() {
		// only attempt to get templates if the user is signed in and has a workspace
		if (!this.rootStore.workspaceStore.hasEmployeeAccess) {
			return;
		}
		const templates = await TemplateApi.getTemplates().catch((error) => {
			console.warn('Failed to get templates', error);
		});

		if (templates) {
			this.addOrUpdateTemplates(templates);
		}
	}

	addOrUpdateTemplates(templates: ProjectTemplate[]) {
		this.templates = templates;
	}

	addTemplate(template: ProjectTemplate) {
		this.templates.push(template);
	}

	async deleteTemplate(templateId: number): Promise<boolean> {
		const templateIndex = this.templates.findIndex((t) => t.templateId == templateId);
		if (templateIndex >= 0) {
			const success = await this.templates[templateIndex].delete();
			if (success) {
				this.templates.splice(templateIndex, 1);
				return true;
			}
		}

		return false;
	}
}

export enum ProjectUrgency {
	// eslint-disable-next-line no-unused-vars
	Minimal = 'minimal',
	// eslint-disable-next-line no-unused-vars
	Normal = 'normal',
	// eslint-disable-next-line no-unused-vars
	Elevated = 'elevated',
	// eslint-disable-next-line no-unused-vars
	Urgent = 'urgent',
	// eslint-disable-next-line no-unused-vars
	Critical = 'critical',
}

/**
 * Project template entity
 */
export default class ProjectTemplate {
	templateId?: number;
	workspaceId!: number;
	created: Date = new Date(); // iso datetime
	updated: Date = new Date(); // iso datetime
	deleted?: Date;
	name!: string;
	description?: string;
	category?: string;
	estimatedBudget?: number;
	budgetCurrency: string = 'NOK';
	industryId?: number;
	urgency: ProjectUrgency = ProjectUrgency.Normal;
	priorities: any; // json in SQL corresponds to any type in TypeScript as JSON can contain any data structure
	specialRequirements?: string;
	tags: string[] = [];

	requireComplianceConfirmation: boolean = true;
	requestReview: boolean = true;
	notifyCustomerOnCreate: boolean = true;
	notifyContactPersonOnCreate: boolean = true;
	deadlineDays: number | null = null;
	status: ProjectStatusDTO = ProjectStatusDTO.Received;

	constructor(projectTemplateDto?: ProjectTemplateDTO) {
		makeAutoObservable(this);

		if (projectTemplateDto) {
			this.updateFromDTO(projectTemplateDto);
		}
	}

	/**
	 * Update the project template entity from a DTO
	 * @param {ProjectTemplateDTO} ptDto
	 */
	updateFromDTO(ptDto: ProjectTemplateDTO) {
		this.templateId = ptDto.templateId;
		this.workspaceId = ptDto.workspaceId;
		this.created = new Date(ptDto.created);
		this.updated = new Date(ptDto.updated);
		this.deleted = ptDto.deleted ? new Date(ptDto.deleted) : undefined;
		this.name = ptDto.name ?? '';
		this.description = ptDto.description ?? '';
		this.category = ptDto.category ?? '';
		this.estimatedBudget = ptDto.estimatedBudget ? toNumber(ptDto.estimatedBudget) : undefined;
		this.budgetCurrency = ptDto.budgetCurrency ?? this.budgetCurrency;
		this.industryId = ptDto.industryId ?? undefined;
		this.urgency = ptDto.urgency ?? ProjectUrgency.Normal;
		this.priorities = ptDto.priorities ?? '';
		this.specialRequirements = ptDto.specialRequirements ?? '';
		this.tags = ptDto.tags ?? [];
	}

	/**
	 * Convert the project template entity to a DTO
	 * @returns {ProjectTemplateDTO} The DTO of the project template
	 */
	get toDTO(): ProjectTemplateDTO {
		return {
			templateId: this.templateId,
			workspaceId: this.workspaceId,
			created: this.created.toISOString(),
			updated: this.updated.toISOString(),
			deleted: this.deleted ? this.deleted.toISOString() : undefined,
			name: this.name,
			description: this.description,
			category: this.category,
			estimatedBudget: this.estimatedBudget,
			budgetCurrency: this.budgetCurrency,
			industryId: this.industryId,
			urgency: this.urgency,
			priorities: this.priorities,
			specialRequirements: this.specialRequirements,
			tags: this.tags,
		};
	}

	async save(): Promise<ProjectTemplate | null> {
		const result = await TemplateApi.upsertTemplate(this);

		if (result) {
			this.updateFromDTO(result.toDTO);
			return this;
		} else {
			return null;
		}
	}

	async delete(): Promise<boolean> {
		const result = await TemplateApi.deleteTemplate(this.templateId!);

		if (result) {
			this.deleted = new Date();
			return true;
		} else {
			return false;
		}
	}
}
