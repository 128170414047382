import config from '../../config/config';
import { ConnectionMetadataExternal } from '../../Connection.types';
import { FetchWrapper } from '../../fetch/FetchWrapper';

/**
 * Logging
 */
export default class LogApi {
	static async logError(log: any, metadata: ConnectionMetadataExternal) {
		const host = config.hosts.api;
		const url = `//${host}/v1/log/error`;
		await FetchWrapper.post(url, {
			body: JSON.stringify({
				log,
				metadata,
			}),
		}).catch((err) => {
			console.debug('LogApi.logError', err);
		});
	}

	static async logInfo(log: any, metadata: ConnectionMetadataExternal) {
		const host = config.hosts.api;
		const url = `//${host}/v1/log/info`;

		try {
			await FetchWrapper.post(url, {
				body: JSON.stringify({
					log,
					metadata,
				}),
			});
		} catch (err) {
			// do nothing
		}
	}

	static async logWarning(log: any, metadata: ConnectionMetadataExternal) {
		const host = config.hosts.api;
		const url = `//${host}/v1/log/warning`;

		try {
			await FetchWrapper.post(url, {
				body: JSON.stringify({
					log,
					metadata,
				}),
			});
		} catch (err) {
			// do nothing
		}
	}

	static async logDebug(log: any, metadata: ConnectionMetadataExternal) {
		const host = config.hosts.api;
		const url = `//${host}/v1/log/debug`;
		try {
			await FetchWrapper.post(url, {
				body: JSON.stringify({
					log,
					metadata,
				}),
			});
		} catch (err) {
			// do nothing
		}
	}
}
