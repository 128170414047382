import {
	Avatar,
	Divider,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import WorkIcon from '@mui/icons-material/Work';
import { action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { TriggerType } from '../../Trigger.types';
import ListItemLink from '../common/ListItemLink';
import ProfilePicture from '../common/ProfilePicture';
import './NotificationEntry.scss';
import { MessageType } from '../../types';
import LastMessageText from '../common/LastMessageText';
import Message from '../../stores/Message';

type Props = {
	notification: any;
};

const NotificationEntry = observer(
	class NotificationEntry extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				notificationStore: computed,
				settingsStore: computed,
				profileStore: computed,
				notification: computed,
				data: computed,
				trigger: computed,
				eventUserId: computed,
				channelLink: computed,
				eventLink: computed,
				profileFromEventData: computed,
				primaryText: computed,
				messageText: computed,
				secondaryText: computed,
				iconOrAvatar: computed,
				clearNotification: action,
			});

			this.clearNotification = this.clearNotification.bind(this);
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get notificationStore() {
			return this.rootStore.notificationStore;
		}

		get settingsStore() {
			return this.rootStore.settingsStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get projectStore() {
			return this.rootStore.projectStore;
		}

		get notification() {
			return this.props.notification;
		}

		get data() {
			return this.notification?.triggerData?.event.data;
		}

		get trigger() {
			return this.notification?.triggerData;
		}

		get eventUserId() {
			switch (this.trigger?.urn) {
				case TriggerType.CHANNEL_MESSAGE_NEW:
					return this.data?.sender?.userId;
				default:
					return null;
			}
		}

		get channelLink() {
			if (this.data?.channelId) {
				const channelId = this.data?.channelId;
				// find channel
				// check type
				// if type is project, then we need to find the roject
				// else it's inbox...

				return `/app/pro/channels/${channelId}`;
			}

			return null;
		}

		get eventLink() {
			switch (this.trigger?.urn) {
				case TriggerType.CHANNEL_MESSAGE_NEW:
					return this.channelLink;
				case TriggerType.PROJECT_ASSIGNED_TO_EMPLOYEE:
					// eslint-disable-next-line no-case-declarations
					const projectId = this.data?.project?.id;
					// eslint-disable-next-line no-case-declarations
					const project = this.projectStore.getProject(projectId);
					if (!project?.customerChannel?.id) return null;
					return `/app/pro/channels/${project?.customerChannel?.id}`;
				default:
					return null;
			}
		}

		get profileFromEventData() {
			if (this.eventUserId) {
				return this.profileStore.getProfile(this.eventUserId);
			}

			return null;
		}

		get primaryText() {
			switch (this.trigger?.urn) {
				case TriggerType.EVENT_CREATED:
					return new Date(this.data?.start).toLocaleString();
				default:
					// eslint-disable-next-line no-case-declarations
					const d = new Date(this.notification.created);
					return d.toLocaleString();
			}
		}

		get messageText() {
			const message: Message = this.data;
			switch (message?.type) {
				case MessageType.MESSAGE:
					if (message?.text) {
						return this.settingsStore.applyEmojiSettingsToText(this.data.text);
					}
					return 'No message text';
				case MessageType.SMS:
					return 'SMS';
				case MessageType.WIDGET:
					// eslint-disable-next-line no-case-declarations
					const widget = message?.widgets?.[0];
					return widget?.type;
			}

			return 'TEMP';
		}

		get secondaryText() {
			switch (this.trigger?.urn) {
				case TriggerType.CHANNEL_MESSAGE_NEW:
					return <LastMessageText message={this.data} />;
				case TriggerType.EVENT_CREATED:
					return this.data?.title;
				case TriggerType.PROJECT_ASSIGNED_TO_EMPLOYEE:
					return 'Du er blitt tildelt en jobb';
				default:
					return this.notification.triggerUrn;
			}
		}

		get iconOrAvatar() {
			if (this.profileFromEventData) {
				return <ProfilePicture profile={this.profileFromEventData} />;
			}

			return (
				<Avatar>
					<WorkIcon />
				</Avatar>
			);
		}

		async clearNotification(e: any) {
			e.preventDefault();

			try {
				await this.notificationStore.clearNotifications([this.notification.id]);
			} catch (err) {
				console.error(err);
			}
		}

		renderEntry() {
			return (
				<>
					<ListItemAvatar>{this.iconOrAvatar}</ListItemAvatar>
					<ListItemText
						className="NotificationEntry-text text-ellipsis--2"
						primary={this.primaryText}
						secondary={this.secondaryText}
					/>
					<ListItemSecondaryAction>
						<IconButton edge="end" aria-label="comments" onClick={this.clearNotification}>
							<ClearIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</>
			);
		}

		render() {
			if (this.eventLink) {
				return (
					<React.Fragment>
						<ListItemLink to={this.eventLink} className="NotificationEntry">
							{this.renderEntry()}
						</ListItemLink>
						<Divider component="li" />
					</React.Fragment>
				);
			}

			return (
				<React.Fragment>
					<ListItem className="NotificationEntry">{this.renderEntry()}</ListItem>
					<Divider component="li" />
				</React.Fragment>
			);
		}
	}
);

export default NotificationEntry;
