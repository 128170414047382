export enum ChannelTypeDTO {
	// eslint-disable-next-line no-unused-vars
	ProjectInternal = 'PROJECT-INTERNAL',
	// eslint-disable-next-line no-unused-vars
	ProjectExternal = 'PROJECT-EXTERNAL',
	// eslint-disable-next-line no-unused-vars
	ProjectCustomer = 'PROJECT-CUSTOMER',
	// eslint-disable-next-line no-unused-vars
	Public = 'PUBLIC', // anyone in workspace can join
	// eslint-disable-next-line no-unused-vars
	Private = 'PRIVATE', // set up members when you create the channel - can't change members after
	// eslint-disable-next-line no-unused-vars
	Restricted = 'RESTRICTED', // aka join by invite only
	// eslint-disable-next-line no-unused-vars
	CustomerService = 'CUSTOMER-SERVICE', // aka join by invite only
	// eslint-disable-next-line no-unused-vars
	Assistant = 'ASSISTANT', // aka join by invite only
	// eslint-disable-next-line no-unused-vars
	Agent = 'AGENT', // Conversational AI
	// eslint-disable-next-line no-unused-vars
	Partner = 'PARTNER', // Partner workspace
}

export type ChannelDTO = {
	id: string;
	channelType: ChannelTypeDTO;
	workspaceId: string;
	topic?: string;
	description?: string;
};
