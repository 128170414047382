import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx';
import CompanyApi, { PublicCompanyRating } from '../../api/endpoints/CompanyApi';
import { IndustryDTO, TargetDTO, CompanyPageDTO, ElvirksomhetsRegisteretType } from '../../dto/company.types';
import { CompanyStore } from './CompanyStore';
import { Profile } from '../ProfileStore';

export class Company {
	id: string;
	workspaceId: string = '';
	orgNumber: string = '';
	co2SavedInKg: number = 0;
	avgResponseTimeInMin: number = 0;
	industries: IndustryDTO[] = [];
	name: string = '';
	slug: string = '';
	address: string = '';
	postalCode: string = '';
	description: string = '';
	videoUrl: string = '';
	domain: string = '';
	city: string = '';
	phone: string = '';
	email: string = '';
	webpage: string = '';
	logo: string = '';
	about: string = '';
	firstMessage: string = '';
	ingress: string | undefined;
	targets: TargetDTO[] = [];
	coordinates!: {
		latitude: number;
		longitude: number;
	};
	employees?: Profile[];
	plan?: string;
	radius: number = 0;

	reviews?: PublicCompanyRating;

	t?: string; //tracking id

	store: CompanyStore;
	autoSave: boolean = true; // Indicator for submitting changes in this Company to the server
	saveHandler: IReactionDisposer;

	page?: CompanyPageDTO;

	elvirksomhetsregisteret?: ElvirksomhetsRegisteretType;

	constructor(store: CompanyStore, id?: number) {
		makeAutoObservable(this, {
			id: false,
			store: false,
			autoSave: false,
			saveHandler: false,
			dispose: false,
		});

		this.id = '' + id;
		this.store = store;

		this.saveHandler = reaction(
			() => this.asJson, // Observe everything that is used in the JSON
			() => {
				// If autoSave is true, send JSON to the server
				if (this.autoSave) {
					// TODO Send JSON to the server
				}
			}
		);
	}

	async loadCompanyPage() {
		const result = await CompanyApi.getCompanyPage(+this.workspaceId);
		if (result.statusCode === 200) {
			this.page = result.data;
			return true;
		}

		return false;
	}

	async saveCompanyPage() {
		if (this.page) {
			const result = await CompanyApi.updateCompanyPage(this.page);
			if (result.statusCode === 200) {
				this.page = result.data;
				return true;
			}
		}
		throw new Error('Failed to save company page');
	}

	async loadReviews() {
		const result = await CompanyApi.getCompanyReviews(this.workspaceId);
		if (result.statusCode === 200) {
			runInAction(() => {
				this.reviews = result.data;
			});
		}
	}

	async loadElvirksomhetsregisteret() {
		const response = await CompanyApi.getElvirksomhetsregisteretFromWorkspaceId(this.workspaceId);

		if (response.statusCode === 200) {
			runInAction(() => (this.elvirksomhetsregisteret = response.data));
		}
	}

	delete() {
		// TODO Remove company from the server
		this.store.removeCompany(this);
	}

	get asJson() {
		return {
			id: this.id,
			workspaceId: this.workspaceId,
			name: this.name,
			about: this.about,
			slug: this.slug,
			address: this.address,
			description: this.description,
			videoUrl: this.videoUrl,
			domain: this.domain,
			orgNumber: this.orgNumber,
			postalCode: this.postalCode,
			city: this.city,
			phone: this.phone,
			email: this.email,
			webpage: this.webpage,
			ingress: this.ingress,
			logo: this.logo,
			coordinates: this.coordinates,
			employees: this.employees,
			plan: this.plan,
			avgResponseTimeInMin: this.avgResponseTimeInMin,
			co2SavedInKg: this.co2SavedInKg,
			radius: this.radius,
		};
	}

	get formattedAddress() {
		let addressString = ``;
		if (this.address) {
			addressString += this.address;
		}

		if (this.postalCode) {
			addressString += `, ${this.postalCode}`;
		}

		if (this.city) {
			addressString += ` ${this.city}`;
		}

		return addressString || 'Ukjent adresse';
	}

	updateIndustries(industries: IndustryDTO[]) {
		if (!industries || !Array.isArray(industries)) {
			return;
		}

		this.industries = industries;
	}

	updateFromJson(json: any) {
		this.autoSave = false; // Prevent sending of our changes back to the server.
		this.workspaceId = json.workspaceId;
		this.name = json.name ?? this.name;
		this.description = json.description ?? this.description;
		this.updateIndustries(json.industries);
		this.videoUrl = json.videoUrl ?? this.videoUrl;
		this.domain = json.domain ?? this.domain;
		this.slug = json.slug ?? this.slug;
		this.address = json.address ?? this.address;
		this.postalCode = json.postalCode ?? this.postalCode;
		this.city = json.city ?? this.city;
		this.phone = json.phone ?? this.phone;
		this.email = json.email ?? this.email;
		this.about = json.about ?? this.about;
		this.webpage = json.webpage ?? this.webpage;
		this.firstMessage = json.firstMessage ?? this.firstMessage;
		this.orgNumber = json.orgNumber ?? this.orgNumber;
		// Enforce max 55 chars
		this.ingress =
			json.ingress && json.ingress.length > 55 ? json.ingress.slice(0, 55) : json.ingress ?? this.ingress;
		this.logo = json.logo;
		this.t = json.t;
		this.radius = json.radius ?? this.radius ?? 0;

		this.co2SavedInKg = json.co2SavedInKg ?? this.co2SavedInKg ?? 0;
		this.avgResponseTimeInMin = json.avgResponseTimeInMin ?? this.avgResponseTimeInMin ?? 0;

		if (json.coordinates) {
			this.coordinates = {
				latitude: json.coordinates.y,
				longitude: json.coordinates.x,
			};
		}
		this.employees = json.employees ?? this.employees;
		this.plan = json.plan ?? this.plan;

		if (json.target?.postCode && !this.isNear(json.target.postCode)) {
			this.targets.push(json.target);
		}

		this.autoSave = true;
	}

	isNear(postCode?: string): TargetDTO | undefined {
		if (!postCode) {
			return undefined;
		}

		const hit = this.targets.find((t) => '' + t.postCode === '' + postCode);
		return hit;
	}

	dispose() {
		this.saveHandler();
	}
}
