import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse } from '../../fetch/FetchWrapper';

export type RatingDTO = {
	id?: string;
	workspaceId: string;
	question?: string;
	subratings?: RatingDTO[];
	created: string;
	updated: string;
	userId: string;
	score: number;
	comment: string;
	ratingForUsers?: RatingForUserDTO[];
};

export type ProjectRatingDTO = {
	projectId: string;
	rating: RatingDTO;
};

export type RatingForUserDTO = {
	userId: string;
	rating: RatingDTO;
};

/**
 * Rating
 */
export default class RatingApi {
	static async getRatings(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/rating/ratings`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async getProjectRating(projectId: number): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/rating/project/${projectId}`;

		return FetchWrapper.get(url, {
			requireAuth: true,
		});
	}

	static async postRating(projectRating: ProjectRatingDTO): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/rating/ratings`;

		return FetchWrapper.post(url, {
			body: JSON.stringify({
				projectRating,
			}),
			requireAuth: true,
		});
	}
}
