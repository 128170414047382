import { makeAutoObservable, runInAction } from 'mobx';
import PartnershipApi from '../../api/endpoints/PartnershipApi';
import { RootStore } from '../RootStore';
import Partnership from './Partnership';
import { PartnershipDTO, PartnershipRequestDTO, PremiumPartnershipListingDTO } from './Partnership.types';
import PartnershipRequest from './PartnershipRequest';
import PremiumPartnershipListing from './PremiumPartnershipListing';
import { Trigger, TriggerType } from '../../Trigger.types';
import { Company } from '../company/Company';
import LiteEvent from '../../helpers/LiteEvent';

export type PartnerWithNameAndDistance = {
	partner: PartnershipDTO;
	name: string;
	distance: number;
	latitude: number;
	longitude: number;
	radiusKm: number;
	workspaceId: number;
	parentWorkspaceId?: number;
	parentWorkspaceName?: string;
	tags: string[];
};

export default class PartnershipManager {
	rootStore: RootStore;

	partnershipRequests: PartnershipRequest[] = [];
	partnerships: Partnership[] = [];
	premiumPartnershipListings: PremiumPartnershipListing[] = [];

	isInitialized = false;
	isLoading = false;

	private readonly onPartnershipUpdated = new LiteEvent<any>();

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, {
			rootStore: false,
		});

		this.rootStore = rootStore;

		this.initialize();
	}

	initialize() {
		if (this.isInitialized) {
			return;
		}

		this.isInitialized = true;

		this.rootStore.workspaceStore.WorkspaceLoaded.on(() => {
			this.refresh().catch((error) => {
				console.error('Failed to refresh partnership data', error);
			});
		});

		this.rootStore.userStore.SignedOut.on(() => {
			this.reset();
		});
	}

	get partnershipCompanies() {
		const currentWorkspaceId = this.rootStore.workspaceStore.workspace?.id;
		if (!currentWorkspaceId) {
			return [];
		}
		return this.partnerships
			.map((p) => {
				const partnerWorkspaceId = p.workspaceId1 === +currentWorkspaceId ? p.workspaceId2 : p.workspaceId1;
				const company = this.rootStore.companyStore.findCompanyByWorkspaceId(partnerWorkspaceId);
				return company;
			})
			.filter(Boolean) as Company[];
	}

	public get PartnershipUpdated() {
		return this.onPartnershipUpdated.expose();
	}

	async refresh() {
		this.reset();
		if (this.isLoading) {
			return;
		}
		this.isLoading = true;
		if (this.rootStore.workspaceStore.hasEmployeeAccess) {
			await this.fetchPartnerships();
			await this.fetchPartnershipRequests();
			await this.fetchPremiumPartnershipListings();
		}
		runInAction(() => {
			this.isLoading = false;
			this.onPartnershipUpdated.trigger();
		});
	}

	reset() {
		this.partnerships = [];
		this.partnershipRequests = [];
		this.premiumPartnershipListings = [];
	}

	processTrigger(trigger: Trigger<any>) {
		const { urn, event } = trigger;
		switch (urn) {
			case TriggerType.PARTNERSHIP_CREATED:
			case TriggerType.PARTNERSHIP_UPDATED:
				this.addOrUpdatePartnershipFromDTO(event.data);
				break;
			case TriggerType.PARTNERSHIP_DELETED:
				this.removePartnership(event.data.partnershipId);
				break;
			case TriggerType.PARTNERSHIP_REQUEST_CREATED:
			case TriggerType.PARTNERSHIP_REQUEST_UPDATED:
			case TriggerType.PARTNERSHIP_REQUEST_DELETED:
				this.addOrUpdatePartnershipRequestFromDTO(event.data);
				break;
			case TriggerType.PARTNERSHIP_PREMIUM_LISTING_CREATED:
			case TriggerType.PARTNERSHIP_PREMIUM_LISTING_UPDATED:
			case TriggerType.PARTNERSHIP_PREMIUM_LISTING_DELETED:
				this.addOrUpdatePremiumPartnershipListingFromDTO(event.data);
				break;
		}

		runInAction(() => {
			this.onPartnershipUpdated.trigger();
		});
	}

	async fetchPartnersWithDistanceFromAddress(addressId: number): Promise<PartnerWithNameAndDistance[]> {
		const response = await PartnershipApi.fetchPartnersWithDistanceFromAddress(addressId);
		if (response.statusCode === 200) {
			return response.data;
		}
		return [];
	}

	async fetchPartnerships() {
		const response = await PartnershipApi.getPartners();
		if (response.statusCode === 200) {
			response.data.forEach((partnership) => this.addOrUpdatePartnershipFromDTO(partnership));
		}
	}

	async fetchPartnershipRequests() {
		const response = await PartnershipApi.getPartnershipRequests();
		if (response.statusCode === 200) {
			response.data.forEach((partnershipRequest) =>
				this.addOrUpdatePartnershipRequestFromDTO(partnershipRequest)
			);
		}
	}

	async fetchPremiumPartnershipListings() {
		const response = await PartnershipApi.getPremiumPartnershipListings();
		if (response.statusCode === 200) {
			response.data.forEach((listing) => this.addOrUpdatePremiumPartnershipListingFromDTO(listing));
		}
	}

	async createRequest(request: PartnershipRequestDTO) {
		const response = await PartnershipApi.createRequest(request);
		if (response.statusCode === 200) {
			this.addOrUpdatePartnershipRequestFromDTO(response.data);
			return true;
		}
		return false;
	}

	addOrUpdatePartnershipFromDTO(partnership: PartnershipDTO) {
		const existingPartnership = this.partnerships.find((p) => p.partnershipId === partnership.partnershipId);
		if (existingPartnership) {
			existingPartnership.update(partnership);
		} else {
			const newPartnership = new Partnership(partnership);
			this.partnerships.push(newPartnership);
		}
	}

	addOrUpdatePartnershipRequestFromDTO(partnershipRequest: PartnershipRequestDTO) {
		const existingPartnershipRequest = this.partnershipRequests.find(
			(p) => p.requestId === partnershipRequest.requestId
		);
		if (existingPartnershipRequest) {
			existingPartnershipRequest.update(partnershipRequest);
		} else {
			const newPartnershipRequest = new PartnershipRequest(partnershipRequest);
			this.partnershipRequests.push(newPartnershipRequest);
		}
	}

	removePartnership(partnershipId: number) {
		const index = this.partnerships.findIndex((p) => p.partnershipId === partnershipId);
		if (index >= 0) {
			this.partnerships.splice(index, 1);
		}
	}

	addOrUpdatePremiumPartnershipListingFromDTO(listing: PremiumPartnershipListingDTO) {
		const existingListing = this.premiumPartnershipListings.find((l) => l.listingId === listing.listingId);
		if (existingListing) {
			existingListing.update(listing);
		} else {
			const newListing = new PremiumPartnershipListing(listing);
			this.premiumPartnershipListings.push(newListing);
		}
	}
}
