import { Theme, createTheme } from '@mui/material';

const defaultTheme = (baseTheme: Theme, options: any) => {
	const { isDarkMode } = options;

	const darkPalette = {
		mode: 'dark',
		background: {
			default: '#212121',
			paper: '#212121',
		},
		divider: 'rgba(255, 255, 255, 0.1)',
		error: {
			main: '#d44141',
		},
		info: {
			main: '#3a91bb',
		},
		primary: {
			main: '#e91e63',
		},
		secondary: {
			main: '#00bcd4',
		},
		success: {
			main: '#6eb351',
		},
		text: {
			primary: '#f6f6f6',
			secondary: '#00bcd4',
			disabled: 'rgba(23, 57, 99, 0.38)',
			// hint: 'rgba(23, 57, 99, 0.38)',
		},
		warning: {
			main: '#fdb714',
		},
	};

	const lightPalette = {
		background: {
			default: '#F8F7FF',
			paper: '#FFF',
		},
		divider: 'rgba(23, 57, 99, 0.12)',
		info: {
			main: '#3a91bb',
		},
		error: {
			main: '#D62828',
		},
		primary: {
			main: '#341863',
		},
		secondary: {
			main: '#FFB300',
		},
		success: {
			main: '#5DD9C1',
		},
		text: {
			primary: '#36382E',
			// secondary: '#E09D00',
			// disabled: 'rgba(23, 57, 99, 0.38)',
			// hint: 'rgba(23, 57, 99, 0.38)',
		},
		warning: {
			main: '#fdb714',
		},
	};

	const theme = createTheme(
		{
			...baseTheme,
			// overrides: {
			// 	MuiButton: {
			// 		containedPrimary: {
			// 			'backgroundColor': '#499167',
			// 			'&:hover': {
			// 				backgroundColor: '#407F5A',
			// 			},
			// 		},
			// 	},
			// 	MuiFab: {
			// 		primary: {
			// 			'backgroundColor': '#499167',
			// 			'&:hover': {
			// 				backgroundColor: '#407F5A',
			// 			},
			// 		},
			// 	},
			// },
			palette: options.isDarkMode ? darkPalette : lightPalette,
		},
		[isDarkMode]
	);

	return theme;
};

export default defaultTheme;
